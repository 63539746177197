




















































































































































































































































































































import { Toggle } from '@/app/components';
import { unitTransformations } from '@/app/constants';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationProvider } from 'vee-validate';
import { useDataModel } from '../composable';
import { DataType } from '../constants';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'NumericType',
    components: { Toggle, ValidationProvider },
    model: {
        prop: 'metadata',
        event: 'updateMetadata',
    },
    props: {
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        metadata: {
            type: Object,
            default: null,
        },
        isProposal: {
            type: Boolean,
            required: false,
        },
        initialMetadata: {
            type: Object,
            required: false,
        },
        model: {
            type: Object,
            required: true,
        },
        isFieldDraft: {
            type: Boolean,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        // changesToBeSaved: {
        //     type: Array,
        //     default: () => [],
        // },
        // savedChange: {
        //     type: Boolean,
        //     required: false,
        // },
    },
    setup(props, { emit }) {
        const copiedModel = computed(() => props.model);
        const activeMetadata = ref<any>(null);
        const { defineDatatypeMetadata } = useDataModel(copiedModel); // fixDatatypeMetadata, defineDatatypeMetadata, isDraft,

        // const unitKeys = Object.keys({ None: {}, 'Not relevant': {}, ...unitTransformations });

        const unitKeys = computed(() => Object.keys(unitTransformations));
        const sortedUnitKeys = computed(() => unitKeys.value.sort((a: any, b: any) => a.localeCompare(b)));
        const totalUnitKeys = computed(() => {
            // if (
            //     props.initialMetadata &&
            //     !isDraft.value &&
            //     !props.isProposal &&
            //     !readOnly.value &&
            //     !activeMetadata.measurementType
            // ) {
            //     return ['None', 'Not relevant'];
            // }
            return ['None', 'Not relevant', ...sortedUnitKeys.value];
        });

        // const totalUnitKeys = ['None', 'Not relevant', ...sortedUnitKeys.value];

        // const unitTransformationOptions = computed(() => {
        //     if (unitKeys.includes(activeMetadata.measurementType)) {
        //         return unitTransformations[activeMetadata.value.measurementType].options.sort((a: any, b: any) => {
        //             return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        //         });
        //     }
        //     return [];
        // });

        const sortedUnitTransformationOptions = computed(() => {
            if (activeMetadata.value?.measurementType && activeMetadata.value.measurementType !== 'Not relevant') {
                return unitTransformations[activeMetadata.value.measurementType].options.sort((a: any, b: any) =>
                    a.localeCompare(b),
                );
            }
            return [];
        });

        const setActiveMetadata = () => {
            if (props.metadata && !props.isProposal) {
                activeMetadata.value = R.clone(props.metadata);
            } else {
                activeMetadata.value = defineDatatypeMetadata(DataType.Integer); // DataType.Double is the same as Integer

                if (props.metadata && props.metadata.measurementType) {
                    // for when the user provides measurement type/ unit when suggesting a field
                    activeMetadata.value.measurementType = R.clone(props.metadata.measurementType);
                    activeMetadata.value.measurementUnit = R.clone(props.metadata.measurementUnit);
                }

                emit('updateMetadata', activeMetadata.value);
            }
        };
        setActiveMetadata();

        const updateMetadata = () => {
            // also disable "ordered" metadata if "multiple" is changed to false
            if (!activeMetadata.value.multiple && activeMetadata.value.ordered) {
                activeMetadata.value.ordered = false;
            }
            emit('updateMetadata', activeMetadata.value);
        };

        const inField = ref<boolean>(false);

        const disableMultiple = computed(
            () => (props.initialMetadata?.multiple && !props.isProposal && !props.isFieldDraft) || props.readOnly,
        );

        const disableOrdered = computed(
            () =>
                !activeMetadata.value.multiple ||
                (props.initialMetadata?.multiple &&
                    !props.initialMetadata?.ordered &&
                    !props.isProposal &&
                    !props.isFieldDraft) ||
                props.readOnly,
        );

        const disableSensitive = computed(
            () =>
                (props.initialMetadata &&
                    !props.initialMetadata?.sensitive &&
                    !props.isProposal &&
                    !props.isFieldDraft) ||
                props.readOnly,
        );

        const disableMetadataConditions = computed(
            () => props.initialMetadata && !props.isFieldDraft && !props.isProposal,
        );

        const disableIndexMongo = computed(
            () => props.readOnly || (disableMetadataConditions.value && !props.initialMetadata?.indexMongo),
        );

        const disableIndexES = computed(
            () => props.readOnly || (disableMetadataConditions.value && !props.initialMetadata?.indexES),
        );

        const enableMeasurementType = computed(
            () =>
                // eslint-disable-next-line no-useless-escape
                (props.initialMetadata && activeMetadata.value.measurementType === '\s*Not relevant\s*') ||
                (!activeMetadata.value.measurementType && activeMetadata.value.measurementUnit),
        );

        const resetMetadataMeasurements = () => {
            activeMetadata.value.measurementUnit = null;
            if (activeMetadata.value.measurementType === 'None') {
                activeMetadata.value.measurementType = null;
            }
            updateMetadata();
        };

        const showMeasurementUnit = computed(
            () =>
                (activeMetadata.value.measurementType ||
                    (!activeMetadata.value.measurementType && activeMetadata.value.measurementUnit)) &&
                activeMetadata.value.measurementType !== 'None',
        );

        const disableMeasurementUnit = computed(
            () =>
                props.initialMetadata &&
                !props.isFieldDraft &&
                !props.isProposal &&
                (activeMetadata.value.measurementType === 'Not relevant' || !activeMetadata.value.measurementType) &&
                activeMetadata.value.measurementUnit,
        );

        const enableFreeTextMeasurementUnit = computed(
            () =>
                ((!props.initialMetadata || props.isFieldDraft || props.isProposal) &&
                    activeMetadata.value.measurementType === 'Not relevant') ||
                (!activeMetadata.value.measurementType && activeMetadata.value.measurementUnit),
        );

        return {
            disableMultiple,
            disableOrdered,
            disableSensitive,
            updateMetadata,
            activeMetadata,
            showMeasurementUnit,
            disableMeasurementUnit,
            enableFreeTextMeasurementUnit,
            resetMetadataMeasurements,
            enableMeasurementType,
            unitKeys,
            inField,
            totalUnitKeys,
            sortedUnitTransformationOptions,
            disableIndexMongo,
            disableIndexES,
        };
    },
});
