<template>
    <div class="bg-white rounded shadow">
        <slot name="header" />
        <slot />
        <slot name="footer" />
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'Card',
});
</script>
