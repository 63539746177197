import sanitizeHtml from 'sanitize-html';

export const S = {
    has: (key: string, obj: any): boolean => {
        // eslint-disable-next-line no-prototype-builtins
        return obj && key && obj.hasOwnProperty(key);
    },
    formatCamelCase: (text: string): string => {
        const result = text.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    },
    capitalizeFirstLetter: (text: string): string => text.charAt(0).toUpperCase() + text.slice(1),
    escapeHtml: (str: string) =>
        str.replace(
            /[&<>'"]/g,
            (tag) =>
                ({
                    '&': '&amp;',
                    '<': '&lt;',
                    '>': '&gt;',
                    "'": '&#39;',
                    '"': '&quot;',
                }[tag] || tag),
        ),
    isMacOS: () => window.navigator.userAgent.indexOf('Mac OS') !== -1,
    sanitizeHtml: (input: string) => sanitizeHtml(input, { allowedAttributes: false, disallowedTagsMode: 'escape' }),
    fillWithZeros: (arg: number | string, length: number) => {
        let stringToFill = String(arg).trim();
        for (let i = 0; i < length - String(arg).length; i++) {
            stringToFill = `0${stringToFill}`;
        }
        return stringToFill;
    },
};
