export enum Aggregations {
    GROUP_BY = 'GroupBy',
}

export class AggregationFunction {
    public static SUM = new AggregationFunction('Sum', 'sum');

    public static MIN = new AggregationFunction('Min', 'min');

    public static MAX = new AggregationFunction('Max', 'max');

    public static AVG = new AggregationFunction('Avg', 'avg');

    public static COUNT = new AggregationFunction('Count', 'count');

    constructor(readonly label: string, readonly key: string) {
        this.label = label;
        this.key = key;
    }

    static all() {
        return [
            AggregationFunction.SUM,
            AggregationFunction.MIN,
            AggregationFunction.MAX,
            AggregationFunction.AVG,
            AggregationFunction.COUNT,
        ];
    }
}
