

























import * as R from 'ramda';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'PageHeaderTabs',
    model: {
        prop: 'currentTab',
        event: 'change',
    },
    props: {
        currentTab: { type: Object, default: null },
        tabs: { type: Array, required: true },
        idField: { type: String, default: 'id' },
        labelField: { type: String, default: 'label' },
        disabledField: { type: String, default: 'disabled' },
        countField: { type: String, default: 'count' },
    },
    setup(props, { emit }) {
        const getId = (tab: any) => {
            return tab[props.idField];
        };

        const getLabel = (tab: any) => {
            return tab[props.labelField];
        };

        const getDisabled = (tab: any) => {
            if (
                !R.has(props.disabledField, tab) ||
                R.isNil(tab[props.disabledField]) ||
                R.isEmpty(tab[props.disabledField])
            )
                return false;
            return tab[props.disabledField];
        };

        const getCount = (tab: any) => {
            return tab[props.countField];
        };

        const hasCount = (tab: any) => {
            return !R.isNil(tab[props.countField]) && !R.isEmpty(tab[props.countField]);
        };

        const change = (tab: any) => {
            const label = getLabel(tab);
            emit('change', label);
        };

        return { getId, getLabel, getDisabled, hasCount, getCount, change };
    },
});
