import { StatusCode } from '@/modules/asset/constants';
import { computed, Ref } from '@vue/composition-api';

export function useAssetStatus(status: Ref<StatusCode | undefined>) {
    const label = computed(() => {
        switch (status.value) {
            case StatusCode.Available:
                return 'Available';
            default:
                return status.value;
        }
    });

    const statusClass = {
        [StatusCode.Incomplete]: 'text-white bg-slateblue-600',
        [StatusCode.Uploading]: 'text-white bg-teal-600',
        [StatusCode.Updating]: 'text-white bg-orange-800',
        [StatusCode.Deleted]: 'text-white bg-cherry-900',
        [StatusCode.Available]: 'text-white bg-green-800',
        [StatusCode.Archived]: 'text-white bg-slate-500', // for model assets
    };

    const colour = computed(() => (status.value ? statusClass[status.value] : 'text-yellow-700 bg-yellow-100'));

    const icon = computed(() => null);

    const iconColour = computed(() => {
        switch (status.value) {
            case StatusCode.Incomplete:
                return 'text-yellow-700';
            case StatusCode.Uploading:
                return 'text-purple-700';
            case StatusCode.Updating:
                return 'text-blue-700';
            case StatusCode.Deleted:
                return 'text-red-700';
            case StatusCode.Available:
            default:
                return 'text-yellow-700';
        }
    });

    return { label, colour, icon, iconColour, statusClass };
}
