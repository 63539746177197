<template>
    <div
        :class="[{ 'rounded-full': rounded }, { indeterminate: indeterminate }, height]"
        class="relative w-full overflow-hidden bg-neutral-300"
    >
        <div
            :aria-valuenow="percentage"
            :class="[`${color}`, { 'absolute top-0': indeterminate }, { 'rounded-full': rounded }]"
            :style="{ width: `${percentage}%` }"
            aria-valuemax="100"
            aria-valuemin="0"
            class="h-full progressbar"
            role="progressbar"
        >
            <span class="flex items-center h-full">
                <slot></slot>
            </span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ProgressBar',
    inheritAttrs: false,
    props: {
        color: {
            type: String,
            default: 'bg-primary-500',
        },
        percentage: {
            type: Number,
            default: 0,
        },
        rounded: {
            type: Boolean,
            default: true,
        },
        indeterminate: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: 'h-2',
        },
    },
});
</script>

<style scoped>
@keyframes progress-indeterminate {
    0% {
        width: 30%;
        left: -40%;
    }
    60% {
        left: 100%;
        width: 100%;
    }
    to {
        left: 100%;
        width: 0;
    }
}

.progressbar {
    transition: width 0.25s ease;
}

.indeterminate .progressbar {
    animation: progress-indeterminate 1.4s ease infinite;
}
</style>
