































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import VueTagsInput from '@johmun/vue-tags-input';
import { Status } from '../constants';
import { LinkIcon } from '@vue-hero-icons/outline';
import ChangeIndication from './ChangeIndication.vue';

export default defineComponent({
    name: 'ConceptOverview',
    components: { ChangeIndication, VueTagsInput, LinkIcon },
    props: {
        concept: {
            type: Object,
            required: true,
        },
        isProposal: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        modelStatus: {
            type: String,
            required: true,
        },
        changesToBeSaved: {
            type: Boolean,
            default: false,
        },
        savedConcepts: {
            type: Array,
            default: () => [],
        },
    },
    setup(props: any, { emit }) {
        const tag = ref('');
        const tags = computed(() => {
            const tmpArray = [] as any;

            if (props.concept.relatedTerms) {
                props.concept.relatedTerms.forEach((r: any) => tmpArray.push({ text: r }));
            }
            return tmpArray;
        });

        const dataType = computed(() => (props.concept.type === 'base64binary' ? 'binary' : props.concept.type));

        const deprecatedField = computed(
            () => props.concept.status === Status.Deprecated && props.modelStatus !== Status.Deprecated,
        );
        const newField = computed(
            () => props.concept.status === Status.Draft && props.modelStatus === Status.UnderRevision,
        );

        const unsavedChange = computed(() => {
            let unsaved = null;
            if (props.concept && props.savedConcepts.includes(props.concept.uid)) unsaved = false;
            if (props.selected && props.changesToBeSaved) unsaved = true;
            return unsaved;
        });

        const includesLongRelatedTerm = computed(
            () => !!props.concept?.relatedTerms?.find((rt: string) => rt.length > 35),
        );

        return {
            emit,
            tag,
            tags,
            Status,
            dataType,
            deprecatedField,
            newField,
            unsavedChange,
            includesLongRelatedTerm,
        };
    },
});
