

















import { defineComponent } from '@vue/composition-api';

export default defineComponent({ name: 'FakeWizardTabs', props: { steps: { type: Number, default: 2 } } });
