export enum VisualisationOptionsDescription {
    MainTitle = 'Text to display as a title of chart.',
    AxisTitle = 'Text to display as a title of axis.',
    FontSize = 'Font size of the text.',
    Description = 'Text to display as a description of the chart.',
    MarkersSize = 'Size of the marker point.',
    Labels = 'Determine whether to show data labels or not.',
    Grid = 'Determine whether to show a grid area (including x-axis / y-axis)',
    Legend = 'Determine whether to show the legend container and adjust its position.',
    VerticalOffset = 'Sets the top offset for legend container.',
    YLabelVerticalOffset = 'Sets the vertical offset for y-axis labels.',
    YLabelHorizontalOffset = 'Sets the horizontal offset for y-axis labels.',
    Filters = 'Apply filters on data input.',
    Ticks = 'Draw ticks on the axis to specify intervals.',
    Crosshairs = 'Show crosshairs on axis when the user moves the mouse over chart area.',
    Tooltip = 'Show a tooltip when the user hovers over the chart area.',
    NumberOfDecimals = 'The number of fractions to display when there are floating values in y-axis.',
    SeriesField = 'Field name to retrieve data from.',
    AggregationFunction = 'Function used to calculate an aggregation.',
    SeriesTypeSection = 'Data type of x-axis.',
    GroupByField = 'Field name to group data by.',
    BucketInterval = 'Amount of values to apply the aggregation function.',
    BucketUnit = 'Unit used to group the datetime values.',
    BinSize = 'Number of buckets to apply the aggregation.',
    MaximumReturnedValues = 'Determine maximum number of results to be shown in visualisation.',
    HeatmapColors = "Determine whether to use single or multiple color for values' ranges.",
    HeatmapNegatives = 'When enabled, it will reverse the shades for negatives but keep the positive shades as it is. Negative values will have darker shades.',
    HeatmapInverse = 'In a multiple series heatmap, flip the color-scale to fill the heatmaps vertically instead of horizontally.',
    HeatmapFillborders = 'Determine whether the stroke/border around the heatmap cell has the same color as the cell color.',
    HeatmapDistributedRanges = "Determine whether each row in a heatmap will have it's own lowest and highest range and colors will be shaded for each series.",
}
