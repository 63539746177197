














































































































































































import { useAxios, useDuration } from '@/app/composable';
import * as R from 'ramda';
import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import { XCircleIcon, ClockIcon, CheckCircleIcon } from '@vue-hero-icons/outline';
import { WeatherDynamicIcon } from '@/app/components';
import { MonitoringExecutionMetrics, MonitoringExecutionQuery } from '@/app/types';
import { MonitoringAPI } from '@/app/api';
import { WorkflowType } from '@/app/constants';
import { ExecutionStatus } from '@/modules/workflow-designer/constants';

export default defineComponent({
    name: 'IncidentSummary',
    props: {
        query: { type: Object as PropType<MonitoringExecutionQuery>, required: true },
        pipelineType: {
            type: String,
            default: () => WorkflowType.DataCheckin,
            validator: function (value: WorkflowType) {
                return Object.values(WorkflowType).includes(value);
            },
        },
        showProblematic: { type: Boolean, default: false },
    },
    components: { SelfBuildingSquareSpinner, XCircleIcon, WeatherDynamicIcon, ClockIcon, CheckCircleIcon },
    setup(props: any, { root, emit }: { root: any; emit: any }) {
        const { exec, loading } = useAxios(true);
        const problematicStatuses = [ExecutionStatus.Failed, ExecutionStatus.Skipped, ExecutionStatus.Cancelled];
        // const loading = true;
        const { formatToHighestMetric } = useDuration();
        const metrics: Ref<MonitoringExecutionMetrics> = ref<MonitoringExecutionMetrics>({
            totalExecutions: 0,
            successfulExecutions: 0,
            failedExecutions: 0,
            problematicExecutions: undefined,
            avgExecutionTime: 0,
            avgSuccessfulExecutionTime: 0,
            avgFailedExecutionTime: 0,
            activePipelines: 0,
            failedPipelines: 0,
            resolvedPipelines: 0,
        });

        const fetchMetrics = async (query: MonitoringExecutionQuery) => {
            await exec(MonitoringAPI.executionMetrics(query, props.pipelineType))
                .then((res: any) => {
                    metrics.value = res.data.results;
                    emit('set-facets', res.data.facets);
                })
                .catch((e) => {
                    (root as any).$toastr.e(e.response.data.message, 'Error');
                    throw e;
                });
        };

        const problematicExecutions = computed(() =>
            R.has('problematicExecutions', metrics.value) && !R.isNil(metrics.value.problematicExecutions)
                ? metrics.value.problematicExecutions
                : undefined,
        );

        const failedExecutionsPercent = computed(() =>
            metrics.value.totalExecutions > 0
                ? Math.round((metrics.value.failedExecutions / metrics.value.totalExecutions) * 100)
                : 0,
        );

        const problematicExecutionsPercent = computed(() => {
            if (!R.isNil(problematicExecutions.value))
                return metrics.value.totalExecutions > 0
                    ? Math.round((problematicExecutions.value / metrics.value.totalExecutions) * 100)
                    : 0;
            return undefined;
        });

        const executionTime = computed(() => formatToHighestMetric(metrics.value.avgExecutionTime * 1000));

        const failedPipelines = computed(() =>
            metrics.value && metrics.value.failedPipelines && R.is(Number, metrics.value.failedPipelines)
                ? metrics.value.failedPipelines
                : 0,
        );

        const resolvedPipelines = computed(() =>
            metrics.value && metrics.value.resolvedPipelines && R.is(Number, metrics.value.resolvedPipelines)
                ? metrics.value.resolvedPipelines
                : 0,
        );

        const activePipelines = computed(() => R.pathOr(0, ['value', 'activePipelines'], metrics));
        const failedExecutions = computed(() => R.pathOr(0, ['value', 'failedExecutions'], metrics));
        const totalExecutions = computed(() => R.pathOr(0, ['value', 'totalExecutions'], metrics));

        const selectedProblematicStatuses = computed(() =>
            problematicStatuses.filter((status: string) => props.query.facets.status.includes(status)),
        );

        watch(
            () => props.query,
            (query: MonitoringExecutionQuery, oldQuery: MonitoringExecutionQuery | undefined) => {
                if (!oldQuery || !R.equals({ ...oldQuery, sortBy: undefined }, { ...query, sortBy: undefined }))
                    fetchMetrics(query);
            },
            { deep: true, immediate: true },
        );

        watch(
            () => loading.value,
            (l: boolean) => emit('loading', l),
            { immediate: true },
        );

        return {
            failedPipelines,
            resolvedPipelines,
            activePipelines,
            failedExecutions,
            totalExecutions,
            metrics,
            loading,
            executionTime,
            failedExecutionsPercent,
            problematicExecutionsPercent,
            problematicExecutions,
            selectedProblematicStatuses,
            formatToHighestMetric,
        };
    },
});
