





























































import * as R from 'ramda';
import { computed, defineComponent, ref } from '@vue/composition-api';
import ConfirmButton from './ConfirmButton.vue';

export default defineComponent({
    name: 'DropdownMenu',
    props: {
        items: {
            type: Array,
            required: true,
        },
        dropdownClasses: {
            type: Array,
            default: () => ['w-48', 'right-0'],
        },
        selected: {
            type: String,
            default: null,
        },
    },
    components: { ConfirmButton },
    setup(props, { root }) {
        const isOpen = ref(false);

        const processedItems = computed((): {
            name: string;
            requiresConfirm: boolean;
            action: any;
            once?: boolean;
        }[] => {
            const updatedItems: { name: string; requiresConfirm: boolean; action: any }[] = [];
            props.items.forEach((option: any) => {
                updatedItems.push({
                    ...option,
                    name: option.name,
                    requiresConfirm: R.hasIn('requiresConfirm', option) ? option.requiresConfirm : false,
                    action: option.action,
                });
            });
            return updatedItems;
        });

        const toggle = () => {
            isOpen.value = !isOpen.value;
        };

        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                isOpen.value = false;
            }
        };

        const actionClicked = (itemIdx: number) => {
            const item: { name: string; requiresConfirm: boolean; action: any } = processedItems.value[itemIdx];
            item.action();
            isOpen.value = false;
        };

        document.addEventListener('keydown', handleEscape);
        root.$once('hook:beforeDestroy', () => {
            document.removeEventListener('keydown', handleEscape);
        });

        return { isOpen, toggle, actionClicked, processedItems };
    },
});
