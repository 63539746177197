import { computed, ref, Ref } from '@vue/composition-api';
import { PaginateQuery, PaginationOptions } from '../interfaces';
import { useQueryParams } from './query-params';

export function usePagination(root: any, options: PaginationOptions) {
    const page = ref<number>(options.page ? options.page : 1);
    const pageSize = ref<number>(options.pageSize ? options.pageSize : 10);
    const total = ref<Ref<Number> | number>(options.total ? options.total : 0);
    const queryParam = ref<string>(options.queryParam ? options.queryParam : 'page');

    const { set, get } = useQueryParams(root, root.$router, root.$route.name);

    const currentPage: Ref<number> = computed({
        get: () => parseInt(get(queryParam.value, false, page.value), 10),
        set: (newView: number) => set(queryParam.value, newView, page.value),
    });

    const handleChangePage = (newPage: number) => (currentPage.value = newPage);

    const pagination = computed(() => {
        const query: PaginateQuery = {
            page: currentPage.value,
            pageSize: pageSize.value,
            total: total.value,
        };

        return query;
    });

    return { pagination, handleChangePage };
}
