import { FeatureFlagType } from '../types';

export enum Scope {
    Retrieve = 'Retrieve',
    Upload = 'Upload',
    Monitor = 'Monitor',
    Execute = 'Execute',
}

export const AccessTokenScope: Record<
    Scope,
    {
        name: string;
        description: string;
        features: FeatureFlagType[];
    }
> = {
    [Scope.Retrieve]: {
        name: Scope.Retrieve.toLowerCase(),
        description: 'Retrieve the data of one or multiple assets',
        features: ['retrieve.api', 'retrieve.files', 'retrieve.stream'],
    },
    [Scope.Upload]: {
        name: Scope.Upload.toLowerCase(),
        description: "Upload your data to the Platform's API",
        features: ['harvester.platform-api'],
    },
    [Scope.Monitor]: {
        name: Scope.Monitor.toLowerCase(),
        description: 'Monitor the execution status of a pipeline',
        features: ['harvester.platform-api'],
    },
    [Scope.Execute]: {
        name: Scope.Execute.toLowerCase(),
        description: 'Trigger execution of an analytics pipeline',
        features: ['analytics'],
    },
};

export const tokenScopes = Object.values(AccessTokenScope);
