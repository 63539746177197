

















































import { useMonitoringPeriod } from '@/app/composable';
import { computed, defineComponent } from '@vue/composition-api';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DateTimePicker } from '..';
dayjs.extend(utc);

export default defineComponent({
    name: 'MonitoringTimeRangeFilter',
    props: {
        period: { type: String, required: true },
        start: { type: Date, required: false },
        end: { type: Date, required: false },
    },
    components: { DateTimePicker },
    setup(props, { emit }) {
        const { listAll: timePeriodsArray, findByKey } = useMonitoringPeriod();
        const customStart = computed({
            get: () => (props.start ? dayjs.utc(props.start).toISOString() : props.start),
            set: (newStart: any) => {
                emit('change', props.period, newStart, props.end);
            },
        });

        const customEnd = computed({
            get: () => (props.end ? dayjs.utc(props.end).toISOString() : props.end),
            set: (newEnd: any) => {
                emit('change', props.period, props.start, newEnd);
            },
        });

        const currentPeriodObject = computed(() => findByKey(props.period));
        const timePeriodChange = (timePeriodKey: any) => {
            const tp = findByKey(timePeriodKey);
            if (tp.isCustom) {
                emit(
                    'change',
                    timePeriodKey,
                    findByKey(props.period).start().toISOString(),
                    findByKey(props.period).end().toISOString(),
                );
            } else {
                emit('change', timePeriodKey, tp.start(), tp.end());
            }
        };

        return { currentPeriodObject, customStart, customEnd, timePeriodsArray, timePeriodChange };
    },
});
