import { UserRoles } from '@/app/constants/user-roles';
import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';
import { clone, includes } from 'ramda';

export interface AuthState {
    user: any;
}

const authModule = defineModule({
    namespaced: true,
    state: (): AuthState => {
        return {
            user: null,
        };
    },
    mutations: {
        CLEAR_USER(state: AuthState) {
            state.user = null;
        },
        SET_USER(state: AuthState, newUser: any) {
            state.user = clone(newUser);
        },
    },
    getters: {
        isAuthenticated: (state: AuthState) => !!state.user,
        hasRole: (state: AuthState) => (role: UserRoles) => includes(role, state.user.roles),
    },
});

export default authModule;
export const authModuleActionContext = (context: any) => moduleActionContext(context, authModule);
