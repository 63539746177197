














































import { useModelBrowser } from '@/app/composable';
import { Concept, Model } from '@/app/interfaces';
import { ChevronRightIcon } from '@vue-hero-icons/outline';
import { PropType, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ModelPath',
    props: {
        model: { type: Object as PropType<Model>, required: true },
        path: { type: Array as PropType<Concept[]>, required: true },
        selectedFields: { type: Boolean, default: false },
    },
    components: { ChevronRightIcon },
    setup() {
        const { getConceptFromId } = useModelBrowser();

        return { getConceptFromId };
    },
});
