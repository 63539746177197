import { AxiosRequestConfig } from 'axios';

const resource = '/api/notification';

export default {
    fetch: (page?: number, pageSize?: number) =>
        ({ method: 'GET', url: `${resource}`, params: { page, pageSize } } as AxiosRequestConfig),
    fetchOne: (id: number) => ({ method: 'GET', url: `${resource}/${id}` } as AxiosRequestConfig),
    markAsSeen: (id: number) => ({ method: 'PATCH', url: `${resource}/${id}/seen` } as AxiosRequestConfig),
    markAllAsSeen: () => ({ method: 'PATCH', url: `${resource}/seen` } as AxiosRequestConfig),
    delete: (id: number) => ({ method: 'DELETE', url: `${resource}/${id}` } as AxiosRequestConfig),
};
