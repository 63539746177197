import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/mqtt';

export default {
    testCredentialsAndCreateSample: (data: any, fileType: string) =>
        ({
            method: 'POST',
            url: `${endpoint}/user/consume/${fileType}`,
            data,
        } as AxiosRequestConfig),
};
