



































import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
    name: 'MonitoringTables',
    model: { prop: 'current', event: 'change' },
    props: {
        current: { type: String, default: null },
        tables: { type: Array as PropType<{ key: string; label: string }[]>, default: () => [] },
    },
    setup(props, { emit }) {
        const currentTable = computed(() =>
            props.tables.find((t: { key: string; label: string }) => t.key === props.current),
        );

        const currentKey = computed({
            get: () => props.current,
            set: (newCurrent: string) => emit('change', newCurrent),
        });

        return { currentTable, currentKey };
    },
});
