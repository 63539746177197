



























import { Concept, Model } from '@/app/interfaces';
import { ModelsAPI } from '@/modules/data-model/api';
import { SuggestionModal } from '@/modules/data-model/components';
import { useSuggestions } from '@/modules/data-model/composable';
import { PropType, computed, defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
    name: 'ConceptSuggestion',
    props: {
        model: { type: Object as PropType<Model>, required: true },
        concepts: { type: Array as PropType<Concept[]>, required: true },
        inline: { type: Boolean, default: false },
    },
    components: { SuggestionModal },
    setup(props, { root }) {
        const model = computed(() => props.model);
        const concepts = computed(() => props.concepts);
        const { showModal: showSuggestionModal, initConcept, domainConcepts, domainStandards } = useSuggestions(
            model,
            concepts,
        );
        const newSuggestion = ref<any>(null);

        const createSuggestion = () => {
            newSuggestion.value = initConcept();
            showSuggestionModal.value = true;
        };

        const saveSuggestion = async () => {
            try {
                await ModelsAPI.createSuggestion(newSuggestion.value);
                showSuggestionModal.value = false;
                (root as any).$toastr.s('Suggestion submitted successfuly');
            } catch {
                (root as any).$toastr.e('Error submitting suggestion');
            }
        };

        return {
            createSuggestion,
            saveSuggestion,
            domainConcepts,
            domainStandards,
            showSuggestionModal,
            newSuggestion,
        };
    },
});
