

























import { defineComponent } from '@vue/composition-api';
import Tab from './Tab.vue';

export default defineComponent({
    name: 'BaseTabs',
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        breadcrumbMode: {
            type: Boolean,
            default: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    components: { Tab },
    setup(props, { emit }) {
        const tabClicked = (tabIndex: number) => {
            emit('click', tabIndex);
        };
        return { tabClicked };
    },
});
