<template>
    <treeselect
        :value="context.model ? context.model : null"
        :options="context.options"
        v-bind="context.attributes"
        @input="context.attributes.input"
        @select="context.attributes.select"
    />
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';

export default {
    components: {
        Treeselect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
};
</script>
