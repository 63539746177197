import * as views from '@/app/views';
import alertRoutes from '@/modules/alert/router';
import apolloRoutes from '@/modules/apollo/router';
import assetRoutes from '@/modules/asset/router';
import { AuthGuard } from '@/modules/auth/router/auth.guard';
import { OrganisationGuard } from '@/modules/auth/router/organisation.guard';
import dataModelRoutes from '@/modules/data-model/router';
import notificationRoutes from '@/modules/notification/router';
import organizationRoutes from '@/modules/organization/router';
import retrievalRoutes from '@/modules/retrieval/router';
import userRoutes from '@/modules/user/router';
import workflowRoutes from '@/modules/workflow-designer/router';
import { VueConstructor } from 'vue';
import multiguard from 'vue-router-multiguard';

const routes = [
    {
        name: 'home',
        path: '/',
        component: views.Home as VueConstructor<Vue>,
        meta: { title: 'Home' },
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: views.Dashboard as VueConstructor<Vue>,
        meta: { title: 'Dashboard' },
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'about',
        path: '/about',
        component: views.About as VueConstructor<Vue>,
        meta: { title: 'About' },
    },
    {
        name: '500',
        path: '/500',
        component: views.Error500 as VueConstructor<Vue>,
        meta: { title: '500' },
    },
    {
        name: '503',
        path: '/503',
        component: views.Error503 as VueConstructor<Vue>,
        meta: { title: '503' },
    },
    {
        name: '404',
        path: '/404',
        component: views.Error404 as VueConstructor<Vue>,
        meta: { title: '404' },
    },
    ...apolloRoutes,
    ...assetRoutes,
    ...userRoutes,
    ...organizationRoutes,
    // ...searchRoutes,
    ...dataModelRoutes,
    ...notificationRoutes,
    ...workflowRoutes,
    ...retrievalRoutes,
    ...alertRoutes,
    // 404 route must be last
    {
        path: '*',
        redirect: { name: '404' },
    },
];

export default routes;
