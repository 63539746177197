import { useAxios } from '@/app/composable';
import { Ref, computed, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { WindowEvent } from '../constants';
import { PaginationOptions } from '../interfaces';
import { ESearchQuery, ESortingOption } from '../types';
import { FacetFilterType, useFacetsFilters } from './facets-filters';
import { useQueryParams } from './query-params';
import { useRouter } from './router';
import { useWindowEventListeners } from './window-event-listeners';

export function useSearch(
    root: any,
    routeName: string,
    pageSize: number,
    sortingClass: typeof ESortingOption,
    defaultTab: string,
    setSearchQuery: Function,
    search: Function,
    uriList: string[] = [],
) {
    const commonQueryParams = ['tab', 'search', 'field', 'partialMatch', 'caseSensitive', 'sortBy', 'page'];

    const router = useRouter();
    const { exec, loading, error } = useAxios(true);
    const { get, setMany } = useQueryParams(root, router, routeName);
    const { getValue, setFacetsFilters, capitalize, defaultValue } = useFacetsFilters();

    const textNumberFilters = ref<any>({});

    // Query parameters
    const page = ref<number>(get('page') ? parseInt(get('page'), 10) : 1);
    const query: Ref<ESearchQuery> = ref({
        input: { text: get('search') ? get('search') : '*', field: !R.isNil(get('field')) ? get('field') : undefined },
        settings: {
            caseSensitive: get('caseSensitive') ? (get('caseSensitive') === 'true' ? true : false) : false,
            partialMatch: get('partialMatch') ? (get('partialMatch') === 'true' ? true : false) : true,
        },
    });
    const isRelevanceAvailable = (queryText: string): boolean =>
        !R.isEmpty(queryText.trim()) && queryText.trim() !== '*';
    const sortBy = ref(
        get('sortBy') ? get('sortBy') : sortingClass.default(isRelevanceAvailable(query.value.input.text)).label,
    );
    const tab = ref<any>(get('tab') ? get('tab') : defaultTab);
    const sortingFields: Ref<ESortingOption[]> = computed(() =>
        sortingClass.available(isRelevanceAvailable(query.value.input.text)),
    );

    const sortByObject: any = sortingClass.findByLabel(sortBy.value, isRelevanceAvailable(query.value.input.text));
    const searchQuery: Ref<{
        query: ESearchQuery;
        facets: Record<string, string[]>;
        filters: Record<string, any>;
        pagination: PaginationOptions;
        sortBy: { field: string; asc: boolean };
        pipelineType?: string;
        assetTypeId?: number;
        settings: {
            organisationId: number | null | undefined;
            userId?: number | null | undefined;
            excludeSchema?: boolean | undefined;
        };
    }> = ref<any>({
        query: {
            input: { text: query.value.input.text ? query.value.input.text : '*', field: query.value.input.field },
            settings: query.value.settings,
        },
        facets: {},
        filters: {},
        pagination: { page: page.value, size: pageSize },
        sortBy: { field: sortByObject.field, asc: sortByObject.asc },
        settings: { organisationId: null },
    });
    const queryParams = ref<any>({});

    /**
     * Sets the page query parameter
     * @param value The page value
     */
    const setPage = (value: number) => {
        page.value = value;
        queryParams.value['page'] = { value, default: 1 }; // default is 1
    };

    /**
     * Sets the caseSensitive query parameter
     * @param value The caseSensitive value
     */
    const setCaseSensitive = (value: boolean) => {
        query.value.settings.caseSensitive = value;
        queryParams.value['caseSensitive'] = { value, default: false }; // default is false
    };

    /**
     * Sets the partialMatch query parameter
     * @param value The partialMatch value
     */
    const setPartialMatch = (value: boolean) => {
        query.value.settings.partialMatch = value;
        queryParams.value['partialMatch'] = { value, default: true }; // default is true
    };

    /**
     * Sets the tab query parameter, adjusts facets, filters and query parameters, sets page to 1
     * and executes search
     * @param value The tab value
     */
    const setTab = (value: string) => {
        tab.value = value;
        queryParams.value['tab'] = { value, default: defaultTab };
        setSearchQuery();
        removeUnusedQueryParams();
        addMissingFacetQueryParams();
        addMissingFilterQueryParams();
        setPage(1);
        search();
    };

    /**
     * Sets the common search query values (pagination page, query text, query settings)
     */
    const setCommonSearchQueryValues = () => {
        searchQuery.value.pagination.page = page.value;
        searchQuery.value.query.input.text = query.value.input.text ? query.value.input.text : '*';
        searchQuery.value.query.input.field = query.value.input.field;

        if (!query.value.input.text) {
            query.value.settings = { caseSensitive: false, partialMatch: true };
            setCaseSensitive(false);
            setPartialMatch(true);
        }

        searchQuery.value.query.settings = query.value.settings;
    };

    /**
     * Sets the page query parameter and executes search
     */
    const updateCurrentPage = () => {
        queryParams.value['page'] = { value: page.value, default: 1 }; // default is 1
        search();
    };

    /**
     * Sets the search query facet value, the facet query parameter, sets page to 1 and executes search
     * @param facetKey The facet key
     * @param facetValue The facet value
     * @param facetType The facet type
     */
    const facetChanged = (facetKey: string, facetValue: any, facetType: FacetFilterType) => {
        setFacetsFilters(searchQuery.value.facets, facetKey, facetValue, facetType);
        queryParams.value[facetKey] = { value: getValue(searchQuery.value.facets, facetKey, facetType) };
        setPage(1);
        search();
    };

    /**
     * Sets the search query filter value, the filter query parameter, sets page to 1 and executes search
     * @param filterKey The filter key
     * @param filterValue The filter value
     * @param filterType The filter type
     * @param minOrMax Defined only when filter is "date" or "datetime", can be "min" or "max"
     */
    const filterChanged = (filterKey: string, filterValue: any, filterType: FacetFilterType, minOrMax = '') => {
        setFacetsFilters(searchQuery.value.filters, filterKey, filterValue, filterType, minOrMax);
        if ([FacetFilterType.Text, FacetFilterType.Number].includes(filterType))
            textNumberFilters.value[filterKey] = searchQuery.value.filters[filterKey].id;
        queryParams.value[`${filterKey}${capitalize(minOrMax)}`] = {
            value: getValue(searchQuery.value.filters, filterKey, filterType, minOrMax),
        };
        setPage(1);
        search();
    };

    /**
     * Removes query parameters that do not exist in search query's facets and filters
     */
    const removeUnusedQueryParams = () => {
        Object.keys(root.$route.query).forEach((param: string) => {
            if (
                !commonQueryParams.includes(param) &&
                !Object.keys(searchQuery.value.facets).includes(param) &&
                Object.keys(searchQuery.value.filters).every(
                    (key: string) => param !== key && param !== `${key}Min` && param !== `${key}Max`,
                )
            )
                queryParams.value[param] = { value: null };
        });
    };

    /**
     * Adds missing query parameters that exist in search query's facets
     */
    const addMissingFacetQueryParams = () => {
        Object.keys(searchQuery.value.facets).forEach((facetKey: string) => {
            if (!Object.keys(root.$route.query).includes(facetKey) && searchQuery.value.facets[facetKey].length > 0) {
                let facetValues = searchQuery.value.facets[facetKey];
                if (uriList.includes(facetKey))
                    facetValues = facetValues.map((value: any) => encodeURIComponent(value));
                queryParams.value[facetKey] = { value: facetValues.join(',') };
            }
        });
    };

    /**
     * Adds missing query parameters that exist in search query's filters
     */
    const addMissingFilterQueryParams = () => {
        Object.keys(searchQuery.value.filters).forEach((filterKey: string) => {
            switch (R.type(searchQuery.value.filters[filterKey])) {
                case 'Object':
                    if (
                        searchQuery.value.filters[filterKey].min &&
                        !Object.keys(root.$route.query).includes(`${filterKey}Min`)
                    )
                        queryParams.value[`${filterKey}Min`] = {
                            value: searchQuery.value.filters[filterKey].min.toISOString(),
                        };
                    else if (
                        searchQuery.value.filters[filterKey].max &&
                        !Object.keys(root.$route.query).includes(`${filterKey}Max`)
                    )
                        queryParams.value[`${filterKey}Max`] = {
                            value: searchQuery.value.filters[filterKey].max.toISOString(),
                        };
                    else if (
                        !R.isNil(searchQuery.value.filters[filterKey].id) &&
                        !Object.keys(root.$route.query).includes(filterKey)
                    )
                        queryParams.value[filterKey] = { value: searchQuery.value.filters[filterKey].id };
                    break;
                case 'Array':
                    if (
                        !Object.keys(root.$route.query).includes(filterKey) &&
                        searchQuery.value.filters[filterKey].length > 0
                    )
                        queryParams.value[filterKey] = { value: searchQuery.value.filters[filterKey].join(',') };
                    break;
                default:
                    if (
                        !Object.keys(root.$route.query).includes(filterKey) &&
                        !R.isNil(searchQuery.value.filters[filterKey])
                    )
                        queryParams.value[filterKey] = { value: searchQuery.value.filters[filterKey] };
            }
        });
    };

    /**
     * Sets the sortBy query parameter and executes search
     * @param label The label of sortBy option
     */
    const sortByChanged = (label: string, forceSearch: boolean = true) => {
        const sortObject: any = sortingClass.findByLabel(label, isRelevanceAvailable(query.value.input.text));

        sortBy.value = sortObject.label;
        searchQuery.value.sortBy = { field: sortObject.field, asc: sortObject.asc };

        queryParams.value['sortBy'] = {
            value: sortObject.label,
            default: sortingClass.default(isRelevanceAvailable(query.value.input.text)).label,
        }; // default is Date updated descending
        if (forceSearch) search();
    };

    /**
     * Clears all search query's facets and filters and the respective query parameters, sets page to 1
     * and executes search
     */
    const clearFilters = () => {
        Object.keys(searchQuery.value.facets).forEach((facet: any) => {
            searchQuery.value.facets[facet].splice(0);
            queryParams.value[facet] = { value: searchQuery.value.facets[facet].join(',') };
        });
        Object.keys(searchQuery.value.filters).forEach((filter: any) => {
            switch (R.type(searchQuery.value.filters[filter])) {
                case 'Object':
                    if (Object.keys(searchQuery.value.filters[filter]).includes('id')) {
                        if (Object.keys(textNumberFilters.value).includes(filter))
                            textNumberFilters.value[filter] = null;
                        searchQuery.value.filters[filter].id = null;
                        queryParams.value[filter] = { value: null };
                    } else if (
                        Object.keys(searchQuery.value.filters[filter]).includes('min') &&
                        Object.keys(searchQuery.value.filters[filter]).includes('max')
                    ) {
                        searchQuery.value.filters[filter] = { min: null, max: null };
                        queryParams.value[`${filter}Min`] = { value: null };
                        queryParams.value[`${filter}Max`] = { value: null };
                    }
                    break;
                case 'Array':
                    searchQuery.value.filters[filter].splice(0);
                    queryParams.value[filter] = { value: searchQuery.value.filters[filter].join(',') };
                    break;
                default:
                    searchQuery.value.filters[filter] = null;
                    queryParams.value[filter] = { value: null };
            }
        });
        setPage(1);
        search();
    };

    const onEnter = () => {
        setQueryInputInQueryParameters(query.value.input);

        setPage(1);
        search();
    };

    /**
     * Clears query's text and settings, clears the search query parameter, sets page to 1
     * and executes search
     */
    const clearSearch = () => {
        query.value = { input: { text: '' }, settings: { caseSensitive: false, partialMatch: true } };
        setQueryInputInQueryParameters(query.value.input);

        setPage(1);
        search();
    };

    /**
     * Adjusts search query's facets based on query parameters
     */
    const adjustFacets = () => {
        for (const key of Object.keys(searchQuery.value.facets)) {
            if (R.type(searchQuery.value.facets[key]) === 'Array') {
                const prevValues = R.clone(searchQuery.value.facets[key]);
                const currValues = defaultValue(get, key);
                // remove previous values that are not included anymore
                prevValues.forEach((prevValue: any) => {
                    if (!currValues.includes(prevValue))
                        searchQuery.value.facets[key].splice(searchQuery.value.facets[key].indexOf(prevValue), 1);
                });
                // add new values that were not included before
                currValues.forEach((currValue: any) => {
                    if (!searchQuery.value.facets[key].includes(currValue))
                        searchQuery.value.facets[key].push(currValue);
                });
            } else searchQuery.value.facets[key] = defaultValue(get, key);
        }
    };

    /**
     * Adjusts search query's filters based on query parameters
     */
    const adjustFilters = () => {
        for (const key of Object.keys(searchQuery.value.filters)) {
            if (R.type(searchQuery.value.filters[key]) === 'Object') {
                if (Object.keys(searchQuery.value.filters[key]).includes('min'))
                    searchQuery.value.filters[key].min = defaultValue(get, `${key}Min`, false);
                if (Object.keys(searchQuery.value.filters[key]).includes('max'))
                    searchQuery.value.filters[key].max = defaultValue(get, `${key}Max`, false);
                if (Object.keys(searchQuery.value.filters[key]).includes('id'))
                    searchQuery.value.filters[key].id = defaultValue(get, key, false);
            } else if (R.type(searchQuery.value.filters[key]) === 'Array') {
                const prevValues = R.clone(searchQuery.value.filters[key]);
                const currValues = defaultValue(get, key);
                // remove previous values that are not included anymore
                prevValues.forEach((prevValue: any) => {
                    if (!currValues.includes(prevValue))
                        searchQuery.value.filters[key].splice(searchQuery.value.filters[key].indexOf(prevValue), 1);
                });
                // add new values that were not included before
                currValues.forEach((currValue: any) => {
                    if (!searchQuery.value.filters[key].includes(currValue))
                        searchQuery.value.filters[key].push(currValue);
                });
            } else searchQuery.value.filters[key] = defaultValue(get, key, false);
        }
    };

    /**
     * Gets query parameters and adjusts search query's facets and filters and executes search
     */
    const setSearchFromQueryParams = () => {
        page.value = get('page') ? parseInt(get('page'), 10) : 1;
        sortBy.value = get('sortBy')
            ? get('sortBy')
            : sortingClass.default(isRelevanceAvailable(query.value.input.text)).label;
        tab.value = get('tab') ? get('tab') : defaultTab;
        query.value = {
            input: {
                text: R.isNil(get('search')) || get('search') === '' ? '*' : get('search'),
                field: !R.isNil(get('field')) ? get('field') : undefined,
            },
            settings: {
                caseSensitive: get('caseSensitive') ? (get('caseSensitive') === 'true' ? true : false) : false,
                partialMatch: get('partialMatch') ? (get('partialMatch') === 'true' ? true : false) : true,
            },
        };
        searchQuery.value.sortBy = { field: sortByObject.field, asc: sortByObject.asc };
        setSearchQuery();
        adjustFacets();
        adjustFilters();
        for (const key of Object.keys(textNumberFilters.value))
            textNumberFilters.value[key] = defaultValue(get, key, false);
        search();
    };

    const setQueryInputInQueryParameters = (input: { text: string; field?: string | undefined }) => {
        queryParams.value['search'] = { value: input.text === '*' ? '' : input.text, default: '' };
        queryParams.value['field'] = { value: input.field, default: undefined };
    };

    /**
     * Sets search, caseSensitive and patialMatch query parameters and executes search
     * @param value The boolean value
     * @param setting The setting (caseSensitive or partialMatch)
     */
    const toggleSettings = (value: boolean, setting: string) => {
        setQueryInputInQueryParameters(query.value.input);
        if (setting === 'caseSensitive') setCaseSensitive(value);
        else setPartialMatch(value);
        search();
    };

    const setQueryParams = () => {
        setMany(queryParams.value);
        queryParams.value = {};
    };

    const { onEvent } = useWindowEventListeners();

    const unsubscribeSearch: Ref<Function | undefined> = ref(undefined);

    // Add window event listener to handle back/forward browser buttons
    onEvent(WindowEvent.HistoryBackAndForward, setSearchFromQueryParams, (callback: any) => {
        unsubscribeSearch.value = callback;
    });

    watch(
        () => query.value,
        (newQuery: ESearchQuery, oldQuery: ESearchQuery | undefined) => {
            // if only the field changed and the text is empty then don't search
            // since it won't change the results
            if (
                newQuery.input.field !== oldQuery?.input.field &&
                newQuery.input.text === '*' &&
                oldQuery?.input.text === '*'
            )
                return;

            setQueryInputInQueryParameters(newQuery.input);

            // if query text changes then reset the sort
            if (oldQuery?.input.text !== newQuery.input.text)
                sortByChanged(sortingClass.default(isRelevanceAvailable(newQuery.input.text)).label, false);
            setPartialMatch(R.isNil(newQuery.settings?.partialMatch) ? true : newQuery.settings.partialMatch);
            setCaseSensitive(R.isNil(newQuery.settings?.caseSensitive) ? false : newQuery.settings.caseSensitive);
            setPage(1);
            search();
        },
    );

    return {
        exec,
        loading,
        error,
        get,
        textNumberFilters,
        page,
        sortBy,
        tab,
        query,
        searchQuery,
        setTab,
        updateCurrentPage,
        onEnter,
        facetChanged,
        filterChanged,
        sortByChanged,
        clearFilters,
        clearSearch,
        toggleSettings,
        setCommonSearchQueryValues,
        unsubscribeSearch,
        setQueryParams,
        setPartialMatch,
        setCaseSensitive,
        sortingFields,
    };
}
