import { useAxios, useFeatureFlags } from '@/app/composable';
import { Ref, computed, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import { StatisticsAPI } from '../api';
import store from '@/app/store';
import { DashboardStatistic } from '../interfaces';

dayjs.extend(utc);

export function useDashboard() {
    const { exec, loading } = useAxios(true);
    const { isEnabled, areAnyEnabled } = useFeatureFlags();

    const user = computed(() => store.state.auth.user);

    const error: Ref<string | undefined> = ref<string | undefined>();
    const lastUpdatedOn: Ref<string | undefined> = ref<string | undefined>();
    const lastUpdatedSince: Ref<string | undefined> = ref<string | undefined>();

    const allAssetStatistics: Ref<DashboardStatistic[]> = computed((): DashboardStatistic[] => {
        const all: DashboardStatistic[] = [
            {
                path: ['assets', 'datasets'],
                title: 'Datasets',
                singular: 'dataset',
                plural: 'datasets',
                percent: 0,
                mine: 0,
                organisation: 0,
                link: {
                    overview: { name: 'assets', query: { tab: 'datasets' } },
                    mine: { name: 'assets', query: { tab: 'datasets', user: `${user.value.id}` } },
                    organisation: { name: 'assets', query: { tab: 'datasets' } },
                },
            },
            {
                path: ['assets', 'models'],
                title: 'Models',
                singular: 'model',
                plural: 'models',
                percent: 0,
                mine: 0,
                organisation: 0,
                link: {
                    overview: { name: 'assets', query: { tab: 'models' } },
                    mine: { name: 'assets', query: { tab: 'models', user: `${user.value.id}` } },
                    organisation: { name: 'assets', query: { tab: 'models' } },
                },
                feature: 'analytics',
            },
            {
                path: ['assets', 'results'],
                title: 'Results',
                singular: 'result',
                plural: 'results',
                mine: 0,
                organisation: 0,
                percent: 0,
                link: {
                    overview: { name: 'assets', query: { tab: 'results' } },
                    mine: { name: 'assets', query: { tab: 'results', user: `${user.value.id}` } },
                    organisation: { name: 'assets', query: { tab: 'results' } },
                },
                feature: 'analytics',
            },
        ];

        return all.filter(
            (stat: DashboardStatistic) =>
                R.isNil(stat.feature) ||
                (!Array.isArray(stat.feature) && isEnabled(stat.feature)) ||
                (Array.isArray(stat.feature) && areAnyEnabled(stat.feature)),
        );
    });

    const allPipelineAndretrievalStatistics: Ref<DashboardStatistic[]> = computed((): DashboardStatistic[] => {
        const all: DashboardStatistic[] = [
            {
                path: ['pipelines', 'dataCheckin'],
                title: 'Data Check-in Pipelines',
                singular: 'data checkin pipeline',
                plural: 'data checkin pipelines',
                mine: 0,
                organisation: 0,
                percent: 0,
                link: {
                    overview: { name: 'data-checkin-jobs', query: {} },
                    mine: { name: 'data-checkin-jobs', query: {} },
                    organisation: { name: 'data-checkin-jobs', query: { tab: 'shared' } },
                },
            },
            {
                path: ['pipelines', 'dataAnalytics'],
                title: 'Data Analytics Pipelines',
                singular: 'analytics pipeline',
                plural: 'analytics pipelines',
                mine: 0,
                organisation: 0,
                percent: 0,
                link: {
                    overview: { name: 'workflows', query: {} },
                    mine: { name: 'workflows', query: {} },
                    organisation: { name: 'workflows', query: { tab: 'shared' } },
                },
                feature: 'analytics',
            },
            {
                path: ['retrievalQueries'],
                title: 'Retrieval Queries',
                singular: 'retrieval query',
                plural: 'retrieval queries',
                mine: 0,
                organisation: 0,
                percent: 0,
                link: {
                    overview: { name: 'retrieval', query: {} },
                    mine: { name: 'retrieval', query: {} },
                },
                feature: ['retrieve.api', 'retrieve.files', 'retrieve.stream'],
            },
        ];
        return all.filter(
            (stat: DashboardStatistic) =>
                R.isNil(stat.feature) ||
                (!Array.isArray(stat.feature) && isEnabled(stat.feature)) ||
                (Array.isArray(stat.feature) && areAnyEnabled(stat.feature)),
        );
    });

    const asset: Ref<DashboardStatistic[]> = ref<DashboardStatistic[]>(allAssetStatistics.value);

    const pipelineAndRetrieval: Ref<DashboardStatistic[]> = ref<DashboardStatistic[]>(
        allPipelineAndretrievalStatistics.value,
    );

    const execution: Ref<{
        series: { name: string; data: number[] }[];
        labels: string[];
    }> = ref<{
        series: { name: string; data: number[] }[];
        labels: string[];
    }>({ series: [], labels: [] });
    const executionDates: Ref<Record<string, string>> = ref<Record<string, string>>({});

    const getPercent = (numerator: number, denomenator: number) =>
        denomenator > 0 ? Math.round((numerator / denomenator + Number.EPSILON) * 100) : 0;

    const fetchStatistics = (forceClearCache = false) => {
        exec(StatisticsAPI.statistics(forceClearCache))
            .then((res: any) => {
                lastUpdatedOn.value = dayjs.utc(res.data.lastUpdatedOn).format('DD/MM/YYYY HH:mm');
                lastUpdatedSince.value = dayjs.utc(res.data.lastUpdatedOn).fromNow();
                const uniqueDates: string[] = R.uniq(
                    (isEnabled('analytics')
                        ? [...res.data.executions.dataCheckin, ...res.data.executions.dataAnalytics]
                        : res.data.executions.dataCheckin
                    ).map((entry: { timestamp: string; count: number }) =>
                        dayjs.utc(entry.timestamp).hour(0).minute(0).second(0).millisecond(0).toISOString(),
                    ),
                );

                // extract distinct dates for the month
                // and create a map between the label to be used in the chart and the actual full date (that includes the year)
                // i.e 10 Jul -> 2023-07-10T00:00:00.000Z
                executionDates.value = R.sort((a, b) => dayjs(a).diff(b), uniqueDates).reduce(
                    (acc: Record<string, string>, dateStr: string) => {
                        acc[dayjs.utc(dateStr).format('DD MMM')] = dateStr;
                        return acc;
                    },
                    {},
                );

                asset.value = allAssetStatistics.value.map((stat: DashboardStatistic) => {
                    return {
                        ...stat,
                        ...R.path(stat.path, res.data),
                        percent: getPercent(
                            (R.path(stat.path, res.data) as any).mine,
                            (R.path(stat.path, res.data) as any).organisation,
                        ),
                    };
                });

                pipelineAndRetrieval.value = allPipelineAndretrievalStatistics.value.map((stat: DashboardStatistic) => {
                    return {
                        ...stat,
                        ...R.path(stat.path, res.data),
                        percent: getPercent(
                            (R.path(stat.path, res.data) as any).mine,
                            (R.path(stat.path, res.data) as any).organisation,
                        ),
                    };
                });

                const executionLabels: string[] = Object.keys(executionDates.value);
                execution.value = {
                    series: [
                        {
                            name: 'Data Check-in Pipelines',
                            data: executionLabels.map((label: string) => {
                                const point = res.data.executions.dataCheckin.find(
                                    (entry: { timestamp: string; count: number }) =>
                                        dayjs.utc(entry.timestamp).format('DD MMM') === label,
                                );
                                return point ? point.count : 0;
                            }),
                        },
                    ],
                    labels: executionLabels,
                };
                if (isEnabled('analytics'))
                    execution.value.series.push({
                        name: 'Data Analytics Pipelines',
                        data: executionLabels.map((label: string) => {
                            const point = res.data.executions.dataAnalytics.find(
                                (entry: { timestamp: string; count: number }) =>
                                    dayjs.utc(entry.timestamp).format('DD MMM') === label,
                            );
                            return point ? point.count : 0;
                        }),
                    });
            })
            .catch((e) => {
                error.value = e.message;
            });
    };

    const refetch = () => fetchStatistics(true);

    const getDateRangeForLabel = (label: string): { start: string; end: string } => {
        const dayObj = dayjs(executionDates.value[label]).utc();
        return {
            start: dayObj.hour(0).minute(0).second(0).millisecond(0).toISOString(),
            end: dayObj.hour(23).minute(59).second(59).millisecond(999).toISOString(),
        };
    };

    fetchStatistics();
    return {
        asset,
        pipelineAndRetrieval,
        execution,
        loading,
        lastUpdatedOn,
        lastUpdatedSince,
        refetch,
        getDateRangeForLabel,
    };
}
