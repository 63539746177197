






import { defineComponent } from '@vue/composition-api';
import Scrollbar from '../Scrollbar.vue';

export default defineComponent({
    name: 'TwAccordion',
    components: { Scrollbar },
});
