











import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'TailwindButton',
    inheritAttrs: false,
    props: {
        color: {
            type: String,
            default: 'blue',
        },
        size: {
            type: String,
            default: 'md', // sm, md, lg
        },
        outline: {
            type: Boolean,
            required: false,
            default: false,
        },
        icon: {
            type: Boolean,
            required: false,
            default: false,
        },
        round: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const colors = {
            blue: {
                base:
                    'bg-blue-600 text-white border-blue-600 hover:bg-blue-700 hover:border-blue-700 hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-blue-600 bg-white text-blue-600 hover:bg-blue-700 hover:border-blue-700 hover:text-white disabled:cursor-not-allowed',
            },
            green: {
                base:
                    'bg-green-700 text-white border-green-700 hover:bg-green-700 hover:border-green-800 hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-green-700 bg-white text-green-800 hover:bg-green-700 hover:border-green-700 hover:text-white disabled:cursor-not-allowed',
            },
            indigo: {
                base:
                    'bg-indigo-600 text-white border-indigo-600 hover:bg-indigo-700 hover:border-indigo-700 hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-indigo-600 bg-white text-indigo-600 hover:bg-indigo-700 hover:border-indigo-700 hover:text-white disabled:cursor-not-allowed',
            },
            primary: {
                base:
                    'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 hover:border-primary-700 hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-primary-600 bg-white text-primary-600 hover:bg-primary-700 hover:border-primary-700 hover:text-white disabled:cursor-not-allowed',
            },
            secondary: {
                base:
                    'bg-secondary-600 text-white border-secondary-600 hover:bg-secondary-700 hover:border-secondary-700 hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-secondary-600 bg-white text-secondary-600 hover:bg-secondary-700 hover:border-secondary-700 hover:text-white disabled:cursor-not-allowed',
            },
            neutral: {
                base:
                    'bg-neutral-500 text-white border-neutral-500 hover:bg-neutral-700 hover:border-neutral-700 hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-neutral-600 bg-white text-neutral-600 hover:bg-neutral-700 hover:border-neutral-700 hover:text-white disabled:cursor-not-allowed',
            },
            red: {
                base:
                    'bg-red-600 text-white border-red-600 hover:bg-red-700 hover:border-red-700  hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-red-600 bg-white text-red-600 hover:bg-red-700 hover:border-red-700 hover:text-white disabled:cursor-not-allowed',
            },
            yellow: {
                base:
                    'bg-yellow-600 text-white border-yellow-600 hover:bg-yellow-700 hover:border-yellow-700 hover:text-white disabled:cursor-not-allowed',
                outline:
                    'border-yellow-600 bg-white text-yellow-600 hover:bg-yellow-700 hover:border-yellow-700 hover:text-white disabled:cursor-not-allowed',
            },
            white: {
                base: 'bg-white text-primary-600 border-white hover:text-primary-600 disabled:cursor-not-allowed',
                outline: 'border-white bg-white text-primary-600 hover:text-primary-600 disabled:cursor-not-allowed',
            },
        };
        const colorClasses = computed(() => {
            const { color } = props;
            const { base, outline } = colors[color];
            return props.outline ? outline : base;
        });
        const sizeClasses = computed(() => {
            const isIcon = props.icon;
            const sizeMappings = {
                xs: `h-6 text-xs ${isIcon ? 'px-2' : 'px-4'}`,
                sm: `h-8 text-sm ${isIcon ? 'px-4' : 'px-6'}`,
                md: `h-10 ${isIcon ? 'px-3' : 'px-8'}`,
                lg: `text-lg h-12 ${isIcon ? 'px-4' : 'px-12'}`,
            } as any;

            return sizeMappings[props.size] || sizeMappings.md;
        });

        const btnClasses = computed(() => {
            const borderRadiusClasses = props.round ? 'rounded-full' : 'rounded';
            return `${colorClasses.value} ${sizeClasses.value} ${borderRadiusClasses}`;
        });

        return { btnClasses, colors, colorClasses, sizeClasses };
    },
});
