import { useAxios } from '@/app/composable';
import { Ref, computed, ref } from '@vue/composition-api';
import store from '@/app/store';
import { UserRoles } from '../constants';
import { ExceptionEventsAPI } from '../api';

export function useExceptionEvents() {
    const { exec, loading } = useAxios(true);

    const count: Ref<number> = ref<number>(0);
    const show: Ref<boolean> = computed(() => store.getters.auth.hasRole(UserRoles.Admin));

    const fetch = async () => {
        const res = await exec(ExceptionEventsAPI.getCount());
        count.value = res?.data;
    };

    if (show.value) fetch();

    return { show, loading, count };
}
