




























































































































import { ChevronLeftIcon, CollectionIcon, ChevronRightIcon, SearchIcon, PlusIcon } from '@vue-hero-icons/outline';
import FilterHighLevelConcepts from '../components/FilterHighLevelConcepts.vue';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { ButtonGroup, Scrollbar, SvgImage } from '@/app/components';
import HighlevelConcept from './HighlevelConcept.vue';
import { useDataModel } from '../composable';
import { HighLevelConceptFilters } from '../constants';

export default defineComponent({
    name: 'HighlevelConcepts',
    props: {
        model: { type: Object, required: true },
        concepts: Array,
        suggestions: Array,
        selectedHLConcept: { type: Object, required: false },
        selectedFilter: String,
        filterOptions: Array,
        changesToBeSaved: { type: Boolean, default: false },
        savedConcepts: { type: Array, default: () => [] },
        scrollToCreatedConcept: { type: Object, required: false },
    },
    components: {
        ButtonGroup,
        HighlevelConcept,
        Scrollbar,
        SvgImage,
        ChevronLeftIcon,
        CollectionIcon,
        ChevronRightIcon,
        SearchIcon,
        PlusIcon,
        FilterHighLevelConcepts,
    },
    setup(props: any, { emit }: { emit: any }) {
        // UI variables

        const copiedModel = computed(() => props.model);
        const collapsedConcepts = ref<boolean>(false);
        const conceptsIconHovered = ref<boolean>(false);
        const searchHighLevelConceptsText = ref('');

        const proposedMode = computed(() => props.selectedFilter === HighLevelConceptFilters.Proposed);

        const { filterConcepts: filterHLConcepts, isUnderRevision, readOnly, filterByText } = useDataModel(copiedModel);

        const filteredConcepts = computed(() => {
            let highlevelConcepts: any[];
            if (proposedMode.value) {
                highlevelConcepts = filterByText(props.suggestions, searchHighLevelConceptsText.value);
            } else {
                highlevelConcepts = filterHLConcepts(
                    props.concepts,
                    props.selectedFilter,
                    false,
                    searchHighLevelConceptsText.value,
                );
            }

            return highlevelConcepts?.sort((a: any, b: any) => {
                return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            });
        });

        const filterConcepts = (filter: string) => {
            emit('filter-change', filter);
        };

        const conceptSelected = (concept: any) => {
            emit('selected', concept, proposedMode.value);
        };

        const addConcept = () => {
            emit('add');
        };

        const filterChange = (filter: any) => {
            filterConcepts(filter);
        };

        const scrollToConcept = (con: string) => {
            const element = document.getElementById(con);
            if (element) {
                element.scrollIntoView();
                emit('clear-scrolling');
            }
        };

        watch(
            () => props.scrollToCreatedConcept,
            (createdConcept: any) => {
                if (createdConcept && createdConcept.parentId === copiedModel.value.id) {
                    // added check in order for scrolling to be "activated" only if a high level concept is created and not a field
                    scrollToConcept(`${createdConcept.id}_${createdConcept.status}`);
                }
            },
            { immediate: true },
        );

        return {
            addConcept,
            collapsedConcepts,
            conceptSelected,
            conceptsIconHovered,
            emit,
            filterChange,
            filterConcepts,
            filteredConcepts,
            proposedMode,
            searchHighLevelConceptsText,
            isUnderRevision,
            readOnly,
        };
    },
});
