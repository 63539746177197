





























































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
    name: 'TwAccordionCard',
    props: {
        collapsed: {
            type: Boolean,
            default: true,
        },
        borders: {
            type: Boolean,
            default: true,
        },
        headerClasses: {
            type: String,
            default: '',
        },
        collapsedChevronClasses: {
            type: String,
            default: '',
        },
        expandedChevronClasses: {
            type: String,
            default: '',
        },
        bodyClasses: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const isCollapsed = ref<boolean>(props.collapsed);
        const onCollapseClicked = () => {
            isCollapsed.value = !isCollapsed.value;
        };
        const showBorders = props.borders;

        return { showBorders, isCollapsed, onCollapseClicked };
    },
});
