





























































































import { defineComponent } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

export default defineComponent({
    name: 'FormModal',
    components: { ValidationObserver },
    props: {
        title: {
            type: String,
            required: false,
        },
        saveButtonText: {
            type: String,
            default: 'Save',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        width: {
            type: String,
            default: 'min-w-1/3 sm:max-w-lg sm:w-full',
        },
        allowSave: {
            type: Boolean,
            default: true,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
