













































































import * as R from 'ramda';
import { Status } from '../constants';
import { ValidationProvider, extend } from 'vee-validate';
import { requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useDataModel } from '../composable';

extend('required', requiredValidator);

export default defineComponent({
    name: 'TransferField',
    model: {
        prop: 'parentId',
        event: 'updateParentId',
    },

    props: {
        parentId: {
            type: Number,
            required: true,
        },
        selectedField: {
            type: Object,
            required: true,
        },
        highlevelConcepts: {
            type: Array,
            default: () => [],
        },
        model: {
            type: Object,
            required: true,
        },
    },
    components: {
        ValidationProvider,
    },

    setup(props, { emit }) {
        const choice = ref<string>('default');
        const copiedModel = computed(() => props.model);
        const { isDraft, isUnderRevision } = useDataModel(copiedModel);

        const defaultParentId = ref(R.clone(props.parentId));
        const transferToId = ref(null);

        const resetConceptTransfer = () => {
            transferToId.value = null;
            emit('updateParentId', defaultParentId);
        };

        const transferToConcept = () => {
            emit('updateParentId', transferToId.value);
        };

        const sortedFilteredActiveHLConcepts = computed(() =>
            filteredActiveHLConcepts.value?.sort((a: any, b: any) => a.name.localeCompare(b.name)),
        );

        const filteredActiveHLConcepts = computed<any>(() => {
            if (!props.highlevelConcepts) return [];

            if (isDraft.value) {
                return props.highlevelConcepts.filter(
                    (h: any) =>
                        h.status === Status.Draft &&
                        props.selectedField.parentId !== h.id &&
                        props.selectedField.referenceConceptId !== h.id,
                );
            }

            if (isUnderRevision.value) {
                return props.highlevelConcepts.filter(
                    (h: any) =>
                        (h.status === Status.UnderRevision || h.status === Status.Draft) &&
                        props.selectedField.parentId !== h.id &&
                        props.selectedField.referenceConceptId !== h.id,
                );
            }

            return props.highlevelConcepts.filter(
                (h: any) =>
                    h.status === Status.Stable &&
                    props.selectedField.parentId !== h.id &&
                    props.selectedField.referenceConceptId !== h.id,
            );
        });

        return {
            sortedFilteredActiveHLConcepts,
            choice,
            transferToId,
            resetConceptTransfer,
            transferToConcept,
        };
    },
});
