var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3 bg-white border rounded-r shadow",class:_vm.selected ? 'border-primary-700 border-l-4' : 'border-neutral-300 rounded-l cursor-pointer',on:{"click":function($event){!_vm.selected ? _vm.emit('edit') : null}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-full py-2 space-y-2"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex items-center truncate"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.concept.name.length > 50 ? _vm.concept.name : ''),expression:"concept.name.length > 50 ? concept.name : ''"}],staticClass:"flex items-center mr-2 text-sm font-semibold line-clamp-1",class:{
                            'italic text-secondary-700': _vm.isProposal,
                            'text-primary-700': !_vm.isProposal,
                            'w-96 2xl:w-full': _vm.concept.name.length > 50,
                        }},[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.concept.name)+" ")])]),_c('h2',{staticClass:"text-lg font-bold text-neutral-800"},[(_vm.concept.type === 'object')?_c('button',{staticClass:"flex rounded focus:outline-none focus:ring",on:{"click":function($event){$event.stopPropagation();return _vm.emit('goToReferenceHighLevelConcept', _vm.concept.referenceConceptId)}}},[_c('div',{staticClass:"flex items-center px-2 text-sm capitalize rounded text-neutral-100 bg-neutral-600 hover:bg-neutral-700"},[_c('span',{staticClass:"text-sm font-semibold cursor-pointer"},[_vm._v(" "+_vm._s(_vm.concept.referenceConceptName)+" ")]),_c('LinkIcon',{staticClass:"w-4 h-4 ml-1"})],1)]):_c('span',{staticClass:"flex px-2 text-sm capitalize rounded text-neutral-100 bg-neutral-600"},[_vm._v(_vm._s(_vm.dataType))])])]),_c('div',{staticClass:"flex",class:{
                        'justify-between items-center':
                            _vm.concept.status === _vm.Status.Deprecated || _vm.modelStatus === _vm.Status.UnderRevision,
                    }},[(_vm.unsavedChange !== null)?_c('ChangeIndication',{attrs:{"unsaved":_vm.unsavedChange}}):_vm._e(),(_vm.newField || _vm.deprecatedField)?_c('h2',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(((_vm.newField ? 'New' : 'Deprecated') + " field")),expression:"`${newField ? 'New' : 'Deprecated'} field`"}],staticClass:"ml-2 text-lg font-bold"},[_c('div',{staticClass:"flex items-center p-1 rounded-full xl:hidden",class:{
                                'bg-primary-500': _vm.newField,
                                'bg-red-500': _vm.deprecatedField,
                            }}),_c('span',{staticClass:"items-center hidden px-2 text-sm capitalize rounded xl:flex",class:{
                                'text-primary-100 bg-primary-500': _vm.newField,
                                'bg-red-500 text-red-100': _vm.deprecatedField,
                            }},[_vm._v(" "+_vm._s(_vm.newField ? 'New' : 'Deprecated')+" ")])]):_vm._e()],1)]),_c('div',{staticClass:"flex flex-row items-center justify-between w-full space-x-2"},[_c('span',{staticClass:"flex text-xs font-bold uppercase text-neutral-500 whitespace-nowrap"},[_vm._v("Related terms")]),(_vm.concept.relatedTerms && _vm.concept.relatedTerms.length)?_c('div',{staticClass:"w-5/6"},[_c('VueTagsInput',{staticClass:"remove-x remove-add-new remove-pointer-events truncated-content remove-actions",attrs:{"tags":_vm.tags,"disabled":""},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1):_c('div',{staticClass:"block w-5/6 px-1 text-sm italic text-neutral-600"},[_vm._v("Not Available")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }