var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dl',{staticClass:"grid grid-cols-2 gap-3 lg:gap-5 lg:grid-cols-3 xl:grid-cols-5"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                (_vm.failedPipelines + " " + (_vm.activePipelines === 1 ? 'pipeline' : 'pipelines') + " " + (_vm.activePipelines === 1 ? 'has' : 'have') + " failed at least once out of the " + _vm.activePipelines + " " + (_vm.activePipelines === 1 ? 'pipeline' : 'pipelines') + " executed")
            ),expression:"\n                `${failedPipelines} ${activePipelines === 1 ? 'pipeline' : 'pipelines'} ${\n                    activePipelines === 1 ? 'has' : 'have'\n                } failed at least once out of the ${activePipelines} ${\n                    activePipelines === 1 ? 'pipeline' : 'pipelines'\n                } executed`\n            "}],staticClass:"relative p-3 overflow-hidden bg-white rounded-lg shadow 2xl:p-5"},[_c('dt',[_c('div',{staticClass:"absolute hidden p-3 bg-red-700 rounded-md lg:block cursor-help"},[(!_vm.loading)?_c('XCircleIcon',{staticClass:"w-6 h-6 text-white",attrs:{"aria-hidden":"true"}}):_c('SelfBuildingSquareSpinner',{staticClass:"mx-auto",attrs:{"animation-duration":5000,"size":23,"color":"white"}})],1),_c('p',{staticClass:"text-sm font-medium truncate lg:ml-16 text-neutral-500"},[_vm._v("Failed Pipelines")])]),_c('dd',{staticClass:"flex items-baseline lg:ml-16"},[(!_vm.loading)?_c('p',{staticClass:"text-base font-semibold lg:text-xl text-neutral-900"},[_vm._v(" "+_vm._s(_vm.failedPipelines)+" "),_c('span',{staticClass:"text-sm font-medium text-neutral-600"},[_vm._v(" from "+_vm._s(_vm.activePipelines)+" executed ")])]):_c('p',{staticClass:"h-8"})])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                (_vm.resolvedPipelines + " " + (_vm.resolvedPipelines === 1 ? 'pipeline' : 'pipelines') + " " + (_vm.resolvedPipelines === 1 ? 'was' : 'were') + " resolved after failing at least once")
            ),expression:"\n                `${resolvedPipelines} ${resolvedPipelines === 1 ? 'pipeline' : 'pipelines'} ${\n                    resolvedPipelines === 1 ? 'was' : 'were'\n                } resolved after failing at least once`\n            "}],staticClass:"relative p-3 overflow-hidden bg-white rounded-lg shadow 2xl:p-5"},[_c('dt',[_c('div',{staticClass:"absolute hidden p-3 bg-green-800 rounded-md lg:block cursor-help"},[(!_vm.loading)?_c('CheckCircleIcon',{staticClass:"w-6 h-6 text-white",attrs:{"aria-hidden":"true"}}):_c('SelfBuildingSquareSpinner',{staticClass:"mx-auto",attrs:{"animation-duration":5000,"size":23,"color":"white"}})],1),_c('p',{staticClass:"text-sm font-medium truncate lg:ml-16 text-neutral-500"},[_vm._v("Resolved Pipelines")])]),_c('dd',{staticClass:"flex items-baseline lg:ml-16"},[(!_vm.loading)?_c('p',{staticClass:"text-base font-semibold lg:text-xl text-neutral-900"},[_vm._v(" "+_vm._s(_vm.resolvedPipelines)+" "),_c('span',{staticClass:"text-sm font-medium text-neutral-600"},[_vm._v(" from "+_vm._s(_vm.failedPipelines)+" failed ")])]):_c('p',{staticClass:"h-6"})])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.failedExecutionsPercent === 0
                    ? "No executions have failed"
                    : (_vm.failedExecutionsPercent + "% of executions failed")
            ),expression:"\n                failedExecutionsPercent === 0\n                    ? `No executions have failed`\n                    : `${failedExecutionsPercent}% of executions failed`\n            "}],staticClass:"relative p-3 overflow-hidden bg-white rounded-lg shadow 2xl:p-5"},[_c('dt',[_c('div',{staticClass:"absolute flex-row items-center hidden p-3 space-x-1 text-xs bg-yellow-600 rounded-md lg:flex cursor-help"},[(!_vm.loading)?_c('WeatherDynamicIcon',{staticClass:"rounded-full",attrs:{"weather":_vm.totalExecutions > 0 ? _vm.failedExecutions / _vm.totalExecutions : 0,"skinny":true,"allWhite":true}}):_c('SelfBuildingSquareSpinner',{staticClass:"mx-auto",attrs:{"animation-duration":5000,"size":23,"color":"white"}})],1),_c('p',{staticClass:"text-sm font-medium truncate lg:ml-16 text-neutral-500"},[_vm._v("Failed Executions")])]),_c('dd',{staticClass:"flex items-baseline lg:ml-16"},[(!_vm.loading)?_c('p',{staticClass:"text-base font-semibold lg:text-xl text-neutral-900"},[_vm._v(" "+_vm._s(_vm.failedExecutions)+" "),_c('span',{staticClass:"text-sm font-medium text-neutral-600"},[_vm._v(" from "+_vm._s(_vm.totalExecutions)+" ")])]):_c('p',{staticClass:"h-6"})])]),(_vm.showProblematic)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                _vm.problematicExecutionsPercent === 0
                    ? "No executions were problematic"
                    : (_vm.problematicExecutionsPercent + "% of executions were problematic")
            ),expression:"\n                problematicExecutionsPercent === 0\n                    ? `No executions were problematic`\n                    : `${problematicExecutionsPercent}% of executions were problematic`\n            "}],staticClass:"relative p-3 overflow-hidden bg-white rounded-lg shadow 2xl:p-5"},[_c('dt',[_c('div',{staticClass:"absolute flex-row items-center hidden p-3 space-x-1 text-xs bg-purple-600 rounded-md lg:flex cursor-help"},[(!_vm.loading)?_c('WeatherDynamicIcon',{staticClass:"rounded-full",attrs:{"weather":_vm.totalExecutions > 0 && _vm.problematicExecutions !== undefined
                                ? _vm.problematicExecutions / _vm.totalExecutions
                                : 0,"skinny":true,"allWhite":true}}):_c('SelfBuildingSquareSpinner',{staticClass:"mx-auto",attrs:{"animation-duration":5000,"size":23,"color":"white"}})],1),_c('p',{staticClass:"text-sm font-medium truncate lg:ml-16 text-neutral-500"},[_vm._v("Problematic Executions")])]),_c('dd',{staticClass:"flex items-baseline lg:ml-16"},[(!_vm.loading)?_c('p',{staticClass:"text-base font-semibold lg:text-xl text-neutral-900"},[_vm._v(" "+_vm._s(_vm.problematicExecutions)+" "),_c('span',{staticClass:"text-sm font-medium text-neutral-600"},[_vm._v(" from "+_vm._s(_vm.totalExecutions)+" ")])]):_c('p',{staticClass:"h-6"})])]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                ("The average execution time of the " + _vm.totalExecutions + " " + (_vm.totalExecutions === 1 ? 'execution' : 'executions') + " performed")
            ),expression:"\n                `The average execution time of the ${totalExecutions} ${\n                    totalExecutions === 1 ? 'execution' : 'executions'\n                } performed`\n            "}],staticClass:"relative p-3 overflow-hidden bg-white rounded-lg shadow 2xl:p-5"},[_c('dt',[_c('div',{staticClass:"absolute hidden p-3 bg-blue-700 rounded-md lg:block cursor-help"},[(!_vm.loading)?_c('ClockIcon',{staticClass:"w-6 h-6 text-white",attrs:{"aria-hidden":"true"}}):_c('SelfBuildingSquareSpinner',{staticClass:"mx-auto",attrs:{"animation-duration":5000,"size":23,"color":"white"}})],1),_c('p',{staticClass:"text-sm font-medium truncate lg:ml-16 text-neutral-500"},[_vm._v("Average Execution Time")])]),_c('dd',{staticClass:"flex items-baseline lg:ml-16"},[(!_vm.loading)?_c('p',{staticClass:"text-base font-semibold truncate lg:text-xl text-neutral-900"},[_vm._v(" "+_vm._s(_vm.executionTime.value.toFixed(2))+" "),_c('span',{staticClass:"text-sm truncate text-neutral-600"},[_vm._v(_vm._s(_vm.executionTime.metric))])]):_c('p',{staticClass:"h-6"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }