























































import { BookOpenIcon } from '@vue-hero-icons/outline';
import { defineComponent, Ref, ref } from '@vue/composition-api';

import { Concept, Model } from '@/app/interfaces';
import store from '@/app/store';
import OnClickOutside from '../OnClickOutside.vue';
import ModelBrowser from './ModelBrowser.vue';
import PathBuilder from './PathBuilder.vue';

export default defineComponent({
    name: 'ModelBrowserOverlay',
    components: { BookOpenIcon, ModelBrowser, PathBuilder, OnClickOutside },
    setup() {
        const open: Ref<boolean> = ref<boolean>(false);
        const model: Ref<Model | undefined> = ref<Model | undefined>();
        const path: Ref<Concept[]> = ref<Concept[]>([]);
        const goToConcept: Ref<string[]> = ref<string[]>([]);
        const pinned: Ref<boolean> = ref<boolean>(false);
        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        window.addEventListener('keydown', function (e) {
            if ((e.key === 'b' && e.metaKey && isMacOS) || (e.key === 'b' && e.ctrlKey && !isMacOS)) {
                e.stopImmediatePropagation();
                open.value = !open.value;
            }
        });

        const setModel = (newModel: Model | undefined) => {
            model.value = newModel;
        };

        const setPath = (newPath: Concept[]) => {
            path.value = newPath;
        };

        const clearPath = () => {
            store.dispatch.modelBrowser.setPath({ path: [] });
        };

        const conceptClicked = (conceptUid: string) => {
            goToConcept.value = [conceptUid];
        };
        const closeModelBrowser = () => {
            if (!pinned.value) open.value = false;
        };

        return {
            open,
            model,
            path,
            isMacOS,
            goToConcept,
            pinned,
            setModel,
            setPath,
            clearPath,
            conceptClicked,
            closeModelBrowser,
        };
    },
});
