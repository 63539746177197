import { alertModule } from '@/modules/alert/store';
import auth from '@/modules/auth/store';
import { dataModelModule } from '@/modules/data-model/store';
import { modelBrowserModule } from '@/modules/data-model/store/model-browser';
import { notificationModule } from '@/modules/notification/store';
import { organisationModule } from '@/modules/organization/store';
import { pipelineDesignerModule, versionsModule } from '@/modules/workflow-designer/store';
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { executionErrorsModule } from './execution-errors';
import { featureFlagsModule } from './feature-flags';
import { locationsModule } from './locations';
import { webSocketsModule } from './web-sockets';

Vue.use(Vuex);

const { store, rootActionContext, moduleActionContext } = createDirectStore({
    modules: {
        auth,
        notificationEngine: notificationModule,
        alertEngine: alertModule,
        modelBrowser: modelBrowserModule,
        executionVersions: versionsModule,
        executionErrors: executionErrorsModule,
        webSocketsEngine: webSocketsModule,
        dataModel: dataModelModule,
        organisation: organisationModule,
        locations: locationsModule,
        pipelineDesigner: pipelineDesignerModule,
        featureFlags: featureFlagsModule,
    },
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions.
export { moduleActionContext, rootActionContext };
// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module 'vuex' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Store<S> {
        direct: AppStore;
    }
}
