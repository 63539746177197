export interface TimezoneType {
    label: string;
    tzCode: string;
}

export const timezoneSet: TimezoneType[] = [
    { label: '(GMT-11:00) Niue', tzCode: 'Pacific/Niue' },
    { label: '(GMT-11:00) Pago Pago', tzCode: 'Pacific/Pago_Pago' },
    { label: '(GMT-10:00) Hawaii Time', tzCode: 'Pacific/Honolulu' },
    { label: '(GMT-10:00) Rarotonga', tzCode: 'Pacific/Rarotonga' },
    { label: '(GMT-10:00) Tahiti', tzCode: 'Pacific/Tahiti' },
    { label: '(GMT-09:30) Marquesas', tzCode: 'Pacific/Marquesas' },
    { label: '(GMT-09:00) Alaska Time', tzCode: 'America/Anchorage' },
    { label: '(GMT-09:00) Gambier', tzCode: 'Pacific/Gambier' },
    { label: '(GMT-08:00) Pacific Time', tzCode: 'America/Los_Angeles' },
    { label: '(GMT-08:00) Pacific Time - Tijuana', tzCode: 'America/Tijuana' },
    { label: '(GMT-08:00) Pacific Time - Vancouver', tzCode: 'America/Vancouver' },
    { label: '(GMT-08:00) Pacific Time - Whitehorse', tzCode: 'America/Whitehorse' },
    { label: '(GMT-08:00) Pitcairn', tzCode: 'Pacific/Pitcairn' },
    { label: '(GMT-07:00) Mountain Time', tzCode: 'America/Denver' },
    { label: '(GMT-07:00) Mountain Time - Arizona', tzCode: 'America/Phoenix' },
    { label: '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan', tzCode: 'America/Mazatlan' },
    { label: '(GMT-07:00) Mountain Time - Dawson Creek', tzCode: 'America/Dawson_Creek' },
    { label: '(GMT-07:00) Mountain Time - Edmonton', tzCode: 'America/Edmonton' },
    { label: '(GMT-07:00) Mountain Time - Hermosillo', tzCode: 'America/Hermosillo' },
    { label: '(GMT-07:00) Mountain Time - Yellowknife', tzCode: 'America/Yellowknife' },
    { label: '(GMT-06:00) Belize', tzCode: 'America/Belize' },
    { label: '(GMT-06:00) Central Time', tzCode: 'America/Chicago' },
    { label: '(GMT-06:00) Central Time - Mexico City', tzCode: 'America/Mexico_City' },
    { label: '(GMT-06:00) Central Time - Regina', tzCode: 'America/Regina' },
    { label: '(GMT-06:00) Central Time - Tegucigalpa', tzCode: 'America/Tegucigalpa' },
    { label: '(GMT-06:00) Central Time - Winnipeg', tzCode: 'America/Winnipeg' },
    { label: '(GMT-06:00) Costa Rica', tzCode: 'America/Costa_Rica' },
    { label: '(GMT-06:00) El Salvador', tzCode: 'America/El_Salvador' },
    { label: '(GMT-06:00) Galapagos', tzCode: 'Pacific/Galapagos' },
    { label: '(GMT-06:00) Guatemala', tzCode: 'America/Guatemala' },
    { label: '(GMT-06:00) Managua', tzCode: 'America/Managua' },
    { label: '(GMT-05:00) America Cancun', tzCode: 'America/Cancun' },
    { label: '(GMT-05:00) Bogota', tzCode: 'America/Bogota' },
    { label: '(GMT-05:00) Easter Island', tzCode: 'Pacific/Easter' },
    { label: '(GMT-05:00) Eastern Time', tzCode: 'America/New_York' },
    { label: '(GMT-05:00) Eastern Time - Iqaluit', tzCode: 'America/Iqaluit' },
    { label: '(GMT-05:00) Eastern Time - Toronto', tzCode: 'America/Toronto' },
    { label: '(GMT-05:00) Guayaquil', tzCode: 'America/Guayaquil' },
    { label: '(GMT-05:00) Havana', tzCode: 'America/Havana' },
    { label: '(GMT-05:00) Jamaica', tzCode: 'America/Jamaica' },
    { label: '(GMT-05:00) Lima', tzCode: 'America/Lima' },
    { label: '(GMT-05:00) Nassau', tzCode: 'America/Nassau' },
    { label: '(GMT-05:00) Panama', tzCode: 'America/Panama' },
    { label: '(GMT-05:00) Port-au-Prince', tzCode: 'America/Port-au-Prince' },
    { label: '(GMT-05:00) Rio Branco', tzCode: 'America/Rio_Branco' },
    { label: '(GMT-04:00) Atlantic Time - Halifax', tzCode: 'America/Halifax' },
    { label: '(GMT-04:00) Barbados', tzCode: 'America/Barbados' },
    { label: '(GMT-04:00) Bermuda', tzCode: 'Atlantic/Bermuda' },
    { label: '(GMT-04:00) Boa Vista', tzCode: 'America/Boa_Vista' },
    { label: '(GMT-04:00) Caracas', tzCode: 'America/Caracas' },
    { label: '(GMT-04:00) Curacao', tzCode: 'America/Curacao' },
    { label: '(GMT-04:00) Grand Turk', tzCode: 'America/Grand_Turk' },
    { label: '(GMT-04:00) Guyana', tzCode: 'America/Guyana' },
    { label: '(GMT-04:00) La Paz', tzCode: 'America/La_Paz' },
    { label: '(GMT-04:00) Manaus', tzCode: 'America/Manaus' },
    { label: '(GMT-04:00) Martinique', tzCode: 'America/Martinique' },
    { label: '(GMT-04:00) Port of Spain', tzCode: 'America/Port_of_Spain' },
    { label: '(GMT-04:00) Porto Velho', tzCode: 'America/Porto_Velho' },
    { label: '(GMT-04:00) Puerto Rico', tzCode: 'America/Puerto_Rico' },
    { label: '(GMT-04:00) Santo Domingo', tzCode: 'America/Santo_Domingo' },
    { label: '(GMT-04:00) Thule', tzCode: 'America/Thule' },
    { label: '(GMT-03:30) Newfoundland Time - St. Johns', tzCode: 'America/St_Johns' },
    { label: '(GMT-03:00) Araguaina', tzCode: 'America/Araguaina' },
    { label: '(GMT-03:00) Asuncion', tzCode: 'America/Asuncion' },
    { label: '(GMT-03:00) Belem', tzCode: 'America/Belem' },
    { label: '(GMT-03:00) Buenos Aires', tzCode: 'America/Argentina/Buenos_Aires' },
    { label: '(GMT-03:00) Campo Grande', tzCode: 'America/Campo_Grande' },
    { label: '(GMT-03:00) Cayenne', tzCode: 'America/Cayenne' },
    { label: '(GMT-03:00) Cuiaba', tzCode: 'America/Cuiaba' },
    { label: '(GMT-03:00) Fortaleza', tzCode: 'America/Fortaleza' },
    { label: '(GMT-03:00) Godthab', tzCode: 'America/Godthab' },
    { label: '(GMT-03:00) Maceio', tzCode: 'America/Maceio' },
    { label: '(GMT-03:00) Miquelon', tzCode: 'America/Miquelon' },
    { label: '(GMT-03:00) Montevideo', tzCode: 'America/Montevideo' },
    { label: '(GMT-03:00) Palmer', tzCode: 'Antarctica/Palmer' },
    { label: '(GMT-03:00) Paramaribo', tzCode: 'America/Paramaribo' },
    { label: '(GMT-03:00) Punta Arenas', tzCode: 'America/Punta_Arenas' },
    { label: '(GMT-03:00) Recife', tzCode: 'America/Recife' },
    { label: '(GMT-03:00) Rothera', tzCode: 'Antarctica/Rothera' },
    { label: '(GMT-03:00) Salvador', tzCode: 'America/Bahia' },
    { label: '(GMT-03:00) Santiago', tzCode: 'America/Santiago' },
    { label: '(GMT-03:00) Stanley', tzCode: 'Atlantic/Stanley' },
    { label: '(GMT-02:00) Noronha', tzCode: 'America/Noronha' },
    { label: '(GMT-02:00) Sao Paulo', tzCode: 'America/Sao_Paulo' },
    { label: '(GMT-02:00) South Georgia', tzCode: 'Atlantic/South_Georgia' },
    { label: '(GMT-01:00) Azores', tzCode: 'Atlantic/Azores' },
    { label: '(GMT-01:00) Cape Verde', tzCode: 'Atlantic/Cape_Verde' },
    { label: '(GMT-01:00) Scoresbysund', tzCode: 'America/Scoresbysund' },
    { label: '(GMT+00:00) Abidjan', tzCode: 'Africa/Abidjan' },
    { label: '(GMT+00:00) Accra', tzCode: 'Africa/Accra' },
    { label: '(GMT+00:00) Bissau', tzCode: 'Africa/Bissau' },
    { label: '(GMT+00:00) Canary Islands', tzCode: 'Atlantic/Canary' },
    { label: '(GMT+00:00) Casablanca', tzCode: 'Africa/Casablanca' },
    { label: '(GMT+00:00) Danmarkshavn', tzCode: 'America/Danmarkshavn' },
    { label: '(GMT+00:00) Dublin', tzCode: 'Europe/Dublin' },
    { label: '(GMT+00:00) El Aaiun', tzCode: 'Africa/El_Aaiun' },
    { label: '(GMT+00:00) Faeroe', tzCode: 'Atlantic/Faroe' },
    { label: '(GMT+00:00) GMT (no daylight saving)', tzCode: 'Etc/GMT' },
    { label: '(GMT+00:00) Lisbon', tzCode: 'Europe/Lisbon' },
    { label: '(GMT+00:00) London', tzCode: 'Europe/London' },
    { label: '(GMT+00:00) Monrovia', tzCode: 'Africa/Monrovia' },
    { label: '(GMT+00:00) Reykjavik', tzCode: 'Atlantic/Reykjavik' },
    { label: '(GMT+01:00) Algiers', tzCode: 'Africa/Algiers' },
    { label: '(GMT+01:00) Amsterdam', tzCode: 'Europe/Amsterdam' },
    { label: '(GMT+01:00) Andorra', tzCode: 'Europe/Andorra' },
    { label: '(GMT+01:00) Berlin', tzCode: 'Europe/Berlin' },
    { label: '(GMT+01:00) Brussels', tzCode: 'Europe/Brussels' },
    { label: '(GMT+01:00) Budapest', tzCode: 'Europe/Budapest' },
    { label: '(GMT+01:00) Central European Time - Belgrade', tzCode: 'Europe/Belgrade' },
    { label: '(GMT+01:00) Central European Time - Prague', tzCode: 'Europe/Prague' },
    { label: '(GMT+01:00) Ceuta', tzCode: 'Africa/Ceuta' },
    { label: '(GMT+01:00) Copenhagen', tzCode: 'Europe/Copenhagen' },
    { label: '(GMT+01:00) Gibraltar', tzCode: 'Europe/Gibraltar' },
    { label: '(GMT+01:00) Lagos', tzCode: 'Africa/Lagos' },
    { label: '(GMT+01:00) Luxembourg', tzCode: 'Europe/Luxembourg' },
    { label: '(GMT+01:00) Madrid', tzCode: 'Europe/Madrid' },
    { label: '(GMT+01:00) Malta', tzCode: 'Europe/Malta' },
    { label: '(GMT+01:00) Monaco', tzCode: 'Europe/Monaco' },
    { label: '(GMT+01:00) Ndjamena', tzCode: 'Africa/Ndjamena' },
    { label: '(GMT+01:00) Oslo', tzCode: 'Europe/Oslo' },
    { label: '(GMT+01:00) Paris', tzCode: 'Europe/Paris' },
    { label: '(GMT+01:00) Rome', tzCode: 'Europe/Rome' },
    { label: '(GMT+01:00) Stockholm', tzCode: 'Europe/Stockholm' },
    { label: '(GMT+01:00) Tirane', tzCode: 'Europe/Tirane' },
    { label: '(GMT+01:00) Tunis', tzCode: 'Africa/Tunis' },
    { label: '(GMT+01:00) Vienna', tzCode: 'Europe/Vienna' },
    { label: '(GMT+01:00) Warsaw', tzCode: 'Europe/Warsaw' },
    { label: '(GMT+01:00) Zurich', tzCode: 'Europe/Zurich' },
    { label: '(GMT+02:00) Amman', tzCode: 'Asia/Amman' },
    { label: '(GMT+02:00) Athens', tzCode: 'Europe/Athens' },
    { label: '(GMT+02:00) Beirut', tzCode: 'Asia/Beirut' },
    { label: '(GMT+02:00) Bucharest', tzCode: 'Europe/Bucharest' },
    { label: '(GMT+02:00) Cairo', tzCode: 'Africa/Cairo' },
    { label: '(GMT+02:00) Chisinau', tzCode: 'Europe/Chisinau' },
    { label: '(GMT+02:00) Damascus', tzCode: 'Asia/Damascus' },
    { label: '(GMT+02:00) Gaza', tzCode: 'Asia/Gaza' },
    { label: '(GMT+02:00) Helsinki', tzCode: 'Europe/Helsinki' },
    { label: '(GMT+02:00) Jerusalem', tzCode: 'Asia/Jerusalem' },
    { label: '(GMT+02:00) Johannesburg', tzCode: 'Africa/Johannesburg' },
    { label: '(GMT+02:00) Khartoum', tzCode: 'Africa/Khartoum' },
    { label: '(GMT+02:00) Kiev', tzCode: 'Europe/Kiev' },
    { label: '(GMT+02:00) Maputo', tzCode: 'Africa/Maputo' },
    { label: '(GMT+02:00) Moscow-01 - Kaliningrad', tzCode: 'Europe/Kaliningrad' },
    { label: '(GMT+02:00) Nicosia', tzCode: 'Asia/Nicosia' },
    { label: '(GMT+02:00) Riga', tzCode: 'Europe/Riga' },
    { label: '(GMT+02:00) Sofia', tzCode: 'Europe/Sofia' },
    { label: '(GMT+02:00) Tallinn', tzCode: 'Europe/Tallinn' },
    { label: '(GMT+02:00) Tripoli', tzCode: 'Africa/Tripoli' },
    { label: '(GMT+02:00) Vilnius', tzCode: 'Europe/Vilnius' },
    { label: '(GMT+02:00) Windhoek', tzCode: 'Africa/Windhoek' },
    { label: '(GMT+03:00) Baghdad', tzCode: 'Asia/Baghdad' },
    { label: '(GMT+03:00) Istanbul', tzCode: 'Europe/Istanbul' },
    { label: '(GMT+03:00) Minsk', tzCode: 'Europe/Minsk' },
    { label: '(GMT+03:00) Moscow+00 - Moscow', tzCode: 'Europe/Moscow' },
    { label: '(GMT+03:00) Nairobi', tzCode: 'Africa/Nairobi' },
    { label: '(GMT+03:00) Qatar', tzCode: 'Asia/Qatar' },
    { label: '(GMT+03:00) Riyadh', tzCode: 'Asia/Riyadh' },
    { label: '(GMT+03:00) Syowa', tzCode: 'Antarctica/Syowa' },
    { label: '(GMT+03:30) Tehran', tzCode: 'Asia/Tehran' },
    { label: '(GMT+04:00) Baku', tzCode: 'Asia/Baku' },
    { label: '(GMT+04:00) Dubai', tzCode: 'Asia/Dubai' },
    { label: '(GMT+04:00) Mahe', tzCode: 'Indian/Mahe' },
    { label: '(GMT+04:00) Mauritius', tzCode: 'Indian/Mauritius' },
    { label: '(GMT+04:00) Moscow+01 - Samara', tzCode: 'Europe/Samara' },
    { label: '(GMT+04:00) Reunion', tzCode: 'Indian/Reunion' },
    { label: '(GMT+04:00) Tbilisi', tzCode: 'Asia/Tbilisi' },
    { label: '(GMT+04:00) Yerevan', tzCode: 'Asia/Yerevan' },
    { label: '(GMT+04:30) Kabul', tzCode: 'Asia/Kabul' },
    { label: '(GMT+05:00) Aqtau', tzCode: 'Asia/Aqtau' },
    { label: '(GMT+05:00) Aqtobe', tzCode: 'Asia/Aqtobe' },
    { label: '(GMT+05:00) Ashgabat', tzCode: 'Asia/Ashgabat' },
    { label: '(GMT+05:00) Dushanbe', tzCode: 'Asia/Dushanbe' },
    { label: '(GMT+05:00) Karachi', tzCode: 'Asia/Karachi' },
    { label: '(GMT+05:00) Kerguelen', tzCode: 'Indian/Kerguelen' },
    { label: '(GMT+05:00) Maldives', tzCode: 'Indian/Maldives' },
    { label: '(GMT+05:00) Mawson', tzCode: 'Antarctica/Mawson' },
    { label: '(GMT+05:00) Moscow+02 - Yekaterinburg', tzCode: 'Asia/Yekaterinburg' },
    { label: '(GMT+05:00) Tashkent', tzCode: 'Asia/Tashkent' },
    { label: '(GMT+05:30) Colombo', tzCode: 'Asia/Colombo' },
    { label: '(GMT+05:30) India Standard Time', tzCode: 'Asia/Kolkata' },
    { label: '(GMT+05:45) Kathmandu', tzCode: 'Asia/Kathmandu' },
    { label: '(GMT+06:00) Almaty', tzCode: 'Asia/Almaty' },
    { label: '(GMT+06:00) Bishkek', tzCode: 'Asia/Bishkek' },
    { label: '(GMT+06:00) Chagos', tzCode: 'Indian/Chagos' },
    { label: '(GMT+06:00) Dhaka', tzCode: 'Asia/Dhaka' },
    { label: '(GMT+06:00) Moscow+03 - Omsk', tzCode: 'Asia/Omsk' },
    { label: '(GMT+06:00) Thimphu', tzCode: 'Asia/Thimphu' },
    { label: '(GMT+06:00) Vostok', tzCode: 'Antarctica/Vostok' },
    { label: '(GMT+06:30) Cocos', tzCode: 'Indian/Cocos' },
    { label: '(GMT+06:30) Rangoon', tzCode: 'Asia/Yangon' },
    { label: '(GMT+07:00) Bangkok', tzCode: 'Asia/Bangkok' },
    { label: '(GMT+07:00) Christmas', tzCode: 'Indian/Christmas' },
    { label: '(GMT+07:00) Davis', tzCode: 'Antarctica/Davis' },
    { label: '(GMT+07:00) Hanoi', tzCode: 'Asia/Saigon' },
    { label: '(GMT+07:00) Hovd', tzCode: 'Asia/Hovd' },
    { label: '(GMT+07:00) Jakarta', tzCode: 'Asia/Jakarta' },
    { label: '(GMT+07:00) Moscow+04 - Krasnoyarsk', tzCode: 'Asia/Krasnoyarsk' },
    { label: '(GMT+08:00) Brunei', tzCode: 'Asia/Brunei' },
    { label: '(GMT+08:00) China Time - Beijing', tzCode: 'Asia/Shanghai' },
    { label: '(GMT+08:00) Choibalsan', tzCode: 'Asia/Choibalsan' },
    { label: '(GMT+08:00) Hong Kong', tzCode: 'Asia/Hong_Kong' },
    { label: '(GMT+08:00) Kuala Lumpur', tzCode: 'Asia/Kuala_Lumpur' },
    { label: '(GMT+08:00) Macau', tzCode: 'Asia/Macau' },
    { label: '(GMT+08:00) Makassar', tzCode: 'Asia/Makassar' },
    { label: '(GMT+08:00) Manila', tzCode: 'Asia/Manila' },
    { label: '(GMT+08:00) Moscow+05 - Irkutsk', tzCode: 'Asia/Irkutsk' },
    { label: '(GMT+08:00) Singapore', tzCode: 'Asia/Singapore' },
    { label: '(GMT+08:00) Taipei', tzCode: 'Asia/Taipei' },
    { label: '(GMT+08:00) Ulaanbaatar', tzCode: 'Asia/Ulaanbaatar' },
    { label: '(GMT+08:00) Western Time - Perth', tzCode: 'Australia/Perth' },
    { label: '(GMT+08:30) Pyongyang', tzCode: 'Asia/Pyongyang' },
    { label: '(GMT+09:00) Dili', tzCode: 'Asia/Dili' },
    { label: '(GMT+09:00) Jayapura', tzCode: 'Asia/Jayapura' },
    { label: '(GMT+09:00) Moscow+06 - Yakutsk', tzCode: 'Asia/Yakutsk' },
    { label: '(GMT+09:00) Palau', tzCode: 'Pacific/Palau' },
    { label: '(GMT+09:00) Seoul', tzCode: 'Asia/Seoul' },
    { label: '(GMT+09:00) Tokyo', tzCode: 'Asia/Tokyo' },
    { label: '(GMT+09:30) Central Time - Darwin', tzCode: 'Australia/Darwin' },
    { label: "(GMT+10:00) Dumont D'Urville", tzCode: 'Antarctica/DumontDUrville' },
    { label: '(GMT+10:00) Eastern Time - Brisbane', tzCode: 'Australia/Brisbane' },
    { label: '(GMT+10:00) Guam', tzCode: 'Pacific/Guam' },
    { label: '(GMT+10:00) Moscow+07 - Vladivostok', tzCode: 'Asia/Vladivostok' },
    { label: '(GMT+10:00) Port Moresby', tzCode: 'Pacific/Port_Moresby' },
    { label: '(GMT+10:00) Truk', tzCode: 'Pacific/Chuuk' },
    { label: '(GMT+10:30) Central Time - Adelaide', tzCode: 'Australia/Adelaide' },
    { label: '(GMT+11:00) Casey', tzCode: 'Antarctica/Casey' },
    { label: '(GMT+11:00) Eastern Time - Hobart', tzCode: 'Australia/Hobart' },
    { label: '(GMT+11:00) Eastern Time - Melbourne, Sydney', tzCode: 'Australia/Sydney' },
    { label: '(GMT+11:00) Efate', tzCode: 'Pacific/Efate' },
    { label: '(GMT+11:00) Guadalcanal', tzCode: 'Pacific/Guadalcanal' },
    { label: '(GMT+11:00) Kosrae', tzCode: 'Pacific/Kosrae' },
    { label: '(GMT+11:00) Moscow+08 - Magadan', tzCode: 'Asia/Magadan' },
    { label: '(GMT+11:00) Norfolk', tzCode: 'Pacific/Norfolk' },
    { label: '(GMT+11:00) Noumea', tzCode: 'Pacific/Noumea' },
    { label: '(GMT+11:00) Ponape', tzCode: 'Pacific/Pohnpei' },
    { label: '(GMT+12:00) Funafuti', tzCode: 'Pacific/Funafuti' },
    { label: '(GMT+12:00) Kwajalein', tzCode: 'Pacific/Kwajalein' },
    { label: '(GMT+12:00) Majuro', tzCode: 'Pacific/Majuro' },
    { label: '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', tzCode: 'Asia/Kamchatka' },
    { label: '(GMT+12:00) Nauru', tzCode: 'Pacific/Nauru' },
    { label: '(GMT+12:00) Tarawa', tzCode: 'Pacific/Tarawa' },
    { label: '(GMT+12:00) Wake', tzCode: 'Pacific/Wake' },
    { label: '(GMT+12:00) Wallis', tzCode: 'Pacific/Wallis' },
    { label: '(GMT+13:00) Auckland', tzCode: 'Pacific/Auckland' },
    { label: '(GMT+13:00) Enderbury', tzCode: 'Pacific/Enderbury' },
    { label: '(GMT+13:00) Fakaofo', tzCode: 'Pacific/Fakaofo' },
    { label: '(GMT+13:00) Fiji', tzCode: 'Pacific/Fiji' },
    { label: '(GMT+13:00) Tongatapu', tzCode: 'Pacific/Tongatapu' },
    { label: '(GMT+14:00) Apia', tzCode: 'Pacific/Apia' },
    { label: '(GMT+14:00) Kiritimati', tzCode: 'Pacific/Kiritimati' },
];

export const minimalTimezoneSet: TimezoneType[] = [
    { label: 'UTC', tzCode: 'UTC' },
    { label: '(UTC-11:00) Pago Pago', tzCode: 'Pacific/Pago_Pago' },
    { label: '(UTC-10:00) Hawaii Time', tzCode: 'Pacific/Honolulu' },
    { label: '(UTC-10:00) Tahiti', tzCode: 'Pacific/Tahiti' },
    { label: '(UTC-09:00) Alaska Time', tzCode: 'America/Anchorage' },
    { label: '(UTC-08:00) Pacific Time', tzCode: 'America/Los_Angeles' },
    { label: '(UTC-07:00) Mountain Time', tzCode: 'America/Denver' },
    { label: '(UTC-06:00) Central Time', tzCode: 'America/Chicago' },
    { label: '(UTC-05:00) Eastern Time', tzCode: 'America/New_York' },
    { label: '(UTC-04:00) Atlantic Time - Halifax', tzCode: 'America/Halifax' },
    { label: '(UTC-03:00) Buenos Aires', tzCode: 'America/Argentina/Buenos_Aires' },
    { label: '(UTC-02:00) Sao Paulo', tzCode: 'America/Sao_Paulo' },
    { label: '(UTC-01:00) Azores', tzCode: 'Atlantic/Azores' },
    { label: '(UTC+00:00) London', tzCode: 'Europe/London' },
    { label: '(UTC+01:00) Berlin', tzCode: 'Europe/Berlin' },
    { label: '(UTC+02:00) Helsinki', tzCode: 'Europe/Helsinki' },
    { label: '(UTC+03:00) Istanbul', tzCode: 'Europe/Istanbul' },
    { label: '(UTC+04:00) Dubai', tzCode: 'Asia/Dubai' },
    { label: '(UTC+04:30) Kabul', tzCode: 'Asia/Kabul' },
    { label: '(UTC+05:00) Maldives', tzCode: 'Indian/Maldives' },
    { label: '(UTC+05:30) India Standard Time', tzCode: 'Asia/Calcutta' },
    { label: '(UTC+05:45) Kathmandu', tzCode: 'Asia/Kathmandu' },
    { label: '(UTC+06:00) Dhaka', tzCode: 'Asia/Dhaka' },
    { label: '(UTC+06:30) Cocos', tzCode: 'Indian/Cocos' },
    { label: '(UTC+07:00) Bangkok', tzCode: 'Asia/Bangkok' },
    { label: '(UTC+08:00) Hong Kong', tzCode: 'Asia/Hong_Kong' },
    { label: '(UTC+08:30) Pyongyang', tzCode: 'Asia/Pyongyang' },
    { label: '(UTC+09:00) Tokyo', tzCode: 'Asia/Tokyo' },
    { label: '(UTC+09:30) Central Time - Darwin', tzCode: 'Australia/Darwin' },
    { label: '(UTC+10:00) Eastern Time - Brisbane', tzCode: 'Australia/Brisbane' },
    { label: '(UTC+10:30) Central Time - Adelaide', tzCode: 'Australia/Adelaide' },
    { label: '(UTC+11:00) Eastern Time - Melbourne, Sydney', tzCode: 'Australia/Sydney' },
    { label: '(UTC+12:00) Nauru', tzCode: 'Pacific/Nauru' },
    { label: '(UTC+13:00) Auckland', tzCode: 'Pacific/Auckland' },
    { label: '(UTC+14:00) Kiritimati', tzCode: 'Pacific/Kiritimati' },
];

export const cleanTimezoneSet: TimezoneType[] = [
    { label: 'UTC', tzCode: 'UTC' },
    { label: 'GMT-11:00', tzCode: 'Pacific/Pago_Pago' },
    { label: 'GMT-10:00', tzCode: 'Pacific/Honolulu' },
    { label: 'GMT-10:00', tzCode: 'Pacific/Tahiti' },
    { label: 'GMT-09:00', tzCode: 'America/Anchorage' },
    { label: 'GMT-08:00', tzCode: 'America/Los_Angeles' },
    { label: 'GMT-07:00', tzCode: 'America/Denver' },
    { label: 'GMT-06:00', tzCode: 'America/Chicago' },
    { label: 'GMT-05:00', tzCode: 'America/New_York' },
    { label: 'GMT-04:00', tzCode: 'America/Halifax' },
    { label: 'GMT-03:00', tzCode: 'America/Argentina/Buenos_Aires' },
    { label: 'GMT-02:00', tzCode: 'America/Sao_Paulo' },
    { label: 'GMT-01:00', tzCode: 'Atlantic/Azores' },
    { label: 'GMT+00:00', tzCode: 'Europe/London' },
    { label: 'GMT+01:00', tzCode: 'Europe/Berlin' },
    { label: 'GMT+02:00', tzCode: 'Europe/Helsinki' },
    { label: 'GMT+03:00', tzCode: 'Europe/Istanbul' },
    { label: 'GMT+04:00', tzCode: 'Asia/Dubai' },
    { label: 'GMT+04:30', tzCode: 'Asia/Kabul' },
    { label: 'GMT+05:00', tzCode: 'Indian/Maldives' },
    { label: 'GMT+05:30', tzCode: 'Asia/Calcutta' },
    { label: 'GMT+05:45', tzCode: 'Asia/Kathmandu' },
    { label: 'GMT+06:00', tzCode: 'Asia/Dhaka' },
    { label: 'GMT+06:30', tzCode: 'Indian/Cocos' },
    { label: 'GMT+07:00', tzCode: 'Asia/Bangkok' },
    { label: 'GMT+08:00', tzCode: 'Asia/Hong_Kong' },
    { label: 'GMT+08:30', tzCode: 'Asia/Pyongyang' },
    { label: 'GMT+09:00', tzCode: 'Asia/Tokyo' },
    { label: 'GMT+09:30', tzCode: 'Australia/Darwin' },
    { label: 'GMT+10:00', tzCode: 'Australia/Brisbane' },
    { label: 'GMT+10:30', tzCode: 'Australia/Adelaide' },
    { label: 'GMT+11:00', tzCode: 'Australia/Sydney' },
    { label: 'GMT+12:00', tzCode: 'Pacific/Nauru' },
    { label: 'GMT+13:00', tzCode: 'Pacific/Auckland' },
    { label: 'GMT+14:00', tzCode: 'Pacific/Kiritimati' },
];
