




























































import { computed, defineComponent, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';

dayjs.extend(utc);

export default defineComponent({
    name: 'DateTimePicker',
    model: {
        prop: 'datetime',
        event: 'change',
    },
    props: {
        datetime: {
            type: String,
        },
        mode: {
            type: String,
            default: 'datetime',
        },
        dateDisplayFormat: {
            type: String,
            default: 'DD MMMM YYYY',
        },
        timeDisplayFormat: {
            type: String,
            default: 'HH:mm',
        },
        displayPrefix: {
            type: String,
            default: '',
        },
        displaySuffix: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        defaultToNow: {
            type: Boolean,
            default: true,
        },
        noDateMessage: {
            type: String,
            default: 'No date selected',
        },
        showClear: {
            type: Boolean,
            default: false,
        },
        dispatchChangeEventOnInit: {
            type: Boolean,
            default: false,
        },
        minDate: {
            type: String,
            default: null,
        },
        maxDate: {
            type: String,
            default: null,
        },
    },
    setup(props, { emit }) {
        const openPicker = ref<boolean>(false);

        const formatValue = (date: Date) => {
            if (R.isNil(date)) return null;

            const isoFormat = dayjs(date).utc().toISOString();
            switch (props.mode) {
                case 'date':
                    return R.split('T', isoFormat)[0];
                case 'time':
                    return R.split('T', isoFormat)[1];
                default:
                    return isoFormat;
            }
        };

        const formatIntoDate = (datetimeString: string) => {
            const isoFormat = dayjs.utc().toISOString();
            switch (props.mode) {
                case 'date':
                    return dayjs.utc(`${datetimeString}T${R.split('T', isoFormat)[1]}`).toISOString();
                case 'time':
                    return dayjs.utc(`${R.split('T', isoFormat)[0]}T${datetimeString}`).toISOString();
                default:
                    return dayjs.utc(datetimeString).toISOString();
            }
        };

        const displayFormat = computed(() => {
            switch (props.mode) {
                case 'date':
                    return props.dateDisplayFormat;
                case 'time':
                    return props.timeDisplayFormat;
                default:
                    return `${props.dateDisplayFormat} ${props.timeDisplayFormat}`;
            }
        });

        const tempDatetime = computed({
            get: (): string | null => {
                if (R.isNil(props.datetime)) {
                    return null;
                }

                return formatIntoDate(props.datetime);
            },
            set: (newDatetime: any) => {
                const formattedNewDate = formatValue(newDatetime);

                if (formattedNewDate !== props.datetime) {
                    emit('change', formattedNewDate);
                }
            },
        });

        const displayDate = computed(() =>
            tempDatetime.value
                ? `${props.displayPrefix} ${dayjs.utc(tempDatetime.value).format(displayFormat.value)} ${
                      props.displaySuffix
                  }`
                : null,
        );

        const clear = () => {
            emit('change', null);
        };

        const close = () => {
            openPicker.value = false;
        };

        if (props.defaultToNow && R.isNil(props.datetime)) {
            const newDate = formatValue(new Date());
            emit('change', newDate);
        }

        if (props.dispatchChangeEventOnInit) {
            emit('change', tempDatetime.value);
        }

        return { openPicker, tempDatetime, displayDate, clear, close };
    },
});
