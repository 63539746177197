











import { PropType, defineComponent } from '@vue/composition-api';
import { Component } from 'vue/types/options';

export default defineComponent({
    name: 'GraphError',
    props: {
        error: {
            type: Object as PropType<{ message: string; tooltip: string; icon: Component; classes: string }>,
            required: true,
        },
    },
});
