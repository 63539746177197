






import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import { useKeycloak } from '@/modules/auth/composables/keycloak';
import { useOrganisation } from '@/modules/organization/composable';
import { UserStatus } from '@/modules/organization/constants';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { useLocations, useModelBrowser } from '../composable';
import { EmptyLayout, selectLayout } from '../layout';

export default defineComponent({
    name: 'App',
    metaInfo: {
        titleTemplate: '%s - Product',
    },
    setup(props, { root }) {
        // initializes services like feature flags
        store.dispatch.featureFlags.initialize();
        const { isEnabled, isAuthenticated, initializeKeycloak } = useKeycloak();
        const isGuest = computed(
            () =>
                store.state.auth.user &&
                !R.isEmpty(store.state.auth.user) &&
                store.state.auth.user.status === UserStatus.Pending &&
                isEnabled(),
        );
        const layout = computed(() => (isGuest.value ? EmptyLayout : selectLayout(root.$route.name)));
        const isReady = ref(false);

        // If there's no user loaded, try to fetch it
        if (!store.state.auth.user) {
            Auth.user()
                .then(async ({ data }) => {
                    if (isEnabled() && !isAuthenticated()) await initializeKeycloak();
                    store.commit.auth.SET_USER(data);
                    if (data.status === UserStatus.Pending && !data.organisationId) {
                        root.$router.push({ name: 'organization-registration' });
                    } else if (data.status === UserStatus.Pending && data.organisationId) {
                        root.$router.push({ name: 'unverified-organization' });
                    } else {
                        store.dispatch.notificationEngine.fetchNotifications();
                        store.dispatch.modelBrowser.initialize();
                        store.dispatch.executionVersions.loadVersions();
                        store.dispatch.executionErrors.loadExecutionErrors();
                        store.dispatch.dataModel.loadDomains();
                        useModelBrowser().initialize();
                        useLocations().initialize();
                        if (data.organisationId) useOrganisation(data.organisationId).initialize();
                    }
                    isReady.value = true;
                })
                .catch(() => {
                    isReady.value = true;
                });
        } else {
            // Users that register for the first time in the platform without organization
            if (store.state.auth.user.status === UserStatus.Pending && !store.state.auth.user.organisationId) {
                root.$router.push({ name: 'organization-registration' });
                // Users that have already register an organization and waiting for verification
            } else if (store.state.auth.user.status === UserStatus.Pending && store.state.auth.user.organisationId) {
                root.$router.push({ name: 'unverified-organization' });
            } else {
                store.dispatch.notificationEngine.fetchNotifications();
                useModelBrowser().initialize();
            }
        }

        return { layout, isReady };
    },
});
