export class ESortingOption {
    static readonly RELEVANCE = new ESortingOption('relevance', false, 'Relevance');

    constructor(public readonly field: string, public readonly asc: boolean, public readonly label: string) {
        this.field = field;
        this.asc = asc;
        this.label = label;
    }

    static all() {
        return [this.RELEVANCE];
    }

    static available(relevanceAvailable: boolean): ESortingOption[] {
        if (relevanceAvailable) return [this.RELEVANCE];
        return [];
    }

    static default(relevanceAvailable: boolean): ESortingOption {
        throw new Error('Not implemented');
    }

    static find(field: string, asc: boolean, relevanceAvailable: boolean): ESortingOption | null {
        for (let i = 0; i < this.available(relevanceAvailable).length; i++) {
            const sortOption = this.available(relevanceAvailable)[i];
            if (JSON.stringify(sortOption.field) === JSON.stringify(field) && sortOption.asc === asc) {
                return sortOption;
            }
        }
        return null;
    }

    static findByLabel(label: string, relevanceAvailable: boolean): ESortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.available(relevanceAvailable)[i];
            if (sortOption.label === label) {
                return sortOption;
            }
        }
        return null;
    }

    protected static calculateDefault(relevanceAvailable: boolean): ESortingOption | undefined {
        if (relevanceAvailable) return this.RELEVANCE;
        return undefined;
    }
}
