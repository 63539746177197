











































































































































































import { SvgImage } from '@/app/components';
import { useSockets } from '@/app/composable/socket';
import store from '@/app/store';
import { StatusCode } from '@/modules/asset/constants';
import {
    CloudDownloadIcon,
    CloudUploadIcon,
    DesktopComputerIcon,
    HomeIcon,
    SearchIcon,
    ShareIcon,
    TemplateIcon,
    ViewGridIcon,
    XIcon,
    ChartSquareBarIcon,
} from '@vue-hero-icons/outline';
import { computed, defineComponent } from '@vue/composition-api';
import { useFeatureFlags } from '../composable';
import { UserRoles } from '../constants';

export default defineComponent({
    name: 'SideMenu',
    components: { HomeIcon, CloudUploadIcon, ViewGridIcon, SearchIcon, ShareIcon, TemplateIcon, XIcon, SvgImage },
    props: {
        mobileMenuOpen: { type: Boolean, default: false },
    },
    setup() {
        const { isEnabled: isFeatureEnabled, areAnyEnabled: areAnyFeaturesEnabled } = useFeatureFlags();
        const isModelModerator = computed(() => store.getters.auth.hasRole(UserRoles.ModelModerator));
        const isAdmin = computed(() => store.getters.auth.hasRole(UserRoles.Admin));
        const user = computed(() => store.state.auth.user);
        const { connected, socketConnect } = useSockets();
        const defaultAssetStatus = Object.values(StatusCode).filter(
            (statusCode: StatusCode) => ![StatusCode.Deleted, StatusCode.Archived].includes(statusCode),
        );

        const sidebarNavigation = computed(() => [
            {
                name: 'Home',
                route: 'dashboard',
                icon: HomeIcon,
                exact: true,
                visible: !!user.value?.organisationId || isAdmin.value,
            },
            {
                name: 'Data Check-in',
                route: 'data-checkin-jobs',
                icon: CloudUploadIcon,
                exact: false,
                visible: !!user.value?.organisationId,
            },
            {
                name: 'My Assets',
                route: 'assets',
                query: { status: defaultAssetStatus.join(','), reset: true },
                icon: ViewGridIcon,
                exact: false,
                visible: !!user.value?.organisationId,
            },
            {
                name: 'Analytics',
                route: 'workflows',
                icon: ShareIcon,
                exact: false,
                visible: isFeatureEnabled('analytics') && !!user.value?.organisationId,
            },
            {
                name: 'Dashboards',
                href: process.env.VUE_APP_DASHBOARD_BUILDER,
                icon: ChartSquareBarIcon,
                exact: false,
                visible: isFeatureEnabled('dashboard.builder') && !!user.value?.organisationId,
            },
            {
                name: 'Retrieval',
                route: 'retrieval',
                icon: CloudDownloadIcon,
                exact: false,
                visible:
                    areAnyFeaturesEnabled(['retrieve.api', 'retrieve.files', 'retrieve.stream']) &&
                    !!user.value?.organisationId,
            },
            {
                name: 'Data Models',
                route: 'model-manager',
                icon: TemplateIcon,
                exact: false,
                visible: isFeatureEnabled('model-manager') && (isModelModerator.value || isAdmin.value),
            },
            {
                name: 'Admin Portal',
                route: 'admin-portal',
                icon: DesktopComputerIcon,
                exact: false,
                visible: isAdmin.value,
            },
        ]);

        return { sidebarNavigation, isModelModerator, connected, socketConnect };
    },
});
