import { AxiosRequestConfig } from 'axios';
import { PaginateQuery } from '../interfaces/paginate-query.interface';

const endpoint = 'api/exception-event';

export default {
    getCount: () => ({ method: 'GET', url: `${endpoint}/count` } as AxiosRequestConfig),
    getEvents: (query: PaginateQuery, sortBy?: { field: string; asc: boolean }) => {
        const params = {
            page: query.page,
            limit: query.pageSize,
        };

        if (sortBy) {
            params['sortBy'] = `${sortBy.field}:${sortBy.asc ? 'ASC' : 'DESC'}`;
        }

        return {
            method: 'GET',
            url: `${endpoint}`,
            params,
        } as AxiosRequestConfig;
    },
    trigger: (data: any) => ({ method: 'PUT', url: `${endpoint}/trigger`, data } as AxiosRequestConfig),
    ignore: (data: any) => ({ method: 'POST', url: `${endpoint}/ignore`, data } as AxiosRequestConfig),
};
