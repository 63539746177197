import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';
import * as R from 'ramda';

export interface Location {
    label: string;
    id: string;
    flag?: string;
    children?: Location[];
    countryId?: string;
    countryContinentId?: string;
}

export interface Country {
    id: string;
    code: string;
    name: string;
    flag: string;
    continent?: { id: string };
}

export interface Continent {
    countries: Country[];
    name: string;
}

export interface LocationsState {
    locations: Location[];
}

export const locationsModule = defineModule({
    namespaced: true,
    state: (): LocationsState => {
        return {
            locations: [],
        };
    },
    mutations: {
        SET_LOCATIONS(state: LocationsState, locations: Location[]) {
            state.locations = locations;
        },
    },
    getters: {
        locationOptions: (state: LocationsState): Location[] => state.locations,
        countries: (state: LocationsState): Country[] => {
            if (state.locations.length && state.locations[0].children) {
                return state.locations[0].children.reduce((acc: Country[], continent: Location) => {
                    if (continent.children)
                        continent.children.forEach((country: Location) => {
                            acc.push({
                                code: country.id,
                                name: country.label,
                                flag: country.flag ?? '',
                                id: country.countryId ?? '',
                                continent: { id: country.countryContinentId ?? '' },
                            });
                        });
                    return acc;
                }, []);
            }
            return [];
        },
    },
    actions: {
        setLocations({ commit }, continents: Continent[]) {
            const sort = R.sortBy(R.prop('name'));
            const locations: Location[] = [
                {
                    label: 'Global',
                    id: 'GLOBAL',
                    children: sort(continents).reduce((acc: Location[], continent: Continent) => {
                        acc.push({
                            label: continent.name,
                            id: continent.name.toUpperCase(),
                            children: sort(continent.countries).reduce((cacc: Location[], country: Country) => {
                                cacc.push({
                                    label: country.name,
                                    id: country.code,
                                    flag: country.flag,
                                    countryId: country.id,
                                    countryContinentId: country.continent?.id,
                                });
                                return cacc;
                            }, []),
                        });
                        return acc;
                    }, []),
                },
            ];
            commit('SET_LOCATIONS', locations);
        },
    },
});

export const locationsModuleActionContext = (context: any) => moduleActionContext(context, locationsModule);
