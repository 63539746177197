import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';
import { WebSocketsRoom } from '../interfaces';

export interface WebSocketsState {
    joinedRooms: WebSocketsRoom[];
    connected: boolean;
}

export const webSocketsModule = defineModule({
    namespaced: true,
    state: (): WebSocketsState => {
        return {
            joinedRooms: [],
            connected: false,
        };
    },
    mutations: {
        ADD_ROOM(state: WebSocketsState, room: WebSocketsRoom) {
            state.joinedRooms = [room, ...state.joinedRooms];
        },
        REMOVE_ROOM(state: WebSocketsState, room: WebSocketsRoom) {
            state.joinedRooms = state.joinedRooms.filter(
                (rm: WebSocketsRoom) => rm.type !== room.type && rm.entityId !== room.entityId,
            );
        },
        SET_WEBSOCKETS_CONNECTED(state: WebSocketsState) {
            state.connected = true;
        },
        SET_WEBSOCKETS_DISCONNECTED(state: WebSocketsState) {
            state.connected = false;
        },
    },
    actions: {
        addRoom({ state, commit }, room) {
            if (
                !state.joinedRooms.find(
                    (joinedRoom: WebSocketsRoom) => JSON.stringify(joinedRoom) === JSON.stringify(room),
                )
            )
                commit('ADD_ROOM', room);
        },
        removeRoom({ commit }, room) {
            commit('REMOVE_ROOM', room);
        },
        setWebSocketsConnected({ commit }) {
            commit('SET_WEBSOCKETS_CONNECTED');
        },
        setWebSocketsDisconnected({ commit }) {
            commit('SET_WEBSOCKETS_DISCONNECTED');
        },
    },
});

export const webSocketsModuleActionContext = (context: any) => moduleActionContext(context, webSocketsModule);
