









import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'Tab',
    props: {
        tab: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
        lastElement: {
            type: Boolean,
            default: false,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
        breadcrumbMode: {
            type: Boolean,
            default: true,
        },
        totalTabs: {
            type: Number,
            required: true,
        },
        compact: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const currentlyActive = computed(() => props.index === props.activeTab);
        const active = computed(() => (props.breadcrumbMode ? props.index <= props.activeTab : currentlyActive.value));
        const hideRoundingLeft = computed(() => {
            if (props.breadcrumbMode && active.value && props.index > 0) {
                return true;
            }
            return false;
        });
        const hideRoundingRight = computed(() => {
            const isLastElement = props.index === props.totalTabs - 1;
            const isCurrentlyActive = props.index === props.activeTab;
            if (props.breadcrumbMode && !isLastElement && !isCurrentlyActive) {
                return true;
            }
            return false;
        });
        const tabClicked = () => {
            if (props.clickable) {
                emit('click', props.index);
            }
        };
        return { currentlyActive, active, hideRoundingLeft, hideRoundingRight, tabClicked };
    },
});
