export enum AlertSourceType {
    DataCheckin = 'data-checkin',
    Analytics = 'analytics',
    Dataset = 'dataset',
    Result = 'result',
}

export enum AlertEntityType {
    Workflow = 'Workflow',
    Asset = 'Asset',
}

export enum AlertEventType {
    DataCheckinFailed = 'DATA_CHECKIN_PIPELINE_FAILED',
    AnalyticsFailed = 'ANALYTICS_PIPELINE_FAILED',
    AnalyticsSkipped = 'ANALYTICS_PIPELINE_SKIPPED',
    AnalyticsTaskFailed = 'ANALYTICS_TASK_FAILED',
    DatasetTimeliness = 'DATASET_TIMELINESS',
    DatasetCompleteness = 'DATASET_COMPLETENESS',
    DatasetQualityImprovements = 'DATASET_QUALITY_IMPROVEMENTS',
    ResultTimeliness = 'RESULT_TIMELINESS',
    ResultCompleteness = 'RESULT_COMPLETENESS',
}

export enum QualityMetrics {
    Timeliness = 'timeliness',
    Completeness = 'completeness',
    QualityImprovenents = 'quality improvements',
}
