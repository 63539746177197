


















import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { useJsonObject } from '../composable';
import JsonView from './JsonView.vue';

export default defineComponent({
    name: 'JsonParser',
    model: {
        prop: 'selectedItems',
        event: 'updateSelectedItems',
    },
    components: {
        JsonView,
    },
    props: {
        json: {
            type: [Object, Array],
            required: false,
        },
        selectedItems: {
            type: Array,
            required: false,
        },
        convert: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Boolean,
            default: true,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        showLine: {
            type: Boolean,
            default: false,
        },
        disableSelectionOfMultipleObjectArrays: {
            type: Boolean,
            default: false,
        },
        focusPath: {
            type: String,
            default: null,
        },
        separator: {
            type: String,
            default: '.',
        },
        disabledPaths: {
            type: Array,
            default: () => [],
        },
        additionalData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        jsonSize: {
            type: Number,
            default: 10,
        },
        emptyMessage: {
            type: String,
            default: '',
        },
        path: {
            type: String,
            default: 'res',
        },
    },
    setup(props, { emit }) {
        const objectArrays = new Map();

        const { typeMismatches, getSuperObject, addMissingFields, convertToJSON, getAllPaths } = useJsonObject();

        /**
         * @param objectArray  The map object in which to store key to complex object key mapping.
         * @param res The json response object itself
         * @param prefix From which key prefix are we coming from
         * @param parentArrayKey The complext object parent key if we are coming from any
         */
        const markObjectArrays = (
            objectArray: Map<string, string>,
            res: any,
            prefix = props.path,
            parentArrayKey?: string,
        ): any => {
            if (res instanceof Array) {
                for (let i = 0; i < res.length; i += 1) {
                    const newKey = `${prefix}[${i}]`;

                    if (res[i] instanceof Object) {
                        // add it to map if it is a complex object
                        objectArrays.set(prefix, prefix);
                        markObjectArrays(objectArrays, res[i], newKey, prefix);
                    } else {
                        markObjectArrays(objectArrays, res[i], newKey);
                    }
                }
            } else if (res instanceof Object) {
                const keys = Object.keys(res);

                if (!R.isEmpty(parentArrayKey)) {
                    // add it to map if it belongs to a complex object
                    objectArrays.set(prefix, parentArrayKey);
                }

                for (let i = 0; i < keys.length; i += 1) {
                    const objectKey = keys[i];
                    const newKey = `${prefix}.${objectKey}`;
                    markObjectArrays(objectArrays, res[objectKey], newKey, parentArrayKey);
                }
            } else if (!R.isEmpty(parentArrayKey)) {
                // add it to map if it belongs to a complex object
                objectArrays.set(prefix, parentArrayKey);
            }
        };

        const addAdditionalData = (obj: any) => {
            if (R.isNil(obj)) return [];

            const objClone: any = R.clone<any>(obj);
            for (let a = 0; a < Object.keys(props.additionalData).length; a++) {
                const additionalKey: string = Object.keys(props.additionalData)[a];
                if (Array.isArray(objClone)) {
                    if (objClone.length > 0) {
                        for (let j = 0; j < objClone.length; j++) {
                            objClone[j][additionalKey] = props.additionalData[additionalKey];
                        }
                    } else {
                        for (let j = 0; j < props.jsonSize; j++) {
                            objClone[j] = { [additionalKey]: props.additionalData[additionalKey] };
                        }
                    }
                } else {
                    objClone[additionalKey] = props.additionalData[additionalKey];
                }
            }
            return objClone;
        };

        const data = computed(() => {
            if (R.isEmpty(props.json)) {
                return null;
            }
            const jsonObject = addAdditionalData(R.clone(props.json));

            if (props.convert) {
                return convertToJSON(jsonObject, props.path, props.separator);
            }
            return jsonObject;
        });

        // create complex object map if disabling of multiple
        // complex objects is selected
        if (props.disableSelectionOfMultipleObjectArrays) {
            markObjectArrays(objectArrays, data.value);
        }
        const allPaths = ref<string[]>(getAllPaths(data.value, '', '', props.separator));

        const getNestedArrayNodes = (node: string, nodes: string[] = []): string[] => {
            if (node.endsWith('[0]')) {
                const n = node.slice(0, -3);
                nodes.push(n);

                return [...nodes, ...getNestedArrayNodes(n)];
            }
            return nodes;
        };

        const getParentNodes = (node: string): string[] => {
            let nodes: string[] = [];
            if (node.endsWith('[0]')) {
                nodes = getNestedArrayNodes(node, nodes);
            }

            const parts = node.split(props.separator);
            for (let i = parts.length - 1; i > 0; i -= 1) {
                parts.pop();

                const parent = parts.join(props.separator);
                nodes.push(parent);

                // get nested array nodes
                nodes = getNestedArrayNodes(parent, nodes);
            }
            return nodes;
        };

        const getChildrenNodes = (node: string): string[] => {
            const childrenNodes = [];
            for (let i = 0; i < allPaths.value.length; i += 1) {
                if (allPaths.value[i].startsWith(`${node}${props.separator}`)) {
                    childrenNodes.push(allPaths.value[i]);
                }
            }
            return childrenNodes;
        };

        const removeElement = (obj: any, element: string) => {
            if (obj === undefined) {
                return;
            }

            if (R.is(Array, obj)) {
                for (const item of obj) {
                    removeElement(item, element);
                }
            }

            const parts = element.split(props.separator);
            if (parts.length === 1) {
                delete obj[element]; // eslint-disable-line no-param-reassign
                return;
            }
            let first = parts[0];
            parts.shift();
            const newElement = parts.join(props.separator);
            if (first.endsWith('[0]')) {
                first = first.replaceAll('[0]', '');
                let array: any[];
                if (first.length === 0) {
                    array = obj;
                } else {
                    array = obj[first];
                }
                if (typeof array === 'undefined') {
                    return;
                }
                for (let i = 0; i < array.length; i += 1) {
                    if (R.is(Array, array[i])) {
                        for (const item of array[i]) {
                            removeElement(item, newElement);
                        }
                    } else {
                        removeElement(array[i], newElement);
                    }
                }
            } else {
                removeElement(obj[first], newElement);
            }
        };

        const isObjectEmpty = (obj: any) => {
            return R.isNil(obj) || (!R.is(String, obj) && R.isEmpty(obj));
        };

        const dropEmpty = (obj: any): any =>
            R.type(obj) === 'Object' || R.type(obj) === 'Array' ? R.reject(isObjectEmpty, R.map(dropEmpty, obj)) : obj;

        const changeSelection = (items: any) => {
            // Resets currently selected complex object in case it was disselected
            emit('updateSelectedItems', items);
            const selected: string[] = [];
            let obj: any = props.json && R.is(Array, props.json) ? [] : {};
            if (items.length > 0) {
                obj = R.clone(props.json);

                const all = getAllPaths(data.value, '', props.path, props.separator);
                const unselectedPaths = R.difference(all, items);
                for (let i = 0; i < unselectedPaths.length; i += 1) {
                    const parentNodes = getParentNodes(unselectedPaths[i]);
                    items = items.filter((item: any) => !parentNodes.includes(item));
                }

                for (let i = 0; i < items.length; i += 1) {
                    if (R.startsWith(`${props.path}${props.separator}`, items[i])) {
                        selected.push(R.replace(`${props.path}${props.separator}`, '', items[i]));
                    } else {
                        selected.push(R.replace(`${props.path}`, '', items[i]));
                    }
                }

                const remaining = new Set();
                for (let i = 0; i < selected.length; i += 1) {
                    remaining.add(selected[i]);
                    const parentNodes = getParentNodes(selected[i]);
                    for (let j = 0; j < parentNodes.length; j += 1) {
                        remaining.add(parentNodes[j]);
                    }
                    const childrenNodes = getChildrenNodes(selected[i]);
                    for (let j = 0; j < childrenNodes.length; j += 1) {
                        remaining.add(childrenNodes[j]);
                    }
                }

                const removing = allPaths.value.filter((node: string) => !remaining.has(node));

                for (let i = 0; i < removing.length; i += 1) {
                    removeElement(obj, removing[i]);
                }
            }
            const addObj = dropEmpty(addAdditionalData(obj));
            const superObj = getSuperObject(R.clone(addObj), {});
            emit('selected-data-changed', addMissingFields(R.clone(addObj), superObj));
            emit('selected-items-changed', items);
        };

        if (props.selectedItems !== undefined) {
            if (props.selectedItems.length === allPaths.value.length) changeSelection(['res', ...props.selectedItems]);
            else changeSelection(props.selectedItems);
        }

        watch(
            () => props.additionalData,
            () => changeSelection(props.selectedItems || []),
        );

        watch(
            () => props.json,
            () => emit('json-response-changed', convertToJSON(props.json, props.path, props.separator)),
            { immediate: true },
        );

        return { changeSelection, data, typeMismatches };
    },
});
