import { computed, Ref, ref, watch } from '@vue/composition-api';
import Fuse from 'fuse.js';
import { pluck } from 'ramda';

export const useFuse = <T = Record<string, any>>(query: Ref<string>, data: Ref<T[]>, options: Fuse.IFuseOptions<T>) => {
    const createFuse = () => {
        return new Fuse(data.value ?? [], options);
    };

    const fuse = ref(createFuse());

    watch(
        () => data.value,
        (newValue) => {
            fuse.value.setCollection(newValue);
        },
        { deep: true },
    );

    const result = computed<T[]>(() => {
        if (query.value === '') return data.value;
        return pluck('item', fuse.value.search(query.value));
    });

    return { result };
};
