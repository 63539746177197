




















































































/**
 * A component allowing for the display of a JSON object
 * as a tree giving the ability to the user to select nodes
 * of the JSON
 */

import { computed, defineComponent } from '@vue/composition-api';
import * as R from 'ramda';
import { ExclamationIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'JsonView',
    components: { ExclamationIcon },
    props: {
        selectedItems: {
            type: Array,
            default() {
                return [];
            },
        },
        identifier: {
            type: String,
            required: true,
        },
        data: {
            type: [Object, Array, String, Number, Boolean],
            required: false,
        },
        separator: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        hasNext: {
            type: Boolean,
            default: false,
        },
        isRoot: {
            type: Boolean,
            default: true,
        },
        focusPath: {
            type: String,
            default: null,
        },
        disabledPaths: {
            type: Array,
            default: () => [],
        },
        unselectablePaths: {
            type: Array,
            default: () => [],
        },
        emptyMessage: {
            type: String,
            default: '',
        },
        typeMismatches: {
            type: Array,
            default: () => [],
        },
    },
    setup(props: any, { emit }: { emit: any }) {
        const isObject = computed(() => !R.isNil(props.data) && props.data instanceof Object);
        const isArray = computed(() => !R.isNil(props.data) && Array.isArray(props.data));
        const isObjectEmpty = (obj: any) => {
            return R.isNil(obj) || (!R.is(String, obj) && R.isEmpty(obj));
        };
        const emptyChildren = (data: any) => {
            if (R.is(Array, data)) {
                const children = data.reduce((accumulator: any[], node: any) => {
                    if (R.is(Array, node)) {
                        if (!emptyChildren(node)) {
                            accumulator.push(node);
                        }
                    } else if (R.is(Object, node)) {
                        if (!emptyChildren(node)) {
                            accumulator.push(node);
                        }
                    } else if (!isObjectEmpty(node)) {
                        accumulator.push(node);
                    }
                    return accumulator;
                }, []);
                return R.isEmpty(children);
            }
            if (R.is(Object, data)) {
                const children = Object.keys(data).reduce((accumulator: any, key: any) => {
                    const node = data[key];

                    if (R.is(Array, node)) {
                        if (!emptyChildren(node)) {
                            return { ...accumulator, [key]: node };
                        }
                    } else if (R.is(Object, node)) {
                        if (!emptyChildren(node)) {
                            return { ...accumulator, [key]: node };
                        }
                    } else if (!isObjectEmpty(node)) {
                        return { ...accumulator, [key]: node };
                    }
                    return accumulator;
                }, {});
                return R.isEmpty(children);
            }

            return isObjectEmpty(data);
        };
        const isEmpty = computed(() => isObjectEmpty(props.data) || emptyChildren(props.data));

        const isNumber = (val: any) => {
            return R.is(Number, val);
        };

        const highlightThisNode = computed(() => props.focusPath && props.identifier.startsWith(props.focusPath));

        const getChildren = (parentId: string, children: any) => {
            let selectedPaths: string[] = [];
            if (R.is(Array, children)) {
                children.forEach((child: any, index: number) => {
                    const childFullKey = `${parentId}[${index}]`;
                    selectedPaths.push(childFullKey);
                    selectedPaths = selectedPaths.concat(getChildren(childFullKey, child));
                });
            } else if (R.is(Object, children)) {
                Object.keys(children).forEach((childKey: any) => {
                    const child = children[childKey];
                    const childFullKey = `${parentId}${props.separator}${childKey}`;
                    selectedPaths.push(childFullKey);
                    if (R.is(Object, child) && Object.keys(child).length > 0) {
                        selectedPaths = selectedPaths.concat(getChildren(childFullKey, child));
                    }
                });
            }

            return selectedPaths;
        };
        const hasChildren = computed(() => R.is(Object, props.data));

        const isDisabled = computed(() => props.disabledPaths.includes(props.identifier));

        const isUnselectable = computed(() => props.unselectablePaths.includes(props.identifier));

        const isTypeMismatch = computed(() => props.typeMismatches.includes(props.identifier));

        /**
         * Called for the rendering of each checkbox when the component is selectable
         * Decides based on a given function whether or not the checkbox will be shown
         */
        const isSelectable = () => {
            return props.selectable && !isUnselectable.value && !isEmpty.value;
        };

        const disselectSelfAndAllParents = (path: string, selections: string[]) => {
            const result = [...selections];

            // splits the path by . to find all parts and rebuilds a list of parents and itself
            // i.e for path 'a.b.c.d' we get ['a', 'a.b', 'a.b.c', 'a.b.c.d']
            const allPaths = path.split(props.separator).reduce((accumulator: string[], pathPiece: string) => {
                const lastElement = accumulator.length > 0 ? accumulator[accumulator.length - 1] : null;
                let parentPath = pathPiece;
                if (lastElement) {
                    parentPath = `${lastElement}${props.separator}${pathPiece}`;
                }
                accumulator.push(parentPath);
                return accumulator;
            }, []);

            // goes through all paths and removes them from the selections
            // considers both the path itself and in case of an array (ending in [0])
            // the path without it.
            allPaths.forEach((parentArrayPath: string) => {
                if (result.includes(parentArrayPath)) {
                    result.splice(result.indexOf(parentArrayPath), 1);
                }
                const parentPath = parentArrayPath.replace(/\[\d\]$/, '');
                if (result.includes(parentPath)) {
                    result.splice(result.indexOf(parentPath), 1);
                }
            });
            return result;
        };

        /**
         * Triggered on checking of a checkbox to trigger a change event to parent
         */
        const onCheck = () => {
            let selections: any[] = [...props.selectedItems];

            // if current checkbox was not selected before it means it is now
            if (!selections.includes(props.identifier)) {
                selections.push(props.identifier);
            } else {
                selections = disselectSelfAndAllParents(props.identifier, selections);
            }

            // is now selected and has children
            if (selections.includes(props.identifier) && hasChildren.value) {
                selections = selections.concat(
                    getChildren(props.identifier, props.data).filter((id) => !selections.includes(id)),
                );
            }
            // is now de-selected and has children
            if (!selections.includes(props.identifier) && hasChildren.value) {
                const childrenPaths: string[] = getChildren(props.identifier, props.data);
                selections = selections.filter((child) => !childrenPaths.includes(child));
            }

            emit('change', selections);
        };

        const change = (selections: string[]) => {
            emit('change', selections);
        };
        const checked = computed(() => props.selectedItems.includes(props.identifier));

        return {
            isObject,
            isArray,
            isSelectable,
            onCheck,
            isNumber,
            isTypeMismatch,
            change,
            checked,
            highlightThisNode,
            isDisabled,
            isUnselectable,
            isEmpty,
            emptyChildren,
            ExclamationIcon,
        };
    },
});
