import { watch } from '@vue/composition-api';
import * as R from 'ramda';

const callbacks: any[] = [];

/**
 * Handles in interaction with the query params of the url. You can use it to set and get variables
 *
 * @param root - The root object as it comes from the component setup
 * @param router - the router object as it comes from useRouter
 * @param routeName - the current routeName
 * @returns
 */
export function useQueryParams(root: any, router: any, routeName: string, previousQueryParams?: string) {
    const ARRAY_SEPARATOR = ',';

    interface QueryParam {
        value: string | string[] | number | number[] | boolean | Date | null | undefined;
        default?: string | string[] | number | number[] | boolean | Date | null | undefined;
    }

    /**
     * Used to get a variable from the query params
     * @param key - The param to retrieve
     * @param isArray - Whether the parameter is expected to be an array
     * @param defaultValue - The default value to be returned if the parameter does not exist
     *
     * @returns the value of the requested query param
     */
    const get = (
        key: string,
        isArray: boolean = false,
        defaultValue: string | string[] | number | null = null,
    ): any => {
        const currentQueryParams = root.$route.query || {};
        if (!R.has(key, currentQueryParams)) return defaultValue;
        if (R.isNil(currentQueryParams[key]) || R.isEmpty(currentQueryParams[key])) return isArray ? [] : null;
        if (isArray) return (currentQueryParams[key] as string).split(ARRAY_SEPARATOR);
        return currentQueryParams[key] || defaultValue;
    };

    /**
     * Sets the value of a query parameter in the object of current query parameters
     *
     * @param queryParams - The current query parameters
     * @param key - The key of the query parameter to set
     * @param value - The value of the query parameter to set
     * @param defaultValue - If a default is provided then the query parameter will be removed if the value matches the default
     */
    const setQueryParam = (
        queryParams: Record<string, string | number | boolean | Date | null | undefined>,
        key: string,
        value: QueryParam['value'],
        defaultValue?: QueryParam['default'],
    ) => {
        const processedValue = R.is(Array, value) ? (value as any[]).join(ARRAY_SEPARATOR) : value;
        const processedDefaultValue = R.is(Array, defaultValue)
            ? (defaultValue as any[]).join(ARRAY_SEPARATOR)
            : defaultValue;

        if (R.isNil(processedDefaultValue) || processedValue !== processedDefaultValue)
            queryParams[key] =
                !R.isNil(processedValue) && R.is(String, processedValue) ? processedValue.trim() : processedValue;

        if (
            Object.keys(queryParams).includes(key) &&
            (processedValue === processedDefaultValue ||
                ((R.isNil(processedDefaultValue) || R.isEmpty(processedDefaultValue)) &&
                    (R.isNil(processedValue) || R.isEmpty(processedValue))))
        )
            delete queryParams[key];
        return queryParams;
    };

    /**
     * Sets the value of a query parameter
     *
     * @param key - The key of the query parameter to set
     * @param value - The value of the query parameter to set
     * @param defaultValue - If a default is provided then the query parameter will be removed if the value matches the default
     */
    const set = (key: string, value: QueryParam['value'], defaultValue?: QueryParam['default']) => {
        const currentQueryParams = root.$route.query || {};
        const newQueryParams = setQueryParam({ ...currentQueryParams }, key, value, defaultValue);
        if (JSON.stringify(newQueryParams) !== JSON.stringify(currentQueryParams))
            router.push({ name: routeName, query: newQueryParams, params: { queryParams: previousQueryParams } });
    };

    /**
     * Sets the values of many query params
     *
     * @param queryParams - The query params
     */
    const setMany = (queryParams: Record<string, QueryParam>) => {
        const currentQueryParams = root.$route.query || {};
        let newQueryParams = { ...currentQueryParams };
        Object.keys(queryParams).forEach((key: string) => {
            newQueryParams = setQueryParam(newQueryParams, key, queryParams[key].value, queryParams[key].default);
        });
        if (JSON.stringify(newQueryParams) !== JSON.stringify(currentQueryParams))
            router.push({ name: routeName, query: newQueryParams, params: { queryParams: previousQueryParams } });
    };

    const onChange = (callback: any) => {
        callbacks.push(callback);
    };

    watch(
        () => root.$route.query,
        () => callbacks.forEach((callback: any) => callback()),
    );

    return { get, set, setMany, onChange };
}
