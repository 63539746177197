







































































import { InputErrorIcon } from '@/app/components';
import { S } from '@/app/utilities';
import { InformationCircleIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';

export default defineComponent({
    name: 'TailwindInput',
    model: {
        prop: 'value',
        event: 'update-value',
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        id: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        leadingLabel: {
            type: String,
            default: null,
        },
        leadingLabelWidth: {
            type: String,
            default: 'w-36',
        },
        prefix: {
            type: String,
            default: null,
        },
        suffix: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        errors: {
            type: Array,
            default: null,
        },
        fullError: {
            type: Boolean,
            default: true,
        },
        errorColour: {
            type: String,
            default: 'text-red-700',
        },
        additionalInputClasses: {
            type: [String, Array],
        },
        step: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        preprocess: {
            type: Function,
            default: (val: any) => {
                return val;
            },
        },
        failedRules: {
            type: Object,
            default: () => ({}),
        },
        validationMessages: {
            type: Object,
            default: () => ({}),
        },
    },
    components: { InputErrorIcon, InformationCircleIcon },
    setup(props, { emit }) {
        const valueRef = ref<any>(props.value);

        const errorsString = computed(() => {
            const errorStrings = [];
            if (!props.errors || props.errors.length === 0) {
                return null;
            }
            if (props.errors.length === 1) {
                return S.sanitizeHtml(props.errors[0] as string);
            }
            for (let e = 0; e < props.errors.length; e++) {
                const error = S.sanitizeHtml(props.errors[e] as string);
                errorStrings.push(`<li>${error}</li>`);
            }
            return `<ul>${errorStrings.join('')}</ul>`;
        });

        const validationHelpMessage = computed(() => {
            const failedRulesKeys = Object.keys(props.failedRules);
            const validationMessagesKeys = Object.keys(props.validationMessages);
            if (
                validationMessagesKeys.length &&
                failedRulesKeys.length &&
                validationMessagesKeys[0] === failedRulesKeys[0]
            ) {
                return props.validationMessages[validationMessagesKeys[0]];
            }
            return null;
        });

        const change = () => {
            const newValue = (props.preprocess as any)(valueRef.value);
            emit('update-value', newValue);
            emit('change', newValue);
        };

        const enter = () => {
            emit('enter', valueRef.value);
        };

        watch(
            () => props.value,
            (updatedValue: any) => {
                valueRef.value = updatedValue;
            },
        );
        return { errorsString, validationHelpMessage, valueRef, change, enter };
    },
});
