import { UserRoles } from '@/app/constants';
import { AuthGuard } from '@/modules/auth/router/auth.guard';
import { RoleGuard } from '@/modules/auth/router/role.guard';
import { NavigationGuard, Route } from 'vue-router';
import multiguard from 'vue-router-multiguard';

type Next = Parameters<NavigationGuard>[2];

export default [
    {
        name: 'model-manager',
        path: '/models',
        component: () => import(/* webpackChunkName: "data-model" */ '@/modules/data-model/views/Models.vue'),
        meta: { title: 'Models', feature: 'model-manager' },
        beforeEnter: multiguard([
            AuthGuard,
            (to: Route, from: Route, next: Next) =>
                RoleGuard(to, from, next, [UserRoles.Admin, UserRoles.ModelModerator]),
        ]),
    },
    {
        name: 'model-manager:edit',
        path: '/models/:id',
        component: () => import(/* webpackChunkName: "data-model" */ '@/modules/data-model/views/ModelManager.vue'),
        props: true,
        beforeEnter: multiguard([
            AuthGuard,
            (to: Route, from: Route, next: Next) =>
                RoleGuard(to, from, next, [UserRoles.Admin, UserRoles.ModelModerator]),
        ]),
    },
];
