export class ExecutionSortingOption {
    static readonly STATUS_ASC = new ExecutionSortingOption([['status']], true, 'Status Ascending');
    static readonly STATUS_DESC = new ExecutionSortingOption([['status']], false, 'Status Descending');

    static readonly DATE_EXECUTED_ASC = new ExecutionSortingOption(
        [['createdAt']],
        false,
        'Date executed newest first',
    );

    static readonly DATE_EXECUTED_DESC = new ExecutionSortingOption(
        [['createdAt']],
        true,
        'Date executed oldest first',
    );

    private constructor(
        public readonly fieldPaths: any[][],
        public readonly asc: boolean,
        public readonly label: string,
    ) {
        this.fieldPaths = fieldPaths;
        this.asc = asc;
        this.label = label;
    }

    static all(): ExecutionSortingOption[] {
        return [this.DATE_EXECUTED_ASC, this.DATE_EXECUTED_DESC, this.STATUS_ASC, this.STATUS_DESC];
    }

    static find(fieldPaths: any[][], asc: boolean): ExecutionSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (JSON.stringify(sortOption.fieldPaths) === JSON.stringify(fieldPaths) && sortOption.asc === asc) {
                return sortOption;
            }
        }
        return null;
    }

    static findByLabel(label: string): ExecutionSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (sortOption.label === label) {
                return sortOption;
            }
        }
        return null;
    }
}
