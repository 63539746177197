import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';

export interface OrganisationState {
    departments: Department[];
    users: OrgUser[];
}

export interface OrgUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    username: string;
    status: string;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
}

export interface Role {
    id: string;
    name: string;
}

export interface DepartmentUser {
    user: User;
    role: Role;
}

export interface Department {
    id: string;
    name: string;
    users: DepartmentUser[];
}

export const organisationModule = defineModule({
    namespaced: true,
    state: (): OrganisationState => {
        return {
            departments: [],
            users: [],
        };
    },
    mutations: {
        SET_DEPARTMENTS(state: OrganisationState, departments: Department[]) {
            state.departments = departments;
        },
        SET_USERS(state: OrganisationState, users: OrgUser[]) {
            state.users = users;
        },
    },
    getters: {
        departments: (state: OrganisationState) => state.departments,
        users: (state: OrganisationState) => state.users,
        userOptions: (state: OrganisationState) =>
            state.users.map((user: OrgUser) => ({
                id: parseInt(user.id),
                label: `${user.firstName} ${user.lastName}`,
            })),
    },
    actions: {
        setUsers: ({ commit }, users: OrgUser[]) => {
            commit('SET_USERS', users);
        },
        setDepartments: ({ commit }, departments: Department[]) => {
            commit('SET_DEPARTMENTS', departments);
        },
    },
});

export const organisationModuleActionContext = (context: any) => moduleActionContext(context, organisationModule);
