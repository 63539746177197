import { AxiosRequestConfig } from 'axios';

const dataModelURL = '/api/data-model';

export default {
    models: () => ({ method: 'GET', url: dataModelURL } as AxiosRequestConfig),
    getDomain: (id: number) =>
        ({ method: 'GET', url: `${dataModelURL}/model-browser/domains/${id}` } as AxiosRequestConfig),
    getConceptsByUids: (data: { uids: string[]; majorVersion: number; domainUid: string }[]) =>
        ({ method: 'POST', url: `${dataModelURL}/concepts-by-uuids`, data } as AxiosRequestConfig),
};
