<template>
    <v-date-picker
        :key="context.attributes.key"
        v-model="context.model"
        :masks="{ input: context.attributes.format }"
        :model-config="{ timeAdjust: '00:00:00' }"
        timezone="utc"
        :validation="context.attributes.validation"
        :validationName="context.attributes.validationName"
        :help="context.attributes.help"
        :helpPosition="context.attributes.helpPosition"
        :min-date="
            context.attributes['disabled-dates'] && context.attributes['disabled-dates'].to
                ? context.attributes['disabled-dates'].to
                : null
        "
        :max-date="
            context.attributes['disabled-dates'] && context.attributes['disabled-dates'].from
                ? context.attributes['disabled-dates'].from
                : null
        "
    >
        <template v-slot="{ inputValue, inputEvents }">
            <input
                class="w-full text-sm leading-6 text-center text-neutral-800 form-input"
                type="text"
                :value="inputValue"
                :placeholder="context.attributes.placeholder"
                v-on="inputEvents"
            />
        </template>
    </v-date-picker>
</template>

<script>
export default {
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
};
</script>
