









































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'DataModelTreeNode',
    props: {
        data: {
            type: Object,
            required: true,
        },
        path: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isRootSelectable: {
            type: Boolean,
            default: true,
        },
        selections: {
            type: Array,
            default: () => [],
        },
        selectable: {
            type: Boolean,
            default: true,
        },
        autoselectChildren: {
            type: Boolean,
            default: false,
        },
        nameField: {
            type: String,
            default: 'name',
        },
        isVisible: {
            type: Function,
            default: () => {
                return true;
            },
        },
        selectionKey: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const name = computed(() => {
            const splitPath: string[] = props.path.split('.');
            if (splitPath.length > 0) {
                return splitPath[splitPath.length - 1];
            }
            return null;
        });

        const parents = computed(() => {
            const splitPath: string[] = props.path.split('.');
            const parentKeys: string[] = [];
            let currentPrefix: string | null = null;
            splitPath.forEach((key, index) => {
                if (index < splitPath.length - 1) {
                    if (currentPrefix) {
                        currentPrefix = `${currentPrefix}.${key}`;
                    } else {
                        currentPrefix = `${key}`;
                    }
                    parentKeys.push(currentPrefix);
                }
            });
            return parentKeys;
        });

        const hasChildren = computed(
            () => 'children' in props.data && props.data.children && props.data.children.length > 0,
        );

        const hasParent = computed(() => parents.value.length > 0);

        const checked = computed(() =>
            props.selectionKey
                ? props.selections.includes(props.data[props.selectionKey])
                : props.selections.includes(props.path),
        );

        const getChildrenPaths = (parentPath: string, children: any[]) => {
            let selectedPaths: string[] = [];
            children.forEach((child: any) => {
                if ('children' in child && child.children.length > 0) {
                    selectedPaths = selectedPaths.concat(
                        getChildrenPaths(`${parentPath}.${child[props.nameField]}`, child.children),
                    );
                }
                selectedPaths.push(`${parentPath}.${child[props.nameField]}`);
            });
            return selectedPaths;
        };

        const getChildrenKeys = (children: any[], key: string) => {
            let selectedKeys: string[] = [];
            children.forEach((child: any) => {
                if ('children' in child && child.children.length > 0) {
                    selectedKeys = selectedKeys.concat(getChildrenKeys(child.children, key));
                }
                selectedKeys.push(child[key]);
            });
            return selectedKeys;
        };

        /**
         * Triggered on checking of a checkbox to trigger a change event to parent
         */
        const onCheckBasedOnPaths = () => {
            let paths: any[] = [...props.selections];
            if (props.autoselectChildren) {
                // is now selected and has children
                if (!props.selections.includes(props.path) && hasChildren.value) {
                    paths = paths.concat(
                        getChildrenPaths(props.path, props.data.children).filter((path) => !paths.includes(path)),
                    );
                }

                // is now de-selected and has children
                if (props.selections.includes(props.path) && hasChildren.value) {
                    const childrenPaths: string[] = getChildrenPaths(props.path, props.data.children);
                    paths = paths.filter((path) => !childrenPaths.includes(path));
                }

                // is now de-selected and has a parent
                if (props.selections.includes(props.path) && hasParent.value) {
                    parents.value.forEach((path: string) => {
                        if (paths.includes(path)) {
                            paths.splice(paths.indexOf(path), 1);
                        }
                    });
                }
            }
            if (!paths.includes(props.path)) {
                paths.push(props.path);
            } else {
                paths.splice(paths.indexOf(props.path), 1);
            }
            emit('change', paths);
        };

        const onCheckBasedOnKey = (key: string) => {
            let keySelections: any[] = [...props.selections];
            if (props.autoselectChildren) {
                // is now selected and has children
                if (!props.selections.includes(props.data[key]) && hasChildren.value) {
                    keySelections = keySelections.concat(
                        getChildrenKeys(props.data.children, key).filter(
                            (keyValue: any) => !keySelections.includes(keyValue),
                        ),
                    );
                }

                // is now de-selected and has children
                if (props.selections.includes(props.data[key]) && hasChildren.value) {
                    const childrenPaths: string[] = getChildrenKeys(props.data.children, key);
                    keySelections = keySelections.filter((keyValue) => !childrenPaths.includes(keyValue));
                }

                // is now de-selected and has a parent
                if (props.selections.includes(props.data[key]) && hasParent.value) {
                    parents.value.forEach((keyValue: string) => {
                        if (keySelections.includes(keyValue)) {
                            keySelections.splice(keySelections.indexOf(keyValue), 1);
                        }
                    });
                }
            }
            if (!keySelections.includes(props.data[key])) {
                keySelections.push(props.data[key]);
            } else {
                keySelections.splice(keySelections.indexOf(props.data[key]), 1);
            }
            emit('change', keySelections);
        };

        const onCheck = () => {
            if (props.selectionKey) {
                onCheckBasedOnKey(props.selectionKey);
            } else {
                onCheckBasedOnPaths();
            }
        };

        const change = (path: string) => {
            emit('change', path);
        };

        return { name, hasChildren, onCheck, change, checked };
    },
});
