


























































import { InformationCircleIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, nextTick, PropType, ref, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { S } from '@/app/utilities';
import { useVariables } from '../composable';
import { VariableType } from '../constants';
import { Variable } from '../interfaces';
import InputErrorIcon from './InputErrorIcon.vue';

export default defineComponent({
    name: 'VariableAwareInput',
    model: { prop: 'value', event: 'update-value' },
    props: {
        value: { type: [String, Number], default: '' },
        id: { type: String, default: null },
        type: {
            type: String as () => VariableType,
            default: VariableType.String,
            validator: (typeVal: string) => Object.values(VariableType).some((vt: string) => vt === typeVal),
        },
        placeholder: { type: String, default: null },
        disabled: { type: Boolean, default: false },
        errors: { type: Array, default: null },
        showFullError: { type: Boolean, default: true },
        step: { type: Number, default: null },
        errorColour: { type: String, default: 'text-red-700' },
        availableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
        failedRules: { type: Object, default: () => ({}) },
        validationMessages: { type: Object, default: () => ({}) },
        startInEdit: { type: Boolean, default: false },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    components: { InputErrorIcon, InformationCircleIcon },
    setup(props, { emit }) {
        const inputValueRef: Ref<any> = ref<any>();
        const inEdit: Ref<boolean> = ref<boolean>(props.startInEdit);
        const availableVariables = computed(() => props.availableVariables);
        const type = computed(() => props.type);
        const rules = computed(() => props.rules);
        const currentValue: Ref<string> = ref<string>(R.isNil(props.value) ? '' : String(props.value));
        const { displayValue, validationValue, inputType, hasAvailableVariables, transform } = useVariables(
            currentValue,
            type,
            availableVariables,
            rules,
        );
        const inputValue: Ref<string> = computed({
            get: () => currentValue.value,
            set: (newValue: any) => (currentValue.value = String(newValue)),
        });
        const inputStep: Ref<number | null> = computed(() => (inputType.value === 'number' ? props.step : null));

        const errorsString = computed(() => {
            const errorStrings = [];
            if (!props.errors || props.errors.length === 0) {
                return null;
            }
            if (props.errors.length === 1) {
                return S.sanitizeHtml(props.errors[0] as string);
            }
            for (let e = 0; e < props.errors.length; e++) {
                const error = S.sanitizeHtml(props.errors[e] as string);
                errorStrings.push(`<li>${error}</li>`);
            }
            return `<ul>${errorStrings.join('')}</ul>`;
        });

        const change = () => {
            emit('update-value', transform(inputValue.value));
            emit('change', false);
        };

        const finishEdit = () => {
            inEdit.value = false;
            emit('finished-editing');
        };

        const validationHelpMessage = computed(() => {
            const failedRulesKeys = Object.keys(props.failedRules);
            const validationMessagesKeys = Object.keys(props.validationMessages);
            if (
                validationMessagesKeys.length &&
                failedRulesKeys.length &&
                validationMessagesKeys[0] === failedRulesKeys[0]
            ) {
                return props.validationMessages[validationMessagesKeys[0]];
            }
            return null;
        });

        watch(
            () => inEdit.value,
            (inEditNewValue: boolean) => {
                if (inEditNewValue && inputValueRef.value) {
                    nextTick(() => {
                        inputValueRef.value.focus();
                    });
                }
            },
            { immediate: true },
        );

        watch(
            () => validationValue.value,
            (newValidationValue: any) => {
                emit('validate', newValidationValue);
            },
            { immediate: true },
        );

        watch(
            () => props.value,
            (newValue: any) => {
                currentValue.value = R.isNil(newValue) ? '' : String(newValue);
            },
        );

        return {
            inEdit,
            inputValue,
            displayValue,
            inputValueRef,
            inputType,
            inputStep,
            errorsString,
            validationHelpMessage,
            hasAvailableVariables,
            change,
            finishEdit,
        };
    },
});
