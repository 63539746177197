var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full space-y-2"},[_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full mr-2",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific concept can appear multiple times in the same asset.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific concept can appear multiple times in the same asset.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow multiple occurrences of this field")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.multiple,"disabled":_vm.disableMultiple,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.multiple ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.multiple),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "multiple", $$v)},expression:"activeMetadata.multiple"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether ordering is important for multiple appearances of the same concept.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether ordering is important for multiple appearances of the same concept.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Order of multiple occurrences is important")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.ordered,"disabled":_vm.disableOrdered,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.ordered ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.ordered),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "ordered", $$v)},expression:"activeMetadata.ordered"}})],1)]),_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full mr-2",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: 'An indication whether the specific concept can be encrypted.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content: 'An indication whether the specific concept can be encrypted.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow field to be encrypted")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.encryption,"disabled":_vm.readOnly,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.encryption ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.encryption),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "encryption", $$v)},expression:"activeMetadata.encryption"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific concept shall contain data that must be treated with extra security as they pertain to the privacy of an individual or organisation, such as racial or ethnic origin; religious or philosophical beliefs; genetic data, etc.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific concept shall contain data that must be treated with extra security as they pertain to the privacy of an individual or organisation, such as racial or ethnic origin; religious or philosophical beliefs; genetic data, etc.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Might contain sensitive information")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.sensitive,"disabled":_vm.disableSensitive,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.sensitive ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.sensitive),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "sensitive", $$v)},expression:"activeMetadata.sensitive"}})],1)]),_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full",class:{
                'xl:w-1/2': _vm.collapsedLeftPane,
            }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("An indication whether the specific field should be indexed in MongoDB."),expression:"`An indication whether the specific field should be indexed in MongoDB.`"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow indexing of this field in MongoDB")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.indexMongo,"disabled":_vm.disableIndexMongo,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.indexMongo ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.indexMongo),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "indexMongo", $$v)},expression:"activeMetadata.indexMongo"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{
                'xl:w-1/2': _vm.collapsedLeftPane,
                'xl:ml-2': _vm.collapsedLeftPane,
            }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("An indication whether the specific field should be indexed in Elasticsearch."),expression:"`An indication whether the specific field should be indexed in Elasticsearch.`"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow indexing of this field in Elasticsearch")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.indexES,"disabled":_vm.disableIndexES,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.indexES ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.indexES),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "indexES", $$v)},expression:"activeMetadata.indexES"}})],1)]),_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0 ': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific concept shall contain data that directly identify an individual and will need to be anonymised prior to being stored.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific concept shall contain data that directly identify an individual and will need to be anonymised prior to being stored.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Might contain identifying information")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.identifier,"disabled":_vm.readOnly,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.identifier ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.identifier),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "identifier", $$v)},expression:"activeMetadata.identifier"}})],1),_c('div',{staticClass:"w-1/2"})]),_c('div',{class:{
            'xl:flex xl:justify-between xl:items-start': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"w-full mr-2 space-y-2",class:{ 'xl:w-1/2 ': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication of the measurement type that is applicable to a concept, e.g. referring to distance, temperature, etc.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication of the measurement type that is applicable to a concept, e.g. referring to distance, temperature, etc.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"w-full text-sm font-normal text-neutral-700"},[_vm._v("Measurement Type")]),(_vm.enableMeasurementType)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeMetadata.measurementType),expression:"activeMetadata.measurementType"}],staticClass:"block w-full text-sm leading-5 form-select text-neutral-700 disabled:text-neutral-600",attrs:{"name":"Measurement Type","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.activeMetadata, "measurementType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Other")])]):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeMetadata.measurementType),expression:"activeMetadata.measurementType"}],staticClass:"block w-full text-sm leading-5 form-select",class:_vm.activeMetadata.measurementType && (!_vm.initialMetadata || _vm.isFieldDraft || _vm.isProposal)
                        ? 'text-neutral-700'
                        : 'text-neutral-600',attrs:{"name":"Measurement Type","disabled":(_vm.initialMetadata && !_vm.isProposal && !_vm.isFieldDraft) || _vm.readOnly},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.activeMetadata, "measurementType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.resetMetadataMeasurements()}]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select measurement type")]),_vm._l((_vm.totalUnitKeys),function(type){return _c('option',{key:type.id,domProps:{"value":type}},[_c('span',[_vm._v(_vm._s(type === 'Not relevant' ? 'Other' : type))])])})],2)]),(_vm.showMeasurementUnit)?_c('div',{staticClass:"w-full space-y-2",class:{ 'xl:w-1/2 ': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                    "The default measurement unit to which all values in the specific field will be transformed."
                ),expression:"\n                    `The default measurement unit to which all values in the specific field will be transformed.`\n                "}],staticClass:"w-full text-sm font-normal text-neutral-700"},[_vm._v("Measurement Unit")]),(!_vm.initialMetadata || _vm.isProposal)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("This field is required"),expression:"`This field is required`"}],staticClass:"text-red-500"},[_vm._v("*")]):_vm._e(),_c('div',{staticClass:"w-full"},[(_vm.disableMeasurementUnit)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeMetadata.measurementUnit),expression:"activeMetadata.measurementUnit"}],key:_vm.activeMetadata.measurementType,staticClass:"block w-full text-sm leading-5 form-select text-neutral-700 disabled:text-neutral-600",attrs:{"name":"Measurement Unit","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.activeMetadata, "measurementUnit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":_vm.activeMetadata.measurementUnit}},[_vm._v(" "+_vm._s(_vm.activeMetadata.measurementUnit)+" ")])]):(_vm.enableFreeTextMeasurementUnit)?_c('ValidationProvider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeMetadata.measurementUnit),expression:"activeMetadata.measurementUnit"}],key:_vm.activeMetadata.measurementType,staticClass:"block w-full text-sm form-input text-neutral-700",class:{ 'border-red-500': errors.length },attrs:{"name":"Measurement Unit","type":"text","placeholder":"Enter measurement unit"},domProps:{"value":(_vm.activeMetadata.measurementUnit)},on:{"click":function($event){_vm.inField = true},"mouseleave":function($event){_vm.inField ? _vm.updateMetadata() : (_vm.inField = false)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.activeMetadata, "measurementUnit", $event.target.value)}}}),(errors.length)?_c('div',{staticClass:"mt-1 text-xs text-red-600"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,3857719434)}):_c('ValidationProvider',{key:_vm.activeMetadata.measurementType,attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.activeMetadata.measurementUnit),expression:"activeMetadata.measurementUnit"}],key:_vm.activeMetadata.measurementType,staticClass:"block w-full text-sm leading-5 form-select disabled:text-neutral-600",class:{
                            'text-neutral-700':
                                _vm.activeMetadata.measurementUnit && (!_vm.initialMetadata || _vm.isFieldDraft || _vm.isProposal),
                            'text-neutral-600': _vm.activeMetadata.measurementUnit === null,
                            'border-red-500': errors.length,
                        },attrs:{"name":"Measurement Unit","disabled":(_vm.initialMetadata && !_vm.isProposal && !_vm.isFieldDraft) || _vm.readOnly},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.activeMetadata, "measurementUnit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.updateMetadata()}]}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select measurement unit")]),_vm._l((_vm.sortedUnitTransformationOptions),function(unit){return _c('option',{key:unit,domProps:{"value":unit}},[_c('span',[_vm._v(_vm._s(unit))])])})],2),(errors.length)?_c('div',{staticClass:"mt-1 text-xs text-red-600"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2138340970)})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }