import BaseLayout from './BaseLayout.vue';
import EmptyLayout from './EmptyLayout.vue';

export { BaseLayout, EmptyLayout };

export const selectLayout = (routeName: string | null | undefined) => {
    switch (routeName) {
        case null:
        case 'organization-registration':
        case 'unverified-organization':
        case 'home':
        case 'about':
        case '500':
        case '404':
        case '503':
            return EmptyLayout;
        default:
            return BaseLayout;
    }
};
