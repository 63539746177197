var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-date-picker',{key:_vm.context.attributes.key,attrs:{"masks":{ input: _vm.context.attributes.format },"model-config":{ timeAdjust: '00:00:00' },"timezone":"utc","validation":_vm.context.attributes.validation,"validationName":_vm.context.attributes.validationName,"help":_vm.context.attributes.help,"helpPosition":_vm.context.attributes.helpPosition,"min-date":_vm.context.attributes['disabled-dates'] && _vm.context.attributes['disabled-dates'].to
            ? _vm.context.attributes['disabled-dates'].to
            : null,"max-date":_vm.context.attributes['disabled-dates'] && _vm.context.attributes['disabled-dates'].from
            ? _vm.context.attributes['disabled-dates'].from
            : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var inputValue = ref.inputValue;
            var inputEvents = ref.inputEvents;
return [_c('input',_vm._g({staticClass:"w-full text-sm leading-6 text-center text-neutral-800 form-input",attrs:{"type":"text","placeholder":_vm.context.attributes.placeholder},domProps:{"value":inputValue}},inputEvents))]}}]),model:{value:(_vm.context.model),callback:function ($$v) {_vm.$set(_vm.context, "model", $$v)},expression:"context.model"}})}
var staticRenderFns = []

export { render, staticRenderFns }