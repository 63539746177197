/**
 * This file is a clone of the one found in `@vue-composable/axios` (so it should be a drop-in replacement).
 * The only difference (and the reason for copying this here), is the addition of a response interceptor, that
 * redirects the user to the login page. While we make sure that the user is authenticated when we navigate
 * from one page to the next one, we don't do that while we interact within a page. The added interceptor
 * is responsible to perform that.
 */

import { isString, isBoolean, isObject, usePromise, PromiseResultFactory } from 'vue-composable';
import { makeAxios } from '@vue-composable/axios';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance, CancelTokenSource } from 'axios';
import { ComputedRef, Ref } from '@vue/composition-api';
import { useErrors } from './errors';

export interface MakeAxiosReturn<TData>
    extends PromiseResultFactory<Promise<AxiosResponse<TData>>, [AxiosRequestConfig | string]> {
    readonly client: AxiosInstance;
    readonly data: ComputedRef<TData | null>;
    readonly status: Ref<number | null>;
    readonly statusText: Ref<string | null>;

    cancel: (message?: string) => void;
    readonly isCancelled: Ref<boolean>;
    readonly cancelledMessage: Ref<string | null | undefined>;
    // readonly
}

export interface AxiosReturn<TData> extends MakeAxiosReturn<TData> {}

export function useAxios<TData = any>(throwException?: boolean): AxiosReturn<TData>;
export function useAxios<TData = any>(
    url: string,
    config?: AxiosRequestConfig,
    throwException?: boolean,
): AxiosReturn<TData>;
export function useAxios<TData = any>(url: string, throwException?: boolean): AxiosReturn<TData>;
export function useAxios<TData = any>(config?: AxiosRequestConfig, throwException?: boolean): AxiosReturn<TData>;
export function useAxios<TData = any>(
    configUrlThrowException?: AxiosRequestConfig | string | boolean,
    configThrowException?: AxiosRequestConfig | boolean,
    throwException = false,
): AxiosReturn<TData> {
    const config =
        !isString(configUrlThrowException) && !isBoolean(configUrlThrowException)
            ? configUrlThrowException
            : isObject(configThrowException)
            ? (configThrowException as AxiosRequestConfig)
            : undefined;
    throwException = isBoolean(configUrlThrowException)
        ? configUrlThrowException
        : isBoolean(configThrowException)
        ? configThrowException
        : throwException;

    const axiosClient = axios.create(config);

    const { redirectToLogin } = useErrors();
    axiosClient.interceptors.response.use(
        (response) => response,
        (error) => {
            // Calling the 'test-api' endpoint should not redirect to login for unauthorised response
            if (error.response.status === 401 && error.response.config.url !== '/api/apollo/test-api') {
                redirectToLogin();
            } else {
                if (throwException) throw error;
                return error;
            }
        },
    );

    const use = makeAxios<TData>(axiosClient, throwException);

    // if url provided in the config, execute it straight away
    // NOTE: `false` is passed to the `exec` to prevent the exception to be thrown
    if (typeof configUrlThrowException === 'string') {
        (use.exec as any)({ ...config, url: configUrlThrowException }, false);
    } else if (config && config.url) {
        (use.exec as any)(config, false);
    }

    return use;
}
