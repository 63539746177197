







import { defineComponent } from '@vue/composition-api';
import { ClockIcon } from '@vue-hero-icons/outline';
export default defineComponent({
    name: 'NotificationExceptionEventIcon',
    components: { ClockIcon },
    props: {
        referenceType: {
            type: String,
            required: false,
        },
        eventType: {
            type: String,
            required: true,
        },
        dropdown: {
            type: Boolean,
            default: false,
        },
    },
});
