import { useRouter } from '@/app/composable/router';
import { UserRoles } from '@/app/constants';
import store from '@/app/store';
import { NavigationGuard, Route } from 'vue-router';

type Next = Parameters<NavigationGuard>[2];

export const OrganisationGuard = async (to: Route, from: Route, next: Next) => {
    const { user } = store.state.auth;

    const isAdmin = store.getters.auth.hasRole(UserRoles.Admin);

    if (user?.organisationId || (to.name === 'dashboard' && isAdmin)) {
        return next();
    }

    const root = useRouter();

    (root.app as any).$toastr.e('You do not have access to view this page.', 'Error');

    return next({ name: 'home' });
};
