












































import { defineComponent } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';

export default defineComponent({
    name: 'Toggle',
    model: {
        prop: 'checked',
        event: 'update-toggle',
    },
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
        // Only accepts: primary, secondary, neutral, green, red
        checkedColour: {
            type: String,
            default: 'primary',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        uncheckedColour: {
            type: String,
            default: 'bg-neutral-400',
        },
        backgroundColour: {
            type: String,
            default: 'bg-white',
        },
        additionalInputClasses: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const uuid = `toggle_${uuidv4()}`;

        const change = () => {
            emit('update-toggle', !props.checked);
            emit('change', !props.checked);
        };

        return { uuid, change };
    },
});
