




import * as R from 'ramda';
import { defineComponent, ref } from '@vue/composition-api';
import { useCache, useAxios } from '@/app/composable';

export default defineComponent({
    name: 'SvgImage',
    props: {
        src: {
            type: String,
            required: true,
        },
        originalFill: {
            type: String,
            default: '#6C63FF',
        },
        newFill: {
            type: String,
            default: 'currentColor',
        },
    },
    setup(props) {
        const rootElement = ref<HTMLElement | null>(null);
        const { exec } = useAxios(true);
        const { checkCacheAndFetchItem, addToCache } = useCache();

        const setupSvg = (data: any) => {
            // Parse responseText and create an SVG element
            const domParser = new DOMParser();
            const result = domParser.parseFromString(data, 'text/xml');
            const svg = result.getElementsByTagName('svg')[0];
            svg.setAttribute('width', '100%');
            svg.setAttribute('height', '100%');

            // Append SVG element to the dom
            if (rootElement.value) {
                rootElement.value.appendChild(svg);
            }
        };

        const fetchSvg = (): void => {
            exec(props.src)
                .then((res: any) => {
                    // Replace original color
                    const data = res.data.split(props.originalFill.toLowerCase()).join(props.newFill);
                    setupSvg(data);
                    const headersToBeCached = {
                        'Content-Type': 'image/svg+xml',
                    };
                    addToCache(props.src, data, headersToBeCached);
                })
                .catch(() => {
                    console.error(`No SVG element found in the given file: ${props.src}`);
                });
        };

        checkCacheAndFetchItem(props.src)
            .then((res: string | null) => {
                if (R.isNil(res)) {
                    fetchSvg();
                } else {
                    setupSvg(res);
                }
            })
            .catch((error: any) => {
                throw new Error(error);
            });

        return { rootElement };
    },
});
