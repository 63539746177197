export const localPathValidator = {
    message: (field: any) => {
        return `The specified ${field} is not a valid path \n\t    Sample path for linux: /home/my_output\n\t    Sample path for Windows: C:\\Documents\\my_output`;
    },
    validate: (value: any) => {
        const winPath = new RegExp('([a-zA-Z]:)?(\\\\[a-z  A-Z0-9_.-]+)+\\\\?');

        const linuxPath = new RegExp('^(/[^/]*)+/?$');

        return winPath.test(value) || linuxPath.test(value);
    },
};
