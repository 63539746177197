import { moduleActionContext } from '@/app/store';
import { useAxios } from '@/app/composable';
import { defineModule } from 'direct-vuex';
import ExecutionAPI from '../api/execution';
import { Model } from '@/app/interfaces';
import { BlockAPI, ScheduleAPI, WorkflowAPI } from '../api';
import { Block, ExecutionLog, ScheduleType, WorkflowTrigger } from '../types';
import dayjs from 'dayjs';
import { isNil, has } from 'ramda';
import { WorkflowType } from '@/app/constants';
import { MonitoringAPI } from '@/app/api';

export interface ExecutionVersionsState {
    dataCheckinExecutionVersion: string | null;
    analyticsExecutionVersion: string | null;
}

export interface PipelineDesignerState {
    currentWorkflowId: string | undefined;
    availableModels: Model[] | undefined;
    isLoadingModels: boolean;
    blocks: Block[] | undefined;
    isLoadingBlocks: boolean;
    schedules: ScheduleType[] | undefined;
    isLoadingSchedules: boolean;
    triggers: WorkflowTrigger[] | undefined;
    isLoadingTriggers: boolean;
    executionLogsMap: Record<string, ExecutionLog[]>;
    isLoadingExecutionLogs: boolean;
}

export const versionsModule = defineModule({
    namespaced: true,
    state: (): ExecutionVersionsState => {
        return {
            dataCheckinExecutionVersion: null,
            analyticsExecutionVersion: null,
        };
    },
    mutations: {
        SET_VERSIONS(
            state: ExecutionVersionsState,
            versions: { dataCheckinExecutionVersion: string; analyticsExecutionVersion: string },
        ) {
            state.analyticsExecutionVersion = versions.analyticsExecutionVersion;
            state.dataCheckinExecutionVersion = versions.dataCheckinExecutionVersion;
        },
    },
    actions: {
        async loadVersions({ commit }) {
            const { exec } = useAxios(true);
            await exec(ExecutionAPI.getExecutionVersions()).then((result) => {
                if (result && result.data) {
                    commit('SET_VERSIONS', result.data);
                }
            });
        },
    },
});

export const pipelineDesignerModule = defineModule({
    namespaced: true,
    state: (): PipelineDesignerState => {
        return {
            currentWorkflowId: undefined,
            availableModels: undefined,
            isLoadingModels: false,
            blocks: undefined,
            isLoadingBlocks: false,
            schedules: undefined,
            isLoadingSchedules: false,
            triggers: undefined,
            isLoadingTriggers: false,
            executionLogsMap: {},
            isLoadingExecutionLogs: false,
        };
    },
    mutations: {
        CLEAR_PIPELINE_DEPENDANTS(state: PipelineDesignerState) {
            state.availableModels = undefined;
            state.schedules = undefined;
            state.triggers = undefined;
            state.executionLogsMap = {};
        },
        SET_CURRENT_PIPELINE(state: PipelineDesignerState, id: string) {
            state.currentWorkflowId = id;
        },
        SET_MODELS(state: PipelineDesignerState, models: Model[]) {
            state.availableModels = models;
        },
        SET_LOADING_MODELS_STATE(state: PipelineDesignerState, loading: boolean) {
            state.isLoadingModels = loading;
        },
        SET_BLOCKS(state: PipelineDesignerState, blocks: Block[]) {
            state.blocks = blocks;
        },
        SET_LOADING_BLOCKS_STATE(state: PipelineDesignerState, loading: boolean) {
            state.isLoadingBlocks = loading;
        },
        SET_SCHEDULES(state: PipelineDesignerState, schedules: ScheduleType[]) {
            state.schedules = schedules;
        },
        SET_LOADING_SCHEDULES_STATE(state: PipelineDesignerState, loading: boolean) {
            state.isLoadingSchedules = loading;
        },
        SET_TRIGGERS(state: PipelineDesignerState, triggers: WorkflowTrigger[]) {
            state.triggers = triggers;
        },
        SET_LOADING_TRIGGERS_STATE(state: PipelineDesignerState, loading: boolean) {
            state.isLoadingTriggers = loading;
        },
        SET_EXECUTION_LOGS_MAP(state: PipelineDesignerState, executionLogsMap: Record<string, ExecutionLog[]>) {
            state.executionLogsMap = executionLogsMap;
        },
        SET_LOADING_EXECUTION_LOGS_STATE(state: PipelineDesignerState, loading: boolean) {
            state.isLoadingExecutionLogs = loading;
        },
    },
    getters: {
        availableModels: (state: PipelineDesignerState) => state.availableModels,
        availableBlocks: (state: PipelineDesignerState) => state.blocks,
        schedules: (state: PipelineDesignerState) => state.schedules,
        triggers: (state: PipelineDesignerState) => state.triggers,
        executionLogsMap: (state: PipelineDesignerState) => state.executionLogsMap,
        isLoadingModels: (state: PipelineDesignerState) => state.isLoadingModels,
        isLoadingBlocks: (state: PipelineDesignerState) => state.isLoadingBlocks,
        isLoadingSchedules: (state: PipelineDesignerState) => state.isLoadingSchedules,
        isLoadingTriggers: (state: PipelineDesignerState) => state.isLoadingTriggers,
        isLoadingExecutionLogs: (state: PipelineDesignerState) => state.isLoadingExecutionLogs,
    },
    actions: {
        async setPipelineId({ commit }, id: string) {
            commit('CLEAR_PIPELINE_DEPENDANTS');
            commit('SET_CURRENT_PIPELINE', id);
        },
        async loadBlocks({ commit, state }) {
            const { exec } = useAxios(true);
            commit('SET_LOADING_BLOCKS_STATE', true);
            exec(BlockAPI.getBlocks()).then((result: any) => {
                commit('SET_BLOCKS', result.data);
                commit('SET_LOADING_BLOCKS_STATE', false);
            });
        },
        async loadAvailableModels({ commit, state }, force: boolean = false) {
            if (!state.currentWorkflowId || (!force && !isNil(state.availableModels))) return;

            const { exec } = useAxios(true);
            commit('SET_LOADING_MODELS_STATE', true);
            await exec(WorkflowAPI.getAvailableModels(state.currentWorkflowId)).then((result: any) => {
                commit('SET_MODELS', result.data);
                commit('SET_LOADING_MODELS_STATE', false);
            });
        },
        async loadSchedules({ commit, state }, force: boolean = false) {
            if (!state.currentWorkflowId || (!force && !isNil(state.schedules))) return;
            commit('SET_LOADING_SCHEDULES_STATE', true);
            ScheduleAPI.getSchedules(state.currentWorkflowId).then((result: any) => {
                commit('SET_LOADING_SCHEDULES_STATE', false);
                const schedules = result.data.map((resSchedule: ScheduleType) => {
                    return {
                        ...resSchedule,
                        endDate: new Date(
                            dayjs(new Date(resSchedule.endDate as Date))
                                .subtract(1, 'day')
                                .format(),
                        ),
                    };
                });
                commit('SET_SCHEDULES', schedules);
            });
        },
        async loadTriggers({ commit, state }, force: boolean = false) {
            if (!state.currentWorkflowId || (!force && !isNil(state.triggers))) return;
            const { exec } = useAxios(true);
            commit('SET_LOADING_TRIGGERS_STATE', true);
            exec(WorkflowAPI.getTriggers(state.currentWorkflowId)).then((result: any) => {
                commit('SET_LOADING_TRIGGERS_STATE', false);
                commit('SET_TRIGGERS', result.data);
            });
        },
        async loadExecutionLogs({ commit, state }, ids: string[]) {
            if (state.isLoadingExecutionLogs) return;
            const { exec } = useAxios(true);
            commit('SET_LOADING_EXECUTION_LOGS_STATE', true);
            exec(MonitoringAPI.multipleExecutionLogs(ids, state.currentWorkflowId, WorkflowType.Analytics)).then(
                (result: any) => {
                    commit('SET_LOADING_EXECUTION_LOGS_STATE', false);
                    commit('SET_EXECUTION_LOGS_MAP', {
                        ...state.executionLogsMap,
                        ...ids.reduce((acc: Record<string, ExecutionLog[]>, id: string) => {
                            acc[id] = [];
                            return acc;
                        }, {}),
                        ...result.data.reduce((acc: any, executionLog: ExecutionLog) => {
                            // acc is Record<string, ExecutionLog[]> but ts lint fails...
                            if (!has(executionLog.executionId, acc)) acc[executionLog.executionId] = [];
                            acc[executionLog.executionId].push(executionLog);
                            return acc;
                        }, {}),
                    });
                },
            );
        },
        async clearPipelineDependents({ commit }) {
            commit('CLEAR_PIPELINE_DEPENDANTS');
        },
    },
});

export const versionsModuleActionContext = (context: any) => moduleActionContext(context, versionsModule);
