import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/workflow-execution';

export default {
    cancel: (uid: string | number, workflowId: string | number) =>
        ({ method: 'DELETE', url: `${endpoint}/${workflowId}/execution/${uid}` } as AxiosRequestConfig),
    getExecutionVersions: () => ({ method: 'GET', url: `${endpoint}/image/versions` } as AxiosRequestConfig),
    // delete: (uid: string | number) => ({ method: 'DELETE', url: `${endpoint}/${uid}/delete` } as AxiosRequestConfig),
};
