

















import { AssetType } from '@/modules/asset/constants';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
    name: 'TimelinessBadge',
    props: {
        value: { type: String, required: true },
        minimal: { type: Boolean, default: false },
        showTooltip: { type: Boolean, default: true },
        assetType: { type: String as PropType<AssetType>, default: AssetType.Dataset },
    },
    setup(props) {
        const info = computed(() => {
            return {
                fresh: {
                    classes: 'bg-green-100 text-green-800',
                    tooltip: `The data asset is currently up-to-date: new ${
                        props.assetType === AssetType.Dataset ? 'data' : 'results'
                    } have been successfully added in the asset as expected by the asset's Accrual Periodicity`,
                },
                stale: {
                    classes: 'bg-red-100 text-red-700',
                    tooltip: `The data asset is not up-to-date as new ${
                        props.assetType === AssetType.Dataset ? 'data' : 'results'
                    } (from the execution of the corresponding data ${
                        props.assetType === AssetType.Dataset ? 'check-in' : 'analytics'
                    } pipeline) have not been added in the asset in the frequency that is expected by the asset's Accrual Periodicity`,
                },
                unknown: {
                    classes: 'bg-neutral-200 text-neutral-700',
                    tooltip: 'The data asset does not have any data yet or its Accrual Periodicity is unknown',
                },
            };
        });

        return { info };
    },
});
