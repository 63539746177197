









































import { DocumentIcon } from '@vue-hero-icons/outline';
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon, ClockIcon } from '@vue-hero-icons/solid';
import { defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
import { useFilters } from '../composable';

export default defineComponent({
    name: 'FileIcon',
    components: {
        DocumentIcon,
        CheckCircleIcon,
        XCircleIcon,
        ExclamationCircleIcon,
        ClockIcon,
    },
    props: {
        file: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { formatBytes, capitalise } = useFilters();

        return {
            dayjs,
            formatBytes,
            capitalise,
        };
    },
});
