






import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { defineComponent, computed, ref } from '@vue/composition-api';

export default defineComponent({
    name: 'Scrollbar',
    components: { PerfectScrollbar },
    props: {
        additionalClasses: {
            type: String,
            default: '',
        },
        overflowY: {
            type: Boolean,
            default: true,
        },
        overflowX: {
            type: Boolean,
            default: false,
        },
        scrollUp: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const scrollbarRef = ref<any>(null);
        const baseClasses: string[] = [];

        const totalClasses = computed(() => {
            const calculatedClasses = baseClasses;
            if (props.overflowX) {
                calculatedClasses.push('overflow-x-auto');
            }
            if (props.overflowY) {
                calculatedClasses.push('overflow-y-auto');
            }
            return calculatedClasses;
        });

        const scrollToTop = () => {
            scrollbarRef.value.$el.scrollTop = 0;
        };

        return { totalClasses, scrollbarRef, scrollToTop };
    },
});
