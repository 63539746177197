export enum ColumnType {
    String = 'string',
    Text = 'text',
    Integer = 'integer',
    Double = 'double',
    Number = 'number',
    Uid = 'uid',
    Date = 'date',
    Datetime = 'datetime',
    Array = 'array',
    Object = 'object',
    Boolean = 'boolean',
}

export enum ColumnPosition {
    Left = 'left',
    Center = 'center',
    Right = 'right',
}
