import { ApexSeriesOptions, YAxisSeriesOption } from '../types';

export enum BucketUnit {
    SECOND = 'second',
    MINUTE = 'minute',
    HOUR = 'hour',
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    QUARTER = 'quarter',
    YEAR = 'year',
}

export enum bucketInterval {
    DEFAULT = 5,
}

export enum bucketBinSize {
    DEFAULT = 1,
}

export enum filtersOperator {
    DEFAULT = 'AND',
}

// In case of name edit, also modify ApexChartType enum
export enum pageSize {
    DEFAULT = 30,
    area = 800,
    bar = 30,
    donut = 50,
    heatmap = 800,
    line = 800,
    pie = 50,
    radar = 50,
    scatter = 800,
    radialBar = 1,
}

export enum pageNumber {
    DEFAULT = 1,
}

export const defaultSeriesOptions: ApexSeriesOptions = {
    groupBy: {
        name: '',
        fields: [],
        type: '',
        buckets: null,
    },
    series: [],
    filters: {
        operator: filtersOperator.DEFAULT,
        conditions: [],
    },
    label: '',
    pageSize: pageSize.DEFAULT,
};

export const bucketProps: any = {
    numeric: { interval: bucketInterval.DEFAULT },
    datetime: { unit: BucketUnit.DAY, binSize: bucketBinSize.DEFAULT },
    category: null,
};

export const YAxisSeriesSchema: YAxisSeriesOption = {
    name: '',
    chartType: null,
    field: '',
    type: null,
    function: null,
    filters: [],
};
