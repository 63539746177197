export function useBlob() {
    const download = (data: BlobPart[], filename: string, contentType: string = 'application/json') => {
        const blob = new Blob(data, { type: contentType });
        const link = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.download = filename;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return { download };
}
