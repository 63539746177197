import { useVariableUtils } from '../composable';
import { ValidatorUtils } from './utils';
import * as R from 'ramda';

export const variableAwareValidator = {
    validate: (value: any, args: any) => {
        if (R.isNil(value) || R.isEmpty(value) || args.length === 0) {
            return true;
        }
        const { getMatchedVariables } = useVariableUtils();

        // validator expect available ariables and input value with variables in it
        const { availableVariables, value: parameterValue } = JSON.parse(args[0]);
        const variables = getMatchedVariables(parameterValue, availableVariables);
        return variables.length <= 1;
    },
    message(field: string) {
        return `'${ValidatorUtils.prettifyName(field)}' can contain at most one variable`;
    },
};
