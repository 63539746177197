export enum AccessLevel {
    Public = 'Public',
    SelectiveSharing = 'SelectiveSharing',
    Private = 'Private',
    OrganisationLevel = 'OrganisationLevel',
    Restricted = 'Restricted',
}

export const AccessLevelsOptions = {
    [AccessLevel.Private]: 'Only me',
    [AccessLevel.Restricted]: 'Restricted Access',
    [AccessLevel.OrganisationLevel]: 'Organisation-level Access',
    // [AccessLevel.SelectiveSharing]: 'Selective Sharing', // TODO: hide for now
    [AccessLevel.Public]: 'Public Access',
};

export const AccessLevelsExtensiveOptions = {
    [AccessLevel.Private]: 'Only me (No other user)',
    [AccessLevel.Restricted]: 'Restricted Access (Selected users / departments within the same organisation)',
    [AccessLevel.OrganisationLevel]: 'Organisation-level Access (All users within the same organisation)',
    // [AccessLevel.SelectiveSharing]: 'Selective Sharing (Selected external / third-party organisations)', // TODO: hide for now
    [AccessLevel.Public]: 'Public Access (All users)',
};
