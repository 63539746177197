







import { CheckIcon, PencilIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ChangeIndication',
    props: {
        unsaved: {
            type: Boolean,
            default: false,
        },
        changes: {
            type: Object,
        },
        field: {
            type: String,
        },
    },
    components: {
        CheckIcon,
        PencilIcon,
    },

    setup(props) {
        const calculateChange = computed(() => {
            if (props.changes && props.field) {
                return props.changes[props.field];
            }
            return props.unsaved;
        });

        return { calculateChange };
    },
});
