import { StatusCode } from '@/modules/asset/constants';
import { RetrievalAccessibility } from '@/modules/retrieval/constants/retrieval-accessibility.constants';
import { computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { Asset } from '../types';
import { useFeatureFlags } from '@/app/composable';

export function useAssetRetrieval(asset: Ref<Asset | undefined>, user: Ref<{ id: number; organisationId: number }>) {
    const { isEnabled } = useFeatureFlags();
    const status = computed(() => asset.value?.status);
    const isLocal = computed<boolean>(() => {
        if (asset.value) {
            if (!R.isNil(asset.value.isLocal)) return asset.value.isLocal;
            return !!asset.value.metadata?.filenames?.length;
        }
        return false;
    });
    const belongsToUserOrOrganisation = computed(() => {
        if (asset && user.value) {
            if (user.value.organisationId && asset.value?.createdBy.organisationId)
                return user.value.organisationId === asset.value?.createdBy.organisationId;
            return user.value.id === asset.value?.createdBy.id;
        }
        return true;
    });

    const accessibilityKey = computed(() => {
        if (asset.value && asset.value.metadata && asset.value.metadata.distribution) {
            const accessibility = asset.value.metadata.distribution.accessibility;
            if (accessibility.includes('Through an API') && isEnabled('retrieve.api'))
                return RetrievalAccessibility.API;
            if (accessibility.includes('Through a data streaming mechanism') && isEnabled('retrieve.stream'))
                return RetrievalAccessibility.STREAMING;
            if (accessibility.includes('As a downloadable file') && isEnabled('retrieve.files'))
                return RetrievalAccessibility.FILE;

            // As a downloadable file
            return undefined;
        }
        return null;
    });

    const isRetrievalDisabled = computed(() =>
        status.value
            ? [StatusCode.Deleted, StatusCode.Uploading].includes(status.value) ||
              isLocal.value ||
              status.value !== StatusCode.Available
            : true,
    );

    const retrievalTooltip = computed(() => {
        if (isRetrievalDisabled.value) {
            if (status.value === StatusCode.Deleted)
                return 'A retrieval query cannot be configured as the asset has been deleted';
            if (status.value === StatusCode.Uploading)
                return 'A retrieval query cannot be configured until the asset becomes available';
            if (isLocal.value) return 'A retrieval query cannot be configured as the asset has been generated locally';
            return "A retrieval query cannot be configured until the asset's metadata are filled in and it becomes available in the platform";
        }
        return 'Retrieve this asset by configuring a Retrieval Query';
    });

    return { retrievalTooltip, isRetrievalDisabled, belongsToUserOrOrganisation, accessibilityKey };
}
