import { AuthGuard } from '@/modules/auth/router/auth.guard';

export default [
    {
        name: 'notifications',
        path: '/notifications',
        component: () =>
            import(/* webpackChunkName: "notification" */ '@/modules/notification/views/Notifications.vue'),
        meta: { title: 'Notifications' },
        beforeEnter: AuthGuard,
    },
    {
        name: 'notifications-digest',
        path: '/notifications/digest/:id',
        component: () =>
            import(/* webpackChunkName: "notification" */ '@/modules/notification/views/NotificationsDigest.vue'),
        props: true,
        meta: { title: 'Notifications Digest' },
        beforeEnter: AuthGuard,
    },
];
