import { AxiosRequestConfig } from 'axios';
import { AssetType } from '../constants';
import { AssetMetricsQuery } from '../types/asset-metrics-query.type';

const endpoint = '/api/monitoring/assets';

export default {
    getTimeliness: (assetType: AssetType, id: number, query: AssetMetricsQuery) =>
        ({
            method: 'POST',
            url: `${endpoint}/${assetType.toLowerCase()}s/${id}/metrics/timeliness`,
            data: query,
        } as AxiosRequestConfig),
    getCompleteness: (assetType: AssetType, id: number, query: AssetMetricsQuery) =>
        ({
            method: 'POST',
            url: `${endpoint}/${assetType.toLowerCase()}s/${id}/metrics/completeness`,
            data: query,
        } as AxiosRequestConfig),

    getDatasetQualityImprovements: (id: number, query: AssetMetricsQuery) =>
        ({
            method: 'POST',
            url: `${endpoint}/datasets/${id}/metrics/quality-improvement`,
            data: query,
        } as AxiosRequestConfig),
    getReliability: (assetType: AssetType, id: number, query: AssetMetricsQuery) =>
        ({
            method: 'POST',
            url: `${endpoint}/${assetType.toLowerCase()}s/${id}/metrics/reliability`,
            data: query,
        } as AxiosRequestConfig),
    getResultVolume: (id: number, query: AssetMetricsQuery) =>
        ({
            method: 'POST',
            url: `${endpoint}/results/${id}/metrics/volume`,
            data: query,
        } as AxiosRequestConfig),
};
