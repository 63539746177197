import { computed, ref, watch } from '@vue/composition-api';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { useErrors } from './errors';

// HTTP connection to the API
const httpLink = createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const client = new ApolloClient({
    link: httpLink,
    cache,
});

export function useQuery(
    query: any,
    variablesInitial?: any,
    options?: {
        errorPolicy?: 'none' | 'ignore' | 'all';
        fetchPolicy?: 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby' | undefined;
    },
    initialFetch: boolean = true,
) {
    const loading = ref<boolean>(true);
    const error = ref<any>();
    const variables = ref<any>(variablesInitial);
    let onErrorCallback = (e: any) => {
        return e;
    };
    let onResultCallback = (e: any) => {
        return e;
    };
    const result = ref<any>();
    const { parseGQLError } = useErrors();

    const onError = (callback: (...args: any[]) => void) => {
        onErrorCallback = callback;
    };

    const onResult = (callback: (...args: any[]) => void) => {
        onResultCallback = callback;
    };

    const refetch = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            client
                .query({
                    query,
                    variables: variables.value,
                    fetchPolicy: options?.fetchPolicy,
                    errorPolicy: options?.errorPolicy,
                })
                .then(({ data }: any) => {
                    result.value = data;
                    loading.value = false;
                    onResultCallback(data);
                    resolve(data);
                })
                .catch((e: any) => {
                    parseGQLError(e);
                    onErrorCallback(e);
                    error.value = e;
                    reject(e);
                });
        });
    };

    // first time
    if (initialFetch) refetch();

    watch(
        () => variables.value,
        () => {
            refetch();
        },
    );

    return { loading, error, result, variables, refetch, onError, onResult };
}

export function useResult(result: any, defaultValue?: any, pick?: any) {
    return computed(() => {
        const { value } = result;
        if (value) {
            if (pick) {
                try {
                    return pick(value);
                } catch (e) {
                    console.error(e);
                }
            } else {
                const keys = Object.keys(value);
                if (keys.length === 1) {
                    return value[keys[0]];
                }
                return value;
            }
        }
        return defaultValue;
    });
}
