import { AxiosRequestConfig } from 'axios';
import { ApiConfig, ApolloPipeline, ApolloTaskConfiguration } from '../types';

const endpoint = '/api/apollo';

export default {
    get: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    isLock: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}/is-locked` } as AxiosRequestConfig),
    create: (data: ApolloPipeline) => ({ method: 'POST', url: endpoint, data } as AxiosRequestConfig),
    update: (id: string, data: ApolloPipeline) =>
        ({ method: 'PUT', url: `${endpoint}/${id}`, data } as AxiosRequestConfig),
    createCredentials: (id: string, data?: any) =>
        ({ method: 'POST', url: `${endpoint}/${id}/harvester/create-credentials`, data } as AxiosRequestConfig),
    resetPassword: (id: string) =>
        ({ method: 'POST', url: `${endpoint}/${id}/harvester/reset-password` } as AxiosRequestConfig),
    lock: (id: string) => ({ method: 'PATCH', url: `${endpoint}/${id}/lock` } as AxiosRequestConfig),
    run: (id: string) => ({ method: 'PATCH', url: `${endpoint}/${id}/run` } as AxiosRequestConfig),
    unlock: (id: string) => ({ method: 'PATCH', url: `${endpoint}/${id}/unlock` } as AxiosRequestConfig),
    delete: (id: string) => ({ method: 'DELETE', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    getTask: (pipelineId: string, taskName: string) =>
        ({ method: 'GET', url: `${endpoint}/${pipelineId}/${taskName}` } as AxiosRequestConfig),
    updateTask: (pipelineId: string, taskName: string, data: ApolloTaskConfiguration, clearProcessedSample = true) =>
        ({
            method: 'PUT',
            url: `${endpoint}/${pipelineId}/${taskName}${clearProcessedSample ? '' : `?clearProcessedSample=${false}`}`,
            data,
        } as AxiosRequestConfig),
    finalizeTask: (pipelineId: string, taskName: string) =>
        ({ method: 'PATCH', url: `${endpoint}/${pipelineId}/${taskName}/finalise` } as AxiosRequestConfig),
    finalize: (pipelineId: string) =>
        ({ method: 'PATCH', url: `${endpoint}/${pipelineId}/finalise` } as AxiosRequestConfig),
    reviseTask: (pipelineId: string, taskName: string) =>
        ({ method: 'PATCH', url: `${endpoint}/${pipelineId}/${taskName}/revise` } as AxiosRequestConfig),
    executeSampleRun: (id: string, taskName: string) =>
        ({ method: 'PATCH', url: `${endpoint}/${id}/${taskName}/sample-run` } as AxiosRequestConfig),
    clone: (id: string, data: ApolloPipeline) =>
        ({ method: 'POST', url: `${endpoint}/${id}/clone`, data } as AxiosRequestConfig),
    testAPI: (data: ApiConfig) => ({ method: 'POST', url: `${endpoint}/test-api`, data } as AxiosRequestConfig),
};
