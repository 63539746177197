










































































































import { computed, defineComponent, PropType, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';

import { useRouter } from '@/app/composable/router';
import { useMonitoringPeriod, useQueryParams } from '@/app/composable';
import store from '@/app/store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import MonitoringTimeRangeFilter from './MonitoringTimeRangeFilter.vue';
import { ExecutionQuery } from '@/app/types';
import { Scrollbar } from '..';
import { ExecutionStatus } from '@/modules/workflow-designer/constants';
dayjs.extend(utc);

export default defineComponent({
    name: 'MonitoringFilter',
    props: {
        facets: {
            type: Object as PropType<Record<string, { value: string; count: number; selected: boolean }[]>>,
            default: () => {
                return {};
            },
        },
        focus: { type: Date, required: false },
        routeName: { type: String, required: true },
        availableFacets: {
            type: Object as PropType<
                Record<
                    string,
                    {
                        label: string;
                        visible: boolean;
                        default: string[];
                        valueLabel?: any;
                        permitted?: string[] | undefined;
                    }
                >
            >,
            required: true,
        },
        queryParams: {
            type: Object as PropType<
                Record<
                    string,
                    {
                        param: string;
                        default?: string;
                        isArray: boolean;
                        value?: any;
                        path?: string[];
                        set?: any;
                        permitted?: string[];
                    }
                >
            >,
            required: true,
        },
        disabled: { type: Boolean, default: false },
    },
    components: { MonitoringTimeRangeFilter, Scrollbar },
    setup(props, { emit, root }) {
        const OWNERSHIP_OPTIONS = { me: { label: 'Me' }, organisation: { label: 'My organisation' } };

        const router = useRouter();
        const { get, set } = useQueryParams(root, router, props.routeName);
        const searchTextRef: Ref<any> = ref<any>(
            get(
                props.queryParams.searchText.param,
                props.queryParams.searchText.isArray,
                props.queryParams.searchText.default,
            ),
        );
        const timePeriod = ref<string>(
            get(
                props.queryParams.timePeriod.param,
                props.queryParams.timePeriod.isArray,
                props.queryParams.timePeriod.default,
            ),
        );
        const searchTextCurrent = ref<string>(
            get(
                props.queryParams.searchText.param,
                props.queryParams.searchText.isArray,
                props.queryParams.searchText.default,
            ),
        );
        const user = computed(() => store.state.auth.user);
        const { findByKey, findPeriod } = useMonitoringPeriod();

        const ownershipsArray = computed(() =>
            Object.keys(OWNERSHIP_OPTIONS).map((key: string) => {
                return {
                    key: key,
                    ...OWNERSHIP_OPTIONS[key],
                };
            }),
        );

        const facetsArray = computed(() =>
            Object.keys(props.availableFacets).reduce((acc: any[], facetKey: string) => {
                if (props.availableFacets[facetKey].visible)
                    acc.push({
                        ...props.availableFacets[facetKey],
                        key: facetKey,
                        values: props.facets[facetKey],
                    });

                return acc;
            }, []),
        );

        const filters: Ref<{
            query: ExecutionQuery;
            facets: Record<string, any>;
        }> = ref({
            query: {
                userId:
                    get(
                        props.queryParams.ownership.param,
                        props.queryParams.ownership.isArray,
                        props.queryParams.ownership.default,
                    ) === 'me'
                        ? user.value.id
                        : undefined,
                organisationId:
                    get(
                        props.queryParams.ownership.param,
                        props.queryParams.ownership.isArray,
                        props.queryParams.ownership.default,
                    ) === 'organisation'
                        ? user.value.organisationId
                        : undefined,
                dateRange: findByKey(timePeriod.value).isCustom
                    ? {
                          start: get(props.queryParams.timePeriodStart.param),
                          end: get(props.queryParams.timePeriodEnd.param),
                      }
                    : { start: findByKey(timePeriod.value).start(), end: findByKey(timePeriod.value).end() },
                text: get(
                    props.queryParams.searchText.param,
                    props.queryParams.searchText.isArray,
                    props.queryParams.searchText.default,
                ),
                status: [ExecutionStatus.Failed, ExecutionStatus.Skipped, ExecutionStatus.Cancelled],
            },
            facets: Object.keys(props.availableFacets).reduce((acc: Record<string, any>, facetKey: string) => {
                const paramValues: string[] = get(
                    props.queryParams[facetKey].param,
                    props.queryParams[facetKey].isArray,
                    props.queryParams[facetKey].default,
                );
                acc[facetKey] =
                    props.queryParams[facetKey] &&
                    props.queryParams[facetKey].permitted &&
                    (props.queryParams[facetKey].permitted as string[]).length > 0
                        ? paramValues.filter((val: string) => props.queryParams[facetKey].permitted?.includes(val))
                        : paramValues;

                set(props.queryParams[facetKey].param, acc[facetKey], props.queryParams[facetKey].default);
                return acc;
            }, {}),
        });

        const searchText = computed({
            get: (): string => filters.value.query.text ?? '*',
            set: (newText: string) => {
                set(props.queryParams.searchText.param, newText, props.queryParams.searchText.default);
                filters.value.query.text = newText;
            },
        });

        const timePeriodStart = computed({
            get: () =>
                filters.value.query.dateRange?.start ? dayjs.utc(filters.value.query.dateRange?.start).toDate() : null,
            set: (start: Date | null) => {
                if (filters.value.query.dateRange) filters.value.query.dateRange.start = start;
            },
        });

        const timePeriodEnd = computed({
            get: () =>
                filters.value.query.dateRange?.end ? dayjs.utc(filters.value.query.dateRange?.end).toDate() : null,
            set: (end: Date | null) => {
                if (filters.value.query.dateRange) filters.value.query.dateRange.end = end;
            },
        });

        const organisationMode = computed(() => !!user.value.organisationId);

        const ownership = computed({
            get: () => (filters.value.query.organisationId ? 'organisation' : 'me'),
            set: (newValue: string) => {
                set(props.queryParams.ownership.param, newValue, props.queryParams.ownership.default);
                if (newValue === 'me') {
                    filters.value.query.userId = user.value.id;
                    filters.value.query.organisationId = undefined;
                } else {
                    filters.value.query.userId = undefined;
                    filters.value.query.organisationId = user.value.organisationId;
                }
            },
        });

        const facetChange = (facetKey: string, value: string) => {
            const facetsObj = {
                ...filters.value.facets,
            };
            if (facetsObj[facetKey].includes(value)) {
                facetsObj[facetKey].splice(facetsObj[facetKey].indexOf(value), 1);
            } else {
                facetsObj[facetKey] = [...facetsObj[facetKey], value];
            }
            set(props.queryParams[facetKey].param, facetsObj[facetKey], props.queryParams[facetKey].default);
            filters.value.facets[facetKey] = facetsObj[facetKey];
        };

        const facetTotalCount = (facet: { values: { count: number }[] }): number => {
            if (facet && facet.values?.length > 0) {
                return facet.values.reduce((acc: number, f: { count: number }): number => {
                    if (f.count && R.is(Number, f.count)) acc = acc += f.count;
                    return acc;
                }, 0);
            }

            return 0;
        };

        const timePeriodChange = (timePeriodKey: any, start: Date | null, end: Date | null) => {
            timePeriod.value = timePeriodKey;
            set(props.queryParams.timePeriod.param, timePeriodKey, props.queryParams.timePeriod.default);
            if (findByKey(timePeriodKey).isCustom) {
                set(props.queryParams.timePeriodStart.param, start, props.queryParams.timePeriodStart.default);
                set(props.queryParams.timePeriodEnd.param, end, props.queryParams.timePeriodEnd.default);
            } else {
                set(props.queryParams.timePeriodStart.param, null, props.queryParams.timePeriodStart.default);
                set(props.queryParams.timePeriodEnd.param, null, props.queryParams.timePeriodEnd.default);
            }
            timePeriodStart.value = start;
            timePeriodEnd.value = end;
        };

        const setSearchText = (text?: string) => {
            const currentText = (!R.isNil(text) ? text : searchTextCurrent.value).trim();
            if (filters.value.query.text !== currentText) {
                searchText.value = currentText;
            }
        };

        const getFacetValueLabel = (facet: { valueLabel?: any }, value: any) => {
            return facet.valueLabel ? facet.valueLabel(value) : value;
        };

        watch(
            () => props.focus,
            () => {
                if (searchTextRef.value) {
                    searchTextRef.value.focus();
                }
            },
        );

        watch(
            () => filters.value,
            (newFilters: any) => {
                emit('change', R.clone(newFilters));
            },
            { deep: true },
        );

        timePeriodChange(
            findPeriod(timePeriodStart.value, timePeriodEnd.value).period,
            timePeriodStart.value,
            timePeriodEnd.value,
        );

        return {
            filters,
            searchTextCurrent,
            timePeriod,
            timePeriodStart,
            timePeriodEnd,
            facetsArray,
            searchTextRef,
            ownership,
            ownershipsArray,
            organisationMode,
            timePeriodChange,
            facetChange,
            facetTotalCount,
            setSearchText,
            getFacetValueLabel,
        };
    },
});
