























































































































import * as R from 'ramda';
import { useEventListener } from '@vueuse/core';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { SortAscendingIcon, SortDescendingIcon, SearchIcon, FilterIcon } from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';

export default defineComponent({
    name: 'SortedSearch',
    components: {
        ChevronDownIcon,
        SortAscendingIcon,
        SearchIcon,
        SortDescendingIcon,
        FilterIcon,
    },
    model: {
        prop: 'search',
        event: 'search-changed',
    },
    props: {
        sortOptions: {
            type: Array as () => Record<string, any>[],
            required: true,
        },
        search: {
            type: Object,
            required: true,
        },
        filterOptions: {
            type: Array as () => Record<string, any>[],
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isSortOpen = ref(false);
        const isFilterOpen = ref(false);

        const toggleSort = () => {
            isFilterOpen.value = false;
            isSortOpen.value = !isSortOpen.value;
        };

        const toggleFilter = () => {
            isSortOpen.value = false;
            isFilterOpen.value = !isFilterOpen.value;
        };

        const sortBy = computed(() => R.find(R.propEq('key', props.search.sortBy), props.sortOptions)?.label || 'Sort');

        const updateProp = (path: (string | number)[], value: any) => {
            emit('search-changed', R.assocPath(path, value, R.clone(props.search)));
        };

        const handleChangeFilter = (option: any) => {
            emit('search-changed', {
                ...props.search,
                sortOrder: props.search.sortOrder,
                filterBy: option,
            });
            isFilterOpen.value = false;
        };

        const handleChangeSelect = (option: any) => {
            if (props.search.sortBy === option) {
                emit('search-changed', {
                    ...props.search,
                    sortOrder: props.search.sortOrder === 'asc' ? 'desc' : 'asc',
                });
            } else {
                emit('search-changed', {
                    ...props.search,
                    sortBy: option,
                    sortOrder: 'asc',
                });
            }
            isSortOpen.value = false;
        };

        useEventListener(document, 'keydown', (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                isSortOpen.value = false;
                isFilterOpen.value = false;
            }
        });

        return {
            handleChangeSelect,
            handleChangeFilter,
            toggleSort,
            isSortOpen,
            toggleFilter,
            isFilterOpen,
            updateProp,
            sortBy,
        };
    },
});
