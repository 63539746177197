import { ValidatorUtils } from './utils';

import { isNil, isEmpty, is } from 'ramda';
export const requiredValidator = {
    validate: (value: any, args: any) => {
        if (isNil(value)) return false;
        if (isEmpty(value)) return false;
        return true;
    },
    message(field: string) {
        return `${ValidatorUtils.prettifyName(field)} is required`;
    },
};
