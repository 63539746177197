





























































































































































import { useRoute, useRouter } from '@/app/composable/router';
import store from '@/app/store';
import { BookOpenIcon, LockClosedIcon, LockOpenIcon, XIcon } from '@vue-hero-icons/outline';
import { FlagIcon } from '@vue-hero-icons/solid';
import { PropType, computed, defineComponent, onBeforeUnmount, ref, watch } from '@vue/composition-api';
import { CirclesToRhombusesSpinner, SelfBuildingSquareSpinner } from 'epic-spinners';
import * as R from 'ramda';
import { Route } from 'vue-router';
import { useFeatureFlags, useModelBrowser } from '../../composable';
import { Concept, Model } from '../../interfaces';
import Scrollbar from '../Scrollbar.vue';
import SearchBox from '../SearchBox.vue';
import SvgImage from '../SvgImage.vue';
import TwSelect from '../TailwindSelect.vue';
import ConceptNode from './ConceptNode.vue';
import ConceptSuggestion from './ConceptSuggestion.vue';

export default defineComponent({
    name: 'ModelBrowser',
    components: {
        BookOpenIcon,
        XIcon,
        TwSelect,
        CirclesToRhombusesSpinner,
        SelfBuildingSquareSpinner,
        SearchBox,
        ConceptNode,
        Scrollbar,
        SvgImage,
        FlagIcon,
        LockClosedIcon,
        LockOpenIcon,
        ConceptSuggestion,
    },
    model: {
        prop: 'selection',
        event: 'change',
    },
    props: {
        select: { type: Object as PropType<{ model: any }> },
        isVisible: { type: Boolean, default: false },
        isPinned: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
        draggable: { type: Boolean, default: true },
        jumpToConcept: { type: Array as PropType<string[]>, default: () => [] },
    },
    setup(props, { emit }) {
        const {
            model,
            concept,
            searchText,
            modelUid,
            models,
            concepts,
            filteredConcepts,
            modelsLoading,
            conceptsLoading,
            path,
            restrictingConcept,
            restrictingHLC,
            getConceptFromId,
            getConceptFromUid,
        } = useModelBrowser();

        const { flag } = useFeatureFlags();
        const isModelManagerEnabled = flag('model-manager');

        const isMacOS = window.navigator.userAgent.indexOf('Mac OS') !== -1;
        const modelDescription = computed(() =>
            model.value && model.value.description ? model.value.description.trim() : null,
        );

        const updatePath = (con: Concept | undefined) => {
            if (!con) {
                path.value = [];
                return;
            }
            const lastNode: Concept | null = path.value.length > 0 ? path.value[path.value.length - 1] : null;

            if (lastNode && !lastNode.leaf && lastNode.uid === con.parentUid) {
                // if child of already selected parent
                path.value = [...path.value, con];
            } else if (lastNode && lastNode.leaf && lastNode.parentUid === con.parentUid) {
                // if sibling of already selected node
                const newPath = [...path.value];
                newPath.splice(path.value.length - 1, 1, con);
                path.value = newPath;
            } else if (lastNode && lastNode.referenceConceptId && lastNode.referenceConceptId === con.parentClass) {
                // if this is coming from a reference concept
                path.value = [...path.value, con];
            } else if (restrictingConcept.value && !path.value.find((element) => element.uid === con.uid)) {
                if (con.uid === restrictingConcept.value.uid) {
                    path.value = [getConceptFromUid(con.parentUid), con];
                }
                const index = path.value.findIndex(
                    (p: Concept) => p.referenceConceptId && p.referenceConceptId === con.parentId,
                );
                if (index > -1) {
                    path.value = [...path.value].slice(0, index + 1);
                    path.value = [...path.value, con];
                } else if (con.parentUid) {
                    // in case we don't find a match and the concept has a parent we take the two as the new path
                    path.value = [getConceptFromUid(con.parentUid), con];
                }
            } else if (con.parentUid) {
                // in any other case just take parent as first path element and
                // leaf concept itself as second
                path.value = [getConceptFromUid(con.parentUid), con];
            }
        };

        const expandedConcepts = ref<string[]>([]);

        const expandConcept = (uid: string, remove = true) => {
            if (!uid) return;

            let conceptToExpand = getConceptFromUid(uid);

            if (!conceptToExpand || conceptToExpand.referenceConceptId) return;

            if (conceptToExpand.leaf) {
                if (remove) return;
                conceptToExpand = getConceptFromId(conceptToExpand.parentId);
                if (!conceptToExpand || conceptToExpand.referenceConceptId) return;
            }

            if (!expandedConcepts.value.includes(conceptToExpand.uid)) expandedConcepts.value.push(conceptToExpand.uid);
            else if (remove) expandedConcepts.value.splice(expandedConcepts.value.indexOf(conceptToExpand.uid), 1);
        };

        const selectConcept = async (con: string, forceSelect = false) => {
            if (!con) return;
            const selectedCon = getConceptFromUid(con);
            if (!selectedCon) return;

            let pathChanged = false;
            if (selectedCon.leaf) {
                concept.value = selectedCon;
                pathChanged = true;
            } else if (
                (!concept.value || forceSelect) &&
                !selectedCon.leaf &&
                selectedCon.children &&
                selectedCon.children.length > 0
            ) {
                concept.value = selectedCon.children[0];
                pathChanged = true;
            } else if (selectedCon.referenceConceptId) {
                if (forceSelect) updatePath(selectedCon);
                goToConcept(getConceptFromId(selectedCon.referenceConceptId).uid, true, forceSelect);
            }
            if (concept.value && pathChanged) updatePath(concept.value);
        };

        const goToConcept = (con: string, clearSearch = true, forceSelect = true) => {
            concept.value = undefined;
            if (clearSearch) searchText.value = '';

            selectConcept(con, forceSelect);
            expandConcept(con, false);
        };

        const nodeInPath = (node: Concept) => {
            const reversePath = R.clone(path.value);
            reversePath.reverse();
            let lastNode = null;
            for (let p = 0; p < reversePath.length; p++) {
                const pathElement = reversePath[p];
                if (!pathElement.parentId || pathElement.referenceConceptId) {
                    lastNode = pathElement;
                    break;
                }
            }

            return (
                lastNode &&
                (lastNode.uid === node.uid || (lastNode.referenceConceptId && lastNode.referenceConceptId === node.id))
            );
        };

        let scrollTimeout: any;
        const scrollToConcept = (con: string) => {
            if (scrollTimeout) {
                clearTimeout(scrollTimeout);
                scrollTimeout = null;
            }
            if (con)
                scrollTimeout = setTimeout(() => {
                    const element = document.getElementById(con);
                    if (element) {
                        element.scrollIntoView();
                    }
                }, 50);
        };

        const nodeClicked = (con: string) => {
            selectConcept(con, !restrictingConcept.value);
            expandConcept(con);
        };

        const jumpToRestrictingConcept = () => {
            let uid: string | undefined;
            if (restrictingHLC.value?.parentUid) uid = restrictingHLC.value?.parentUid;
            else uid = restrictingHLC.value?.uid;

            if (uid) goToConcept(uid);
        };

        watch(
            () => props.isVisible,
            (visible: boolean) => {
                if (visible && concept.value) {
                    goToConcept(concept.value.uid, false);
                    scrollToConcept(concept.value?.uid);
                }
            },
            { immediate: true },
        );

        watch(
            () => searchText.value,
            (newSearchText: string) => {
                if (newSearchText !== '') concept.value = undefined;
            },
        );

        watch(
            () => model.value,
            (newModel: Model | undefined) => {
                emit('model-change', newModel);
                expandedConcepts.value = [];
            },
            { immediate: true },
        );

        watch(
            () => path.value,
            (newPath: Concept[]) => {
                emit('path-change', newPath);
            },
        );

        watch(
            () => props.jumpToConcept,
            (conceptUid: string[]) => {
                if (conceptUid.length > 0) {
                    path.value = [];
                    for (let u = 0; u < conceptUid.length; u++) {
                        goToConcept(conceptUid[u], true, true);
                    }
                    scrollToConcept(conceptUid[conceptUid.length - 1]);
                }
            },
            { immediate: true },
        );

        watch(
            () => restrictingConcept.value,
            (con: Concept | undefined) => {
                if (
                    con &&
                    (!restrictingHLC.value ||
                        restrictingHLC.value.uid !== con.uid ||
                        expandedConcepts.value.length === 0)
                ) {
                    goToConcept(con.uid, true);
                }
            },
            { immediate: true },
        );

        watch(
            () => concept.value,
            (con: Concept | undefined) => {
                if (con) {
                    expandConcept(con.uid, false);
                    scrollToConcept(con.uid);
                    updatePath(con);
                }
            },
        );

        // on first load
        if (useRoute().name !== 'mapping' || useRoute().params.step !== 'configuration') {
            store.dispatch.modelBrowser.clearMappingSelection();
        }
        useRouter().afterEach((to: Route) => {
            if (to.name !== 'mapping' || to.params.step !== 'configuration')
                store.dispatch.modelBrowser.clearMappingSelection();
        });

        onBeforeUnmount(() => {
            if (concept.value) scrollToConcept(concept.value.uid);
        });

        return {
            path,
            model,
            models,
            concept,
            concepts,
            modelUid,
            expandedConcepts,
            filteredConcepts,
            searchText,
            modelsLoading,
            conceptsLoading,
            modelDescription,
            restrictingConcept,
            isMacOS,
            restrictingHLC,
            isModelManagerEnabled,
            scrollToConcept,
            nodeInPath,
            nodeClicked,
            jumpToRestrictingConcept,
        };
    },
});
