import { AxiosRequestConfig } from 'axios';

const endpoint = 'api/monitoring/statistics';

export default {
    statistics: (forceClearCache: boolean) =>
        ({
            method: 'GET',
            url: `${endpoint}`,
            headers: { 'invalidate-cache': `${forceClearCache}` },
        } as AxiosRequestConfig),
};
