var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full space-y-2"},[_c('div',{staticClass:"space-y-2",class:{
            'xl:flex xl:justify-between xl:items-center xl:space-y-0': _vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full mr-2",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether the specific concept can appear multiple times in the same asset.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether the specific concept can appear multiple times in the same asset.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Allow multiple occurrences of this field")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.multiple,"disabled":_vm.disableMultiple,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.multiple ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.multiple),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "multiple", $$v)},expression:"activeMetadata.multiple"}})],1),_c('div',{staticClass:"flex items-center justify-between w-full",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                        'An indication whether ordering is important for multiple appearances of the same concept.',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content:\n                        'An indication whether ordering is important for multiple appearances of the same concept.',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Order of multiple occurrences is important")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.ordered,"disabled":_vm.disableOrdered,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.ordered ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.ordered),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "ordered", $$v)},expression:"activeMetadata.ordered"}})],1)]),_c('div',{class:{
            'xl:flex xl:justify-between xl:items-center': _vm.collapsedLeftPane,
            'space-y-2': !_vm.collapsedLeftPane,
        }},[_c('div',{staticClass:"flex items-center justify-between w-full mr-2",class:{ 'xl:w-1/2': _vm.collapsedLeftPane }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: 'Denoting if the prefix of a related concept can be customised',
                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',
                }),expression:"{\n                    content: 'Denoting if the prefix of a related concept can be customised',\n                    classes: 'xl:max-w-md  md:max-w-sm max-w-xs  ',\n                }"}],staticClass:"text-sm font-normal text-neutral-700"},[_vm._v("Denoting if the data can be customised")]),_c('Toggle',{attrs:{"checked":_vm.activeMetadata.customizable,"disabled":_vm.readOnly,"checkedColour":"green","backgroundColour":"bg-neutral-100","additionalInputClasses":!_vm.activeMetadata.customizable ? 'bg-neutral-300' : ''},on:{"change":function($event){return _vm.updateMetadata()}},model:{value:(_vm.activeMetadata.customizable),callback:function ($$v) {_vm.$set(_vm.activeMetadata, "customizable", $$v)},expression:"activeMetadata.customizable"}})],1),_c('div',{class:{ 'xl:w-1/2': _vm.collapsedLeftPane }})])])}
var staticRenderFns = []

export { render, staticRenderFns }