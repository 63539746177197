import { AxiosRequestConfig } from 'axios';
import { CreateVisualisationDTO } from '../dto';
import { Visualisation } from '../types';

const endpoint = '/api/visualisation';

export default {
    create: (data: CreateVisualisationDTO) =>
        ({ method: 'POST', url: `${endpoint}/workflow/${data.workflowId}`, data } as AxiosRequestConfig),
    get: (uid: string | number) => ({ method: 'GET', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    update: (visualisation: Visualisation) =>
        ({ method: 'PUT', url: `${endpoint}/${visualisation.id}`, data: visualisation } as AxiosRequestConfig),
    delete: (uid: string) => ({ method: 'DELETE', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
};
