export const ApexChartXAxisValidValues = {
    numeric: ['int', 'double', 'numeric'],
    datetime: ['datetime'],
    category: ['string'],
};

export enum ApexChartLegendPosition {
    Top = 'top',
    Right = 'right',
    Bottom = 'bottom',
    Left = 'left',
}

export enum ApexChartDataTypes {
    DATETIME = 'datetime',
    INT = 'int',
    DOUBLE = 'double',
    STRING = 'string',
}

export const markerSizeOptions = [
    { label: 'No markers', value: 0 },
    { label: 'Small', value: 3 },
    { label: 'Medium', value: 5 },
    { label: 'Large', value: 7 },
    { label: 'Extra Large', value: 10 },
] as const;

export enum ApexChartFontFamily {
    DEFAULT = '"Inter var", sans-serif',
}

export enum ApexChartMarkersShape {
    CIRCLE = 'circle',
    SQUARE = 'square',
}

export enum ApexChartTextAlign {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
}

export enum ApexChartColors {
    AxisBorder = '#78909C', // Bermuda gray
    AxisTicks = '#78909C',
    Text = '#263238', // Oxford Blue dark
    BackGround = '#fff',
    Selection = '#24292e', // Blue Charcoal
    ValueName = '#888', // Gray
    TotalLabel = '#373d3f', // Dark Cyan
    Polygons = '#e8e8e8', // Gray91
    MarkersStroke = '#0ff', // Aqua
    Crosshairs = '#b6b6b6', // Light Gray
    CrosshairsFill = '#B1B9C4', // Blue Gray
    CrosshairsGradientFrom = '#D8E3F0', // Very light gray
    CrosshairsGradientTo = '#BED1E6', // Almost very light gray
    HeatmapDefault = '#ac0806', // dark red
}

export enum ApexChartBorderType {
    SOLID = 'solid',
    DOTTED = 'dotted',
}

export enum ApexChartMessages {
    NO_DATA = 'No data to display',
}

export enum ApexChartdecimalsInFloat {
    DEFAULT = 2,
}

export enum ApexChartLineCurve {
    SMOOTH = 'smooth',
    STRAIGHT = 'straight',
    STEPLINE = 'stepline',
}

export enum ApexChartLineCap {
    BUTT = 'butt',
    SQUARE = 'square',
    ROUND = 'round',
}

export enum ApexChartCrosshairsFillType {
    SOLID = 'solid',
    GRADIENT = 'gradient',
}

export enum ApexChartPositioning {
    BACK = 'back',
    FRONT = 'front',
}
