import { useQuery } from '@/app/composable';
import store from '@/app/store';
import GET_ORGANIZATION_USERS from '../graphql/getOrganizationUsers.graphql';

export function useOrganisation(id: Number) {
    const initialize = () => {
        const { result, onResult } = useQuery(GET_ORGANIZATION_USERS, { id }, { fetchPolicy: 'no-cache' });

        onResult(() => {
            if (result.value?.organisation) {
                store.dispatch.organisation.setUsers(result.value.organisation.users);
                store.dispatch.organisation.setDepartments(result.value.organisation.departments);
            }
        });
    };
    return { initialize };
}
