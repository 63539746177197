var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col w-full space-y-4"},[_c('div',{staticClass:"flex flex-col space-y-4"},[(_vm.error)?_c('div',{staticClass:"flex flex-row p-1 px-2 space-x-1 text-sm text-white bg-red-500 rounded"},[_c('ExclamationCircleIcon',{staticClass:"w-4 h-4 my-auto"}),_c('div',[_vm._v(_vm._s(_vm.error))])],1):_vm._e(),_c('div',{staticClass:"flex flex-row w-full space-x-4"},[_c('ValidationProvider',{class:{ 'w-2/3': _vm.modelClone.version, 'w-full': !_vm.modelClone.version },attrs:{"rules":{
                    required: true,
                    alphanumeric: _vm.modelClone.name,
                    min: 2,
                    max: 50,
                    concept_title_regex_validator: /^[a-zA-Z]+[A-Za-z0-9]*$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"w-full"},[_c('span',{staticClass:"flex items-start text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Title "),(_vm.calculatedChanges && 'name' in _vm.calculatedChanges)?_c('ChangeIndication',{attrs:{"changes":_vm.calculatedChanges,"field":'name'}}):_vm._e()],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelClone.name),expression:"modelClone.name"}],staticClass:"block w-full text-sm form-input text-neutral-700 disabled:bg-neutral-200",class:{ 'border-red-500': errors.length },attrs:{"name":"Title","type":"text","disabled":!_vm.createModel,"placeholder":_vm.createModel ? 'Enter model title' : ''},domProps:{"value":(_vm.modelClone.name)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.modelClone, "name", $event.target.value)},function($event){return _vm.emit('change', 'name', _vm.modelClone.name)}]}}),(errors.length)?_c('div',{staticClass:"mt-1 text-xs font-bold text-red-600"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])]}}])}),(_vm.modelClone.version)?_c('div',{staticClass:"w-1/3 space-y-1"},[_c('div',{staticClass:"w-full"},[_c('span',{staticClass:"flex items-start text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Version")]),_c('VersionInput',{attrs:{"version":_vm.modelClone.version,"disabled":true}})],1)]):_vm._e()],1),_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                max: 1000,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"w-full py-2"},[_c('span',{staticClass:"flex items-start text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Description "),(_vm.calculatedChanges && 'description' in _vm.calculatedChanges)?_c('ChangeIndication',{attrs:{"changes":_vm.calculatedChanges,"field":'description'}}):_vm._e()],1),_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelClone.description),expression:"modelClone.description"}],staticClass:"block w-full text-sm form-textarea text-neutral-700 disabled:bg-neutral-200",class:{
                            'border-red-500': errors.length,
                        },attrs:{"name":"Description","rows":"3","disabled":_vm.readOnly,"placeholder":"Enter the description of the specific model"},domProps:{"value":(_vm.modelClone.description)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.modelClone, "description", $event.target.value)},function($event){return _vm.emit('change', 'description', _vm.modelClone.description)}]}}),(errors.length)?_c('div',{staticClass:"mt-1 text-xs font-bold text-red-600"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()])])]}}])}),_c('div',{staticClass:"flex flex-col w-full space-y-2"},[_c('span',{staticClass:"flex items-start text-xs font-bold tracking-wider uppercase text-neutral-700"},[_vm._v("Standards "),(_vm.calculatedChanges && 'standardsMapping' in _vm.calculatedChanges)?_c('ChangeIndication',{attrs:{"changes":_vm.calculatedChanges,"field":'standardsMapping'}}):_vm._e()],1),_c('ValidationObserver',{ref:"standardsMappingRef",staticClass:"flex flex-col"},[(_vm.modelClone.standardsMapping)?_c('ModelStandards',{attrs:{"model":_vm.modelClone,"calculatedChanges":_vm.calculatedChanges},on:{"updateStandardsMapping":_vm.updateStandardsMapping},model:{value:(_vm.modelClone.standardsMapping),callback:function ($$v) {_vm.$set(_vm.modelClone, "standardsMapping", $$v)},expression:"modelClone.standardsMapping"}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }