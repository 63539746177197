/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { ref } from '@vue/composition-api';
import * as R from 'ramda';

export function useJsonObject() {
    // The paths of the fields that have type mismatch used in json parser
    const typeMismatches = ref<string[]>([]);

    /**
     * Returns the super object of a json object or array which contains all the fields
     * @param record The json object or array
     * @param obj The super object of the json object or array
     * @param path (optional) The path of the field used in json parser
     * @param separator (optional) The separator used in the path (e.g. ||)
     */
    const getSuperObject = (record: any, obj: any, path = '', separator = '') => {
        // For objects
        if (R.type(record) === 'Object') {
            Object.keys(record).forEach((field: any) => {
                // For arrays of objects create super object inside an array
                if (R.type(obj) === 'Array') {
                    if (obj.length === 0) obj.push({});
                    obj = obj[0];
                }
                // If field does not exist in super object then add it
                if (!Object.keys(obj).includes(field)) obj[field] = record[field];
                // Add non null value to overwrite the existing one (to handle the case where the existing one is null or empty)
                else if (
                    record[field] !== null &&
                    (obj[field] === null ||
                        (R.type(obj[field]) === 'Array' && obj[field].length === 0) ||
                        (R.type(obj[field]) === 'Object' && Object.keys(obj[field]).length === 0))
                )
                    obj[field] = record[field];

                // If two records have different type (except Array type) then it is marked as a type mismatch
                if (record[field] !== null && obj[field] !== null && R.type(record[field]) !== R.type(obj[field]))
                    if (R.type(record[field]) === 'Array')
                        // If a record has Array type then set its value to the super object
                        obj[field] = record[field];
                    else if (R.type(obj[field]) !== 'Array') {
                        obj[field] = String(record[field]); // cast to string in case of mismatch
                        const value = `${path}[0]${separator}${field}`;
                        if (!typeMismatches.value.includes(value)) typeMismatches.value.push(value);
                    }

                if (R.type(record[field]) === 'Object' || R.type(record[field]) === 'Array') {
                    let newObj = {}; // {} super object for objects
                    if (R.type(record[field]) === 'Array') newObj = []; // [] super object for arrays
                    if (obj[field] !== null) newObj = obj[field]; // value super object for all other types
                    getSuperObject(record[field], newObj, path, separator);
                    obj[field] = newObj;
                }
            });
        }
        // For arrays
        if (R.type(record) === 'Array') {
            record.forEach((object: any) => getSuperObject(object, obj, path, separator));
        }

        return obj;
    };

    /**
     * Adds missing fields to json irregular object or array
     * @param record The json irregular object or array
     * @param obj The super object of the json irregular object or array which contains all the fields
     */
    const addMissingFields = (record: any, obj: any) => {
        // If record is an Array
        if (R.type(record) === 'Array' && R.type(obj) === 'Object') {
            if (record.length === 0 && Object.keys(obj).length > 0) {
                record.push({});
            }
            record.forEach((object: any, index: number) => {
                record[index] = addMissingFields(object, obj);
            });
        }
        // If record is an Object
        if (R.type(record) === 'Object') {
            const keys = Object.keys(obj);
            keys.forEach((key: string) => {
                // If super object's key is not in the record then add it
                if (!Object.keys(record).includes(key)) {
                    if (R.type(obj[key]) === 'Object') {
                        record[key] = {}; // Add {} for objects
                    } else if (R.type(obj[key]) === 'Array') {
                        record[key] = []; // Add [] for arrays
                    } else {
                        record[key] = null; // Add null for all other types
                    }
                }
                // If record's value is an Object
                if (R.type(record[key]) === 'Object') {
                    record[key] = addMissingFields(record[key], obj[key]);
                }
                // If record's value is an Array
                if (R.type(record[key]) === 'Array' && R.type(obj[key]) === 'Array' && obj[key].length > 0) {
                    let child = obj[key][0];
                    // find the innermost object from last nested array
                    while (R.type(child) === 'Array') {
                        child = child[0];
                    }

                    if (R.type(child) === 'Object') {
                        record[key] = addMissingFields(record[key], child);
                    }
                }
            });
        }
        return record;
    };

    /**
     * Converts to array any record that has type Array in an occurrence in the json object/array
     * @param record The json object or array
     * @param obj The super object of the json object or array which contains all the fields
     */
    const convertToArray = (record: any, obj: any) => {
        // If value in super object is an Array but record is not then make it an Array
        if (R.type(obj) === 'Array' && R.type(record) !== 'Array') {
            if (record) {
                record = [record];
            } else {
                record = [];
            }
        }
        // If record is an Array
        if (R.type(record) === 'Array' && R.type(obj) === 'Object') {
            record.forEach((object: any, index: number) => {
                record[index] = convertToArray(object, obj);
            });
        }
        // If record is an Object
        if (R.type(record) === 'Object') {
            const keys = Object.keys(obj);
            keys.forEach((key: string) => {
                // If super object's value is an Array but record's value is not then make it an Array
                if (R.type(obj[key]) === 'Array' && R.type(record[key]) !== 'Array') {
                    if (record[key]) {
                        record[key] = [record[key]];
                    } else {
                        record[key] = [];
                    }
                }
                // If record's value is an Object
                if (R.type(record[key]) === 'Object') {
                    record[key] = convertToArray(record[key], obj[key]);
                }
                // If record's value is an Array
                if (
                    R.type(record[key]) === 'Array' &&
                    R.type(obj[key]) === 'Array' &&
                    obj[key].length > 0 &&
                    R.type(obj[key][0]) === 'Object'
                ) {
                    record[key] = convertToArray(record[key], obj[key][0]);
                }
            });
        }
        return record;
    };

    /**
     * Finds the super object of a json object or array, converts to array any record that has type Array
     * in an occurrence, adds missing fields and returns the fixed json object or array
     * @param json The json object or array
     */
    const getFixedJSON = (json: any) => {
        const superObj = getSuperObject(R.clone(json), {});
        const fixedJSON = convertToArray(R.clone(json), superObj);
        return addMissingFields(R.clone(fixedJSON), superObj);
    };

    /**
     * Converts json object to json object with its values the type of data
     * @param json The the json object to be converted
     * @param path The path of the field used in json parser
     * @param separator The separator used in the path (e.g. ||)
     */
    const convertToJSON = (json: any, path: string, separator: string): any => {
        if (json instanceof Array) {
            if (json.length > 1) {
                if (json[0] instanceof Object) {
                    const supersetObject: any = getSuperObject(json, {}, path, separator);

                    let item: any = json[0];
                    let obj: any = [];
                    let finalPath = path;
                    const initialObject = obj;

                    // build nested array structure using the innermost object nesting level
                    // e.g. item = [[{"key": "value"}]] output: obj = [[{// super object}]]
                    // e.g. finalPath = `path`[0][0]
                    while (R.is(Array, item)) {
                        obj.push([]);
                        obj = obj[0];
                        item = item[0];
                        finalPath += '[0]';
                    }

                    obj.push(supersetObject);
                    return convertToJSON(initialObject, finalPath, separator);
                }

                const result = [json[0]];
                return convertToJSON(result, `${path}[0]`, separator);
            }
            const convertedObject = convertToJSON(json[0], `${path}[0]`, separator);
            return [convertedObject];
        }
        if (json instanceof Object) {
            const keys = Object.keys(json);
            const convertedObject: any = {};
            for (let i = 0; i < keys.length; i += 1) {
                convertedObject[keys[i]] = convertToJSON(json[keys[i]], `${path}${separator}${keys[i]}`, separator);
            }
            return convertedObject;
        }
        if (R.isNil(json) || (!R.is(String, json) && R.isEmpty(json))) {
            return null;
        }
        return typeof json;
    };

    const getAllPaths = (obj: any, prefix: string, key: string, separator: string): string[] => {
        if (obj instanceof Array) {
            const allPaths = [];
            if (key !== '') {
                allPaths.push(prefix + key);
            }
            let newPrefix = prefix;
            if (!R.endsWith(separator, prefix)) {
                newPrefix = `${prefix}${separator}`;
            }
            if (newPrefix === separator) {
                newPrefix = '';
            }
            const p: any = getAllPaths(obj[0], newPrefix, `${key}[0]`, separator);
            for (let j = 0; j < p.length; j += 1) {
                allPaths.push(p[j]);
            }
            return allPaths;
        }
        if (obj instanceof Object) {
            const keys = Object.keys(obj);
            const allPaths = [];
            if (key !== '') {
                allPaths.push(prefix + key);
            }
            for (let i = 0; i < keys.length; i += 1) {
                let newPrefix = `${prefix + key}${separator}`;
                if (newPrefix === separator) {
                    newPrefix = '';
                }
                const p = getAllPaths(obj[keys[i]], newPrefix, keys[i], separator);
                for (let j = 0; j < p.length; j += 1) {
                    allPaths.push(p[j]);
                }
            }
            return allPaths;
        }

        if (R.isEmpty(key)) return [];
        return [prefix + key];
    };

    return {
        getSuperObject,
        addMissingFields,
        convertToArray,
        typeMismatches,
        getFixedJSON,
        convertToJSON,
        getAllPaths,
    };
}
