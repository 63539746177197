


















import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'TailwindAvatar',
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'bg-default-500',
        },
        size: {
            type: String,
            default: 'md', // sm, md, lg
        },
        round: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const sizeClasses = computed(() => {
            const sizeMappings = {
                xs: `h-10 w-10`,
                sm: `h-12 w-12`,
                md: `h-16 w-16 text-xl`,
                lg: `text-lg h-24 w-24 text-2xl`,
            } as any;
            return sizeMappings[props.size] || sizeMappings.md;
        });

        const roundClasses = computed(() => {
            return props.round ? 'rounded-full' : 'rounded';
        });

        const avatarClasses = computed(() => {
            return `${props.color} ${roundClasses.value} ${sizeClasses.value}`;
        });

        const avatarText = computed(() => {
            if (!props.name || typeof props.name !== 'string' || !props.name.length) {
                return '';
            }
            return props.name.charAt(0);
        });

        return { avatarClasses, avatarText, roundClasses, sizeClasses };
    },
});
