import { AssetType } from '@/modules/asset/constants';
import { AxiosRequestConfig } from 'axios';
import { WorkflowType } from '../constants';
import { MonitoringExecutionQuery } from '../types/monitoring-execution-query.interface';

const monitoringURL = '/api/monitoring';
const pipelineSpecificMonitoringURL = '/api/monitoring/executions';
const assetsMonitoringUrl = '/api/monitoring/assets';

export default {
    executions: (query: MonitoringExecutionQuery, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'POST',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}`,
            data: query,
        } as AxiosRequestConfig),
    longRunningExecutionLogs: (query: MonitoringExecutionQuery) =>
        ({
            method: 'POST',
            url: `${pipelineSpecificMonitoringURL}/data-checkin/${query.query.workflowId}/long-running/logs`,
            data: query,
        } as AxiosRequestConfig),
    executionMetrics: (query: MonitoringExecutionQuery, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'POST',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}/metrics`,
            data: query,
        } as AxiosRequestConfig),
    executionStreamingMetrics: (workflowId: string, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'GET',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}/${workflowId}/streaming-metrics`,
        } as AxiosRequestConfig),
    executionIncidents: (query: MonitoringExecutionQuery, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'POST',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}/incidents`,
            data: query,
        } as AxiosRequestConfig),
    executionTopFailures: (query: MonitoringExecutionQuery, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'POST',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}/top-failures`,
            data: query,
        } as AxiosRequestConfig),
    executionCompleteness: (query: MonitoringExecutionQuery, assetType: AssetType = AssetType.Dataset) =>
        ({
            method: 'POST',
            url: `${assetsMonitoringUrl}/${assetType.toLowerCase()}s/completeness`,
            data: query,
        } as AxiosRequestConfig),
    executionTimeliness: (query: MonitoringExecutionQuery, assetType: AssetType = AssetType.Dataset) =>
        ({
            method: 'POST',
            url: `${assetsMonitoringUrl}/${assetType.toLowerCase()}s/timeliness`,
            data: query,
        } as AxiosRequestConfig),
    executionLogs: (id: string, pipelineId: string, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'GET',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}/${pipelineId}/execution/${id}/logs`,
        } as AxiosRequestConfig),
    multipleExecutionLogs: (ids: string[], pipelineId: string, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'POST',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}/${pipelineId}/logs`,
            data: ids,
        } as AxiosRequestConfig),
    taskStats: (pipelineId: string, taskId: string) =>
        ({
            method: 'GET',
            url: `${pipelineSpecificMonitoringURL}/${WorkflowType.DataCheckin}/${pipelineId}/${taskId}`,
        } as AxiosRequestConfig),
    resetExecutions: (pipelineId: string, pipelineType: WorkflowType = WorkflowType.DataCheckin) =>
        ({
            method: 'DELETE',
            url: `${pipelineSpecificMonitoringURL}/${pipelineType}/${pipelineId}`,
        } as AxiosRequestConfig),
    datasetMetrics: (id: number) =>
        ({ method: 'GET', url: `${monitoringURL}/assets/datasets/${id}/metrics` } as AxiosRequestConfig),
    resultMetrics: (id: number) =>
        ({ method: 'GET', url: `${monitoringURL}/assets/results/${id}/metrics` } as AxiosRequestConfig),
    getLatestExecutionLogs: (pipelineId: string) =>
        ({
            method: 'GET',
            url: `${pipelineSpecificMonitoringURL}/analytics/${pipelineId}/execution/latest/logs`,
        } as AxiosRequestConfig),
    problematicAssets: (query: MonitoringExecutionQuery) =>
        ({
            method: 'POST',
            url: `${pipelineSpecificMonitoringURL}/analytics/problematic-assets`,
            data: query,
        } as AxiosRequestConfig),
};
