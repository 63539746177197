import { ExecutionStatus } from './execution-status.constants';

export class ExecutionStatusWrapper {
    static readonly RUNNING = new ExecutionStatusWrapper(ExecutionStatus.Running, 1);

    static readonly COMPLETED = new ExecutionStatusWrapper(ExecutionStatus.Completed, 2);

    static readonly QUEUED = new ExecutionStatusWrapper(ExecutionStatus.Queued, 3);

    static readonly FAILED = new ExecutionStatusWrapper(ExecutionStatus.Failed, 4);

    static readonly CANCELLED = new ExecutionStatusWrapper(ExecutionStatus.Cancelled, 5);

    private constructor(public readonly status: ExecutionStatus, public readonly priority: number) {
        this.status = status;
        this.priority = priority;
    }

    static all(): ExecutionStatusWrapper[] {
        return [this.COMPLETED, this.RUNNING, this.QUEUED, this.FAILED, this.CANCELLED];
    }

    static finishedStatuses(): ExecutionStatus[] {
        return [this.COMPLETED.status, this.FAILED.status, this.CANCELLED.status];
    }

    static find(status: ExecutionStatus): ExecutionStatusWrapper | null {
        for (let s = 0; s < ExecutionStatusWrapper.all().length; s++) {
            const statusWrapper = ExecutionStatusWrapper.all()[s];
            if (statusWrapper.status === status) {
                return statusWrapper;
            }
        }
        return null;
    }

    static map(): any {
        const statusMap = {};
        for (let s = 0; s < ExecutionStatusWrapper.all().length; s++) {
            const statusWrapper = ExecutionStatusWrapper.all()[s];
            statusMap[statusWrapper.status] = statusWrapper.priority;
        }
        return statusMap;
    }
}
