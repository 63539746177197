import { DataType } from './data-type.constants';
import { ObjectType, BooleanType, BinaryType, DateTimeType, NumericType, StringType } from '../type-components';

export class MetadataComponent {
    static readonly INTEGER = new MetadataComponent(NumericType, DataType.Integer);
    static readonly DOUBLE = new MetadataComponent(NumericType, DataType.Double);
    static readonly STRING = new MetadataComponent(StringType, DataType.String);
    static readonly BOOLEAN = new MetadataComponent(BooleanType, DataType.Boolean);
    static readonly DATETIME = new MetadataComponent(DateTimeType, DataType.DateTime);
    static readonly TIME = new MetadataComponent(DateTimeType, DataType.Time);
    static readonly DATE = new MetadataComponent(DateTimeType, DataType.Date);
    static readonly OBJECT = new MetadataComponent(ObjectType, DataType.Object);
    static readonly BINARY = new MetadataComponent(BinaryType, DataType.Binary);

    private constructor(public readonly component: any, public readonly type: DataType | null) {
        this.component = component;
        this.type = type;
    }

    static all(): MetadataComponent[] {
        return [
            this.INTEGER,
            this.DOUBLE,
            this.STRING,
            this.BOOLEAN,
            this.OBJECT,
            this.BINARY,
            this.DATE,
            this.TIME,
            this.DATETIME,
        ];
    }

    isThis = (type: DataType | null) => {
        return !this.type || (this.type && this.type === type);
    };

    static find(type: DataType | null): MetadataComponent {
        for (let s = 0; s < MetadataComponent.all().length; s++) {
            const component = MetadataComponent.all()[s];
            if (component.isThis(type)) {
                return component;
            }
        }
        throw Error(`Unable to find component with type '${type}'`);
    }
}
