








































import { defineComponent } from '@vue/composition-api';
import { ExclamationIcon, InformationCircleIcon, XCircleIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'GeneralAlert',
    props: {
        text: {
            type: String,
        },
        actionTitle: {
            type: String,
        },
        actionCallback: {
            type: Function,
            default: () => {
                return null;
            },
        },
        hasLoader: {
            type: Boolean,
            default: false,
        },
        alertType: {
            type: String,
            // default: 'danger',
            validator: (value: string) => ['danger', 'info', 'warning'].indexOf(value) >= 0,
        },
    },
    components: { ExclamationIcon, InformationCircleIcon, XCircleIcon },
    setup() {
        const areaClasses = {
            danger: 'bg-red-50 border-red-500',
            info: '  border-blue-700 bg-blue-100',
            warning: 'bg-orange-100 border-orange-500',
        };
        const textClasses = {
            danger: 'text-red-700',
            info: 'text-blue-800',
            warning: 'text-orange-900',
        };
        const baseColorClasses = {
            danger: '#f56565',
            info: '#4299e1',
            warning: '#ecc94b',
        };

        return { textClasses, areaClasses, baseColorClasses };
    },
});
