import * as R from 'ramda';
import { ValidatorUtils } from './utils';

export const alphanumericValidator = {
    checkWhiteSpaces: (text: string) => {
        const regex = new RegExp(/^\S*$/);
        return regex.test(text);
    },
    validate: (value: any) => {
        if (value === null || value === undefined || value === '') {
            return {
                valid: false,
            };
        }
        const noWhiteSpaces = alphanumericValidator.checkWhiteSpaces(value);
        if (noWhiteSpaces) {
            return {
                valid: /^[a-z0-9]+$/i.test(value),
            };
        }
        return {
            valid: noWhiteSpaces,
        };
    },
    message(field: string, args: any) {
        const value = R.is(Array, args) ? args[0] : args['_value_'];
        if ((args.length > 0 || Object.keys(args).length > 0) && value) {
            if (alphanumericValidator.checkWhiteSpaces(value)) {
                return `'${ValidatorUtils.prettifyName(field)}' should include only alphanumeric characters.`;
            }
        }
        return `'${ValidatorUtils.prettifyName(field)}' must have no spaces.`;
    },
};
