import { Model } from '@/app/interfaces';
import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';
import { ModelsAPI } from '../api';

export interface DataModelState {
    domains: Model[];
}

export const dataModelModule = defineModule({
    namespaced: true,
    state: (): DataModelState => {
        return {
            domains: [],
        };
    },
    mutations: {
        SET_DOMAINS(state: DataModelState, domains: Model[]) {
            state.domains = domains;
        },
    },
    getters: {
        domains: (state: DataModelState): Model[] => state.domains,
    },
    actions: {
        async loadDomains({ commit }) {
            await ModelsAPI.domains().then((res) => {
                if (res?.data) commit('SET_DOMAINS', res.data);
            });
        },
    },
});

export const dataModelModuleActionContext = (context: any) => moduleActionContext(context, dataModelModule);
