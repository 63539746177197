




































import { is, has } from 'ramda';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
    name: 'ButtonGroup',
    model: {
        prop: 'activeItem',
        event: 'click',
    },
    props: {
        options: {
            type: Array as PropType<string[] | Record<string, unknown>[]>,
            required: true,
        },
        labelField: {
            type: String,
            default: 'label',
        },
        keyField: {
            type: String,
            default: 'label',
        },
        tooltipField: {
            type: String,
            default: 'tooltip',
        },
        activeItem: {
            type: String,
            required: false,
        },
        activeItems: {
            type: Array as PropType<string[]>,
            required: false,
            default: () => [],
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        whiteBackground: {
            type: Boolean,
            default: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        borderIntensity: {
            type: Number,
            default: 300,
        },
        buttonClasses: {
            type: Array as PropType<String[]>,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const getItemKey = (item: unknown): string => {
            return is(Object, item) ? item[props.keyField] : item;
        };

        const getItemLabel = (item: unknown): string => {
            return is(Object, item) ? item[props.labelField] : item;
        };

        const getItemTooltip = (item: unknown): string => {
            return is(Object, item) ? item[props.tooltipField] : getItemLabel(item);
        };

        const getActiveClasses = (item: unknown): string => {
            return is(Object, item) ? item.activeClasses : '';
        };

        const getIsAvailable = (item: unknown): boolean => {
            return is(Object, item) && has('selectable', item) ? !!item.selectable : true;
        };

        return { getItemKey, getItemLabel, getItemTooltip, getActiveClasses, getIsAvailable };
    },
});
