

















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'LoaderCircle',
    props: {
        tooltip: {
            type: String,
            default: null,
        },
    },
});
