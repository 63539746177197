import { computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export function useMonitoringPeriod() {
    const TIME_PERIODS = {
        lastHour: {
            label: 'Last hour',
            summaryLabel: 'in the last hour',
            start: () => dayjs().utc().subtract(1, 'hour').toDate(),
            end: () => dayjs().utc().toDate(),
        },
        today: {
            label: 'Today',
            summaryLabel: 'today',
            start: () => dayjs().utc().hour(0).minute(0).second(0).millisecond(0).toDate(),
            end: () => dayjs().utc().hour(23).minute(59).second(59).millisecond(999).toDate(),
        },
        lastThreeDays: {
            label: 'Last three days',
            summaryLabel: 'in the last three days',
            start: () => dayjs().utc().subtract(3, 'day').hour(0).minute(0).second(0).millisecond(0).toDate(),
            end: () => dayjs().utc().hour(23).minute(59).second(59).millisecond(999).toDate(),
        },
        lastSevenDays: {
            label: 'Last seven days',
            summaryLabel: 'in the last seven days',
            start: () => dayjs().utc().subtract(7, 'day').hour(0).minute(0).second(0).millisecond(0).toDate(),
            end: () => dayjs().utc().hour(23).minute(59).second(59).millisecond(999).toDate(),
        },
        custom: {
            label: 'Define period',
            summaryLabel: 'in custom period',
            start: () => dayjs().utc().hour(0).minute(0).second(0).millisecond(0).toDate(),
            end: () => dayjs().utc().hour(23).minute(59).second(59).millisecond(999).toDate(),
            isCustom: true,
        },
    };

    const findByKey = (key: string) => {
        return TIME_PERIODS[key];
    };

    const listAll = computed(() =>
        Object.keys(TIME_PERIODS).map((key: string) => {
            return {
                key,
                ...TIME_PERIODS[key],
            };
        }),
    );

    const findPeriod = (
        start: Date | null | undefined,
        end: Date | null | undefined,
    ): {
        label: string;
        start: Date | null | undefined;
        end: Date | null | undefined;
        isCustom: boolean;
        period: string;
        summaryLabel: string;
    } => {
        for (let p = 0; p < listAll.value.length; p++) {
            const period = listAll.value[p];
            if (
                !period.isCustom &&
                start &&
                end &&
                dayjs.utc(period.start()).toISOString() === dayjs.utc(start).toISOString() &&
                dayjs.utc(period.end()).toISOString() === dayjs.utc(end).toISOString()
            )
                return { ...period, start, end, period: period.key };
        }
        return {
            ...TIME_PERIODS.custom,
            start,
            end,
            period: 'custom',
        };
    };
    return { listAll, findByKey, findPeriod };
}
