export const dateFormats = [
    { label: 'Infer from data', value: 'infer' },
    { label: 'YYYYMMDD', value: 'YYYYMMDD' },
    { label: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
    { label: 'YYYY.MM.DD', value: 'YYYY.MM.DD' },
    { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
    { label: 'DDMMYYYY', value: 'DDMMYYYY' },
    { label: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
    { label: 'DD.MM.YYYY', value: 'DD.MM.YYYY' },
    { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
    { label: 'MMDDYYYY', value: 'MMDDYYYY' },
    { label: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
    { label: 'MM.DD.YYYY', value: 'MM.DD.YYYY' },
    { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
    { label: 'D-Mon-YYYY', value: 'D-Mon-YYYY' },
    { label: 'Month D, YYYY', value: 'Month D, YYYY' },
    { label: 'Mon D, YYYY', value: 'Mon D, YYYY' },
];

export const datetimeFormats = [
    { label: 'Infer from data', value: 'infer' },
    { label: 'ISO 8601', value: 'ISO 8601' },
    { label: 'Unix Timestamp (nanoseconds)', value: 'Unix Timestamp (nanoseconds)' },
    { label: 'Unix Timestamp (microseconds)', value: 'Unix Timestamp (microseconds)' },
    { label: 'Unix Timestamp (milliseconds)', value: 'Unix Timestamp (milliseconds)' },
    { label: 'Unix Timestamp (seconds)', value: 'Unix Timestamp (seconds)' },
    { label: 'YYYYMMDDhhmmss', value: 'YYYYMMDDhhmmss' },

    { label: 'YYYY-MM-DD hh:mm:ss', value: 'YYYY-MM-DD hh:mm:ss' },
    { label: 'YYYY-MM-DD hhmmss', value: 'YYYY-MM-DD hhmmss' },
    { label: 'YYYY-MM-DD hh:mm:ss PM/AM', value: 'YYYY-MM-DD hh:mm:ss PM/AM' },

    { label: 'YYYY.MM.DD hh:mm:ss', value: 'YYYY.MM.DD hh:mm:ss' },
    { label: 'YYYY.MM.DD hhmmss', value: 'YYYY.MM.DD hhmmss' },
    { label: 'YYYY.MM.DD hh:mm:ss PM/AM', value: 'YYYY.MM.DD hh:mm:ss PM/AM' },

    { label: 'YYYY/MM/DD hh:mm:ss', value: 'YYYY/MM/DD hh:mm:ss' },
    { label: 'YYYY/MM/DD hhmmss', value: 'YYYY/MM/DD hhmmss' },
    { label: 'YYYY/MM/DD hh:mm:ss PM/AM', value: 'YYYY/MM/DD hh:mm:ss PM/AM' },

    { label: 'YYYYMMDD hh:mm:ss', value: 'YYYYMMDD hh:mm:ss' },
    { label: 'YYYYMMDD hhmmss', value: 'YYYYMMDD hhmmss' },
    { label: 'YYYYMMDD hh:mm:ss PM/AM', value: 'YYYYMMDD hh:mm:ss PM/AM' },

    { label: 'DD-MM-YYYY hh:mm:ss', value: 'DD-MM-YYYY hh:mm:ss' },
    { label: 'DD-MM-YYYY hhmmss', value: 'DD-MM-YYYY hhmmss' },
    { label: 'DD-MM-YYYY hh:mm:ss PM/AM', value: 'DD-MM-YYYY hh:mm:ss PM/AM' },

    { label: 'DD.MM.YYYY hh:mm:ss', value: 'DD.MM.YYYY hh:mm:ss' },
    { label: 'DD.MM.YYYY hhmmss', value: 'DD.MM.YYYY hhmmss' },
    { label: 'DD.MM.YYYY hh:mm:ss PM/AM', value: 'DD.MM.YYYY hh:mm:ss PM/AM' },

    { label: 'DD/MM/YYYY hh:mm:ss', value: 'DD/MM/YYYY hh:mm:ss' },
    { label: 'DD/MM/YYYY hhmmss', value: 'DD/MM/YYYY hhmmss' },
    { label: 'DD/MM/YYYY hh:mm:ss PM/AM', value: 'DD/MM/YYYY hh:mm:ss PM/AM' },

    { label: 'DDMMYYYY hh:mm:ss', value: 'DDMMYYYY hh:mm:ss' },
    { label: 'DDMMYYYY hhmmss', value: 'DDMMYYYY hhmmss' },
    { label: 'DDMMYYYY hh:mm:ss PM/AM', value: 'DDMMYYYY hh:mm:ss PM/AM' },

    { label: 'MM-DD-YYYY hh:mm:ss', value: 'MM-DD-YYYY hh:mm:ss' },
    { label: 'MM-DD-YYYY hhmmss', value: 'MM-DD-YYYY hhmmss' },
    { label: 'MM-DD-YYYY hh:mm:ss PM/AM', value: 'MM-DD-YYYY hh:mm:ss PM/AM' },

    { label: 'MM.DD.YYYY hh:mm:ss', value: 'MM.DD.YYYY hh:mm:ss' },
    { label: 'MM.DD.YYYY hhmmss', value: 'MM.DD.YYYY hhmmss' },
    { label: 'MM.DD.YYYY hh:mm:ss PM/AM', value: 'MM.DD.YYYY hh:mm:ss PM/AM' },

    { label: 'MM/DD/YYYY hh:mm:ss', value: 'MM/DD/YYYY hh:mm:ss' },
    { label: 'MM/DD/YYYY hhmmss', value: 'MM/DD/YYYY hhmmss' },
    { label: 'MM/DD/YYYY hh:mm:ss PM/AM', value: 'MM/DD/YYYY hh:mm:ss PM/AM' },

    { label: 'MMDDYYYY hh:mm:ss', value: 'MMDDYYYY hh:mm:ss' },
    { label: 'MMDDYYYY hhmmss', value: 'MMDDYYYY hhmmss' },
    { label: 'MMDDYYYY hh:mm:ss PM/AM', value: 'MMDDYYYY hh:mm:ss PM/AM' },

    { label: 'D-Mon-YYYY hh:mm:ss', value: 'D-Mon-YYYY hh:mm:ss' },
    { label: 'D-Mon-YYYY hhmmss', value: 'D-Mon-YYYY hhmmss' },
    { label: 'D-Mon-YYYY hh:mm:ss PM/AM', value: 'D-Mon-YYYY hh:mm:ss PM/AM' },

    { label: 'Month D, YYYY hh:mm:ss', value: 'Month D, YYYY hh:mm:ss' },
    { label: 'Month D, YYYY hhmmss', value: 'Month D, YYYY hhmmss' },
    { label: 'Month D, YYYY hh:mm:ss PM/AM', value: 'Month D, YYYY hh:mm:ss PM/AM' },

    { label: 'Mon D, YYYY hh:mm:ss', value: 'Mon D, YYYY hh:mm:ss' },
    { label: 'Mon D, YYYY hhmmss', value: 'Mon D, YYYY hhmmss' },
    { label: 'Mon D, YYYY hh:mm:ss PM/AM', value: 'Mon D, YYYY hh:mm:ss PM/AM' },
];
