export enum ApplicationRoles {
    Admin = 'admin',
    ModelModerator = 'model-moderator',
    Guest = 'guest',
    User = 'user',
}

export enum OrganisationRoles {
    Manager = 'manager',
    Member = 'member',
}

export const UserRoles = { ...ApplicationRoles, ...OrganisationRoles };
export type UserRoles = ApplicationRoles | OrganisationRoles;
