import { useAxios } from '@/app/composable';
import store from '@/app/store';
import { computed, Ref, ref, watch } from '@vue/composition-api';
import Fuse from 'fuse.js';
import * as R from 'ramda';
import { ModelBrowserAPI } from '../api';
import { Concept, Model } from '../interfaces';

interface SearchPayload {
    model: number;
    concept?: string;
}

const initialized: Ref<boolean> = ref<boolean>(false);
const domainLoading: Ref<boolean> = ref<boolean>(false);
const modelsLoading: Ref<boolean> = ref<boolean>(false);
const conceptsLoading: Ref<boolean> = ref<boolean>(false);
const concepts: Ref<Concept[]> = ref<Concept[]>([]);
const fetchedConceptsFrom: Ref<number> = ref<number>(0);

const searchText: Ref<string> = computed({
    get: (): string => store.getters.modelBrowser.getSearchText,
    set: (newSearchText: string) => store.dispatch.modelBrowser.setSearchText({ searchText: newSearchText }),
});

const filteredConcepts: Ref<Concept[]> = computed(() => {
    if (searchText.value.trim() === '') return concepts.value;

    const fuseSearch = new Fuse(concepts.value, {
        keys: [
            { name: 'name', weight: 2 },
            { name: 'relatedTerms', weight: 1 },
            { name: 'tokenizedTerms', weight: 1 },
            ['children', 'name'],
            ['children', 'relatedTerms'],
            ['children', 'tokenizedTerms'],
        ],
        minMatchCharLength: 3,
        shouldSort: true,
        threshold: 0.2,
    });
    const highLevelSearch = fuseSearch.search(searchText.value);
    return highLevelSearch.map((result: { item: Concept }) => {
        const hlc = result.item;
        if (!hlc.children) return hlc;
        const childSearch = new Fuse(hlc.children, {
            keys: [
                { name: 'name', weight: 2 },
                { name: 'relatedTerms', weight: 1 },
                { name: 'tokenizedTerms', weight: 1 },
            ],
            minMatchCharLength: 3,
            shouldSort: true,
            threshold: 0.2,
        });
        return {
            ...hlc,
            children: childSearch.search(searchText.value).map((childResult: { item: Concept }) => childResult.item),
        };
    });
});

const modelsMap: Ref<Record<string, Model>> = computed(() =>
    models.value.reduce((acc: any, model: Model) => {
        acc[model.uid] = model;
        return acc;
    }, {}),
);

const modelsIdMap: Ref<Record<string, string>> = computed(() =>
    Object.values(modelsMap.value).reduce((acc: any, model: Model) => {
        acc[model.id] = model.uid;

        return acc;
    }, {}),
);

const conceptsMap: Ref<Record<string, Concept>> = computed(() =>
    concepts.value.reduce((acc: any, concept: Concept) => {
        acc[concept.uid] = concept;
        for (let c = 0; concept.children && c < concept.children.length; c++) {
            const child = concept.children[c];
            acc[child.uid] = child;
        }
        return acc;
    }, {}),
);

const conceptsIdMap: Ref<Record<string, string>> = computed(() =>
    Object.values(conceptsMap.value).reduce((acc: any, concept: Concept) => {
        acc[concept.id] = concept.uid;

        return acc;
    }, {}),
);

const models = computed(() => store.getters.dataModel.domains);

const modelUid: Ref<string | undefined> = computed({
    get: (): string | undefined => store.getters.modelBrowser.getModel,
    set: (model: string | undefined) => {
        store.dispatch.modelBrowser.setModel({ model });
        path.value = [];
    },
});

const conceptUid: Ref<string | undefined> = computed({
    get: (): string | undefined => store.getters.modelBrowser.getConcept,
    set: (concept: string | undefined) => store.dispatch.modelBrowser.setConcept({ concept }),
});

const model: Ref<Model | undefined> = computed({
    get: (): Model | undefined => {
        if (modelUid.value && R.has(modelUid.value, modelsMap.value)) {
            return modelsMap.value[modelUid.value];
        }
        return undefined;
    },
    set: (newModel: Model | undefined) => {
        modelUid.value = newModel?.uid;
    },
});

const concept: Ref<Concept | undefined> = computed({
    get: (): Concept | undefined => {
        if (conceptUid.value && R.has(conceptUid.value, conceptsMap.value)) {
            return conceptsMap.value[conceptUid.value];
        }

        return undefined;
    },
    set: (newConcept: Concept | undefined) => {
        conceptUid.value = newConcept?.uid;
    },
});

const restrictingConceptId = computed(() => store.getters.modelBrowser.getRestrictedConcept);

const currentPath = computed(() => store.getters.modelBrowser.getPath);

const path: Ref<Concept[]> = computed({
    get: () => {
        const pathConcepts: Concept[] = [];
        if (currentPath.value) {
            for (let p = 0; p < currentPath.value.length; p++) {
                const pathUid = currentPath.value[p];
                if (pathUid in conceptsMap.value) pathConcepts.push(conceptsMap.value[pathUid]);
                else return [];
            }
        }
        return pathConcepts;
    },

    set: (newPath: Concept[]) => {
        store.dispatch.modelBrowser.setPath({ path: newPath.map((p: Concept) => p.uid) });
    },
});

export function useModelBrowser() {
    const { exec } = useAxios(true);

    const restrictingConcept: Ref<Concept | undefined> = computed(() =>
        restrictingConceptId.value ? getConceptFromId(restrictingConceptId.value) : undefined,
    );

    const restrictingHLC: Ref<Concept | undefined> = computed(() => {
        // find the restricting high level concept
        // this comes down to 2 main cases. either the restricting concept is not a leaf
        // node and therefore it's the HLC concept itself
        // or it's a leaf node and therefore the parent of the restricting concept
        // is the HLC restricting concept
        if (restrictingConcept.value && restrictingConcept.value.leaf && path.value.length > 0) {
            return getConceptFromUid(path.value[path.value.length - 1].uid);
        }
        if (restrictingConcept.value && !restrictingConcept.value.leaf) {
            return getConceptFromUid(restrictingConcept.value.uid);
        }
        return undefined;
    });

    const refreshModels = async () => {
        modelsLoading.value = true;
        await store.dispatch.dataModel.loadDomains();
        modelsLoading.value = false;
    };

    const filterConcepts = (children: Concept[], uid: string | undefined, parent: Concept | null): Concept[] => {
        return children.reduce((acc: any[], child: Concept) => {
            child.children = child.children ? filterConcepts(child.children, uid, child) : [];
            if (uid && child.alternativePaths)
                child.alternativePaths = child.alternativePaths.filter((altPath: any) => altPath.uids.includes(uid));
            acc.push(parent ? { ...child, parentUid: parent.uid, parentId: parent.id } : child);
            return acc;
        }, []);
    };

    const fetchConcepts = (filter: SearchPayload): Promise<Concept[]> => {
        return new Promise<Concept[]>((resolve, reject) => {
            exec(ModelBrowserAPI.getDomain(filter.model))
                .then((res: any) => {
                    resolve(filterConcepts(res.data, filter.concept, null));
                })
                .catch((e) => reject(e))
                .finally(() => (domainLoading.value = false));
        });
    };

    const refreshConcepts = (filter: SearchPayload) => {
        conceptsLoading.value = true;

        fetchConcepts(filter)
            .then((newConcepts: Concept[]) => {
                concepts.value = newConcepts;
                fetchedConceptsFrom.value = filter.model;

                if ((!conceptUid.value || !R.has(conceptUid.value, conceptsMap.value)) && concepts.value.length > 0) {
                    for (let pc = 0; pc < concepts.value.length; pc++) {
                        const parentConcept: Concept = concepts.value[pc];
                        if (parentConcept.children && parentConcept.children.length > 0) {
                            concept.value = parentConcept.children[0];
                            break;
                        }
                    }
                } else if (conceptUid.value) {
                    concept.value = conceptsMap.value[conceptUid.value];
                }
            })
            .finally(() => (conceptsLoading.value = false));
    };

    const getConceptFromId = (conceptId: number): Concept => getConceptFromUid(conceptsIdMap.value[String(conceptId)]);
    const getConceptFromUid = (uid: string): Concept => conceptsMap.value[uid];
    const getModelFromId = (modelId: number): Model => getModelFromUid(modelsIdMap.value[String(modelId)]);
    const getModelFromUid = (uid: string): Model => modelsMap.value[uid];

    const searchPayload: Ref<SearchPayload | undefined> = computed(() => {
        return model.value
            ? {
                  model: model.value.id,
                  concept: restrictingConcept.value?.uid,
              }
            : undefined;
    });

    const resetModelBrowser = () => {
        store.dispatch.modelBrowser.clear();
    };

    const initialize = async () => {
        if (initialized.value) return;
        initialized.value = true;
        watch(
            () => searchPayload.value,
            (newFilters: SearchPayload | undefined, oldFilters: SearchPayload | undefined) => {
                if (!newFilters) return;
                if (JSON.stringify(newFilters) !== JSON.stringify(oldFilters)) {
                    if (newFilters.model !== oldFilters?.model) {
                        domainLoading.value = true;
                    }
                    refreshConcepts(newFilters);
                }
            },
            { immediate: true },
        );

        watch(
            () => restrictingConcept.value,
            (restrictingCon: Concept | undefined) => {
                if (
                    restrictingCon &&
                    (path.value.length === 0 ||
                        (path.value[path.value.length - 1].uid !== restrictingCon.uid &&
                            path.value[path.value.length - 1].parentUid !== restrictingCon.uid)) &&
                    restrictingCon.children &&
                    restrictingCon.children.length > 0
                ) {
                    // switch to restricting concept if the current path does not start with this concept
                    concept.value = restrictingCon.children[0];
                }
            },
        );

        watch(
            () => models.value,
            () => {
                if ((!modelUid.value || !R.has(modelUid.value, modelsMap.value)) && models.value.length > 0)
                    model.value = models.value[0];
            },
        );
    };

    return {
        model,
        concept,
        path,
        searchText,
        modelUid,
        models,
        concepts,
        filteredConcepts,
        domainLoading,
        modelsLoading,
        conceptsLoading,
        restrictingConcept,
        restrictingHLC,
        fetchedConceptsFrom,
        initialize,
        getConceptFromId,
        getConceptFromUid,
        resetModelBrowser,
        refreshModels,
        getModelFromId,
    };
}
