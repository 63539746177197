import { Concept, Model } from '@/app/interfaces';
import { Ref, computed, ref } from '@vue/composition-api';

export function useSuggestions(model: Ref<Model>, concepts: Ref<Concept[]>) {
    const domainId = computed<number>(() => (model.value ? model.value.id : -1));
    const showModal = ref<boolean>(false);
    const domainConcepts = computed(() => {
        return concepts.value.reduce(
            (acc: { id: number; referenceId: number | undefined; name: String }[], obj: Concept) => {
                if (!obj.deprecated) {
                    acc.push({
                        id: obj.id,
                        referenceId: obj.referenceId,
                        name: obj.name,
                    });
                }
                return acc;
            },
            [],
        );
    });

    const domainStandards = computed(() => model.value.standardsMapping);

    const initConcept = () => ({
        name: '',
        description: '',
        type: null,
        domainId: domainId.value,
        parentId: null,
        referenceConceptId: null,
        relatedTerms: [],
        metadata: {
            measurementType: null,
            measurementUnit: null,
        },
        standardsMapping: [],
    });

    return { showModal, domainId, domainConcepts, domainStandards, initConcept };
}
