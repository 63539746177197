import { isNil } from 'ramda';
import store from '@/app/store';
import { computed } from '@vue/composition-api';
import { Block } from '@/modules/workflow-designer/types';

export function useBlocks(immediate = true) {
    const blocks = computed((): readonly Block[] => store.getters.pipelineDesigner.availableBlocks || []);
    const loading = computed(() => store.getters.pipelineDesigner.isLoadingBlocks);
    const blocksMap = computed(() =>
        blocks.value.reduce((acc: Record<string, Block>, block: Block) => {
            acc[block.id] = block;
            return acc;
        }, {}),
    );

    const refetch = async () => {
        await store.dispatch.pipelineDesigner.loadBlocks();
    };

    const findById = (id: string) => {
        return blocksMap.value[id];
    };

    if (immediate && isNil(store.getters.pipelineDesigner.availableBlocks) && !loading.value) refetch();

    return { blocks, loading, refetch, blocksMap, findById };
}
