






















import { AdvancedSelect } from '@/app/components';
import { computed, defineComponent, PropType, Ref } from '@vue/composition-api';
import { ModelAlgorithmLibrary } from '../types/model-algorithm-library.type';

export default defineComponent({
    name: 'ModelAlgorithmSelector',
    model: { prop: 'algorithm', event: 'change' },
    props: {
        algorithm: { type: String },
        library: { type: Object as PropType<{ key: ModelAlgorithmLibrary; label: string } | undefined> },
        disabled: { type: Boolean, fefault: false },
    },
    components: { AdvancedSelect },
    setup(props, { emit }) {
        const algorithmsMap: Record<ModelAlgorithmLibrary, string[]> = {
            mllib: [
                'Decision Tree Classifier',
                'Decision Tree Regressor',
                'Kmeans',
                'Linear Regressor',
                'Logistic Regression',
                'Naive Bayes',
                'PCA',
                'Random Forest Classifier',
                'Random Forest Regressor',
                'SVM Classifier',
                'OneHotEncoder',
                'String Indexer',
                'Standard Scaler',
                'MinMax Scaler',
            ],
            sklearn: [
                'AffinityPropagation Clustering',
                'Agglomerative Clustering',
                'DBSCAN Clustering',
                'Decision Tree Classifier',
                'Decision Tree Regressor',
                'Kmeans Clustering',
                'KNN Classifier',
                'KNN Regressor',
                'Linear Regression',
                'Logistic Regression',
                'MeanShift Clustering',
                'Gaussian Naive Bayes Classifier',
                'Multinomial Naive Bayes Classifier',
                'Complement Naive Bayes Classifier',
                'Bernoulli Naive Bayes Classifier',
                'Categorical Naive Bayes Classifier',
                'PCA',
                'Random Forest Classifier',
                'Random Forest Regressor',
                'Spectral Clustering',
                'Support Vector Classifier',
                'Nu-Support Vector Classifier',
                'Linear Support Vector Classifier',
                'One Class Support Vector Classifier',
                'Support Vector Regressor',
                'Nu-Support Vector Regressor',
                'Linear Support Vector Regressor',
                'OneHotEncoder',
                'Label Encoder',
                'Ordinal Encoder',
                'MinMax Scaler',
                'Standard Scaler',
                'Elliptic Envelope',
                'Isolation Forest',
                'Local Outlier Factor',
            ],
            xgboost: ['Xgboost Classifier', 'Xgboost Regressor'],
            statsmodel: ['Vector Autoregression'],
            keras: ['Keras model'],
            pmdarima: ['Auto Arima'],
        };
        const algorithms: Ref<string[]> = computed(() => {
            if (props.library?.key) return algorithmsMap[props.library.key];
            return [];
        });

        const currentAlgorithm: Ref<string | undefined> = computed({
            get: () => {
                return algorithms.value.find((a: string) => a === props.algorithm);
            },
            set: (newAlgorithm: string | undefined): void => {
                emit('change', newAlgorithm);
            },
        });
        const algorithmItems: Ref<{ label: string }[]> = computed(() =>
            algorithms.value.map((label: string) => {
                return { label };
            }),
        );

        return { algorithms, algorithmItems, currentAlgorithm };
    },
});
