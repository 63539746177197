import store from '@/app/store';
import { ExecutionError, ExecutionErrorCategory } from '@/app/store/execution-errors';
import { S } from '@/app/utilities';
import { computed } from '@vue/composition-api';
import * as R from 'ramda';

export function useExecutionErrors() {
    const executionErrors = computed(() => store.state.executionErrors.executionErrors);

    const errorMessage = (
        errorCode: number | string,
    ): { category: string; error: { title: string; description: string } } => {
        const defaultMessage = {
            category: ExecutionErrorCategory.Generic,
            error: { title: 'Unknown Error', description: `Unknown error with code ${errorCode}` },
        };
        if (R.isNil(errorCode)) return defaultMessage;
        const codeString = S.fillWithZeros(errorCode, 4);

        const executionError = executionErrors.value.find((err: ExecutionError) => err.code === codeString);
        if (executionError)
            return {
                category: executionError.category,
                error: { title: executionError.title, description: executionError.description },
            };

        return defaultMessage;
    };

    const errorOccurrence = (errorCount: number, failedExecutions: number) => {
        return Math.round((errorCount / failedExecutions) * 100) / 100;
    };

    const errorOccurrencePercentage = (errorCount: number, failedExecutions: number) => {
        return ((errorCount / failedExecutions) * 100).toFixed(2);
    };

    return { errorOccurrence, errorOccurrencePercentage, errorMessage };
}
