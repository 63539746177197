













































import { defineComponent, ref, computed } from '@vue/composition-api';
import store from '@/app/store';
import PageHeader from './PageHeader.vue';
import SideMenu from './SideMenu.vue';
import { useRoute } from '@/app/composable/router';

export default defineComponent({
    name: 'BaseLayout',
    components: {
        PageHeader,
        SideMenu,
    },
    setup(props, { root }) {
        const mobileMenuOpen = ref(false);
        const isAuthenticated = computed(() => store.getters.auth.isAuthenticated);
        const currentRoute = useRoute();

        // remove hash added by the keyclock authentication to the uri
        if (
            document.location.hash.length > 0 &&
            document.location.hash.match(/^#.*code=[a-zA-Z0-9]*/g) &&
            isAuthenticated
        ) {
            root.$router.push({ ...(currentRoute as any), hash: '' });
        }

        return {
            mobileMenuOpen,
            isAuthenticated,
        };
    },
});
