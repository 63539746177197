





















import SvgImage from '@/app/components/SvgImage.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'Error404',
    metaInfo: {
        title: 'Page not found',
    },
    components: { SvgImage },
});
