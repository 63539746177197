export enum BlockType {
    Basic = 'basic',
    Math = 'math',
    Complex = 'complex',
    Aggregations = 'aggregations',
    TimeSeries = 'time-series',
    Train = 'train',
    Apply = 'apply',
    Evaluate = 'evaluate',
    ConditionalOperations = 'conditional-operations',
    NestedDataTransformation = 'nested-data-transformation',
    StringTransformations = 'string-transformations',
    DatatypeTransformations = 'datatype-transformations',
    DatetimeTransformations = 'datetime-transformations',
    Other = 'other',
    Loop = 'loop',
}
