import { getCurrentInstance } from '@vue/composition-api';
import Vue, { ComponentOptions } from 'vue';
import VueRouter, { NavigationGuard, Route } from 'vue-router';
import router from '../router';

export function onHook(name: keyof ComponentOptions<Vue>, callback: (...args: any) => void) {
    const vm = getCurrentInstance();
    const merge = Vue.config.optionMergeStrategies[name];
    if (vm && merge) {
        const prototype = Object.getPrototypeOf(vm.proxy.$options);
        prototype[name] = merge(vm.proxy.$options[name], callback);
    }
}

export function onBeforeRouteUpdate(callback: NavigationGuard<Vue>) {
    return onHook('beforeRouteUpdate', callback);
}

export function onBeforeRouteLeave(callback: NavigationGuard<Vue>) {
    return onHook('beforeRouteLeave', callback);
}

export function useRoute(): Readonly<Route> {
    return router.currentRoute;
}

export function useRouter(): Readonly<VueRouter> {
    return router;
}
