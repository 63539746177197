const STATIC_CACHE_STORAGE = 'static';

export function useCache() {
    const errorMessage = 'No cache entry found in window object';
    const checkCacheAndFetchItem = (item: string): Promise<string | null> => {
        return new Promise((resolve, reject) => {
            if (!window.caches) reject(errorMessage);
            caches.open(STATIC_CACHE_STORAGE).then(() => {
                caches.match(item).then(async (response: any) => {
                    if (response) {
                        response.text().then((cacheRes: any) => {
                            resolve(cacheRes);
                        });
                    } else {
                        resolve(null);
                    }
                });
            });
        });
    };

    const addToCache = (item: any, data: any, headers: any) => {
        if (!window.caches) throw new Error(errorMessage);
        caches.open(STATIC_CACHE_STORAGE).then((cache) => {
            cache.put(
                item,
                new Response(data, {
                    headers,
                }),
            );
        });
    };

    return {
        checkCacheAndFetchItem,
        addToCache,
    };
}
