import { useQuery } from '@/app/composable';
import store from '@/app/store';
import GET_LOCATIONS from '../graphql/locations.graphql';

export function useLocations() {
    const initialize = () => {
        const { result, onResult } = useQuery(GET_LOCATIONS, {}, { fetchPolicy: 'no-cache' });

        onResult(() => {
            if (result.value) store.dispatch.locations.setLocations(result.value.continents);
        });
    };
    return { initialize };
}
