import { S } from '@/app/utilities';
import { computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { VariableType } from '../constants';
import { Variable } from '../interfaces';

export function useVariableUtils() {
    const variableRegexp = /(\{\{([a-zA-Z0-9+\-_\\*\\/\\(\\) ]+)\}\})/gm;

    const getMatchedVariables = (input: any, availableVariables: Record<string, Variable>) => {
        if (Object.keys(availableVariables).length === 0) return [];
        const matches = String(input).match(variableRegexp);

        if (!matches) return [];
        return matches;
    };

    const getDisplayValue = (input: any, matchedVariables: string[]) => {
        const sanitizedInput = S.sanitizeHtml(input);
        if (matchedVariables.length === 0) return String(sanitizedInput);
        return matchedVariables.reduce((acc: string, group: string) => {
            const trimmedGroup = S.sanitizeHtml(group.replace(/^\{\{/, '').replace(/\}\}$/, ''));
            return acc.replace(group, `<span class="rounded bg-indigo-300 px-2 py-0.5">${trimmedGroup}</span>`);
        }, String(sanitizedInput));
    };
    return { getDisplayValue, getMatchedVariables };
}

export function useVariables(
    input: Ref<string | number>,
    type: Ref<VariableType>,
    availableVariables: Ref<Record<string, Variable>>,
    rules?: Ref<any>,
) {
    const { getDisplayValue, getMatchedVariables } = useVariableUtils();

    const hasAvailableVariables = computed(() => Object.keys(availableVariables.value).length > 0);

    const matchedVariables: Ref<string[]> = computed(() => getMatchedVariables(input.value, availableVariables.value));

    const displayValue: Ref<string> = computed(() => getDisplayValue(input.value, matchedVariables.value));

    const validationValue: Ref<string | number | null> = computed(() => {
        return transform(String(input.value), true);
    });

    const inputType = computed(() => {
        if (hasAvailableVariables.value) return 'text';
        // Leaving here in case we want to put this back in the future
        // if (type.value === VariableType.Integer || type.value === VariableType.Double) return 'number';

        return 'text';
    });

    const parse = (val: any): string | number | null => {
        if (R.isNil(val) || R.isEmpty(String(val).trim())) return null;
        try {
            if (!isNaN(val) && (type.value === VariableType.Integer || type.value === VariableType.Double)) {
                return Number(val);
            }
        } catch {
            return val;
        }
        return val;
    };

    const replacement = computed((): string => {
        if ([VariableType.Double, VariableType.Integer].includes(type.value)) {
            if (rules?.value?.min_value) return `${rules.value.min_value}`;
            if (rules?.value?.max_value) return `${rules.value.max_value}`;
            return '1';
        }

        // in case there is a minimum number of characters the variable should fill in with appropriate characters
        if (rules?.value?.min)
            return Array(rules?.value?.min)
                .fill([VariableType.Double, VariableType.Integer].includes(type.value) ? '1' : 'a')
                .join('');
        return 'a';
    });

    const transform = (val: string, replace: boolean = false): string | number | null => {
        if (matchedVariables.value.length === 0 || !hasAvailableVariables.value) return parse(val);
        if (replace) {
            const replaced: string = matchedVariables.value.reduce((acc: string, group: string) => {
                return acc.replace(group, replacement.value);
            }, String(val));
            return parse(replaced);
        }
        return val;
    };

    return { hasAvailableVariables, displayValue, validationValue, inputType, transform };
}
