



import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import { computed, defineComponent } from '@vue/composition-api';
import { Icon } from '@iconify/vue2';
import * as R from 'ramda';

export default defineComponent({
    name: 'AccessLevelDynamicBadgeCompact',
    props: {
        accessLevel: { type: String, required: true },
    },
    components: { Icon },
    setup(props) {
        const accessLevelIcon = computed(() => {
            if (R.isNil(props.accessLevel)) return;

            switch (props.accessLevel) {
                case AccessLevel.Private:
                    return 'material-symbols:lock'; // lock
                case AccessLevel.OrganisationLevel:
                    return 'bi:building-fill'; // building
                case AccessLevel.SelectiveSharing:
                    return 'bi:building-fill-exclamation'; //building cog
                case AccessLevel.Restricted:
                    return 'fluent:people-settings-24-filled'; // partial
                case AccessLevel.Public:
                    return 'material-symbols:public'; // globe
                default:
                    return null;
            }
        });

        return {
            AccessLevel,
            accessLevelIcon,
        };
    },
});
