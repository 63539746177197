export const KerasStructure = [
    {
        id: 0,
        name: 'trained_model.h5',
        type: 'file',
        children: [],
        required: true,
    },
];

export const MLLIbStructure = [
    {
        id: 0,
        name: 'trained_model',
        type: 'directory',
        children: [
            {
                name: 'stages',
                children: [
                    {
                        name: '0_StringIndexer_89d91e427f55',
                        id: 5,
                        type: 'directory',
                        children: [
                            {
                                name: 'data',
                                id: 50,
                                type: 'directory',
                                required: false,
                                children: [
                                    {
                                        name: 'part-00000-57086c66-73a8-4802-b093-8a48e704ce19-c000.snappy.parquet',
                                        id: 6,
                                        type: 'file',
                                        children: [],
                                        required: false,
                                    },
                                    {
                                        name: '_SUCCESS',
                                        id: 7,
                                        type: 'file',
                                        children: [],
                                        required: false,
                                    },
                                ],
                            },
                            {
                                name: 'metadata',
                                id: 500,
                                type: 'directory',
                                required: false,
                                children: [
                                    {
                                        name: 'part-00000',
                                        id: 8,
                                        type: 'file',
                                        children: [],
                                        required: false,
                                    },
                                    {
                                        name: '_SUCCESS',
                                        id: 9,
                                        type: 'file',
                                        children: [],
                                        required: false,
                                    },
                                ],
                            },
                        ],
                        required: false,
                    },
                ],
                id: 1,
                type: 'directory',
                required: true,
            },
            {
                name: 'metadata',
                children: [
                    {
                        name: 'part-00000',
                        id: 10,
                        type: 'file',
                        children: [],
                        required: false,
                    },
                    {
                        name: '_SUCCESS',
                        id: 11,
                        type: 'file',
                        children: [],
                        required: false,
                    },
                ],
                id: 2,
                type: 'directory',
                required: true,
            },
        ],
        required: true,
    },
];

export const SKLearnStructure = [
    {
        id: 0,
        name: 'trained_model.pkl',
        type: 'file',
        children: [],
        required: true,
    },
];

export const PMDArima = [
    {
        id: 0,
        name: 'trained_model.pkl',
        type: 'file',
        children: [],
        required: true,
    },
];
