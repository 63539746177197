import { UnleashClient } from 'unleash-proxy-client';

import { defineModule } from 'direct-vuex';
import { FeatureFlagClient } from '../types';

export interface FeatureFlagsState {
    initialized: boolean;
    featureFlagClient: FeatureFlagClient;
}

export const featureFlagsModule = defineModule({
    namespaced: true,
    state: (): FeatureFlagsState => {
        return {
            featureFlagClient: {
                isEnabled: () => false,
                onChange: () => false,
            },
            initialized: false,
        };
    },
    getters: {
        isEnabled: (state: FeatureFlagsState) => (feature: string): boolean =>
            state.featureFlagClient.isEnabled(feature),
    },
    actions: {
        async initialize({ state }) {
            if (
                !state.initialized &&
                process.env.VUE_APP_FEATURES_HOST &&
                process.env.VUE_APP_FEATURES_CLIENT_KEY &&
                process.env.VUE_APP_FEATURES_APP_NAME
            ) {
                // initialize unleash feature flag client
                const client = new UnleashClient({
                    url: `${process.env.VUE_APP_FEATURES_HOST}/api/frontend`,
                    clientKey: process.env.VUE_APP_FEATURES_CLIENT_KEY,
                    appName: 'product-frontend',
                    refreshInterval: parseInt(process.env.VUE_APP_FEATURE_FLAG_REFRESH_INTERVAL || '30', 10),
                    context: {
                        properties: {
                            deployment: process.env.VUE_APP_FEATURES_APP_NAME,
                        },
                    },
                });

                // Start the background polling
                await client.start();
                state.featureFlagClient = {
                    isEnabled: (feature: string) => client.isEnabled(feature),
                    onChange: (callback: Function) => client.on('update', callback),
                };
                state.initialized = true;
            } else {
                throw Error(`Unable to initialize unleash feature flags client`);
            }
        },
        onChange: ({ state }, callback: unknown) => state.featureFlagClient.onChange(callback),
    },
});
