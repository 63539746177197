import { ValidatorUtils } from './utils';
import * as R from 'ramda';

export const rejectReservedValidator = {
    validate: (value: any, args: any) => {
        const reservedStrings = args.length > 0 && R.is(Array, args[0]) ? args[0].map((a: any) => String(a)) : [];
        return reservedStrings.filter((s: string) => s === value).length === 0;
    },
    message(field: string, args: any) {
        const reservedStrings =
            Object.keys(args).length > 0 && R.is(Array, args['0']) ? args['0'].map((a: any) => String(a)) : [];
        return `'${ValidatorUtils.prettifyName(field)}' cannot be ${
            reservedStrings.length === 1
                ? '"' + reservedStrings[0] + '"'
                : 'one of: ' + reservedStrings.map((rs) => '"' + rs + '"').join(', ')
        }.`;
    },
};
