import { AlertSourceType } from '@/modules/alert/constants';
import * as R from 'ramda';

const intervalUnitTransformation = (internalUnit: string) => {
    switch (internalUnit) {
        case 'm':
            return 'minute(s)';
        case 'h':
            return 'hour(s)';
        case 'd':
            return 'day(s)';
        default:
            break;
    }
};

const notificationsTemplates = {
    'concept.approved': {
        title: () => 'Concept Approved',
        body: (meta: any) =>
            `${meta.suggestionName} suggestion approved and included in the data model ${meta.referenceTitle}`,
    },
    'concept.created': {
        title: () => 'Concept Created',
        body: (meta: any) => `${meta.suggestionName} suggestion created under ${meta.referenceTitle} model!`,
    },
    'concept.rejected': {
        title: () => 'Concept Rejected',
        body: (meta: any) => `${meta.suggestionName} suggestion rejected!`,
    },
    'concept.deleted': {
        title: () => 'Referenced Concept Deleted',
        body: (meta: any) =>
            `The concept "${meta.referenceTitle}" from Data Model "${meta.parentConceptName}" has been automatically ${meta.action} due to propagation of another concept’s deprecation. In particular, the concept "${meta.referencedConceptName}" from Data Model "${meta.referencedConceptParentName}" which the concept "${meta.referenceTitle}" references has been deprecated and has caused the deprecation of the concept "${meta.referenceTitle}" too.`,
    },
    'dcj.service.completed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `${meta.referenceTitle} successfully finished its execution!`,
    },
    'dcj.service.failed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `Something went wrong with ${meta.referenceTitle}. Check your configuration.`,
    },
    'dcj.asset.deleted': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) =>
            `Data check-in pipeline ${meta.referenceTitle} is suspended because its asset has been deleted.`,
    },
    'dcj.service.skipped': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `Execution of "${meta.referenceTitle}" was skipped: ${meta.reason}`,
    },
    'workflow.service.completed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `Execution of "${meta.referenceTitle}" analytics pipeline has completed successfully.`,
    },
    'workflow.service.failed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) =>
            `Execution of "${meta.referenceTitle}" has failed to be executed. Check the execution logs in Execution History for more information.`,
    },
    'workflow.service.skipped': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `Execution of "${meta.referenceTitle}" was skipped: ${meta.reason}`,
    },
    'workflow.usedBlockDisabled': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) =>
            `One or more blocks used in "${meta.referenceTitle}" have been deprecated. Open the analytics pipeline for more information.`,
    },
    'workflow.triggerConditionLostAccess': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) =>
            `One or more triggers in "${meta.referenceTitle}" are using a pipeline as a condition for which you no longer have access. Open the analytics pipeline for more information.`,
    },
    'workflow.asset.deleted': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) =>
            `Analytics pipeline ${meta.referenceTitle} is suspended because one of its asset has been deleted.`,
    },
    'exceptionEvent.unprocessed': {
        title: (meta: any) => `${meta.referenceTitle}`,
        body: (meta: any) => `Message in ${meta.event} topic remained unprocessed.`,
    },
    'admin.user.deleted': {
        title: () => 'User Deleted',
        body: (meta: any) => `User ${meta.referenceTitle} has been deleted along with the corresponding data`,
    },
    'admin.organisation.deleted': {
        title: () => 'Organisation Deleted',
        body: (meta: any) =>
            `Organisation with id #${meta.referenceId} has been deleted along with the corresponding data`,
    },
    'retrieval.service.deprecated': {
        title: () => 'Retrieval query assets no longer available',
        body: (meta: any) => `One or more assets in the retrieval query "${meta.referenceTitle}" have been deleted.`,
    },
    'retrieval.service.no_access': {
        title: () => 'Retrieval query access has been revoked',
        body: (meta: any) =>
            `The retrieval query "${meta.referenceTitle}" has been denied access to one of the assets utilised.`,
    },
    alert: {
        title: (meta: any) => {
            let t = `A ${meta?.criticality === 'yellow' ? 'yellow' : 'red'} alert has been raised for `;
            switch (meta.sourceType) {
                case AlertSourceType.DataCheckin:
                    t = t + 'a data check-in pipeline';
                    break;
                case AlertSourceType.Analytics:
                    t = t + 'a data analytics pipeline';
                    break;
                case AlertSourceType.Dataset:
                    t = t + 'a datasset';
                    break;
                case AlertSourceType.Result:
                    t = t + 'a result asset';
                    break;

                default:
                    break;
            }
            return t;
        },
        body: (meta: any) => {
            let b = '';
            switch (meta.sourceType) {
                case AlertSourceType.DataCheckin:
                    b =
                        b +
                        `The Data check-in pipeline ${meta?.entityName} has ${
                            meta?.eventCondition?.status?.type
                        } at least ${meta?.eventCondition?.status?.count} time(s) in the last ${
                            meta?.eventCondition?.status?.interval
                        } ${intervalUnitTransformation(meta?.eventCondition?.status?.intervalUnit)}`;
                    break;
                case AlertSourceType.Analytics:
                    b =
                        b +
                        `The Data analytics pipeline ${meta?.entityName} has ${
                            meta?.eventCondition?.status?.type
                        } at least ${meta?.eventCondition?.status?.count} time(s) in the last ${
                            meta?.eventCondition?.status?.interval
                        } ${intervalUnitTransformation(meta?.eventCondition?.status?.intervalUnit)} `;
                    if (meta?.eventCondition?.status?.taskId) {
                        b = b + `for task ${meta?.taskName}`;
                    }
                    break;
                case AlertSourceType.Dataset:
                    b = `The Dataset ${meta?.entityName} `;
                    if (meta?.eventCondition?.metrics?.timeliness) {
                        b =
                            b +
                            `is ${meta?.eventCondition?.metrics?.timeliness} for ${
                                meta.eventCondition.metrics.interval
                            } ${intervalUnitTransformation(meta?.eventCondition?.metrics?.intervalUnit)}`;
                    }
                    if (meta?.eventCondition?.metrics?.completeness) {
                        b = b + `completeness is below ${meta?.eventCondition?.metrics?.completeness}%`;
                    }
                    if (meta?.eventCondition?.metrics?.improvementPercentage) {
                        b = b + `quality improvements exceeds ${meta?.eventCondition?.metrics?.improvementPercentage}%`;
                    }
                    break;
                case AlertSourceType.Result:
                    b = `The Result ${meta?.entityName} `;
                    if (meta?.eventCondition?.metrics?.timeliness) {
                        b =
                            b +
                            `is ${meta?.eventCondition?.metrics?.timeliness} for ${
                                meta.eventCondition.metrics.interval
                            } ${intervalUnitTransformation(meta?.eventCondition?.metrics?.intervalUnit)}`;
                    }
                    if (meta?.eventCondition?.metrics?.completeness) {
                        b = b + `completeness is below ${meta?.eventCondition?.metrics?.completeness}%`;
                    }
                    break;

                default:
                    break;
            }
            return b;
        },
    },
    daily_digest: {
        title: () => 'Daily Digest Report',
        body: () => 'Your daily digest report is ready.',
    },
    weekly_digest: {
        title: () => 'Weekly Digest Report',
        body: () => 'Your weekly digest report is ready.',
    },
};

const notificationDescription = (currentNotification: any) => {
    const body: any = R.path([R.pathOr('', ['eventType'], currentNotification), 'body'], notificationsTemplates);
    if (body) return body(currentNotification.payload);
    return '';
};

const notificationTitle = (currentNotification: any) => {
    const title: any = R.path([R.pathOr('', ['eventType'], currentNotification), 'title'], notificationsTemplates);
    if (title) return title(currentNotification.payload);
    return '';
};

export { notificationDescription, notificationTitle };
