export enum AccrualPeriodicityInterval {
    Second = 'Per Second',
    Minute = 'Per Minute',
    Hour = 'Per Hour',
    Day = 'Per Day',
    Week = 'Per Week',
    Month = 'Per Month',
    NA = 'Not applicable',
}

export const AccrualPeriodicityUnits: Record<
    AccrualPeriodicityInterval,
    { short: string; extendedSingular: string; extendedPlural: string }
> = {
    [AccrualPeriodicityInterval.NA]: {
        short: 'na',
        extendedSingular: 'not applicable',
        extendedPlural: 'not applicable',
    },
    [AccrualPeriodicityInterval.Second]: { short: 's', extendedSingular: 'second', extendedPlural: 'seconds' },
    [AccrualPeriodicityInterval.Minute]: { short: 'm', extendedSingular: 'minute', extendedPlural: 'minutes' },
    [AccrualPeriodicityInterval.Hour]: { short: 'h', extendedSingular: 'hour', extendedPlural: 'hours' },
    [AccrualPeriodicityInterval.Day]: { short: 'd', extendedSingular: 'day', extendedPlural: 'days' },
    [AccrualPeriodicityInterval.Week]: { short: 'w', extendedSingular: 'week', extendedPlural: 'weeks' },
    [AccrualPeriodicityInterval.Month]: { short: 'M', extendedSingular: 'month', extendedPlural: 'months' },
};

export type AccrualPeriodicity = {
    unit: string;
    value: number;
};
