
































































































































































































import store from '@/app/store';
import { useKeycloak } from '@/modules/auth/composables/keycloak';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { UserRoles } from '../constants';

export default defineComponent({
    name: 'Home',
    metaInfo: {
        title: 'Home',
    },
    components: { OrbitSpinner },
    setup(props, { root }) {
        const open = ref(false);
        const isLoggingIn = ref(false);

        const user = computed(() => store.state.auth.user);
        const isModelModerator = computed(() => store.getters.auth.hasRole(UserRoles.ModelModerator));
        const isAuthenticated = computed(() => store.getters.auth.isAuthenticated);

        const tab = computed(() => {
            if (!user.value?.organisationId && isModelModerator.value)
                return { name: 'model-manager', label: 'Data Models' };
            return { name: 'dashboard', label: 'Dashboard' };
        });

        const { isEnabled, register, login: keycloakLogin } = useKeycloak();
        const isKeycloakEnabled = isEnabled();

        const login = async () => {
            isLoggingIn.value = true;
            try {
                await keycloakLogin();
            } catch (e: any) {
                (root as any).$toastr.e(e.message, 'Error');
            } finally {
                isLoggingIn.value = false;
            }
        };

        const keycloakAuthentication = async () => {
            if (store.getters.auth.isAuthenticated) {
                root.$router.push({ name: 'home', hash: '' });
            } else {
                isLoggingIn.value = true;
                try {
                    await keycloakLogin();
                } catch (e: any) {
                    (root as any).$toastr.e(e.message, 'Error');
                } finally {
                    isLoggingIn.value = false;
                }
            }
        };

        if (
            isEnabled() &&
            document.location.hash.length > 0 &&
            document.location.hash.match(/^#.*code=[a-zA-Z0-9]*/g)
        ) {
            keycloakAuthentication();
        }

        return { open, isAuthenticated, login, isKeycloakEnabled, register, isLoggingIn, tab };
    },
});
