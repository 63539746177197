














































































































import { VersionInput } from '@/app/components';
import {
    alphanumericValidator,
    maxLengthValidator,
    minLengthValidator,
    regexValidator,
    requiredValidator,
} from '@/app/validators';
import { ExclamationCircleIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { Status } from '../constants';
import ChangeIndication from './ChangeIndication.vue';
import ModelStandards from './ModelStandards.vue';

extend('required', requiredValidator);
extend('alphanumeric', alphanumericValidator);
extend('min', minLengthValidator);
extend('max', maxLengthValidator);
// giving a custom validator name in order to not be overriden by other 'regex' default validators
extend('concept_title_regex_validator', {
    ...regexValidator,
    message: 'Title must begin with a letter.',
});

export default defineComponent({
    name: 'EditModel',
    model: {
        prop: 'model',
        event: 'update-model',
    },
    props: {
        model: {
            type: Object,
        },
        createModel: {
            type: Boolean,
            default: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        error: {
            type: String,
        },
        changesToBeSaved: {
            type: Object,
            default: () => {},
        },
        savedChanges: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        ChangeIndication,
        ValidationObserver,
        ValidationProvider,
        VersionInput,
        ExclamationCircleIcon,
        ModelStandards,
    },
    setup: (props: any, { emit }) => {
        const modelClone = reactive({
            name: null,
            description: null,
            standardsMapping: [],
            ...R.clone(props.model),
        });

        const standardsMappingRef = ref<any>(false);
        const canSaveStandardsMapping = ref<boolean>(false);

        const hasChange = computed(() => {
            if (JSON.stringify(modelClone) !== JSON.stringify(props.model)) {
                return new Date();
            }
            return null;
        });

        watch(
            () => hasChange.value,
            (change: any) => {
                if (change) {
                    emit('update-model', modelClone);
                }
            },
        );

        watch(
            () => [modelClone.name, modelClone.description, modelClone.standardsMapping],
            () => {
                emit('clear-error');
            },
        );

        const statuses = [Status.Draft, Status.Stable, Status.Deprecated];

        const standardsMappingFormChange = async () => {
            if (standardsMappingRef.value) {
                canSaveStandardsMapping.value = await standardsMappingRef.value.validate();
            } else {
                canSaveStandardsMapping.value = false;
            }
            change(modelClone.standardsMapping);
        };

        const change = (updatedStandardsMappings: any) => {
            emit('change', 'standardsMapping', updatedStandardsMappings);
        };

        const calculatedChanges: any = computed(() => {
            if (props.changesToBeSaved && props.savedChanges) {
                const currentChanges = {};
                if (props.model.uid in props.savedChanges) {
                    props.savedChanges[props.model.uid].forEach((field: string) => (currentChanges[field] = false));
                }
                Object.keys(props.changesToBeSaved.unsaved).forEach((field: string) => {
                    currentChanges[field] = true;
                });
                return currentChanges;
            }

            return null;
        });

        const updateStandardsMapping = (updatedMappings: any) => {
            modelClone.standardsMapping = updatedMappings; // eslint-disable-line no-param-reassign
            emit('change', 'standardsMapping', modelClone.standardsMapping);
        };

        return {
            change,
            emit,
            hasChange,
            modelClone,
            statuses,
            standardsMappingRef,
            canSaveStandardsMapping,
            standardsMappingFormChange,
            calculatedChanges,
            updateStandardsMapping,
        };
    },
});
