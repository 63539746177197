






















































































































































































































































































































import { unitTransformations } from '@/app/constants';
import {
    alphanumericValidator,
    maxLengthValidator,
    minLengthValidator,
    regexValidator,
    requiredValidator,
} from '@/app/validators';
import { DataType } from '@/modules/data-model/constants';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { default as Multiselect, default as VueMultiselect } from 'vue-multiselect';
import { ModelsAPI } from '../api';

extend('max', maxLengthValidator);
extend('required', requiredValidator);
extend('alphanumeric', alphanumericValidator);
extend('min', minLengthValidator);
// giving a custom validator name in order to not be overriden by other 'regex' default validators
extend('concept_name_regex_validator', {
    ...regexValidator,
    message: 'Name must begin with a letter.',
});

export default defineComponent({
    name: 'SuggestionModal',
    model: {
        prop: 'suggestion',
        event: 'updateSuggestion',
    },
    props: {
        suggestion: {
            type: Object,
            required: true,
        },
        concepts: {
            type: Array,
            required: true,
        },
        standards: {
            type: Array,
            required: true,
        },
    },
    components: { ValidationObserver, ValidationProvider, VueMultiselect, Multiselect },
    setup(props, { emit, root }) {
        const suggestionRef = ref<any>(null);
        const domainOfReferencedConcept = ref<any>(null);
        const newSuggestion = ref(R.clone(props.suggestion));
        const types = [
            DataType.Boolean,
            DataType.Date,
            DataType.DateTime,
            DataType.Double,
            DataType.Integer,
            DataType.String,
            DataType.Time,
            DataType.Object,
            'binary',
        ].sort((a: any, b: any) => a.localeCompare(b));
        const sortedConcepts = computed(() => props.concepts.sort((a: any, b: any) => a.name.localeCompare(b.name)));
        const selectedParentConcept: any = computed(() =>
            props.concepts.find((concept: any) => concept.id === newSuggestion.value.parentId),
        );
        /**
         * Display all concepts except from the concept of the parent id
         * for 'object' type fields. The user must not be able to select
         * same reference concept and parent concept
         */
        const sortedFilteredConcepts = computed(() => {
            const referenceConcepts = domainOfReferencedConcept.value?.children?.length
                ? domainOfReferencedConcept.value?.children
                : props.concepts;
            const referenceConceptId = domainOfReferencedConcept.value
                ? selectedParentConcept.value?.referenceId
                : newSuggestion.value.parentId;

            return referenceConcepts
                .filter((concept: any) => concept.id !== referenceConceptId)
                .sort((a: any, b: any) => a.name.localeCompare(b.name));
        });

        const relatedTermError = ref<string | null>(null);

        const addTag = (tag: string) => {
            relatedTermError.value = tag.length > 50 ? 'Related term must be shorter than 50 characters' : null;

            if (!relatedTermError.value) {
                const lowerCaseRelatedTerms = newSuggestion.value.relatedTerms.map((rt: string) => rt.toLowerCase());
                if (!lowerCaseRelatedTerms.includes(tag.toLowerCase())) {
                    newSuggestion.value.relatedTerms.push(tag);
                }
            }
        };

        const clonedStandards = ref(R.clone(props.standards));

        const processedStandards = computed(() =>
            clonedStandards.value.map((sm: any) => ({ label: `${sm.standard} - ${sm.version}`, id: sm })),
        );

        const selectedStandards = computed(() =>
            newSuggestion.value.standardsMapping.map(
                (sm: { name: string; standard: string; type: string; version: string }) => {
                    return { label: `${sm.standard} - ${sm.version}`, id: sm };
                },
            ),
        );

        const addStandardsMappingTag = (standardMapping: {
            label: string;
            id: { standard: string; version: string };
        }) => {
            clonedStandards.value = clonedStandards.value.filter((sm: any) => {
                return `${sm.standard} - ${sm.version}` !== standardMapping.label;
            });
            newSuggestion.value.standardsMapping.push(standardMapping.id);
        };

        const removeStandardsMappingTag = (standardMapping: {
            label: string;
            id: { standard: string; version: string };
        }) => {
            clonedStandards.value.push({
                name: null,
                type: null,
                standard: standardMapping.id.standard,
                version: standardMapping.id.version,
            });

            newSuggestion.value.standardsMapping = newSuggestion.value.standardsMapping.filter((sm: any) => {
                return `${sm.standard} - ${sm.version}` !== standardMapping.label;
            });
        };

        const save = () => {
            const valid = suggestionRef.value.validate();
            // correct/ modify any payload information regarding the suggestion
            if (!newSuggestion.value.parentId) {
                newSuggestion.value.type = DataType.Object;
            } else if (selectedParentConcept.value.referenceId) {
                // if high level concept references a different hlc from another data model
                newSuggestion.value.parentId = selectedParentConcept.value.referenceId;
                newSuggestion.value.domainId = domainOfReferencedConcept.value.id;
            }
            if (newSuggestion.value.type === 'binary') newSuggestion.value.type = DataType.Binary;
            if (![DataType.Integer, DataType.Double].includes(newSuggestion.value.type))
                newSuggestion.value.metadata = null;

            emit('updateSuggestion', newSuggestion.value);
            if (valid) {
                emit('ok');
            }
        };

        const unitKeys = computed(() => Object.keys(unitTransformations));
        const sortedUnitKeys = computed(() => unitKeys.value.sort((a: any, b: any) => a.localeCompare(b)));
        const totalUnitKeys = computed(() => ['None', 'Not relevant', ...sortedUnitKeys.value]);

        const resetMeasurementUnit = () => {
            newSuggestion.value.metadata.measurementUnit = null;
        };

        const resetMeasurementUnitType = () => {
            resetMeasurementUnit();
            newSuggestion.value.metadata.measurementType = null;
        };

        const sortedUnitTransformationOptions = computed(() => {
            if (
                newSuggestion.value.metadata.measurementType &&
                newSuggestion.value.metadata.measurementType !== 'Not relevant'
            ) {
                return unitTransformations[
                    newSuggestion.value.metadata.measurementType
                ].options.sort((a: any, b: any) => a.localeCompare(b));
            }
            return [];
        });

        const showMeasurementUnit = computed(() => {
            return (
                (newSuggestion.value.metadata.measurementType ||
                    (!newSuggestion.value.metadata.measurementType && newSuggestion.value.metadata.measurementUnit)) &&
                newSuggestion.value.metadata.measurementType !== 'None'
            );
        });

        const enableFreeTextMeasurementUnit = computed(
            () => newSuggestion.value.metadata.measurementType === 'Not relevant',
        );

        const changeParentConcept = async () => {
            resetMeasurementUnitType();

            if (selectedParentConcept.value?.referenceId) {
                // retrieve data model of that referenceId with its children in order to display them in dropdown
                try {
                    const res = await ModelsAPI.domainOfConcept(selectedParentConcept.value.referenceId);
                    domainOfReferencedConcept.value = res.data;
                } catch {
                    (root as any).$toastr.e('Error retrieving Data Model of referenced concept');
                }
            } else {
                domainOfReferencedConcept.value = null;
                newSuggestion.value.type = null;
                newSuggestion.value.referenceConceptId = null;
            }
        };

        const resetDataType = () => {
            resetMeasurementUnitType();
            newSuggestion.value.referenceConceptId = null;
        };

        return {
            addTag,
            types,
            save,
            suggestionRef,
            newSuggestion,
            sortedConcepts,
            unitTransformations,
            sortedFilteredConcepts,
            resetMeasurementUnit,
            resetMeasurementUnitType,
            showMeasurementUnit,
            enableFreeTextMeasurementUnit,
            totalUnitKeys,
            sortedUnitTransformationOptions,
            changeParentConcept,
            domainOfReferencedConcept,
            relatedTermError,
            processedStandards,
            addStandardsMappingTag,
            selectedStandards,
            removeStandardsMappingTag,
            resetDataType,
        };
    },
});
