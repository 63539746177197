











import { computed, defineComponent } from '@vue/composition-api';
import { languages } from 'prismjs';
import { highlight } from 'prismjs/components/prism-core';
import { PrismEditor } from 'vue-prism-editor';

export default defineComponent({
    name: 'SQLEditor',
    components: { PrismEditor },
    model: {
        prop: 'code',
        event: 'change',
    },
    props: {
        code: {
            type: String,
            default: '',
        },
        lineNumbers: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    setup: (props, { emit }) => {
        const textInEdit = computed({
            get: (): string => props.code,
            set: (updatedText: string) => emit('change', updatedText),
        });

        const highlighter = (code: string) => {
            return highlight(code, languages.sql);
        };

        return { textInEdit, emit, highlighter };
    },
});
