



































































































































































































































































































import { ConfirmButton, TwButton, TwSelect } from '@/app/components';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { ChevronDownIcon, ExclamationCircleIcon, PlusIcon, TrashIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { useDataModel } from '../composable';
import ChangeIndication from './ChangeIndication.vue';

extend('required', requiredValidator);
extend('max', maxLengthValidator);
export default defineComponent({
    name: 'StandardsMapping',
    model: {
        prop: 'conceptStandardsMapping',
        event: 'updateStandardsMapping',
    },
    props: {
        conceptStandardsMapping: {
            type: Array,
            default: () => [],
        },
        model: {
            type: Object,
            required: true,
        },
        collapsedLeftPane: {
            type: Boolean,
            default: false,
        },
        requiresAttention: {
            type: Boolean,
            default: false,
        },
        changesToBeSaved: {
            type: Array,
            default: () => [],
        },
        savedChange: {
            type: Boolean,
            default: false,
        },
        calculatedChanges: {
            type: Object,
            default: () => {},
        },
        readOnlyFields: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ChangeIndication,
        ConfirmButton,
        ValidationObserver,
        ValidationProvider,
        TwButton,
        ExclamationCircleIcon,
        TrashIcon,
        ChevronDownIcon,
        PlusIcon,
        TwSelect,
    },
    setup(props, { emit }) {
        // UI variables
        const oldStandardMapping = ref<any>(null);
        const standardsMapping = computed<any>(() =>
            props.conceptStandardsMapping && props.conceptStandardsMapping.length
                ? R.clone(props.conceptStandardsMapping)
                : [],
        );
        const hoveredItemId = ref<string | null>(null);
        const standardsMappingRef = ref<HTMLElement>();
        const editingStandardIdx = ref<any>(null);
        const editStandardsMapping = ref<any>(null);
        const addNewStandardsMapping = ref<any>(false);
        const openStandardMappingFormToggle = ref(false);
        const cancelStandardsMappingChanges = ref<any>(null);
        const newStandard = ref(false);
        const userDefinedStandard = ref(false);
        const validateStandard = ref(false);
        const standardMappingInForm: any = ref(null);

        const newStandardsMapping = ref<any>({
            name: null,
            standard: null,
            version: null,
            type: null,
        });

        const filteredStandards = computed(() => {
            return R.uniqBy((sm: any) => sm.standard, copiedModel.value.standardsMapping);
        });

        const clearStandardsMapping = () => {
            newStandardsMapping.value = {
                name: null,
                standard: null,
                version: null,
                type: null,
            };
            if (editStandardsMapping.value) {
                editStandardsMapping.value = null;
                oldStandardMapping.value = null;
            }
            userDefinedStandard.value = false;
            editingStandardIdx.value = null;
        };

        const copiedModel = computed(() => props.model);
        const { readOnly: readOnlyModel } = useDataModel(copiedModel);
        const readOnly = computed(() => readOnlyModel.value || props.readOnlyFields);

        const versionsOfStandard = computed(() => {
            if (standardMappingInForm.value && standardMappingInForm.value.standard) {
                const versionsFromModelSMs = copiedModel.value.standardsMapping
                    .filter((s: any) => s.standard === standardMappingInForm.value.standard)
                    .map((standard: any) => standard.version);

                const versionsFromConceptSMs = standardsMapping.value
                    .filter((s: any) => s.standard === standardMappingInForm.value.standard)
                    .map((standard: any) => standard.version);

                const uniqueVersions = [...new Set([...versionsFromModelSMs, ...versionsFromConceptSMs])];

                return uniqueVersions.map((version: string) => {
                    return { version };
                });
            }
            return [];
        });

        const editStandard = (
            idx: number,
            standardMapping: { name: string; standard: string; version: string; type: string },
        ) => {
            editStandardsMapping.value = R.clone(standardMapping);
            oldStandardMapping.value = R.clone(standardMapping);
            editingStandardIdx.value = idx;
            userDefinedStandard.value = !copiedModel.value.standardsMapping.find(
                (s: any) => s.standard === standardMapping.standard,
            ); // check if this standard is a new one i.e. does not exist in the model standards list

            openStandardMappingForm(false);
        };

        const openStandardMappingForm = (newStandardMapping: boolean) => {
            standardMappingInForm.value = newStandardMapping ? newStandardsMapping.value : editStandardsMapping.value;
            openStandardMappingFormToggle.value = true;
        };

        const removeStandardsMapping = (index: number) => {
            const updatedStandardsMapping = R.filter(
                (standard: any) => standardsMapping.value.indexOf(standard) !== index,
                standardsMapping.value,
            );
            emit('updateStandardsMapping', updatedStandardsMapping);
        };

        const standardAlreadyExists = computed(() => {
            // for edit mode
            let alreadyExists = false;
            if (oldStandardMapping.value && standardMappingInForm.value) {
                const alreadyExistsInEditMode = standardsMapping.value.filter(
                    (smapping: any) =>
                        smapping.standard.toLowerCase() === standardMappingInForm.value.standard?.toLowerCase() &&
                        smapping.version.toLowerCase() === standardMappingInForm.value.version?.toLowerCase() &&
                        (smapping.standard.toLowerCase() !== oldStandardMapping.value.standard.toLowerCase() ||
                            smapping.version.toLowerCase() !== oldStandardMapping.value.version.toLowerCase()),
                );
                if (alreadyExistsInEditMode && alreadyExistsInEditMode.length > 0) return true;
            }

            // for new standard mode
            if (newStandardsMapping.value.standard && newStandardsMapping.value.version) {
                standardsMapping.value.forEach((smapping: any) => {
                    if (
                        smapping.standard.toLowerCase() === newStandardsMapping.value.standard.toLowerCase() &&
                        smapping.version.toLowerCase() === newStandardsMapping.value.version.toLowerCase()
                    )
                        alreadyExists = true;
                });
            }
            return alreadyExists;
        });

        const noChange = computed(() => {
            if (oldStandardMapping.value) {
                return JSON.stringify(oldStandardMapping.value) === JSON.stringify(standardMappingInForm.value);
            }

            return false;
        });

        const showStandardsMappingChangeIndication = computed(() => {
            return !!props.changesToBeSaved.find((c: any) => c.change === 'standardsMapping') || props.savedChange;
        });

        const changeStandardResetVersions = () => {
            if (userDefinedStandard.value) {
                standardMappingInForm.value.standard = null;
            } else {
                standardMappingInForm.value.version = null;
            }
        };

        const closeStandardMappingForm = () => {
            openStandardMappingFormToggle.value = false;
            userDefinedStandard.value = false;
            editingStandardIdx.value = null;
            clearStandardsMapping();
        };

        const addOrUpdateStandardMapping = () => {
            openStandardMappingFormToggle.value = false;
            let updatedStandardsMapping: any = [];
            if (oldStandardMapping.value) {
                const idx = R.findIndex(
                    (sm: any) =>
                        sm.standard === oldStandardMapping.value.standard &&
                        sm.version === oldStandardMapping.value.version,
                    standardsMapping.value,
                );

                updatedStandardsMapping = R.clone(standardsMapping.value);
                updatedStandardsMapping[idx] = standardMappingInForm.value;
            } else {
                updatedStandardsMapping = [...standardsMapping.value, standardMappingInForm.value];
            }
            userDefinedStandard.value = false;
            emit('updateStandardsMapping', updatedStandardsMapping);
            clearStandardsMapping();
        };

        const toggleStandardSelection = () => {
            userDefinedStandard.value = !userDefinedStandard.value;

            standardMappingInForm.value.standard = null;
            standardMappingInForm.value.version = null;

            if (!oldStandardMapping.value) {
                newStandardsMapping.value.standard = null;
                newStandardsMapping.value.version = null;
            }
        };

        return {
            addNewStandardsMapping,
            cancelStandardsMappingChanges,
            changeStandardResetVersions,
            editStandard,
            editStandardsMapping,
            emit,
            newStandard,
            removeStandardsMapping,
            showStandardsMappingChangeIndication,
            standardAlreadyExists,
            standardsMapping,
            standardsMappingRef,
            userDefinedStandard,
            validateStandard,
            versionsOfStandard,
            readOnly,
            standardMappingInForm,
            clearStandardsMapping,
            closeStandardMappingForm,
            addOrUpdateStandardMapping,
            openStandardMappingFormToggle,
            openStandardMappingForm,
            toggleStandardSelection,
            filteredStandards,
            noChange,
            hoveredItemId,
            editingStandardIdx,
        };
    },
});
