import { AuthGuard } from '@/modules/auth/router/auth.guard';
import { OrganisationGuard } from '@/modules/auth/router/organisation.guard';
import multiguard from 'vue-router-multiguard';

export default [
    {
        name: 'assets',
        path: '/assets',
        component: () => import(/* webpackChunkName: "asset" */ '@/modules/asset/views/Assets.vue'),
        meta: { title: 'Assets' },
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'assets:view',
        path: '/assets/:id',
        component: () => import(/* webpackChunkName: "asset" */ '@/modules/asset/views/ViewAsset.vue'),
        meta: { title: 'View Asset' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'assets:edit',
        path: '/assets/:id/edit',
        component: () => import(/* webpackChunkName: "asset" */ '@/modules/asset/views/EditAsset.vue'),
        meta: { title: 'Edit Asset' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'assets:model',
        path: '/assets/model/register',
        component: () => import(/* webpackChunkName: "asset" */ '@/modules/asset/views/RegisterModel.vue'),
        meta: { title: 'Register Model', feature: 'analytics' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'assets:model:edit',
        path: '/assets/model/:id/edit',
        component: () => import(/* webpackChunkName: "asset" */ '@/modules/asset/views/EditModelAsset.vue'),
        meta: { title: 'Edit Model Asset', feature: 'analytics' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'assets:model:publish',
        path: '/assets/model/:status/:id',
        component: () => import(/* webpackChunkName: "asset" */ '@/modules/asset/views/EditModelAsset.vue'),
        meta: { title: 'Publish Model File', feature: 'analytics' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
];
