















































































































import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ShortTypeBadge',
    props: {
        typeName: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: false,
        },
    },
    setup(props) {
        const tooltip = computed(() => props.title ?? props.typeName);

        return { tooltip };
    },
});
