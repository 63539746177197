



































import SvgImage from '@/app/components/SvgImage.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'LoadingSampleRunModal',
    components: { SvgImage },
    props: {
        message: {
            type: String,
            default: 'Cloning...',
        },
    },
});
