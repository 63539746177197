import { useAxios } from '@/app/composable';
import { ModelBrowserAPI } from '../api';
import { Concept } from '../interfaces';

export function useModelConcepts() {
    const { exec } = useAxios(true);
    const conceptsByUids = (
        data: { uids: string[]; majorVersion: number; domainUid: string }[],
    ): Promise<Record<string, Concept>> => {
        return new Promise<Record<string, Concept>>((resolve, reject) => {
            exec(ModelBrowserAPI.getConceptsByUids(data))
                .then((res: any) => {
                    resolve(
                        res.data.reduce((acc: any, concept: Concept) => {
                            acc[concept.uid] = concept;
                            return acc;
                        }, {}),
                    );
                })
                .catch((e) => reject(e));
        });
    };

    return { conceptsByUids };
}
