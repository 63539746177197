
























import { XIcon } from '@vue-hero-icons/outline';
import { defineComponent } from '@vue/composition-api';
import { FacetFilterType } from '../composable/facets-filters';

export default defineComponent({
    name: 'SearchTerms',
    props: {
        terms: { type: Array, required: true },
    },
    components: { XIcon },
    setup(props, { emit }) {
        const removeTerm = (term: any) => {
            if (term.isFacet) emit('facet-changed', term.key, term.value, term.type);
            else if ([FacetFilterType.Date, FacetFilterType.Datetime].includes(term.type))
                for (let i = 0; i < term.value.length; i++) {
                    emit('filter-changed', term.key, null, term.type, term.value[i]);
                }
            else
                emit(
                    'filter-changed',
                    term.key,
                    [FacetFilterType.Checkbox, FacetFilterType.Treeselect].includes(term.type) ? term.value : null,
                    term.type,
                );
        };
        return { removeTerm };
    },
});
