import { moduleActionContext } from '@/app/store';
import { defineModule } from 'direct-vuex';
import * as R from 'ramda';

export interface ModelBrowserState {
    model: string | undefined;
    concept: string | undefined;
    searchText: string;
    path: string[];
    restrictingConcept: number | undefined;
}

const STORE_KEY: string = 'model_browser';
const DEFAULT_STATE: ModelBrowserState = {
    model: undefined,
    concept: undefined,
    searchText: '',
    path: [],
    restrictingConcept: undefined,
};

export const modelBrowserModule = defineModule({
    namespaced: true,
    state: (): ModelBrowserState => {
        return R.clone(DEFAULT_STATE);
    },
    mutations: {
        CLEAR(state: ModelBrowserState) {
            state = R.clone(DEFAULT_STATE);
            sessionStorage.setItem(STORE_KEY, JSON.stringify(state));
            localStorage.setItem(STORE_KEY, JSON.stringify(state));
        },
        SET_MODEL(state: ModelBrowserState, model: string | undefined) {
            state.model = model;
            sessionStorage.setItem(STORE_KEY, JSON.stringify(state));
            localStorage.setItem(STORE_KEY, JSON.stringify(state));
        },
        SET_CONCEPT(state: ModelBrowserState, concept: string | undefined) {
            state.concept = concept;
            sessionStorage.setItem(STORE_KEY, JSON.stringify(state));
        },
        SET_SEARCH_TEXT(state: ModelBrowserState, searchText: string) {
            state.searchText = searchText;
            sessionStorage.setItem(STORE_KEY, JSON.stringify(state));
            localStorage.setItem(STORE_KEY, JSON.stringify(state));
        },
        SET_PATH(state: ModelBrowserState, path: string[]) {
            state.path = path;
            sessionStorage.setItem(STORE_KEY, JSON.stringify(state));
            localStorage.setItem(STORE_KEY, JSON.stringify(state));
        },
        SET_RESTRICTING_CONCEPT(state: ModelBrowserState, concept: number | undefined) {
            state.restrictingConcept = concept;
            sessionStorage.setItem(STORE_KEY, JSON.stringify(state));
            localStorage.setItem(STORE_KEY, JSON.stringify(state));
        },
    },
    getters: {
        getModel: (state: ModelBrowserState): string | undefined => {
            return state.model;
        },
        getConcept: (state: ModelBrowserState): string | undefined => {
            return state.concept;
        },
        getSearchText: (state: ModelBrowserState): string => {
            return state.searchText;
        },
        getPath: (state: ModelBrowserState): string[] => {
            return state.path;
        },
        getRestrictedConcept: (state: ModelBrowserState): number | undefined => {
            return state.restrictingConcept;
        },
    },
    actions: {
        initialize({ commit }) {
            const localStorageEntry = localStorage.getItem(STORE_KEY);
            if (!sessionStorage.getItem(STORE_KEY) && localStorageEntry) {
                sessionStorage.setItem(STORE_KEY, localStorageEntry);
            }
            if (sessionStorage.getItem(STORE_KEY)) {
                const localState: ModelBrowserState = JSON.parse(sessionStorage.getItem(STORE_KEY) as string);
                commit('SET_MODEL', localState.model || DEFAULT_STATE.model);
                commit('SET_CONCEPT', localState.concept || DEFAULT_STATE.concept);
                commit('SET_SEARCH_TEXT', localState.searchText || DEFAULT_STATE.searchText);
                commit('SET_PATH', localState.path || DEFAULT_STATE.path);
                commit('SET_RESTRICTING_CONCEPT', localState.restrictingConcept || DEFAULT_STATE.restrictingConcept);
            }
        },
        setModel({ commit }, { model }) {
            commit('SET_MODEL', model);
        },
        setConcept({ commit }, { concept }) {
            commit('SET_CONCEPT', concept);
        },
        setSearchText({ commit }, { searchText }) {
            commit('SET_SEARCH_TEXT', searchText);
        },
        setPath({ commit }, { path }) {
            commit('SET_PATH', path);
        },
        setMappingSelection({ commit }, { model, concept }) {
            commit('SET_MODEL', model);
            commit('SET_RESTRICTING_CONCEPT', concept);
        },
        setRestrictingConcept({ commit }, { concept }) {
            commit('SET_RESTRICTING_CONCEPT', concept);
        },
        clearMappingSelection({ commit }) {
            commit('SET_RESTRICTING_CONCEPT', undefined);
        },
        clear({ commit }) {
            commit('CLEAR');
        },
    },
});

export const modelBrowserModuleActionContext = (context: any) => moduleActionContext(context, modelBrowserModule);
