



































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { extend } from 'vee-validate';
import { requiredValidator } from '@/app/validators';
import { useDataModel } from '../composable';
import ChangeIndication from './ChangeIndication.vue';
import { DataType, MetadataComponent, Status } from '../constants';

extend('required', requiredValidator);

export default defineComponent({
    name: 'Metadata',
    components: { ChangeIndication },
    model: {
        prop: 'conceptMetadata',
        event: 'updateMetadata',
    },
    props: {
        conceptMetadata: {
            type: Object,
            default: null,
        },
        model: {
            type: Object,
            required: true,
        },
        dataType: {
            type: String as PropType<DataType>,
            required: true,
        },
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        id: {
            type: [Number, String],
            required: false,
        },
        isProposal: {
            type: Boolean,
            required: false,
        },
        initialMetadata: {
            type: Object,
            required: false,
        },
        conceptStatus: {
            type: String,
            required: false,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        calculatedChanges: {
            type: Object,
            default: () => {},
        },
    },
    setup(props, { emit }) {
        const metadataComponent = computed(() => MetadataComponent.find(props.dataType));
        const copiedModel = computed(() => props.model);
        const { isDraft } = useDataModel(copiedModel); // fixDatatypeMetadata, defineDatatypeMetadata,

        const isFieldDraft = computed(() => isDraft.value || props.conceptStatus === Status.Draft);

        // TODO: fixDatatypeMetadata - ask if this should be still performed
        // const defineMetadataObject = (args: any) => {
        //     type.value = { ...args }['0'];
        //     inField.value = false;
        //     if (!props.parentMetadata) {
        //         metadata.value = defineDatatypeMetadata(type.value);
        //         updateMetadata();
        //     } else {
        //         metadata.value = fixDatatypeMetadata(R.clone(props.parentMetadata), type.value);
        //     }
        // };

        const metadata = computed({
            get: () => props.conceptMetadata,
            set: (newMetadata: any) => emit('updateMetadata', newMetadata),
        });

        return {
            metadata,
            metadataComponent,
            copiedModel,
            isFieldDraft,
        };
    },
});
