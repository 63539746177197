import store from '@/app/store';
import { Ref, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';

dayjs.extend(utc);

export enum FacetFilterType {
    Checkbox = 'checkbox',
    Treeselect = 'treeselect',
    Date = 'date',
    Datetime = 'dateTime',
    Text = 'text',
    Number = 'number',
}

export interface FacetFilter {
    label: string;
    placeholder?: string;
    tooltip?: string;
    type: FacetFilterType;
    default: any;
    valueLabel?: any;
    options?: any[];
}

export function useFacetsFilters(encodedFacetsFilters: string[] = []) {
    const userOptions = computed(() => store.getters.organisation.userOptions);

    const capitalize = (value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const capitalizeAll = (value: string) => {
        const words = value.toLowerCase().split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = capitalize(words[i]);
        }
        return words.join(' ');
    };

    const valueLabel = (value: string) => {
        return capitalize(value);
    };

    const reducedFacetsFilters = (facetsFilters: Ref<Record<string, FacetFilter>>) => {
        return Object.keys(facetsFilters.value).reduce((acc: any, key: string) => {
            acc[key] = facetsFilters.value[key].default;
            return acc;
        }, {});
    };

    const getValueLabel = (facetFilter: { valueLabel?: any }, value: any) => {
        return facetFilter.valueLabel ? facetFilter.valueLabel(value) : value;
    };

    const facetTotalCount = (facet: { options: { count: number }[] }): number => {
        if (facet && facet.options?.length > 0) {
            return facet.options.reduce((acc: number, f: { count: number }): number => {
                if (f.count && R.is(Number, f.count)) acc = acc += f.count;
                return acc;
            }, 0);
        }

        return 0;
    };

    const calculateSearchedFacets = (facets: any, facetsInfo: any) => {
        if (facets) {
            const facetsArray = Object.keys(facets).reduce((acc: any[], facetKey: string) => {
                facets[facetKey].forEach((activeFacet: string) => {
                    acc.push({
                        value: activeFacet,
                        key: facetKey,
                        type: facetsInfo[facetKey].type,
                        label: getValueLabel(facetsInfo[facetKey], activeFacet),
                        isFacet: true,
                    });
                });
                return acc;
            }, []);
            return facetsArray;
        }
        return [];
    };

    const getDatetimeValue = (min: any, max: any) => {
        return min && max ? ['min', 'max'] : [min ? 'min' : 'max'];
    };

    const getDatetimeLabel = (type: FacetFilterType, label: string, min: any, max: any) => {
        const format = `DD MMMM YYYY${type === FacetFilterType.Datetime ? ' HH:mm' : ''}`;
        if (min && max) return `${label}: ${dayjs.utc(min).format(format)} - ${dayjs.utc(max).format(format)}`;
        if (min) return `${label}: From ${dayjs.utc(min).format(format)}`;
        return `${label}: Up to ${dayjs.utc(max).format(format)}`;
    };

    const calculateSearchedFilters = (filters: any, filtersInfo: any) => {
        if (filters) {
            const filtersArray = Object.keys(filters).reduce((acc: any[], key: string) => {
                const type = filtersInfo[key].type;
                const label = filtersInfo[key].label;
                const activeValues = filters[key];
                let options = filtersInfo[key].options ? filtersInfo[key].options : [];
                if (key === 'user') options = userOptions.value;

                const obj: any = { value: null, key, type, label: null, isFacet: false };

                switch (filtersInfo[key].type) {
                    case FacetFilterType.Checkbox:
                        activeValues.forEach((activeValue: string) => {
                            obj.value = activeValue;
                            obj.label = options.find((option: any) => option.value === activeValue)?.label;
                            acc.push(R.clone(obj));
                        });
                        break;
                    case FacetFilterType.Treeselect:
                        activeValues.forEach((activeValue: number | string) => {
                            obj.value = { id: activeValue };
                            obj.label = options.find((option: any) => option.id === activeValue)?.label;
                            acc.push(R.clone(obj));
                        });
                        break;
                    case FacetFilterType.Datetime:
                    case FacetFilterType.Date:
                        if (activeValues.min || activeValues.max) {
                            obj.value = getDatetimeValue(activeValues.min, activeValues.max);
                            obj.label = getDatetimeLabel(type, label, activeValues.min, activeValues.max);
                            acc.push(R.clone(obj));
                        }
                        break;
                    case FacetFilterType.Text:
                    case FacetFilterType.Number:
                        if (!R.isNil(activeValues.id)) {
                            obj.value = { id: activeValues.id };
                            obj.label = `${label}: ${activeValues.id}`;
                            acc.push(R.clone(obj));
                        }
                        break;
                    default:
                    // do nothing
                }
                return acc;
            }, []);
            return filtersArray;
        }
        return [];
    };

    const getTextNumberValue = (value: any, type: FacetFilterType) => {
        if (R.isEmpty(value)) return null;
        if (type === FacetFilterType.Number && !R.isNil(value)) return Number(value);
        return value;
    };

    const setFacetsFilters = (facetsFilters: any, key: any, value: any, type: FacetFilterType, minOrMax?: string) => {
        switch (type) {
            case FacetFilterType.Datetime:
                if (minOrMax) facetsFilters[key][minOrMax] = value ? value.toISOString() : null;
                break;
            case FacetFilterType.Date:
                if (minOrMax) facetsFilters[key][minOrMax] = value ? dayjs(value).format('YYYY-MM-DD') : null;
                break;
            case FacetFilterType.Checkbox:
                if (facetsFilters[key].includes(value)) facetsFilters[key].splice(facetsFilters[key].indexOf(value), 1);
                else facetsFilters[key].push(value);
                break;
            case FacetFilterType.Treeselect:
                if (facetsFilters[key].includes(value.id))
                    facetsFilters[key].splice(facetsFilters[key].indexOf(value.id), 1);
                else facetsFilters[key].push(value.id);
                break;
            case FacetFilterType.Text:
            case FacetFilterType.Number:
                facetsFilters[key].id = getTextNumberValue(value, type);
                break;
            default:
            // do nothing
        }
    };

    const getValue = (facetsFilters: any, key: any, type: FacetFilterType, minOrMax?: string) => {
        switch (type) {
            case FacetFilterType.Datetime:
            case FacetFilterType.Date:
                return minOrMax && facetsFilters[key][minOrMax] ? facetsFilters[key][minOrMax] : null;
            case FacetFilterType.Checkbox:
            case FacetFilterType.Treeselect:
                if (encodedFacetsFilters.includes(key))
                    facetsFilters[key] = facetsFilters[key].map((value: any) => encodeURIComponent(value));
                return facetsFilters[key].join(',');
            case FacetFilterType.Text:
            case FacetFilterType.Number:
                return facetsFilters[key].id;
            default:
            // do nothing
        }
    };

    const defaultValue = (get: any, key: string, multiple: boolean = true) => {
        // if query parameter is multiple
        if (multiple) {
            // split by comma to get all values
            const value = get(key) ? get(key).split(',') : [];
            // convert each value to integer (only for user and assetType filters)
            if (['user', 'assetType'].includes(key)) return value.map((a: any) => parseInt(a, 10));
            // decode each encoded uri value (only for encoded filters e.g. spatialCoverage)
            if (encodedFacetsFilters.includes(key)) return value.map((val: any) => decodeURIComponent(val));
            return value;
        }
        // if query parameter is not multiple
        const value = get(key) ? get(key) : null;
        // convert to number if not null (only for assetOrigin and derivativeAssets filters)
        if (['assetOrigin', 'derivativeAssets'].includes(key)) return value === null ? value : Number(value);
        return value;
    };

    return {
        userOptions,
        capitalize,
        capitalizeAll,
        valueLabel,
        reducedFacetsFilters,
        getValueLabel,
        facetTotalCount,
        calculateSearchedFacets,
        calculateSearchedFilters,
        setFacetsFilters,
        getValue,
        defaultValue,
    };
}
