var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"relative flex flex-row rounded shadow-sm"},[(_vm.leadingLabel)?_c('span',{staticClass:"inline-flex items-center px-3 truncate border border-r-0 rounded-l text-neutral-600 border-neutral-300 bg-neutral-100 sm:text-sm",class:[_vm.leadingLabelWidth],attrs:{"title":_vm.leadingLabel}},[_vm._v(" "+_vm._s(_vm.leadingLabel)+" ")]):_vm._e(),(_vm.prefix)?_c('div',{staticClass:"absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"},[_c('span',{staticClass:"text-neutral-500 sm:text-sm"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")])]):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueRef),expression:"valueRef"}],staticClass:"flex-1 block w-full h-8 min-w-0 px-3 py-2 rounded border-neutral-300 focus:ring-primary-300 focus:border-primary-300 sm:text-sm",class:[
                {
                    'pl-16 sm:pl-14': _vm.prefix,
                    'pr-16 sm:pr-14': _vm.suffix,
                    'rounded-l-none': _vm.leadingLabel,
                    'pr-8': _vm.errorsString,
                    'cursor-not-allowed': _vm.disabled,
                },
                _vm.additionalInputClasses ],attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.step,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.valueRef)?_vm._i(_vm.valueRef,null)>-1:(_vm.valueRef)},on:{"input":_vm.change,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.enter.apply(null, arguments)},"click":function($event){$event.stopPropagation();},"change":function($event){var $$a=_vm.valueRef,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueRef=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueRef=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueRef=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueRef),expression:"valueRef"}],staticClass:"flex-1 block w-full h-8 min-w-0 px-3 py-2 rounded border-neutral-300 focus:ring-primary-300 focus:border-primary-300 sm:text-sm",class:[
                {
                    'pl-16 sm:pl-14': _vm.prefix,
                    'pr-16 sm:pr-14': _vm.suffix,
                    'rounded-l-none': _vm.leadingLabel,
                    'pr-8': _vm.errorsString,
                    'cursor-not-allowed': _vm.disabled,
                },
                _vm.additionalInputClasses ],attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.step,"type":"radio"},domProps:{"checked":_vm._q(_vm.valueRef,null)},on:{"input":_vm.change,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.enter.apply(null, arguments)},"click":function($event){$event.stopPropagation();},"change":function($event){_vm.valueRef=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueRef),expression:"valueRef"}],staticClass:"flex-1 block w-full h-8 min-w-0 px-3 py-2 rounded border-neutral-300 focus:ring-primary-300 focus:border-primary-300 sm:text-sm",class:[
                {
                    'pl-16 sm:pl-14': _vm.prefix,
                    'pr-16 sm:pr-14': _vm.suffix,
                    'rounded-l-none': _vm.leadingLabel,
                    'pr-8': _vm.errorsString,
                    'cursor-not-allowed': _vm.disabled,
                },
                _vm.additionalInputClasses ],attrs:{"id":_vm.id,"name":_vm.name,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.step,"type":_vm.type},domProps:{"value":(_vm.valueRef)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.valueRef=$event.target.value},_vm.change],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.enter.apply(null, arguments)},"click":function($event){$event.stopPropagation();}}}),(_vm.suffix)?_c('div',{staticClass:"absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none",class:{ 'pr-8': _vm.errorsString }},[_c('span',{staticClass:"text-neutral-500 sm:text-sm"},[_vm._v(" "+_vm._s(_vm.suffix)+" ")])]):_vm._e(),(_vm.errorsString)?_c('input-error-icon',{staticClass:"pt-1",attrs:{"tooltip":_vm.fullError ? null : _vm.errorsString,"colour":_vm.errorColour}}):_vm._e()],1),(_vm.fullError && _vm.errorsString)?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-sm",class:_vm.errorColour,domProps:{"innerHTML":_vm._s(_vm.errorsString)}}),(_vm.validationHelpMessage)?_c('InformationCircleIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.validationHelpMessage,
                classes: 'max-w-md break-words',
                placement: 'bottom',
            }),expression:"{\n                content: validationHelpMessage,\n                classes: 'max-w-md break-words',\n                placement: 'bottom',\n            }"}],staticClass:"flex-grow-0 flex-shrink-0 w-4 h-4 ml-1",class:_vm.errorColour}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }