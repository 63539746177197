











































import { useEventListener } from '@vueuse/core';
import { defineComponent, ref } from '@vue/composition-api';
import { FilterIcon } from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';

export default defineComponent({
    name: 'FilterHighLevelConcepts',
    components: {
        ChevronDownIcon,
        FilterIcon,
    },
    model: {
        prop: 'filter',
        event: 'filter-changed',
    },
    props: {
        filter: {
            type: String,
            required: true,
        },
        filterOptions: {
            type: Array as () => Record<string, any>[],
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const isFilterOpen = ref(false);

        const toggleFilter = () => {
            isFilterOpen.value = !isFilterOpen.value;
        };

        const updateProp = (value: any) => {
            emit('filter-changed', value);
        };

        const handleChangeFilter = (option: any) => {
            emit('filter-changed', option);
            isFilterOpen.value = false;
        };

        useEventListener(document, 'keydown', (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                isFilterOpen.value = false;
            }
        });

        return {
            handleChangeFilter,
            toggleFilter,
            isFilterOpen,
            updateProp,
        };
    },
});
