export class AlertSortingOption {
    static readonly NAME_ASC = new AlertSortingOption('name', true, 'Name Ascending');

    static readonly NAME_DESC = new AlertSortingOption('name', false, 'Name Descending');

    static readonly DATE_CREATED_DESC = new AlertSortingOption('createdAt', false, 'Date Created (newest first)');

    static readonly DATE_CREATED_ASC = new AlertSortingOption('createdAt', true, 'Date Created (oldest first)');

    static readonly STATUS_ASC = new AlertSortingOption('isEnabled', false, 'Status (active first)');

    static readonly STATUS_DESC = new AlertSortingOption('isEnabled', true, 'Status (deactivated first)');

    static readonly OCCURRENCES_DESC = new AlertSortingOption('occurrences', false, 'Occurences (Highest to Lowest)');

    static readonly NUM_RED_EVENTS_DESC = new AlertSortingOption(
        'numRedEvents',
        false,
        'Number of Red Events (Highest to Lowest)',
    );

    static readonly NUM_YELLOW_EVENTS_DESC = new AlertSortingOption(
        'numYellowEvents',
        false,
        'Number of Yellow Events (Highest to Lowest)',
    );

    private constructor(public readonly field: string, public readonly asc: boolean, public readonly label: string) {
        this.field = field;
        this.asc = asc;
        this.label = label;
    }

    static all(): AlertSortingOption[] {
        return [
            this.NAME_ASC,
            this.NAME_DESC,
            this.DATE_CREATED_ASC,
            this.DATE_CREATED_DESC,
            this.STATUS_ASC,
            this.STATUS_DESC,
            this.OCCURRENCES_DESC,
            this.NUM_RED_EVENTS_DESC,
            this.NUM_YELLOW_EVENTS_DESC,
        ];
    }

    static find(field: string, asc: boolean): AlertSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (JSON.stringify(sortOption.field) === JSON.stringify(field) && sortOption.asc === asc) {
                return sortOption;
            }
        }
        return null;
    }

    static findByLabel(label: string): AlertSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (sortOption.label === label) {
                return sortOption;
            }
        }
        return null;
    }
}
