



























import { defineComponent, ref } from '@vue/composition-api';
import { required, regex } from 'vee-validate/dist/rules';
import { ValidationProvider, extend } from 'vee-validate';

extend('required', required);

export default defineComponent({
    name: 'VersionInput',
    model: {
        prop: 'version',
        event: 'update-version',
    },
    components: { ValidationProvider },
    props: {
        version: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: 'Version',
        },
        placeholder: {
            type: String,
            default: 'Enter a version',
        },
        prefix: {
            type: String,
            default: '',
        },
        versionRegex: {
            type: RegExp,
            default: null,
        },
        versionExample: {
            type: String,
            default: '0.2',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const newVersion = ref(props.version);
        if (newVersion.value && props.prefix) {
            newVersion.value = newVersion.value.replace(new RegExp(`^${props.prefix}`), '');
        }

        const versionRef = ref<any>(null);

        extend('regex', {
            ...regex,
            message: () => {
                return `Not a valid version number. Eg. '${props.versionExample}'`;
            },
        });

        const change = async () => {
            const valid = await versionRef.value.validate();
            if (valid) {
                emit('update-version', `${props.prefix}${newVersion.value}`);
                emit('change', `${props.prefix}${newVersion.value}`);
            } else {
                emit('update-version', null);
                emit('change', null);
            }
        };

        return { newVersion, versionRef, change };
    },
});
