import store from '@/app/store';
import { computed, Ref, ref } from '@vue/composition-api';
import { FeatureFlagType } from '../types';

export function useFeatureFlags() {
    const flags = ref({});
    const isEnabled = (feature: FeatureFlagType): boolean => {
        return store.getters.featureFlags.isEnabled(feature);
    };

    const areAnyEnabled = (features: FeatureFlagType[]): boolean => {
        return features.some((feature: FeatureFlagType) => store.getters.featureFlags.isEnabled(feature));
    };

    const areAllEnabled = (features: FeatureFlagType[]): boolean => {
        return features.every((feature: FeatureFlagType) => store.getters.featureFlags.isEnabled(feature));
    };

    const onChange = (callback: unknown): void => {
        store.dispatch.featureFlags.onChange(callback);
    };

    const flag = (feature: FeatureFlagType): Ref<boolean> => {
        flags.value[feature] = isEnabled(feature);
        return computed(() => flags.value[feature]);
    };

    onChange(() => {
        flags.value = Object.keys(flags.value).reduce((acc: Record<string, boolean>, feature: string) => {
            acc[feature] = isEnabled(feature as FeatureFlagType);
            return acc;
        }, {});
    });

    return { flag, isEnabled, areAnyEnabled, areAllEnabled, onChange };
}
