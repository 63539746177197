



















































































































import { computed, defineComponent, nextTick, PropType, Ref, ref, watch } from '@vue/composition-api';
import { isNil, equals, isEmpty } from 'ramda';
import { SearchIcon } from '@vue-hero-icons/outline';

import { SvgImage } from '.';
import { ESearchQuery } from '../types';
import SearchBox from './SearchBox.vue';

type SearchableField = {
    label: string;
    key: string;
    textColorClasses?: string;
    backgroundClasses?: string;
    highlightClasses?: string;
    widthClasses?: string;
};

export default defineComponent({
    name: 'ESearch',
    model: { prop: 'query', event: 'change' },
    props: {
        query: {
            type: Object as PropType<ESearchQuery>,
            required: true,
        },
        shortcut: { type: String, default: 'I' },
        disabled: { type: Boolean, default: false },
        searchableFields: {
            type: Array as PropType<SearchableField[]>,
            default: () => [],
        },
    },
    components: { SearchBox, SvgImage, SearchIcon },
    setup(props, { emit }) {
        const setFocus: Ref<boolean> = ref<boolean>(false);
        const focus: Ref<Date | undefined> = ref<Date | undefined>();
        const reset: Ref<string | undefined> = ref<string | undefined>();
        const currentField: Ref<SearchableField | undefined> = ref<SearchableField | undefined>(
            props.query.input.field
                ? props.searchableFields.find((field: SearchableField) => field.key === props.query.input.field)
                : undefined,
        );
        const currentSearchText: Ref<string> = ref<string>(
            props.query.input.text === '*' ? '' : props.query.input.text,
        );

        const temporarySearchText = computed({
            get: () => (currentSearchText.value === '*' ? '' : currentSearchText.value),
            set: (newText: string) => {
                const matched = onTab(newText, true);
                if (!matched) currentSearchText.value = newText;
            },
        });

        const caseSensitive: Ref<boolean> = ref<boolean>(
            isNil(props.query.settings?.caseSensitive) ? false : props.query.settings.caseSensitive,
        );
        const exactMatch: Ref<boolean> = ref<boolean>(
            isNil(props.query.settings?.partialMatch) ? false : !props.query.settings.partialMatch,
        );

        const currentQuery: Ref<ESearchQuery> = computed(() => {
            return {
                input: {
                    text:
                        isNil(currentSearchText.value) || isEmpty(currentSearchText.value.trim())
                            ? '*'
                            : currentSearchText.value.trim(),
                    field: currentField.value?.key,
                },
                settings: {
                    caseSensitive: caseSensitive.value,
                    partialMatch: !exactMatch.value,
                },
            };
        });

        const hasMatchForText = (newText: string) => {
            for (let f = 0; f < props.searchableFields.length && newText.trim().length > 0; f++) {
                const field: SearchableField = props.searchableFields[f];

                const { hasMatch } = matchFieldLabel(field, newText);
                // if any field has matched then we set this as the field and the remaining text as
                // the search text input
                if (hasMatch) return true;
            }
            return false;
        };

        const onTab = (newText: string, exactMatchLabel: boolean = false): boolean => {
            for (let f = 0; f < props.searchableFields.length && newText.trim().length > 0; f++) {
                const field: SearchableField = props.searchableFields[f];

                const { hasMatch, matched } = matchFieldLabel(field, newText, exactMatchLabel);
                // if any field has matched then we set this as the field and the remaining text as
                // the search text input
                if (hasMatch) {
                    currentSearchText.value = newText.replace(new RegExp(`(${matched})`, 'i'), '').trim();
                    reset.value = new Date().toISOString();
                    currentField.value = field;
                    setFocus.value = true;
                    // covers case where no search is performed and we
                    // just want to set focus on next rerender of the component
                    setTimeout(() => {
                        focus.value = new Date();
                    }, 100);
                    return true;
                }
            }
            return false;
        };

        const matchFieldLabel = (
            field: SearchableField,
            tempText: string,
            exactMatchLabel: boolean = false,
        ): { matched: string; trailing: string; hasMatch: boolean } => {
            const fieldSelector = `:${field.label}:`;
            const cleanTempText = tempText.trim();

            // if we match the field selector exactly then we use this and reserve the following text as the value
            const absoluteMatches = cleanTempText.match(
                new RegExp(`^(${fieldSelector.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')})(.*)$`, 'i'),
            );
            if (absoluteMatches && absoluteMatches.length > 2) {
                return { matched: absoluteMatches[1], trailing: '', hasMatch: true };
            }
            if (!exactMatchLabel) {
                // if absolute does not match we try to look if we partially match
                const otherMatches = fieldSelector.match(
                    new RegExp(`^(${cleanTempText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')})(.*)$`, 'i'),
                );
                if (otherMatches && otherMatches.length > 2) {
                    return { matched: otherMatches[1], trailing: otherMatches[2], hasMatch: true };
                }
            }

            // nothing has been matched
            return { matched: '', trailing: fieldSelector, hasMatch: false };
        };

        const selectField = (field: SearchableField): void => {
            setFocus.value = true;
            if (isNil(currentField.value) || currentField.value.key !== field.key) currentField.value = field;
            else currentField.value = undefined;
        };

        const onDeleting = (tempText: string) => {
            if (tempText === '' && !isNil(currentField.value)) {
                currentField.value = undefined;
                currentSearchText.value = '';
            }
        };

        const hasSufficientCharacters = (text: string) => text.trim().length >= 2;

        watch(
            () => currentQuery.value,
            (newQuery: ESearchQuery, oldQuery: ESearchQuery | undefined) => {
                if (!equals(newQuery, oldQuery)) emit('change', currentQuery.value);
            },
        );

        watch(
            () => props.disabled,
            (newDisabled: boolean) => {
                if (!newDisabled && setFocus.value)
                    nextTick(() => {
                        focus.value = new Date();
                        setFocus.value = false;
                    });
            },
        );

        return {
            focus,
            reset,
            temporarySearchText,
            caseSensitive,
            exactMatch,
            currentField,
            hasSufficientCharacters,
            hasMatchForText,
            matchFieldLabel,
            selectField,
            onDeleting,
            onTab,
        };
    },
});
