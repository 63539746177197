// Al chartTypes available from https://apexcharts.com/docs/options/chart/type/
// In case of name edit, also modify pageSize enum
export enum ApexChartType {
    Line = 'line',
    Area = 'area',
    Bar = 'bar',
    Radar = 'radar',
    // Histogram = 'histogram',
    Pie = 'pie',
    Donut = 'donut',
    RadialBar = 'radialBar',
    Scatter = 'scatter',
    // Bubble = 'bubble',
    Heatmap = 'heatmap',
    // Candlestick = 'candlestick',
}
