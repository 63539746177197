import { useFeatureFlags, useLocations, useModelBrowser } from '@/app/composable';
import { useSockets } from '@/app/composable/socket';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import { useOrganisation } from '@/modules/organization/composable';
import { NavigationGuard, Route } from 'vue-router';
import { useKeycloak } from '../composables/keycloak';

type Next = Parameters<NavigationGuard>[2];

export const AuthGuard = async (to: Route, from: Route, next: Next) => {
    const { socketInitialization } = useSockets();
    if (store.getters.auth.isAuthenticated) {
        socketInitialization();
        return next();
    }

    const { isEnabled, areAnyEnabled } = useFeatureFlags();
    const { isEnabled: keycloakIsEnabled, login } = useKeycloak();

    try {
        // If not, try to get user (auto-login, if there's a valid cookie)
        const { data: user } = await Auth.user();
        store.commit.auth.SET_USER(user);
        useModelBrowser().initialize();
        useLocations().initialize();
        if (user.organisationId) useOrganisation(user.organisationId).initialize();
        await store.dispatch.executionVersions.loadVersions();
        await store.dispatch.executionErrors.loadExecutionErrors();
        await store.dispatch.dataModel.loadDomains();
        socketInitialization();
        if (
            to.meta?.feature &&
            ((Array.isArray(to.meta.feature) && !areAnyEnabled(to.meta.feature)) ||
                (!Array.isArray(to.meta.feature) && !isEnabled(to.meta.feature)))
        ) {
            return next({ name: '404' });
        }
        return next();
    } catch (e) {
        // Otherwise, redirect log in
        if (keycloakIsEnabled()) {
            return login(to);
        }
    }
};
