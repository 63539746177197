






import { defineComponent } from '@vue/composition-api';
import { InformationCircleIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'NotificationAdministratorIcon',
    components: {
        InformationCircleIcon,
    },
    props: {
        dropdown: {
            type: Boolean,
            default: false,
        },
    },
});
