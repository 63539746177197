












































































import * as R from 'ramda';
import { useEventListener } from '@vueuse/core';
import { defineComponent, ref } from '@vue/composition-api';
import { SearchIcon, FilterIcon } from '@vue-hero-icons/outline';
import { ChevronDownIcon } from '@vue-hero-icons/solid';

export default defineComponent({
    name: 'SearchConcepts',
    components: {
        ChevronDownIcon,
        SearchIcon,
        FilterIcon,
    },
    model: {
        prop: 'search',
        event: 'search-changed',
    },
    props: {
        search: {
            type: Object,
            required: true,
        },
        filterOptions: {
            type: Array as () => Record<string, any>[],
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        action: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const isFilterOpen = ref(false);

        const toggleFilter = () => {
            isFilterOpen.value = !isFilterOpen.value;
        };

        const updateProp = (path: (string | number)[], value: any) => {
            emit('search-changed', R.assocPath(path, value, R.clone(props.search)));
        };

        const handleChangeFilter = (option: any) => {
            emit('search-changed', {
                ...props.search,
                filterBy: option,
            });
            isFilterOpen.value = false;
        };

        useEventListener(document, 'keydown', (e: KeyboardEvent) => {
            if (e.key === 'Esc' || e.key === 'Escape') {
                isFilterOpen.value = false;
            }
        });

        return {
            handleChangeFilter,
            toggleFilter,
            isFilterOpen,
            updateProp,
        };
    },
});
