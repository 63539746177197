var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        _vm.showTooltip
            ? {
                  content: _vm.info[_vm.value].tooltip,
                  classes: 'max-w-lg break-words',
              }
            : undefined
    ),expression:"\n        showTooltip\n            ? {\n                  content: info[value].tooltip,\n                  classes: 'max-w-lg break-words',\n              }\n            : undefined\n    "}],staticClass:"py-0.5 text-center capitalize rounded-md",class:[_vm.info[_vm.value].classes, { 'px-2 font-medium text-sm ': !_vm.minimal, 'px-1 text-xs': _vm.minimal }]},[_vm._v(" "+_vm._s(_vm.value)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }