export const dynamicValues = [
    'current_date',
    'current_timestamp',
    'minutes_ago',
    'hours_ago',
    'days_ago',
    'months_ago',
    'years_ago',
    'minutes_later',
    'hours_later',
    'days_later',
    'months_later',
    'years_later',
    'start_of_the_minute',
    'start_of_the_hour',
    'start_of_the_day',
    'start_of_the_month',
    'start_of_the_year',
    'end_of_the_minute',
    'end_of_the_hour',
    'end_of_the_day',
    'end_of_the_month',
    'end_of_the_year',
];
