

































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'HtmlModal',
    props: {
        additionalClasses: { type: String, default: '' },
    },
});
