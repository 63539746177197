




























































import { defineComponent, computed } from '@vue/composition-api';
import { Status, SuggestionStatus } from '../constants';
import { LinkIcon } from '@vue-hero-icons/outline';
import ChangeIndication from './ChangeIndication.vue';

export default defineComponent({
    name: 'HighlevelConcept',
    props: {
        concept: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        parentStatus: {
            type: String,
            required: true,
        },
        changesToBeSaved: {
            type: Boolean,
            default: () => false,
        },
        savedConcepts: {
            type: Array,
            default: () => [],
        },
        proposedMode: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        LinkIcon,
        ChangeIndication,
    },
    setup(props: any, { emit }: { emit: any }) {
        const isSuggestion = computed(() => props.concept.status === SuggestionStatus.Pending);

        const unsavedChange = computed(() => {
            let unsaved = null;
            if (props.savedConcepts.includes(props.concept.uid)) unsaved = false;
            if (props.selected && props.changesToBeSaved) unsaved = true;
            return unsaved;
        });

        const deprecated = computed(
            () => props.concept.status === Status.Deprecated && props.parentStatus !== Status.Deprecated,
        );

        const newConcept = computed(
            () => props.concept.status === Status.Draft && props.parentStatus === Status.UnderRevision,
        );

        const conceptTooltip = computed(() => {
            if (deprecated.value || newConcept.value)
                return `${props.concept.name} - ${deprecated.value ? 'Deprecated' : 'New'}`;

            return props.concept.name;
        });

        return {
            emit,
            Status,
            isSuggestion,
            deprecated,
            newConcept,
            conceptTooltip,
            unsavedChange,
        };
    },
});
