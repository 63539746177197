export enum FieldFilters {
    Active = 'active',
    All = 'all',
    Deprecated = 'deprecated',
    Draft = 'draft',
    New = 'new',
    Proposed = 'proposed',
    Related = 'related',
    Simple = 'simple',
    UnderRevision = 'in review',
}
