export enum InputParameterCategory {
    Value = 'value',
    Dataframe = 'dataframe',
    Column = 'column',
    Logical = 'logical',
    Complex = 'complex',
    Hidden = 'hidden',
    Model = 'model',
    Asset = 'asset',
    Schema = 'schema',
}
