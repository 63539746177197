





































































































































































































































































import { AlertBanner, Scrollbar } from '@/app/components';
import { ArrowRightIcon, RefreshIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent } from '@vue/composition-api';
import { OrbitSpinner, SelfBuildingSquareSpinner } from 'epic-spinners';
import LineChart from '../components/charts/LineChart.vue';
import { useDashboard, useExceptionEvents, useFeatureFlags } from '../composable';
import store from '../store';

export default defineComponent({
    name: 'Dashboard',
    metaInfo: { title: 'Dashboard' },
    components: {
        AlertBanner,
        ArrowRightIcon,
        OrbitSpinner,
        RefreshIcon,
        LineChart,
        Scrollbar,
        SelfBuildingSquareSpinner,
    },
    setup(props, { root }) {
        const user = computed(() => store.state.auth.user);
        const { flag, areAnyEnabled } = useFeatureFlags();
        const isAnalyticsEnabled = flag('analytics');
        const { show: showExceptionEvents, count: exceptionCount, loading: loadingExceptions } = useExceptionEvents();
        const {
            asset,
            pipelineAndRetrieval,
            execution,
            loading: loadingStatistics,
            lastUpdatedOn: statisticsLastUpdatedOn,
            lastUpdatedSince: statisticsLastUpdatedSince,
            refetch,
            getDateRangeForLabel,
        } = useDashboard();

        const loading = computed(() => loadingExceptions.value || loadingStatistics.value);

        const dataPointClicked = (point: { series: number; dataPoint: number }) => {
            const name = point.series === 0 ? 'data-checkin-jobs:monitoring' : 'workflow:monitoring';
            const category = execution.value.labels[point.dataPoint];

            root.$router.push({
                name,
                query: { period: 'custom', ...getDateRangeForLabel(category), ownership: 'organisation' },
            });
        };

        return {
            loading,
            showExceptionEvents,
            exceptionCount,
            asset,
            pipelineAndRetrieval,
            execution,
            loadingExceptions,
            statisticsLastUpdatedOn,
            statisticsLastUpdatedSince,
            isAnalyticsEnabled,
            areAnyEnabled,
            refetch,
            dataPointClicked,
            user,
        };
    },
});
