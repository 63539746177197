





























































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ConfirmModal',
    props: {
        message: {
            type: String,
            default: 'Please wait...',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
    },
});
