import { AxiosRequestConfig } from 'axios';
import { Alert } from '../interfaces';

const endpoint = '/api/alerts';

export default {
    getAlert: (id: string) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    getUserAlerts: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    createAlert: (data: Alert) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    updateAlert: (id: string, data: Alert) => ({ method: 'PUT', url: `${endpoint}/${id}`, data } as AxiosRequestConfig),
    activateAlert: (id: string) => ({ method: 'PATCH', url: `${endpoint}/${id}/enable` } as AxiosRequestConfig),
    deactivateAlert: (id: string) => ({ method: 'PATCH', url: `${endpoint}/${id}/disable` } as AxiosRequestConfig),
    deleteAlert: (id: string) => ({ method: 'DELETE', url: `${endpoint}/${id}` } as AxiosRequestConfig),
};
