import { AxiosRequestConfig } from 'axios';
import { forEachObjIndexed } from 'ramda';
import { PostPolicyFile } from '../types';

const endpoint = '/api/apollo';

export default {
    getPostPolicy: (pipelineId: string, folder: string, subfolder?: string) =>
        ({
            method: 'GET',
            url: `${endpoint}/${pipelineId}/policy/${folder}${subfolder ? `?subfolder=${subfolder}` : ''}`,
        } as AxiosRequestConfig),
    uploadPolicyFile: (
        file: any,
        filename: string,
        config: any,
        onUploadProgressCallback?: (progressEvent: any) => void,
    ) => {
        const formData = new FormData();
        formData.append('key', `${config.prefix}/${filename}`);
        formData.append('file', file);

        forEachObjIndexed((value: any, key: any) => {
            formData.append(key, value);
        }, config.formData);

        return {
            url: config.postURL,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: onUploadProgressCallback,
        } as AxiosRequestConfig;
    },
    storeSampleFileDetails: (pipelineId: string, data: PostPolicyFile[]) =>
        ({
            method: 'POST',
            url: `${endpoint}/${pipelineId}/store-sample-file-details`,
            data,
        } as AxiosRequestConfig),
    storeTaskFilesDetails: (pipelineId: string, data: PostPolicyFile[], taskName: string = 'harvester') =>
        ({
            method: 'POST',
            url: `${endpoint}/${pipelineId}/${taskName}/store-files-details`,
            data,
        } as AxiosRequestConfig),
};
