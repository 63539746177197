import { AxiosRequestConfig } from 'axios';
import { Task, TaskResultPayload } from '../types';

const endpoint = '/api/task';

export default {
    get: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    update: (task: Task) => ({ method: 'PUT', url: `${endpoint}/${task.id}`, data: task } as AxiosRequestConfig),
    delete: (uid: string) => ({ method: 'DELETE', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    result: (uid: string, data: TaskResultPayload = {}) =>
        ({ method: 'POST', url: `${endpoint}/result/${uid}`, data } as AxiosRequestConfig),
    resultShort: (uid: string) => ({ method: 'GET', url: `${endpoint}/result/${uid}/short` } as AxiosRequestConfig),
    resultStructure: (uid: string) =>
        ({ method: 'GET', url: `${endpoint}/result/${uid}/structure` } as AxiosRequestConfig),
    resultDetails: (uid: string) => ({ method: 'GET', url: `${endpoint}/result/${uid}/details` } as AxiosRequestConfig),
};
