import store from '@/app/store';
import { ExecutionFramework } from './execution-framework.constants';
export class ExecutionFrameworkWrapper {
    static readonly SPARK = new ExecutionFrameworkWrapper(
        ExecutionFramework.spark3,
        'Spark',
        'Execution in the Apache Spark Engine (v3.0)',
        '/img/frameworks/spark3.svg',
        '/img/frameworks/spark3-short.svg',
        store.state.executionVersions.analyticsExecutionVersion || 'latest',
    );

    static readonly PYTHON = new ExecutionFrameworkWrapper(
        ExecutionFramework.python3,
        'Python',
        'Execution in a python environment',
        '/img/frameworks/python3.svg',
        '/img/frameworks/python3-short.svg',
        store.state.executionVersions.analyticsExecutionVersion || 'latest',
    );

    private constructor(
        public readonly framework: ExecutionFramework,
        public readonly label: string,
        public readonly description: string,
        public readonly imagePath: string,
        public readonly shortImagePath: string,
        public readonly version: string,
    ) {
        this.framework = framework;
        this.label = label;
        this.description = description;
        this.version = version;
    }

    static all(): ExecutionFrameworkWrapper[] {
        return [this.SPARK, this.PYTHON];
    }

    static find(framework: ExecutionFramework): ExecutionFrameworkWrapper | null {
        for (let f = 0; f < ExecutionFrameworkWrapper.all().length; f++) {
            const frameworkWrapper = ExecutionFrameworkWrapper.all()[f];
            if (frameworkWrapper.framework === framework) {
                return frameworkWrapper;
            }
        }
        return null;
    }
}
