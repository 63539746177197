































































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'FakeFormBlock',
    props: {
        blocks: { type: Number, default: 1 },
    },
});
