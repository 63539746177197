














































































































































import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import { TwButton, ConfirmButton, Scrollbar } from '@/app/components';
import { ExclamationCircleIcon, TrashIcon, ChevronRightIcon, PlusIcon } from '@vue-hero-icons/outline';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { useDataModel } from '../composable';

extend('required', requiredValidator);
extend('max', maxLengthValidator);
export default defineComponent({
    name: 'ModelStandards',
    model: {
        prop: 'modelStandards',
        event: 'modelStandards',
    },
    props: {
        modelStandards: {
            type: Array,
            default: () => [],
        },
        model: {
            type: Object,
            required: true,
        },

        changesToBeSaved: {
            type: Array,
            default: () => [],
        },
        savedChange: {
            type: Boolean,
            default: false,
        },
        calculatedChanges: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        ConfirmButton,
        ValidationObserver,
        ValidationProvider,
        TwButton,
        ExclamationCircleIcon,
        TrashIcon,
        ChevronRightIcon,
        Scrollbar,
        PlusIcon,
    },
    setup(props, { emit }) {
        // UI variables
        const oldStandardMapping = ref<any>(null);
        const hoveredItemId = ref<string | null>(null);
        const standardsMapping = computed<any>(() =>
            props.modelStandards && props.modelStandards.length ? R.clone(props.modelStandards) : [],
        );
        const editingStandardIdx = ref<any>(null);
        const standardsMappingRef = ref<HTMLElement>();
        const editStandardsMapping = ref<any>(null);
        const addNewStandardsMapping = ref<any>(false);
        const openStandardMappingFormToggle = ref(false);
        const cancelStandardsMappingChanges = ref<any>(null);
        const newStandard = ref(false);
        const validateStandard = ref(false);
        const standardMappingInForm: any = ref(null);

        const newStandardsMapping = ref<any>({
            name: null,
            standard: null,
            version: null,
            type: null,
        });

        const filteredStandards = computed(() => {
            return R.uniqBy((sm: any) => sm.standard, copiedModel.value.standardsMapping);
        });

        const clearStandardsMapping = () => {
            newStandardsMapping.value = {
                name: null,
                standard: null,
                version: null,
                type: null,
            };
            if (editStandardsMapping.value) {
                editStandardsMapping.value = null;
                oldStandardMapping.value = null;
            }
            editingStandardIdx.value = null;
        };

        const copiedModel = computed(() => props.model);
        const { readOnly } = useDataModel(copiedModel);

        const editStandard = (idx: number, standardMapping: { standard: string; version: string }) => {
            editStandardsMapping.value = R.clone(standardMapping);
            oldStandardMapping.value = R.clone(standardMapping);
            editingStandardIdx.value = idx;
            openStandardMappingForm(false);
        };

        const openStandardMappingForm = (newStandardMapping: boolean) => {
            standardMappingInForm.value = newStandardMapping ? newStandardsMapping.value : editStandardsMapping.value;
            openStandardMappingFormToggle.value = true;
        };

        const removeStandardsMapping = (index: number) => {
            const updatedStandardsMapping = R.filter(
                (standard: any) => standardsMapping.value.indexOf(standard) !== index,
                standardsMapping.value,
            );
            emit('updateStandardsMapping', updatedStandardsMapping);
        };

        const standardAlreadyExists = computed(() => {
            // for edit mode
            let alreadyExists = false;
            if (oldStandardMapping.value && standardMappingInForm.value) {
                const alreadyExistsInEditMode = standardsMapping.value.filter(
                    (smapping: any) =>
                        smapping.standard.toLowerCase() === standardMappingInForm.value.standard?.toLowerCase() &&
                        smapping.version.toLowerCase() === standardMappingInForm.value.version?.toLowerCase() &&
                        (smapping.standard.toLowerCase() !== oldStandardMapping.value.standard.toLowerCase() ||
                            smapping.version.toLowerCase() !== oldStandardMapping.value.version.toLowerCase()),
                );
                if (alreadyExistsInEditMode && alreadyExistsInEditMode.length > 0) return true;
            }

            // for new standard mode
            if (newStandardsMapping.value.standard && newStandardsMapping.value.version) {
                standardsMapping.value.forEach((smapping: any) => {
                    if (
                        smapping.standard.toLowerCase() === newStandardsMapping.value.standard.toLowerCase() &&
                        smapping.version.toLowerCase() === newStandardsMapping.value.version.toLowerCase()
                    )
                        alreadyExists = true;
                });
            }
            return alreadyExists;
        });

        const noChange = computed(() => {
            if (oldStandardMapping.value) {
                return JSON.stringify(oldStandardMapping.value) === JSON.stringify(standardMappingInForm.value);
            }

            return false;
        });

        const showStandardsMappingChangeIndication = computed(() => {
            return !!props.changesToBeSaved.find((c: any) => c.change === 'standardsMapping') || props.savedChange;
        });

        const closeStandardMappingForm = () => {
            openStandardMappingFormToggle.value = false;
            editingStandardIdx.value = null;
            clearStandardsMapping();
        };

        const addOrUpdateStandardMapping = () => {
            openStandardMappingFormToggle.value = false;
            let updatedStandardsMapping: any = [];
            if (oldStandardMapping.value) {
                const idx = R.findIndex(
                    (sm: any) =>
                        sm.standard === oldStandardMapping.value.standard &&
                        sm.version === oldStandardMapping.value.version,
                    standardsMapping.value,
                );

                updatedStandardsMapping = R.clone(standardsMapping.value);
                updatedStandardsMapping[idx] = standardMappingInForm.value;
            } else {
                updatedStandardsMapping = [...standardsMapping.value, standardMappingInForm.value];
            }
            emit('updateStandardsMapping', updatedStandardsMapping);
            clearStandardsMapping();
        };

        return {
            addNewStandardsMapping,
            cancelStandardsMappingChanges,
            editStandard,
            editStandardsMapping,
            emit,
            newStandard,
            removeStandardsMapping,
            showStandardsMappingChangeIndication,
            standardAlreadyExists,
            standardsMapping,
            standardsMappingRef,
            validateStandard,
            readOnly,
            standardMappingInForm,
            clearStandardsMapping,
            closeStandardMappingForm,
            addOrUpdateStandardMapping,
            openStandardMappingFormToggle,
            openStandardMappingForm,
            hoveredItemId,
            filteredStandards,
            noChange,
            editingStandardIdx,
        };
    },
});
