





import { ChipIcon, CubeTransparentIcon, DatabaseIcon, ExclamationCircleIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { AssetType } from '../constants';

export default defineComponent({
    name: 'AssetTypeIcon',
    props: {
        type: { type: String as PropType<AssetType>, required: true },
    },
    components: { ChipIcon, DatabaseIcon, CubeTransparentIcon, ExclamationCircleIcon },
    setup(props) {
        const icon = computed(() => {
            switch (props.type.toLowerCase()) {
                case AssetType.Dataset.toLowerCase():
                    return DatabaseIcon;
                case AssetType.Model.toLowerCase():
                    return ChipIcon;
                case AssetType.Result.toLowerCase():
                    return CubeTransparentIcon;
                default:
                    return ExclamationCircleIcon;
            }
        });

        const label = computed(() => {
            switch (props.type.toLowerCase()) {
                case AssetType.Dataset.toLowerCase():
                    return AssetType.Dataset;
                case AssetType.Model.toLowerCase():
                    return AssetType.Model;
                case AssetType.Result.toLowerCase():
                    return AssetType.Result;
                default:
                    return `Unknown asset type ${props.type}`;
            }
        });

        return { icon, label };
    },
});
