
































































import { defineComponent, computed, onUpdated } from '@vue/composition-api';
import { Scrollbar, SvgImage } from '@/app/components';
import ConceptOverview from './ConceptOverview.vue';
import SearchConcepts from '../components/SearchConcepts.vue';
import { useDataModel } from '../composable';
import { PlusIcon } from '@vue-hero-icons/outline';
import { FieldFilters, Status, HighLevelConceptFilters } from '../constants';

export default defineComponent({
    name: 'ConceptContents',

    props: {
        model: {
            type: Object,
            required: true,
        },
        subConcepts: {
            type: Array,
            required: true,
        },
        suggestions: {
            type: Array,
            required: true,
        },
        highLevelConceptStatus: {
            type: String,
            required: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        selectedField: {
            type: Object,
            default: null,
        },
        currentFilter: {
            type: String,
            default: null,
        },
        currentHLFilter: {
            type: String,
            default: null,
        },
        changesToBeSaved: {
            type: Boolean,
            default: false,
        },
        savedConcepts: {
            type: Array,
            default: () => [],
        },

        searchFields: {
            type: Object,
            required: true,
        },
        scrollToCreatedConcept: {
            type: Object,
            required: false,
        },
        readOnlyFields: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ConceptOverview,
        Scrollbar,
        SvgImage,
        SearchConcepts,
        PlusIcon,
    },
    setup(props, { emit }) {
        // UI variables
        const copiedModel = computed(() => props.model);
        const { isDraft, isUnderRevision, readOnly, filterConcepts, isDeprecated } = useDataModel(copiedModel);

        const search = computed({
            get: () => props.searchFields,
            set: (newSearch: any) => emit('update-search-fields', newSearch),
        });

        const deprecatedParent = computed(() => props.highLevelConceptStatus === Status.Deprecated);

        const filterOptions = computed(() => {
            if (
                props.currentHLFilter === HighLevelConceptFilters.Proposed ||
                isDeprecated.value ||
                deprecatedParent.value
            )
                return [];
            if (isDraft.value) {
                if (props.readOnlyFields) {
                    // means they are the fields of a referenced high level concept
                    return [FieldFilters.All, FieldFilters.Simple, FieldFilters.Related, FieldFilters.Deprecated];
                }
                return [FieldFilters.Draft, FieldFilters.Simple, FieldFilters.Related];
            }

            if (isUnderRevision.value) {
                if (props.highLevelConceptStatus === Status.Draft)
                    return [FieldFilters.New, FieldFilters.Simple, FieldFilters.Related];
                return [
                    FieldFilters.All,
                    FieldFilters.UnderRevision,
                    FieldFilters.New,
                    FieldFilters.Simple,
                    FieldFilters.Related,
                    FieldFilters.Proposed,
                    FieldFilters.Deprecated,
                ];
            }
            return [
                FieldFilters.Active,
                FieldFilters.Simple,
                FieldFilters.Related,
                FieldFilters.Proposed,
                FieldFilters.Deprecated,
                FieldFilters.All,
            ];
        });

        const proposedMode = computed(
            () =>
                props.currentHLFilter === HighLevelConceptFilters.Proposed ||
                search.value.filterBy === FieldFilters.Proposed,
        );

        // Methods
        const filterFields = (selection: any) => {
            emit('filter-change', selection);
        };

        const fields = computed(() => (proposedMode.value ? props.suggestions : props.subConcepts));

        const filteredFields = computed(() => {
            search.value.filterBy = props.currentFilter;
            return filterConcepts(fields.value, search.value.filterBy, true, search.value.text);
        });

        const goToReferenceHighLevelConcept = (highLevelId: number) => {
            emit('selectHighLevelConcept', highLevelId);
        };

        const addField = () => {
            emit('add');
        };

        const filterChange = (searchFilter: any) => {
            filterFields(searchFilter.filterBy);
        };

        const scrollToConcept = (con: string) => {
            const element = document.getElementById(con);
            if (element) {
                element.scrollIntoView();
                emit('clear-scrolling');
            }
        };

        const checkSelection = (id: number, status: string) => {
            if (props.selectedField) {
                return props.selectedField.id === id && props.selectedField.status === status;
            }
            return false;
        };

        const checkStatus = computed(() => {
            if (
                props.highLevelConceptStatus === Status.Deprecated &&
                props.currentHLFilter !== HighLevelConceptFilters.Proposed
            )
                return Status.Deprecated;
            if (search.value.filterBy === FieldFilters.All) {
                return '';
            }
            return search.value.filterBy;
        });

        const enableAddFieldButton = computed(
            () =>
                props.currentHLFilter !== HighLevelConceptFilters.Proposed &&
                !readOnly.value &&
                !deprecatedParent.value &&
                !props.readOnlyFields,
        );

        onUpdated(() => {
            if (props.scrollToCreatedConcept && props.scrollToCreatedConcept.parentId !== copiedModel.value.id) {
                // added check in order for scrolling to be "activated" only if a field is created and not a high level concept
                scrollToConcept(`${props.scrollToCreatedConcept.id}_${props.scrollToCreatedConcept.status}`);
            }
        });

        return {
            addField,
            checkSelection,
            emit,
            filterChange,
            filteredFields,
            filterFields,
            filterOptions,
            goToReferenceHighLevelConcept,
            proposedMode,
            Status,
            checkStatus,
            readOnly,
            isDraft,
            HighLevelConceptFilters,
            enableAddFieldButton,
            search,
            isDeprecated,
            fields,
            deprecatedParent,
        };
    },
});
