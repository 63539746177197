






















































































































































































import { Toggle } from '@/app/components';
import { requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { extend } from 'vee-validate';
import { useDataModel } from '../composable';
import { DataType } from '../constants';

extend('required', requiredValidator);

export default defineComponent({
    name: 'BooleanType',
    components: { Toggle },
    model: {
        prop: 'metadata',
        event: 'updateMetadata',
    },
    props: {
        collapsedLeftPane: {
            type: Boolean,
            required: true,
        },
        metadata: {
            type: Object,
            default: null,
        },
        isProposal: {
            type: Boolean,
            required: false,
        },
        initialMetadata: {
            type: Object,
            required: false,
        },
        model: {
            type: Object,
            required: true,
        },
        isFieldDraft: {
            type: Boolean,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        // changesToBeSaved: {
        //     type: Array,
        //     default: () => [],
        // },
        // savedChange: {
        //     type: Boolean,
        //     required: false,
        // },
    },
    setup(props, { emit }) {
        const copiedModel = computed(() => props.model);
        const { defineDatatypeMetadata } = useDataModel(copiedModel);

        const activeMetadata = ref<any>(null);
        const setActiveMetadata = () => {
            if (props.metadata && !props.isProposal) {
                activeMetadata.value = R.clone(props.metadata);
            } else {
                activeMetadata.value = defineDatatypeMetadata(DataType.Boolean);
                emit('updateMetadata', activeMetadata.value);
            }
        };
        setActiveMetadata();

        const updateMetadata = () => {
            // also disable "ordered" metadata if "multiple" is changed to false
            if (!activeMetadata.value.multiple && activeMetadata.value.ordered) {
                activeMetadata.value.ordered = false;
            }
            emit('updateMetadata', activeMetadata.value);
        };

        const disableMultiple = computed(
            () => (props.initialMetadata?.multiple && !props.isProposal && !props.isFieldDraft) || props.readOnly,
        );

        const disableOrdered = computed(
            () =>
                !activeMetadata.value.multiple ||
                (props.initialMetadata?.multiple &&
                    !props.initialMetadata?.ordered &&
                    !props.isProposal &&
                    !props.isFieldDraft) ||
                props.readOnly,
        );

        const disableSensitive = computed(
            () => (disableMetadataConditions.value && !props.initialMetadata?.sensitive) || props.readOnly,
        );

        const disableMetadataConditions = computed(
            () => props.initialMetadata && !props.isFieldDraft && !props.isProposal,
        );

        const disableIndexMongo = computed(
            () => props.readOnly || (disableMetadataConditions.value && !props.initialMetadata?.indexMongo),
        );

        const disableIndexES = computed(() => true); // always disabled for boolean

        return {
            disableMultiple,
            disableOrdered,
            disableSensitive,
            updateMetadata,
            activeMetadata,
            disableIndexMongo,
            disableIndexES,
        };
    },
});
