import * as R from 'ramda';
import { Status, SuggestionStatus } from '../constants';

export function useDataModelFilters() {
    const filterInRelatedTerms = (concept: any, text: string) => {
        for (let i = 0; i < concept.relatedTerms?.length; i++) {
            if (R.toLower(concept.relatedTerms[i]).includes(R.toLower(text))) return true;
        }
        return false;
    };

    const filterByText = (concepts: any[], searchText?: string): any[] => {
        if (searchText) {
            return R.filter(
                (concept) =>
                    R.toLower(concept.name).includes(R.toLower(searchText)) ||
                    R.toLower(concept.description).includes(R.toLower(searchText)) ||
                    filterInRelatedTerms(concept, searchText),
                concepts,
            );
        }
        return concepts;
    };

    /**
     * Returns the filtered concepts
     * @param concepts High level concepts/ high level proposed concepts/ fields/ proposed fields
     * @param filter The filter chosen for high level concepts/ fields
     * @param fields Indications if we are filtering fields or high level concepts
     * @param searchText Name/ description/ related term of high level concept/ field
     */
    const filterConcepts = (concepts: any[], filter: string, fields: boolean, searchText?: string): any[] => {
        let filtered = [...concepts];
        if (filter === 'active') {
            filtered = R.filter((concept) => concept.status === Status.Stable, concepts);
        } else if (filter === 'in review') {
            filtered = R.filter((concept) => concept.status === Status.UnderRevision, concepts);
        } else if (filter === 'draft' || filter === 'new') {
            filtered = R.filter((concept) => concept.status === Status.Draft, concepts);
        } else if (filter === 'deprecated') {
            filtered = R.filter((concept) => concept.status === Status.Deprecated, concepts);
        } else if (filter === 'proposed') {
            filtered = R.filter((concept) => concept.status === SuggestionStatus.Pending, concepts);
        } else if (filter === 'simple' && fields) {
            filtered = R.filter((concept) => concept.type !== 'object', concepts);
        } else if (filter === 'related' && fields) {
            filtered = R.filter((concept) => concept.type === 'object', concepts);
        }

        filtered = filterByText(filtered, searchText);
        return filtered;
    };

    return {
        filterConcepts,
        filterByText,
    };
}
