






































































import { useModelBrowser } from '@/app/composable';
import { Concept, Model } from '@/app/interfaces';
import { ChevronDownIcon, ChevronRightIcon, TerminalIcon, XIcon } from '@vue-hero-icons/outline';
import { PropType, Ref, computed, defineComponent, ref, watch } from '@vue/composition-api';
import Scrollbar from '../Scrollbar.vue';
import ModelPath from './ModelPath.vue';

export default defineComponent({
    name: 'PathBuilder',
    model: { prop: 'path', event: 'change' },
    props: {
        path: { type: Array as PropType<Concept[]>, default: () => [] },
        model: { type: Object as PropType<Model> },
        expanded: { type: Boolean, default: false },
        selectedFields: { type: Boolean, default: false },
    },
    components: { Scrollbar, ChevronDownIcon, ChevronRightIcon, XIcon, TerminalIcon, ModelPath },
    setup(props) {
        const { getConceptFromUid, restrictingConcept } = useModelBrowser();
        const openAlternatives: Ref<boolean> = ref<boolean>(props.expanded);
        const alternatives: Ref<Concept[][]> = computed(() => {
            if (props.path.length > 0) {
                const allAlternatives = props.path[props.path.length - 1].alternativePaths;
                if (allAlternatives)
                    return allAlternatives
                        .filter((a: { uids: string[] }) => a.uids.join('.') !== alternativeKey(props.path))
                        .map((a: { uids: string[] }) => a.uids.map((uid: string) => getConceptFromUid(uid)));
            }

            return [];
        });
        const hasAlternatives = computed(() => alternatives.value.length > 0);

        const alternativeKey = (alternative: Concept[]) => alternative.map((entry: Concept) => entry.uid).join('.');

        watch(
            () => props.expanded,
            (expanded: boolean) => (openAlternatives.value = expanded),
        );

        return { openAlternatives, alternatives, hasAlternatives, restrictingConcept, alternativeKey };
    },
});
