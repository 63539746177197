import { AxiosRequestConfig } from 'axios';
import { ExecutionError } from '../store/execution-errors';

const executionErrorsURL = '/api/execution-error';

export default {
    getExecutionErrors: () => ({ method: 'GET', url: executionErrorsURL } as AxiosRequestConfig),
    createExecutionError: (data: ExecutionError) =>
        ({ method: 'POST', url: `${executionErrorsURL}`, data } as AxiosRequestConfig),
    updateExecutionError: (code: string, data: ExecutionError) =>
        ({ method: 'PUT', url: `${executionErrorsURL}/${code}`, data } as AxiosRequestConfig),
    deleteExecutionError: (code: string) =>
        ({ method: 'DELETE', url: `${executionErrorsURL}/${code}` } as AxiosRequestConfig),
};
