<template>
    <vue-dropzone
        id="dropzone"
        ref="vuedropzone"
        :duplicateCheck="true"
        :include-styling="false"
        :options="dropzoneOptions"
        :useCustomSlot="true"
        :class="{ 'pointer-events-none': disabled }"
        @vdropzone-max-files-exceeded="onMaxFilesExceeded"
        @vdropzone-file-added="onFilesAdded"
    >
        <slot>
            <div
                class="py-2 text-center border border-dashed cursor-pointer bg-neutral-200 border-neutral-400 hover:bg-neutral-300"
            >
                <div class="my-2">
                    <div class="font-bold" :class="disabled ? 'text-neutral-500' : 'text-primary-600'">
                        Drop {{ filesText }} here or click to upload
                    </div>
                </div>
            </div>
        </slot>
    </vue-dropzone>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
import vue2Dropzone from 'vue2-dropzone';

export default defineComponent({
    name: 'FileDropzone',
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        hasPreviewTemplate: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        vueDropzone: vue2Dropzone,
    },
    setup(props, { refs, emit }) {
        const filesText = computed(() => {
            return props.multiple ? 'files' : 'a file';
        });

        const template = () => `<div class="flex items-center px-0 py-2 space-x-2 text-sm text-left ${
            !props.hasPreviewTemplate ? 'hidden' : ''
        }">
                <div class="flex items-center flex-grow space-x-1">
                    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path>
                    </svg>
                    <div class="flex-grow"><span data-dz-name></span></div>
                    <div class="flex-none w-24 text-right"><span data-dz-size></span></div>
                </div>
            </div>
        `;

        const dropzoneOptions = computed(() => {
            return {
                url: 'https://httpbin.org/post',
                method: 'post',
                thumbnailWidth: 150,
                thumbnailHeight: 150,
                addRemoveLinks: false,
                maxFiles: props.multiple ? null : 1, // Doesn't change if you choose "other".
                uploadMultiple: false,
                maxFilesize: null,
                autoProcessQueue: false,
                autoQueue: true,
                createImageThumbnails: false,
                parallelUploads: 2,
                params: {},
                previewTemplate: template(),
            };
        });

        const uploadFiles = (url, params) => {
            const files = refs.vuedropzone.getAcceptedFiles();
            refs.vuedropzone.setOption('url', url);
            refs.vuedropzone.setOption('params', params);
            refs.vuedropzone.setOption('parallelUploads', files.length);
            refs.vuedropzone.processQueue();
        };

        const removeAllFiles = () => {
            refs.vuedropzone.removeAllFiles();
        };

        const removeFile = (file) => {
            refs.vuedropzone.removeFile(file);
        };

        const onMaxFilesExceeded = (file) => {
            emit('max-files-exceeded');
            refs.vuedropzone.removeFile(file);
        };

        const onFilesAdded = () => {
            // Concatenating the already accepted files and the newly added file before sending them via event
            const files = refs.vuedropzone.getAcceptedFiles().concat(refs.vuedropzone.getAddedFiles());
            emit('files-added', files);
        };

        const addFile = (file) => refs.vuedropzone.addFile(file);

        return {
            filesText,
            dropzoneOptions,
            uploadFiles,
            removeAllFiles,
            removeFile,
            onMaxFilesExceeded,
            onFilesAdded,
            addFile,
        };
    },
});
</script>
