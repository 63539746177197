var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col space-y-1 magic-input"},[_c('div',{staticClass:"flex flex-col space-y-1",attrs:{"id":_vm.id},on:{"click":function($event){_vm.inEdit = true}}},[_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"relative flex flex-row w-full"},[((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.inEdit),expression:"inEdit"},{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"inputValueRef",staticClass:"flex flex-1 w-full min-w-0 px-3 py-2 rounded edit-value border-neutral-300 focus:ring-primary-300 focus:border-primary-300 sm:text-sm",class:[
                        {
                            'cursor-not-allowed': _vm.disabled,
                        },
                        { 'pl-3 pr-8': _vm.errorsString } ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.inputStep,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.finishEdit.apply(null, arguments)},"blur":_vm.finishEdit,"input":_vm.change,"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.inEdit),expression:"inEdit"},{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"inputValueRef",staticClass:"flex flex-1 w-full min-w-0 px-3 py-2 rounded edit-value border-neutral-300 focus:ring-primary-300 focus:border-primary-300 sm:text-sm",class:[
                        {
                            'cursor-not-allowed': _vm.disabled,
                        },
                        { 'pl-3 pr-8': _vm.errorsString } ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.inputStep,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.finishEdit.apply(null, arguments)},"blur":_vm.finishEdit,"input":_vm.change,"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"show",rawName:"v-show",value:(_vm.inEdit),expression:"inEdit"},{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"inputValueRef",staticClass:"flex flex-1 w-full min-w-0 px-3 py-2 rounded edit-value border-neutral-300 focus:ring-primary-300 focus:border-primary-300 sm:text-sm",class:[
                        {
                            'cursor-not-allowed': _vm.disabled,
                        },
                        { 'pl-3 pr-8': _vm.errorsString } ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.inputStep,"type":_vm.inputType},domProps:{"value":(_vm.inputValue)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();return _vm.finishEdit.apply(null, arguments)},"blur":_vm.finishEdit,"input":[function($event){if($event.target.composing){ return; }_vm.inputValue=$event.target.value},_vm.change]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inEdit),expression:"!inEdit"}],staticClass:"flex items-center flex-1 w-full h-8 min-w-0 px-2 py-2 bg-white border rounded display-value border-neutral-300 focus:ring-primary-300 focus:border-primary-300 sm:text-sm text-neutral-800"},[_c('span',{staticClass:"overflow-hidden truncate",class:{ 'pr-6': _vm.errorsString },domProps:{"innerHTML":_vm._s(_vm.displayValue)}})]),(_vm.errorsString)?_c('InputErrorIcon',{staticClass:"pt-1",attrs:{"tooltip":_vm.showFullError ? null : _vm.errorsString,"colour":_vm.errorColour}}):_vm._e()],1),(_vm.showFullError && _vm.errorsString)?_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-sm",class:_vm.errorColour,domProps:{"innerHTML":_vm._s(_vm.errorsString)}}),(_vm.validationHelpMessage)?_c('InformationCircleIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.validationHelpMessage,
                        classes: 'max-w-md break-words',
                        placement: 'bottom',
                    }),expression:"{\n                        content: validationHelpMessage,\n                        classes: 'max-w-md break-words',\n                        placement: 'bottom',\n                    }"}],staticClass:"flex-grow-0 flex-shrink-0 w-4 h-4 ml-1",class:_vm.errorColour}):_vm._e()],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }