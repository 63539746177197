import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/data-model';

export default {
    reduced: () => ({ method: 'GET', url: `${endpoint}/reduced` } as AxiosRequestConfig),
    conceptsUidMap: () => ({ method: 'GET', url: `${endpoint}/concepts-uid-map` } as AxiosRequestConfig),
    rootDomains: () => ({ method: 'GET', url: endpoint } as AxiosRequestConfig),
    spatialConcepts: (uid: string, version: number) =>
        ({ method: 'GET', url: `${endpoint}/domains/${uid}/${version}/spatial-id-concepts` } as AxiosRequestConfig),
    getModelByUid: (uid: string, version: number) =>
        ({ method: 'GET', url: `${endpoint}/domain/${uid}/${version}` } as AxiosRequestConfig),
};
