











































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'NotificationDataCheckinJobIcon',
    props: {
        referenceType: {
            type: String,
            required: false,
        },
        eventType: {
            type: String,
            required: true,
        },
        dropdown: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const iconType = computed(() => {
            switch (props.eventType) {
                case 'dcj.info':
                    return 'neutral';
                case 'dcj.service.completed':
                    return 'success';
                case 'dcj.service.failed':
                case 'dcj.service.skipped':
                    return 'fail';
                default:
                    return 'neutral';
            }
        });

        return {
            iconType,
        };
    },
});
