














import { ClipboardCopyIcon } from '@vue-hero-icons/outline';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
    name: 'CopyToClipboardButton',
    components: { ClipboardCopyIcon },
    props: {
        name: {
            type: String,
            required: true,
        },
        tooltipTitle: {
            type: String,
        },
        value: {
            type: [String, Number],
            required: false,
        },
        classes: {
            type: String,
            default: 'text-neutral-500',
        },
    },
    setup(props) {
        const copyItem = ref<any>(null);

        const copyToClipboard = () => {
            copyItem.value = document.querySelector(`#${props.name}`);
            if (copyItem.value.value) {
                copyItem.value.setAttribute('type', 'text');
                copyItem.value.select();
                document.execCommand('copy');
                copyItem.value.setAttribute('type', 'hidden');
            }
        };

        return {
            copyToClipboard,
        };
    },
});
