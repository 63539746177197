import * as R from 'ramda';
import { ValidatorUtils } from './utils';

export const integerValidator = {
    validate: (v: any, args: any) => {
        const value = R.is(Object, v) ? v.value : v;

        if (value === null || value === undefined || value === '') {
            return {
                valid: false,
            };
        }

        // first part of regex looks for normal integers
        // second optional part covers the case where we have
        // scientific numbers using the "e" notation
        return {
            valid: /^[-+]?\d+((\.\d*)?[eE][+-]\d+)?$/.test(value),
        };
    },
    message(field: string) {
        return `'${ValidatorUtils.prettifyName(field)}' must be an integer`;
    },
};
