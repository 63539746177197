import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/assets';

export default {
    getAssets: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    getModelAssets: () => ({ method: 'GET', url: `${endpoint}/models` } as AxiosRequestConfig),
    getInputAssets: (id: number) => ({ method: 'GET', url: `${endpoint}/${id}/input-assets` } as AxiosRequestConfig),
    getAvailableAssets: () => ({ method: 'GET', url: `${endpoint}/available` } as AxiosRequestConfig),
    getAvailableDatasets: () => ({ method: 'GET', url: `${endpoint}/available/datasets` } as AxiosRequestConfig),
    getAvailableResults: () => ({ method: 'GET', url: `${endpoint}/available/results` } as AxiosRequestConfig),
    getAsset: (id: number) => ({ method: 'GET', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    getAssetIndexedOnlyFields: (id: number) =>
        ({ method: 'GET', url: `${endpoint}/${id}/indexed-only` } as AxiosRequestConfig),
    getProcessedSample: (id: number) =>
        ({ method: 'GET', url: `${endpoint}/${id}/processed-sample` } as AxiosRequestConfig),
    getAffectedPipelinesQueries: (id: number) =>
        ({ method: 'GET', url: `${endpoint}/${id}/affected-pipelines-queries` } as AxiosRequestConfig),
    createAsset: (data: any) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    createModelAsset: (data: any) => ({ method: 'POST', url: `${endpoint}/model`, data } as AxiosRequestConfig),
    updateAsset: (id: number, data: any, strict?: boolean) =>
        ({
            method: 'PUT',
            url: `${endpoint}/${id}${strict === false ? `?strict=${strict}` : ''}`,
            data,
        } as AxiosRequestConfig),
    updateAssetAfterFailedTask: (id: number, data: any) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/update-failed`, data } as AxiosRequestConfig),
    deleteAsset: (id: number) => ({ method: 'DELETE', url: `${endpoint}/${id}` } as AxiosRequestConfig),
    getAssetTypes: () => ({ method: 'GET', url: `${endpoint}/asset-types` } as AxiosRequestConfig),
    archiveAsset: (id: number) => ({ method: 'PUT', url: `${endpoint}/${id}/archive` } as AxiosRequestConfig),
    restoreArchivedAsset: (id: number) => ({ method: 'PUT', url: `${endpoint}/${id}/restore` } as AxiosRequestConfig),
};
