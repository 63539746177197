export enum DataType {
    Integer = 'integer',
    Double = 'double',
    Object = 'object',
    String = 'string',
    Boolean = 'boolean',
    DateTime = 'datetime',
    Date = 'date',
    Time = 'time',
    Binary = 'base64binary',
}
