























import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'DescriptionListCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
});
