


























































































import { defineComponent, ref, computed } from '@vue/composition-api';
import VueTagsInput from '@johmun/vue-tags-input';
import { Card } from '@/app/components';
import { Status, SuggestionStatus } from '../constants';
import ChangeIndication from './ChangeIndication.vue';

export default defineComponent({
    name: 'HighlevelConceptDetails',
    props: {
        selectedHighLevelConcept: {
            type: Object,
            required: true,
        },

        underEditing: {
            type: Boolean,
            default: false,
        },
        parentStatus: {
            type: String,
        },
        unsavedChange: {
            type: Boolean,
        },
    },
    components: {
        Card,
        ChangeIndication,
        VueTagsInput,
    },
    setup(props, { emit }) {
        // UI variables
        const tag = ref('');

        const isSuggestion = computed(() => props.selectedHighLevelConcept.status === SuggestionStatus.Pending);

        const tags = computed(() => {
            const tmpArray = [] as any;
            if (props.selectedHighLevelConcept.relatedTerms) {
                props.selectedHighLevelConcept.relatedTerms.forEach((r: any) => tmpArray.push({ text: r }));
            }

            return tmpArray;
        });

        const deprecatedConcept = computed(
            () =>
                props.selectedHighLevelConcept.status === Status.Deprecated && props.parentStatus !== Status.Deprecated,
        );
        const newConcept = computed(
            () => props.selectedHighLevelConcept.status === Status.Draft && props.parentStatus === Status.UnderRevision,
        );

        return {
            emit,
            Status,
            tag,
            tags,
            isSuggestion,
            deprecatedConcept,
            newConcept,
        };
    },
});
