import { useAxios } from '@/app/composable';
import { defineModule } from 'direct-vuex';
import { ExecutionErrorsAPI } from '../api';
import { moduleActionContext } from '../store';

export enum ExecutionErrorCategory {
    Generic = 'Generic',
    Harvester = 'Harvester',
    Mapping = 'Mapping',
    Cleaning = 'Cleaning',
    Anonymiser = 'Anonymiser',
    Encryptor = 'Encryptor',
    Loader = 'Loader',
}

export interface ExecutionError {
    code: string;
    category: ExecutionErrorCategory;
    title: string;
    description: string;
}

export interface ExecutionErrorsState {
    executionErrors: ExecutionError[];
}

export const executionErrorsModule = defineModule({
    namespaced: true,
    state: (): ExecutionErrorsState => {
        return {
            executionErrors: [],
        };
    },
    mutations: {
        SET_EXECUTION_ERRORS(state: ExecutionErrorsState, executionErrors: ExecutionError[]) {
            state.executionErrors = executionErrors;
        },
    },
    actions: {
        async loadExecutionErrors({ commit }) {
            const { exec } = useAxios(true);
            await exec(ExecutionErrorsAPI.getExecutionErrors()).then((result) => {
                if (result?.data) commit('SET_EXECUTION_ERRORS', result.data);
            });
        },
    },
});

export const executionErrorsModuleActionContext = (context: any) => moduleActionContext(context, executionErrorsModule);
