import { Granularity } from '../types/granularity.type';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(utc);
dayjs.extend(weekOfYear);

export function useGraphUtils() {
    const formatEpochBy = (timestamp: string, granularity: Granularity, compact: boolean = false) => {
        const dayjsObj = dayjs.utc(timestamp);
        const weekOfTheYear = dayjsObj.week();

        switch (granularity) {
            case 'hour':
                return dayjsObj.set('minute', 0).format('DD MMM YYYY HH:mm');
            case 'day':
                return dayjsObj.format('DD MMM YYYY');
            case 'week':
                if (compact) return `Week ${weekOfTheYear}`;
                return `Week ${weekOfTheYear} (${dayjsObj
                    .week(weekOfTheYear)
                    .day(1)
                    .format('DD MMM YYYY')} - ${dayjsObj
                    .week(weekOfTheYear)
                    .add(1, 'week')
                    .day(1)
                    .format('DD MMM YYYY')})`;
            case 'month':
                return dayjsObj.format('MMM YYYY');
            case 'minute':
            default:
                return dayjsObj.format('DD MMM YYYY HH:mm');
        }
    };

    return { formatEpochBy };
}
