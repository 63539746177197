






import { defineComponent } from '@vue/composition-api';
import { DocumentReportIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'NotificationDigestIcon',
    components: {
        DocumentReportIcon,
    },
    props: {
        dropdown: {
            type: Boolean,
            default: false,
        },
    },
});
