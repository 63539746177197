






















































import { defineComponent, computed } from '@vue/composition-api';
import { ExclamationIcon, InformationCircleIcon } from '@vue-hero-icons/outline';
import HtmlModal from './HtmlModal.vue';

export default defineComponent({
    name: 'ConfirmModal',
    props: {
        color: {
            type: String,
            default: 'red',
        },
        title: {
            type: String,
            default: 'Delete record',
        },
        message: {
            type: String,
            default:
                'Are you sure you want to delete this record? All the related data will be permanently removed. This action cannot be undone',
        },
        okButtonText: {
            type: String,
            default: 'Delete',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        icon: {
            type: String,
            default: 'exclamation', // exclamation, info
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { HtmlModal, ExclamationIcon, InformationCircleIcon },
    setup(props) {
        const iconColors = {
            blue: 'bg-blue-100 text-blue-600',
            green: 'bg-green-100 text-green-600',
            primary: 'bg-primary-100 text-primary-600',
            red: 'bg-red-100 text-red-700',
            secondary: 'bg-secondary-100 text-secondary-600',
            yellow: 'bg-orange-100 text-orange-800',
        };
        const buttonColors = {
            blue: 'bg-blue-600 hover:bg-blue-500 focus:border-blue-700 focus:ring-blue',
            green: 'bg-green-700 hover:bg-green-600 focus:border-green-700 focus:ring-green',
            primary: 'bg-primary-600 hover:bg-primary-500 focus:border-primary-700 focus:ring-primary',
            red: 'bg-red-700 hover:bg-red-600 focus:border-red-700 focus:ring-red',
            secondary: 'bg-secondary-600 hover:bg-secondary-500 focus:border-secondary-700 focus:ring-secondary',
            yellow: 'bg-orange-800 hover:bg-orange-700 focus:border-orange-800 focus:ring-yellow',
        };

        const iconClasses = computed(() => iconColors[props.color]);
        const buttonClasses = computed(() => buttonColors[props.color]);

        return { iconClasses, buttonClasses };
    },
});
