













































import { computed, defineComponent, ref, Ref } from '@vue/composition-api';
import { ChevronDownIcon } from '@vue-hero-icons/solid';
import { SortAscendingIcon, SortDescendingIcon } from '@vue-hero-icons/outline';
import * as R from 'ramda';

export default defineComponent({
    name: 'Sort',
    model: { prop: 'sort', event: 'change' },
    props: {
        sort: {
            type: Object,
            required: true,
        },
        options: {
            type: Array as () => Record<string, any>[],
            required: true,
        },
        fieldField: { type: String, default: 'field' },
        ascField: { type: String, default: 'asc' },
    },
    components: { ChevronDownIcon, SortAscendingIcon, SortDescendingIcon },
    setup(props, { emit }) {
        const isSortOpen: Ref<boolean> = ref<boolean>(false);

        const sortBy = computed(
            () => R.find(R.propEq('key', props.sort[props.fieldField]), props.options)?.label || 'Sort',
        );

        const handleChangeSelect = (option: any) => {
            const newSort = { ...props.sort };
            if (props.sort[props.fieldField] === option) {
                newSort[props.ascField] = !props.sort[props.ascField];
            } else {
                newSort[props.fieldField] = option;
                newSort[props.ascField] = true;
            }
            emit('change', newSort);
            isSortOpen.value = false;
        };
        return { sortBy, isSortOpen, handleChangeSelect };
    },
});
