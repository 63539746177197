
























import { computed, defineComponent } from '@vue/composition-api';
import { CloudIcon, LightningBoltIcon, SunIcon } from '@vue-hero-icons/solid';
import {
    CloudIcon as CloudIconSkinny,
    LightningBoltIcon as LightningBoltIconSkinny,
    SunIcon as SunIconSkinny,
} from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'WeatherDynamicIcon',
    props: {
        weather: {
            type: Number,
            validator: (value: number) => value >= 0 && value <= 1,
            required: true,
        },
        skinny: { type: Boolean, default: false },
        allWhite: { type: Boolean, default: false },
    },
    components: { LightningBoltIcon, SunIcon, CloudIcon, CloudIconSkinny, LightningBoltIconSkinny, SunIconSkinny },
    setup(props) {
        const weatherIcon = computed(() => {
            if (props.weather === 0) return 'sun';
            else if (props.weather < 0.3) return 'sunWithCloud';
            else if (props.weather < 0.5) return 'cloud';
            else if (props.weather <= 0.8) return 'clouds';
            return 'thunder';
        });

        const sun = computed(() => (props.skinny ? SunIconSkinny : SunIcon));
        const cloud = computed(() => (props.skinny ? CloudIconSkinny : CloudIcon));
        const cloudFull = computed(() => CloudIcon);
        const lightning = computed(() => (props.skinny ? LightningBoltIconSkinny : LightningBoltIcon));

        const sunColor = computed(() => (props.allWhite ? 'text-white' : 'text-yellow-500 hover:text-yellow-600'));
        const lightestCloudColor = computed(() =>
            props.allWhite ? 'text-white' : 'text-gray-400 group-hover:text-gray-500',
        );
        const lightCloudColor = computed(() => (props.allWhite ? 'text-white' : 'text-gray-500 hover:text-gray-600'));
        const darkCloudColor = computed(() =>
            props.allWhite ? 'text-white' : 'text-gray-600 group-hover:text-gray-700',
        );
        const lightningColor = computed(() =>
            props.allWhite ? 'text-white' : 'text-yellow-500 hover:text-yellow-600',
        );

        return {
            weatherIcon,
            sun,
            cloud,
            cloudFull,
            lightning,
            sunColor,
            lightestCloudColor,
            lightCloudColor,
            darkCloudColor,
            lightningColor,
        };
    },
});
