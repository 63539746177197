var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-0 px-3"},[_c('div',{class:{ 'cursor-pointer': !_vm.underEditing },on:{"click":function($event){!_vm.underEditing ? _vm.emit('edit') : null}}},[_c('Card',{class:{ 'border border-primary-700 border-l-4 rounded-r': _vm.underEditing }},[_c('div',{staticClass:"flex items-center justify-between px-3 py-2"},[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"flex items-center mr-1 text-lg font-bold tracking-wide flex-no-grow",class:{
                            'text-primary-700': !_vm.isSuggestion,
                            'italic text-secondary-700': _vm.isSuggestion,
                        }},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.selectedHighLevelConcept.name),expression:"selectedHighLevelConcept.name",modifiers:{"bottom":true}}],staticClass:"truncate 2xl:w-120",class:_vm.deprecatedConcept ? 'w-72' : 'w-80'},[_vm._v(" "+_vm._s(_vm.selectedHighLevelConcept.name)+" ")])])]),_c('div',{staticClass:"flex items-center"},[(_vm.unsavedChange !== null)?_c('ChangeIndication',{attrs:{"unsaved":_vm.unsavedChange}}):_vm._e(),(_vm.newConcept || _vm.deprecatedConcept)?_c('h2',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(((_vm.newConcept ? 'New' : 'Deprecated') + " High Level Concept")),expression:"`${newConcept ? 'New' : 'Deprecated'} High Level Concept`"}],staticClass:"ml-2 text-lg font-bold"},[_c('div',{staticClass:"flex items-center p-1 rounded-full xl:hidden",class:{
                                'bg-primary-500': _vm.newConcept,
                                'bg-red-500': _vm.deprecatedConcept,
                            }}),_c('span',{staticClass:"items-center hidden px-2 text-sm capitalize rounded xl:flex",class:{
                                'text-primary-100 bg-primary-500': _vm.newConcept,
                                'bg-red-500 text-red-100': _vm.deprecatedConcept,
                            }},[_vm._v(" "+_vm._s(_vm.newConcept ? 'New' : 'Deprecated')+" ")])]):_vm._e()],1)]),_c('hr',{staticClass:"w-full"}),_c('div',{staticClass:"flex flex-col p-3 space-y-4"},[_c('div',{staticClass:"flex flex-col justify-between w-full space-x-0 space-y-2 xl:flex-row xl:space-y-0 xl:space-x-2"},[_c('span',{staticClass:"flex items-start w-40 text-xs font-bold tracking-wider uppercase text-neutral-500"},[_vm._v("Description")]),_c('div',{staticClass:"block w-5/6 text-sm break-all text-neutral-600"},[_vm._v(" "+_vm._s(_vm.selectedHighLevelConcept.description)+" ")])]),_c('div',{staticClass:"flex flex-col items-start justify-between w-full space-y-2 xl:items-center xl:flex-row xl:space-y-0 xl:space-x-2"},[_c('span',{staticClass:"flex w-40 text-xs font-bold tracking-wider uppercase text-neutral-500 whitespace-nowrap"},[_vm._v("Related terms")]),(_vm.selectedHighLevelConcept.relatedTerms && _vm.selectedHighLevelConcept.relatedTerms.length)?_c('div',{staticClass:"w-5/6"},[_c('VueTagsInput',{staticClass:"flex-wrap break-all remove-x remove-add-new remove-pointer-events truncated-content remove-actions",attrs:{"tags":_vm.tags,"disabled":""},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1):_c('div',{staticClass:"block w-5/6 px-1 text-sm italic text-neutral-600"},[_vm._v("Not Available")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }