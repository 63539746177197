import * as R from 'ramda';
import { ValidatorUtils } from './utils';

export const urlValidator = {
    validate: (value: any) => {
        if (R.isNil(value) || R.isEmpty(value)) {
            return {
                valid: false,
            };
        }
        return {
            valid: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(value),
        };
    },
    message(field: string) {
        return `${ValidatorUtils.prettifyName(field)} is not a valid URL.`;
    },
};
