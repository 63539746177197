var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex flex-col"},[_c('SearchBox',{key:_vm.reset,staticClass:"w-full",attrs:{"disabled":_vm.disabled,"minCharacters":2,"maxCharacters":50,"autoSearch":false,"additionalInputClasses":['pr-24', _vm.currentField ? _vm.currentField.widthClasses : ''],"shortcut":_vm.shortcut,"focus":_vm.focus,"size":"sm"},on:{"on-deleting":_vm.onDeleting,"on-tab":_vm.onTab,"clear":function($event){_vm.currentField = undefined}},scopedSlots:_vm._u([(_vm.currentField)?{key:"prefix",fn:function(ref){
var inFocus = ref.inFocus;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(("Searching only in field " + (_vm.currentField.label))),expression:"`Searching only in field ${currentField.label}`"}],staticClass:"flex flex-row space-x-1 pt-2.5 pl-2 text-white cursor-help",class:[_vm.currentField.backgroundClasses, inFocus ? 'rounded-tl-md ' : 'rounded-l-md ']},[_c('SearchIcon',{staticClass:"w-5 h-5"}),_c('span',{staticClass:"pr-3 text-sm"},[_vm._v(_vm._s(_vm.currentField.label))])],1)]}}:null,{key:"controls",fn:function(ref){
var currentText = ref.currentText;
var submit = ref.submit;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSufficientCharacters(currentText)),expression:"hasSufficientCharacters(currentText)"},{name:"tooltip",rawName:"v-tooltip",value:(
                    ("Case " + (_vm.caseSensitive ? 'Sensitive' : 'Insensitive') + ". Click to switch to Case " + (!_vm.caseSensitive ? 'Sensitive' : 'Insensitive'))
                ),expression:"\n                    `Case ${caseSensitive ? 'Sensitive' : 'Insensitive'}. Click to switch to Case ${\n                        !caseSensitive ? 'Sensitive' : 'Insensitive'\n                    }`\n                "}],staticClass:"p-1 rounded cursor-pointer",class:{
                    'bg-primary-700 ': _vm.caseSensitive,
                    'hover:bg-primary-200 hover:text-neutral-800 ': !_vm.caseSensitive,
                },on:{"click":function($event){_vm.caseSensitive = !_vm.caseSensitive;
                    submit();}}},[_c('SvgImage',{staticClass:"w-5 h-5 text-neutral-600",class:{
                        'text-white': _vm.caseSensitive,
                    },attrs:{"src":"/img/case_sensitive.svg"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasSufficientCharacters(currentText)),expression:"hasSufficientCharacters(currentText)"},{name:"tooltip",rawName:"v-tooltip",value:(
                    ((_vm.exactMatch ? 'Exact' : 'Partial') + " Match. Click to switch to " + (!_vm.exactMatch ? 'Exact' : 'Partial') + " Match")
                ),expression:"\n                    `${exactMatch ? 'Exact' : 'Partial'} Match. Click to switch to ${\n                        !exactMatch ? 'Exact' : 'Partial'\n                    } Match`\n                "}],staticClass:"p-1 rounded cursor-pointer",class:{
                    'bg-primary-700 ': _vm.exactMatch,
                    'hover:bg-primary-200 hover:text-neutral-800 ': !_vm.exactMatch,
                },on:{"click":function($event){_vm.exactMatch = !_vm.exactMatch;
                    submit();}}},[_c('SvgImage',{staticClass:"w-5 h-5 text-neutral-600",class:{
                        'text-white': _vm.exactMatch,
                    },attrs:{"src":"/img/exact_match.svg"}})],1)]}},{key:"focusPanel",fn:function(ref){
                    var currentText = ref.currentText;
return [_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('div',{staticClass:"flex flex-row space-x-2 text-sm",class:currentText},_vm._l((_vm.searchableFields),function(field){return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                            _vm.currentField && _vm.currentField.key === field.key
                                ? "Remove field limitation"
                                : ("Search only in field " + (field.label))
                        ),expression:"\n                            currentField && currentField.key === field.key\n                                ? `Remove field limitation`\n                                : `Search only in field ${field.label}`\n                        "}],key:field.key,staticClass:"cursor-pointer",class:_vm.currentField && _vm.currentField.key === field.key
                                ? ((field.backgroundClasses) + " text-white rounded-md px-1")
                                : ("text-neutral-600 " + (field.textColorClasses)),on:{"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectField(field)}}},[_c('span',{class:field.highlightClasses},[_vm._v(_vm._s(_vm.matchFieldLabel(field, currentText).matched))]),_c('span',[_vm._v(_vm._s(_vm.matchFieldLabel(field, currentText).trailing))])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasMatchForText(currentText)),expression:"hasMatchForText(currentText)"}],staticClass:"text-xs italic text-neutral-600"},[_vm._v(" Press [Tab] to autocomplete ")])])]}}],null,true),model:{value:(_vm.temporarySearchText),callback:function ($$v) {_vm.temporarySearchText=$$v},expression:"temporarySearchText"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }