
















import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
    name: 'InputErrorIcon',
    props: {
        size: {
            type: String,
            default: 'md',
        },
        tooltip: {
            type: [Object, String],
        },
        colour: {
            type: String,
            default: 'text-red-700',
        },
    },
    setup(props) {
        const classes = computed(() => {
            switch (props.size) {
                case 'xs':
                    return 'w-3 h-3';
                case 'sm':
                    return 'w-4 h-4';
                case 'lg':
                    return 'w-6 h-6';
                case 'md':
                default:
                    return 'w-5 h-5';
            }
        });

        return { classes };
    },
});
