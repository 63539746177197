import { AxiosRequestConfig } from 'axios';
import { CreateTaskDTO } from '../dto/create-task.dto';
import { CreateWorkflowDTO } from '../dto/create-workflow.dto';
import { CreateTriggerDTO, UpdateTriggerDTO } from '../dto/trigger.dto';
import { UpdateWorkflowDTO } from '../dto/update-workflow.dto';
import { SampleConfiguration } from '../types';

const endpoint = '/api/workflow';

export default {
    get: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    getWorkflows: () => ({ method: 'GET', url: `${endpoint}` } as AxiosRequestConfig),
    getWorkflowOptimised: (uid: string) =>
        ({ method: 'GET', url: `${endpoint}/${uid}/optimised` } as AxiosRequestConfig),
    getModels: () => ({ method: 'GET', url: `${endpoint}/models` } as AxiosRequestConfig),
    getResults: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}/results` } as AxiosRequestConfig),
    getAvailableModels: (uid: string) =>
        ({ method: 'GET', url: `${endpoint}/${uid}/input/Model` } as AxiosRequestConfig),
    validateModel: (modelName: string, metadata: any, frameworkVersion: string, sampleFile: any, modelFile: any) => {
        const formData = new FormData();
        formData.append('name', modelName);
        formData.append('metadata', JSON.stringify(metadata));
        formData.append('frameworkVersion', frameworkVersion);
        formData.append('modelFile', modelFile);
        formData.append('sampleFile', sampleFile);
        return {
            url: `${endpoint}/model/validate`,
            method: 'POST',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        } as AxiosRequestConfig;
    },
    getWorkflow: (uid: string | number) => ({ method: 'GET', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    validate: (uid: string | number) => ({ method: 'GET', url: `${endpoint}/${uid}/validate` } as AxiosRequestConfig),
    finalise: (uid: string | number) => ({ method: 'PATCH', url: `${endpoint}/${uid}/finalise` } as AxiosRequestConfig),
    unlock: (uid: string | number) => ({ method: 'PATCH', url: `${endpoint}/${uid}/unlock` } as AxiosRequestConfig),
    upgrade: (uid: string | number) => ({ method: 'PATCH', url: `${endpoint}/${uid}/upgrade` } as AxiosRequestConfig),
    upgradeDry: (uid: string | number) =>
        ({ method: 'PATCH', url: `${endpoint}/${uid}/upgrade/dry` } as AxiosRequestConfig),
    release: (uid: string | number) => ({ method: 'PATCH', url: `${endpoint}/${uid}/release` } as AxiosRequestConfig),
    lockPipeline: (uid: string | number) =>
        ({ method: 'PATCH', url: `${endpoint}/${uid}/lock-pipeline` } as AxiosRequestConfig),
    create: (data: CreateWorkflowDTO) => ({ method: 'POST', url: `${endpoint}`, data } as AxiosRequestConfig),
    update: (uid: string | number, data: UpdateWorkflowDTO) =>
        ({ method: 'PUT', url: `${endpoint}/${uid}`, data } as AxiosRequestConfig),
    delete: (uid: string) => ({ method: 'DELETE', url: `${endpoint}/${uid}` } as AxiosRequestConfig),
    cancelActiveExecutions: (uid: string) =>
        ({ method: 'PATCH', url: `${endpoint}/${uid}/cancel-executions` } as AxiosRequestConfig),
    deleteDisabledTasks: (uid: string) =>
        ({ method: 'DELETE', url: `${endpoint}/${uid}/disabled-tasks` } as AxiosRequestConfig),
    clone: (uid: string | number, data: CreateWorkflowDTO) =>
        ({ method: 'POST', url: `${endpoint}/${uid}/clone`, data } as AxiosRequestConfig),
    createTask: (data: CreateTaskDTO) =>
        ({ method: 'POST', url: `${endpoint}/${data.workflowId}/task`, data } as AxiosRequestConfig),
    run: (
        workflowId: string,
        runType = 'normal',
        taskId: string | null = null,
        frameworkVersion: string,
        sample: SampleConfiguration | null,
    ) =>
        ({
            method: 'POST',
            url: `${endpoint}/${workflowId}/execute`,
            data: {
                type: runType,
                outputTaskId: taskId,
                configuration: { version: frameworkVersion, sample },
            },
        } as AxiosRequestConfig),
    getVisualisations: (uid: string | number) =>
        ({ method: 'GET', url: `${endpoint}/${uid}/visualisations` } as AxiosRequestConfig),
    getProvenanceAssets: (uid: string) =>
        ({ method: 'GET', url: `${endpoint}/${uid}/provenanceassets` } as AxiosRequestConfig),
    getInputAssets: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}/inputassets` } as AxiosRequestConfig),
    getTasks: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}/tasks` } as AxiosRequestConfig),
    getTriggers: (uid: string) => ({ method: 'GET', url: `${endpoint}/${uid}/triggers` } as AxiosRequestConfig),
    createTrigger: (uid: string, data: CreateTriggerDTO) =>
        ({ method: 'POST', url: `${endpoint}/${uid}/triggers`, data } as AxiosRequestConfig),
    updateTrigger: (uid: string, triggerId: string, data: UpdateTriggerDTO) =>
        ({ method: 'PUT', url: `${endpoint}/${uid}/triggers/${triggerId}`, data } as AxiosRequestConfig),
    deleteTrigger: (uid: string, triggerId: string) =>
        ({ method: 'DELETE', url: `${endpoint}/${uid}/triggers/${triggerId}` } as AxiosRequestConfig),
    toggleTrigger: (uid: string, triggerId: string) =>
        ({ method: 'PUT', url: `${endpoint}/${uid}/triggers/${triggerId}/toggle` } as AxiosRequestConfig),
    statistics: () => ({ method: 'GET', url: `${endpoint}/statistics` } as AxiosRequestConfig),
};
