import store from '@/app/store';
import { computed } from '@vue/composition-api';
import * as R from 'ramda';
import { io } from 'socket.io-client';
import Vue from 'vue';
import { WebSocketsEvents, WebSocketsRoomActions, WebSocketsRoomTypes } from '../constants';
import { WebSocketsRoom } from '../interfaces';
import { useErrors } from './errors';

const socketEventBus = new Vue();
let socket: any = null;
const webSocketsState = store.state.webSocketsEngine;
const webSocketsStoreActions = store.dispatch.webSocketsEngine;
export function useSockets() {
    const connected = computed(() => store.state.webSocketsEngine.connected);
    const socketInitialization = () => {
        if (webSocketsState.connected) return;

        socket = io(`/events`, {
            transports: ['websocket'],
            reconnectionDelayMax: 10000,
            withCredentials: true,
        });

        socket.on('connect', () => {
            webSocketsStoreActions.setWebSocketsConnected();
            // if disconnected and connected again, join back any joined rooms before reconnection
            for (const room of webSocketsState.joinedRooms) {
                joinSocketRoom(room.type, room.entityId);
            }
            // join user room upon connection
            const userRoomAlreadyJoined = R.find(R.propEq('type', WebSocketsRoomTypes.User))(
                webSocketsState.joinedRooms,
            );
            if (!userRoomAlreadyJoined) joinSocketRoom(WebSocketsRoomTypes.User);
        });

        socket.on('disconnect', () => {
            webSocketsStoreActions.setWebSocketsDisconnected();
        });

        socket.on('error', (error: any) => {
            throw new Error(error);
        });

        for (const event of Object.values(WebSocketsEvents)) {
            if (event === WebSocketsEvents.Exception) {
                socket.on(WebSocketsEvents.Exception, (exception: any) => {
                    if (exception.type == 'Unauthorized') {
                        const { redirectToLogin } = useErrors();
                        redirectToLogin();
                    } else {
                        const exceptionMsg = `[WS-${exception.type}] ${
                            R.is(Array, exception.message) ? exception.message?.join(',') : exception.message
                        }`;
                        throw new Error(exceptionMsg);
                    }
                });
            } else {
                socket.on(event, (...args: any) => {
                    socketEventBus.$emit(event, ...args);
                });
            }
        }
    };

    const socketConnect = () => {
        socket.connect();
    };

    const socketDisconnect = () => {
        socket.disconnect();
    };

    const socketEmission = (event: string, options: { message: WebSocketsRoom }, callback: Function) => {
        socket.emit(event, options, callback);
    };

    const joinSocketRoom = (type: WebSocketsRoomTypes, entityId: string | number | null = null) => {
        socketEmission(WebSocketsRoomActions.JoinRoom, { message: { type, entityId } }, () => {});
        webSocketsStoreActions.addRoom({ type, entityId });
    };

    const leaveSocketRoom = (type: WebSocketsRoomTypes, entityId: string | number | null = null) => {
        socketEmission(WebSocketsRoomActions.LeaveRoom, { message: { type, entityId } }, () => {});
        webSocketsStoreActions.removeRoom({ type, entityId });
    };

    const subscribe = (event: string, callback: Function) => socketEventBus.$on(event, callback);
    const unsubscribe = (event: string) => socketEventBus.$off(event);

    return {
        subscribe,
        unsubscribe,
        connected,
        socketInitialization,
        socketDisconnect,
        socketEmission,
        WebSocketsEvents,
        socketConnect,
        joinSocketRoom,
        leaveSocketRoom,
        WebSocketsRoomTypes,
    };
}
