import { WindowEvent } from '../constants';

export function useWindowEventListeners() {
    const onEvent = (event: WindowEvent, callback: any, unhookCallback?: any) => {
        window.addEventListener(event, callback);

        if (unhookCallback) {
            unhookCallback(() => {
                window.removeEventListener(event, callback);
            });
        }
    };

    return { onEvent };
}
