import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/kafka';

export default {
    subscribeToTopic: (topic: string, stepId: number) =>
        ({
            method: 'POST',
            url: `${endpoint}/topics/${topic}/subscribe?stepId=${stepId}`,
        } as AxiosRequestConfig),
    getTopicConnectionDetails: (topic: string, stepId: number) =>
        ({
            method: 'GET',
            url: `${endpoint}/topics/${topic}/connection-details?stepId=${stepId}`,
        } as AxiosRequestConfig),
    testCredentialsAndCreateSample: (data: any, fileType: string) =>
        ({
            method: 'POST',
            url: `${endpoint}/user/${data.topic}/consume/${fileType}`,
            data,
        } as AxiosRequestConfig),
    resetTopic: (topic: string, group: string) => {
        return {
            method: 'PATCH',
            url: `${endpoint}/topic/${topic}/group/${group}/reset`,
        } as AxiosRequestConfig;
    },
};
