







import { ref } from '@vue/composition-api';
import * as R from 'ramda';

export default {
    name: 'ConfirmButton',
    props: {
        timeout: {
            type: Number,
            default: 5000,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        once: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: any, { emit }: { emit: any }) {
        const inConfirmMode = ref(false);

        const goIntoConfirmationMode = () => {
            if (!props.disabled) {
                inConfirmMode.value = true;
                if (props.timeout) {
                    setTimeout(() => {
                        inConfirmMode.value = false;
                    }, parseInt(R.toString(props.timeout), 10));
                }
            }
        };

        const confirmAction = () => {
            inConfirmMode.value = false;
            emit('confirmed');
        };
        return { inConfirmMode, goIntoConfirmationMode, confirmAction };
    },
};
