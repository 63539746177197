















































































































import { ModelsAPI } from '../api';
import { Status, ConceptCreationOptions } from '../constants';
import { ValidationProvider, extend } from 'vee-validate';
import { requiredValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';

extend('required', requiredValidator);

export default defineComponent({
    name: 'ImportConceptFromModel',
    components: {
        ValidationProvider,
    },

    props: {
        selectedConceptToBeUsed: {
            type: [Number, String],
            default: null,
        },
        concept: {
            type: Object,
            required: true,
        },
        stableModels: {
            type: Array,
            default: () => [],
        },
        action: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const conceptCreationOptions = [
            { key: ConceptCreationOptions.New, label: 'New Concept' },
            { key: ConceptCreationOptions.FullyImport, label: 'Fully Import from another Data Model' },
            { key: ConceptCreationOptions.PartialImport, label: 'Partial Import from another Data Model' },
            { key: ConceptCreationOptions.Reference, label: 'Reference a concept of another Data Model' },
        ];
        const selectedModelForConceptToBeUsed = ref<number | null>(null);
        const selectedDataModelHLConcepts = ref([]);
        const selectedCreationOption = ref<string>(ConceptCreationOptions.New);
        const chooseDataModel = ref<string>(ConceptCreationOptions.New);
        const conceptToUsedId = ref(null);

        const changeModel = async () => {
            if (selectedModelForConceptToBeUsed.value) {
                await ModelsAPI.getConcepts(selectedModelForConceptToBeUsed.value).then((res: any) => {
                    selectedDataModelHLConcepts.value = res.data.filter((h: any) => h.status === Status.Stable);
                });
            }
            conceptToUsedId.value = null;
            emit('clear-concept-to-be-used');
        };

        const resetDataModelChoices = () => {
            selectedModelForConceptToBeUsed.value = null;
            conceptToUsedId.value = null;
            emit('reset-data-model-choices');
        };

        const createOptionChange = (key: string) => {
            if (key === ConceptCreationOptions.New) {
                resetDataModelChoices();
            }

            selectedCreationOption.value = key;
            setConceptCreationAction();
        };

        const conceptSelectionText = computed(() => {
            if (selectedCreationOption.value === 'reference') return 'Concept to Reference';
            return 'Concept to Copy Fields from';
        });

        const setConceptCreationAction = () => {
            emit('set-concept-to-be-used', {
                action: selectedCreationOption.value,
                id: selectedCreationOption.value === ConceptCreationOptions.New ? null : conceptToUsedId.value,
            });
        };

        return {
            selectedModelForConceptToBeUsed,
            selectedDataModelHLConcepts,
            chooseDataModel,
            changeModel,
            conceptToUsedId,
            resetDataModelChoices,
            conceptCreationOptions,
            selectedCreationOption,
            createOptionChange,
            conceptSelectionText,
            setConceptCreationAction,
            ConceptCreationOptions,
        };
    },
});
