







































































import { SearchIcon, XIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, onUnmounted, onUpdated, PropType, ref, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { S } from '../utilities';

export default defineComponent({
    name: 'SearchBox',
    model: { prop: 'searchText', event: 'changed' },
    props: {
        searchText: { type: String, default: '' },
        placeholder: { type: String, required: false },
        focus: { type: [Date, Boolean], default: false },
        minCharacters: { type: Number, default: 0 },
        maxCharacters: { type: Number },
        // how long to wait before sending the text change
        waitThreshold: { type: Number, default: 0 },
        size: { type: String, default: 'md' },
        clearable: { type: Boolean, default: true },
        shortcut: { type: String, required: false },
        autoSearch: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        additionalInputClasses: {
            type: Array as PropType<string[]>,
            default: () => ['pr-8'],
        },
    },
    components: { SearchIcon, XIcon },
    setup(props, { emit }) {
        const ready: Ref<boolean> = ref<boolean>(false);
        const searchTextRef: Ref<any> = ref<any>();
        const currentSearchText: Ref<string> = ref<string>(
            props.minCharacters > 0 && props.searchText.length < props.minCharacters ? '' : props.searchText,
        );
        let currentSearchTimeout: any;
        const isMacOS = S.isMacOS();
        const inFocus: Ref<boolean> = ref<boolean>(false);

        const text = computed({
            get: () => currentSearchText.value,
            set: (newText: string) => {
                if (currentSearchText.value === newText) return;

                // if min characters are specified
                // and both old and new text is below that number
                // then no need to perform the query
                if (
                    props.minCharacters > 0 &&
                    newText !== '' &&
                    currentSearchText.value.length < props.minCharacters &&
                    newText.length < props.minCharacters
                ) {
                    currentSearchText.value = newText;
                    return;
                }

                // set the current text as the specified one
                currentSearchText.value = newText;

                if (props.autoSearch) {
                    // if no wait threshold is set then emit the change
                    if (props.waitThreshold === 0 || currentSearchText.value === '') submit();
                    else {
                        // otherwise clear any current type
                        if (currentSearchTimeout) clearTimeout(currentSearchTimeout);
                        // and create a new one
                        currentSearchTimeout = setTimeout(() => {
                            submit();
                            currentSearchTimeout = null;
                        }, props.waitThreshold);
                    }
                }
            },
        });

        const placeholderText = computed(() => {
            if (!R.isNil(props.placeholder) && !R.isEmpty(props.placeholder.trim())) return props.placeholder.trim();
            if (props.minCharacters > 0) return `Type at least ${props.minCharacters} characters...`;
            if (!props.autoSearch) return `Type and hit enter to search...`;
            return `Type to search...`;
        });

        const submit = () => {
            if (
                props.minCharacters === 0 ||
                currentSearchText.value.trim().length === 0 ||
                (props.minCharacters > 0 && currentSearchText.value.trim().length >= props.minCharacters)
            ) {
                emit('changed', currentSearchText.value.trim());
            }
        };

        const onKeyup = (e: { key: string }) => {
            if (e.key === 'Enter') {
                submit();
                emit('on-enter', currentSearchText.value);
            } else if (e.key === 'Backspace' || e.key === 'Delete') {
                emit('on-deleting', currentSearchText.value);
            } else if (e.key === 'Tab') {
                emit('on-tab', currentSearchText.value);
            } else {
                emit('keyup', e.key, currentSearchText.value, e);
            }
        };

        const clear = () => {
            text.value = '';
            if (!props.autoSearch) submit();
            emit('clear');
        };

        submit();

        onUpdated(() => (ready.value = true));

        watch(
            () => [ready.value, props.focus],
            ([isReady, shouldFocus]) => {
                if (isReady && shouldFocus) searchTextRef.value.focus();
            },
            { immediate: true },
        );

        watch(
            () => props.searchText,
            (searchText: string) => {
                text.value = searchText;
            },
            { immediate: true },
        );

        // add handling if shortcut key provided
        if (props.shortcut) {
            const focusShortcutKey = (e: any) => {
                if (
                    props.shortcut &&
                    (((e.key === props.shortcut.toLowerCase() || e.key === props.shortcut.toUpperCase()) &&
                        e.metaKey &&
                        isMacOS) ||
                        ((e.key === props.shortcut.toLowerCase() || e.key === props.shortcut.toUpperCase()) &&
                            e.ctrlKey &&
                            !isMacOS))
                ) {
                    e.stopImmediatePropagation();
                    searchTextRef.value?.focus();
                }
            };

            document.addEventListener('keydown', focusShortcutKey);

            onUnmounted(() => {
                document.removeEventListener('keydown', focusShortcutKey);
            });
        }

        return { text, searchTextRef, placeholderText, isMacOS, inFocus, onKeyup, submit, clear };
    },
});
