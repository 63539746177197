import * as R from 'ramda';
import { ValidatorUtils } from './utils';

export const minLengthValidator = {
    message(field: string, args: any) {
        const min = R.is(Array, args) || '0' in args ? args[0] : args.length;
        return `${ValidatorUtils.prettifyName(field)} must have a length of at least ${min} characters`;
    },
    validate(value: any, args: any) {
        const min = R.is(Array, args) ? args[0] : args.length;
        if (R.isNil(value) || R.isEmpty(value) || !R.is(String, value) || value.length < min) {
            return false;
        }
        return true;
    },
};
