












import { PropType, computed, defineComponent, Ref, ref, watch } from '@vue/composition-api';
import { invertObj, isNil } from 'ramda';
import apexchart from 'vue-apexcharts';
import { useAxios } from '@/app/composable';
import { MetricsAPI } from '../api';
import { Asset } from '../types';
import { AssetType, AssetTypeId } from '../constants';
import { XCircleIcon, LightBulbIcon } from '@vue-hero-icons/solid';
import { ApexOptions } from 'apexcharts';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AssetMetricsQuery } from '../types/asset-metrics-query.type';
import { AxiosResponse } from 'axios';
import { SelfBuildingSquareSpinner } from 'epic-spinners';
import { Component } from 'vue/types/options';
import GraphError from './GraphError.vue';
import { useGraphUtils } from '../composable/graph-utils';

dayjs.extend(utc);

export default defineComponent({
    name: 'TimelinessGraph',
    props: {
        asset: { type: Object as PropType<Asset>, required: true },
        query: { type: Object as PropType<AssetMetricsQuery>, required: true },
        height: { type: String, default: '300px' },
    },
    components: { apexchart, SelfBuildingSquareSpinner, GraphError },
    setup(props) {
        const error: Ref<{ message: string; tooltip: string; icon: Component; classes: string } | undefined> = ref();
        const { exec, loading } = useAxios(true);
        const { formatEpochBy } = useGraphUtils();

        const assetType: AssetType = invertObj(AssetTypeId)[props.asset.assetTypeId] as AssetType;

        const series: Ref<
            { name: string; data: { x: string; y: number; noMeasurement: boolean }[]; color?: string }[]
        > = ref([]);

        const options: Ref<ApexOptions> = computed(() => {
            return {
                chart: {
                    type: 'bar',
                    stacked: true,
                    zoom: { enabled: false },
                    animations: { enabled: false },
                },
                dataLabels: {
                    enabled: false,
                },
                yaxis: {
                    title: {
                        text: 'Timeliness (0-100)',
                    },
                    labels: {
                        formatter: (val: number, settings: { dataPointIndex: number; seriesIndex: number }) => {
                            if (series.value.length > settings.seriesIndex)
                                return !isNil(series.value[settings.seriesIndex].data[settings.dataPointIndex]) &&
                                    series.value[settings.seriesIndex].data[settings.dataPointIndex].noMeasurement
                                    ? `No measurement`
                                    : `${formatDecimals(val)}%`;
                            return `${formatDecimals(val)}%`;
                        },
                    },
                    max: 100,
                    tickAmount: 5,
                },
                xaxis: {
                    tickAmount: 'dataPoints',
                    type: 'category',
                    labels: {
                        rotate: -45,
                        formatter: (val: string): string | string[] => {
                            return formatEpochBy(val, props.query.granularity, true);
                        },
                    },
                    title: { text: 'Time' },
                },
                legend: {
                    position: 'top',
                },
            };
        });

        const refetch = () => {
            error.value = undefined;
            exec(MetricsAPI.getTimeliness(assetType, props.asset.id, props.query))
                .then(
                    (
                        res:
                            | AxiosResponse<{
                                  results: { fresh: number; stale: number; unknown: number; timestamp: string }[];
                              }>
                            | undefined,
                    ) => {
                        if (!res) return;
                        const freshData = [];
                        const staleData = [];
                        const unknownData = [];
                        for (let r = 0; r < res.data.results.length; r++) {
                            const { fresh, stale, unknown, timestamp } = res.data.results[r];
                            freshData.push({
                                x: timestamp,
                                y: (fresh || 0) * 100,
                                noMeasurement: isNil(fresh),
                            });
                            staleData.push({
                                x: timestamp,
                                y: (stale || 0) * 100,
                                noMeasurement: isNil(stale),
                            });
                            unknownData.push({
                                x: timestamp,
                                y: (unknown || 0) * 100,
                                noMeasurement: isNil(unknown),
                            });
                        }
                        series.value = [
                            {
                                name: 'Fresh',
                                data: freshData,
                                color: '#6da78b',
                            },
                            {
                                name: 'Stale',
                                data: staleData,
                                color: '#e54e42',
                            },
                            {
                                name: 'Unknown',
                                data: unknownData,
                                color: '#cbd5e0',
                            },
                        ];
                    },
                )
                .catch((e: any) => {
                    if (e?.request?.status === 413)
                        error.value = {
                            message: `Too many data points found with given time period and granularity`,
                            tooltip: `Change selected time period and granularity to reduce number of data points`,
                            icon: LightBulbIcon,
                            classes: 'text-warn-700',
                        };
                    else
                        error.value = {
                            message: `Unable to fetch Timeliness visualisation at this
                time...`,
                            tooltip: e?.response?.data?.message,
                            icon: XCircleIcon,
                            classes: `text-danger-700`,
                        };
                });
        };

        const formatDecimals = (value: number) => {
            return parseFloat(Number(value).toFixed(2));
        };

        watch(
            () => props.query,
            () => refetch(),
            { immediate: true, deep: true },
        );

        return { series, options, error, loading, formatDecimals };
    },
});
