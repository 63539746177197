
















































































import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline';
import { CollectionIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, inject, ref, watch } from '@vue/composition-api';
import Draggable from 'vuedraggable';
import ShortTypeBadge from '../ShortTypeBadge.vue';

const TreeNode = defineComponent({
    name: 'ConceptNode',
    props: {
        node: {
            type: Object,
            required: true,
        },
        selected: {
            type: Object,
            required: false,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledChildren: {
            type: Boolean,
            default: false,
        },
        expandable: {
            type: Boolean,
            default: true,
        },
        idField: {
            type: String,
            required: false,
        },
        draggable: {
            type: Boolean,
            default: true,
        },
        isRoot: {
            type: Boolean,
            default: true,
        },
    },
    components: { Draggable, ShortTypeBadge, ChevronRightIcon, ChevronDownIcon, CollectionIcon },
    setup(props, { emit }) {
        const dragging = inject('dragging', ref(false));
        const hasChildren = computed(() => props.node.children && props.node.children.length > 0);
        const isOpen = ref<boolean>(props.expanded);
        const isSelected = computed(() => props.selected && props.selected.uid === props.node.uid);
        const selectNode = (selected: any) => {
            emit('selected', isSelected.value ? null : selected);
        };
        const nodeClicked = (node: any, open: boolean) => {
            emit('node-clicked', node, open);
        };
        const open = () => {
            if (props.expandable) {
                if (hasChildren.value) {
                    isOpen.value = !isOpen.value;
                    selectNode(null);
                } else if (!props.isRoot) {
                    selectNode(props.node);
                }
            }
            nodeClicked(props.node.uid, isOpen.value);
        };

        const nodeType = computed(() => (props.node.type === 'object' ? 'concept' : props.node.type));

        watch(
            () => props.expanded,
            (newExpanded: boolean) => {
                isOpen.value = newExpanded;
            },
            { immediate: true },
        );

        return { selectNode, nodeClicked, hasChildren, isOpen, isSelected, open, dragging, nodeType };
    },
});

export default TreeNode;
