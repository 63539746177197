import * as R from 'ramda';
import { S } from '@/app/utilities';

export class QueryOperant {
    static readonly EQUALS = new QueryOperant('EQUALS', 'equals', '==');

    static readonly NOT_EQUALS = new QueryOperant('NOT_EQUALS', 'not equals', '!=', QueryOperant.EQUALS);

    static readonly CONTAINS = new QueryOperant('CONTAINS', 'contains');

    static readonly STARTS_WITH = new QueryOperant('STARTS_WITH', 'starts with');

    static readonly ENDS_WITH = new QueryOperant('ENDS_WITH', 'ends with');

    static readonly GREATER_THAN = new QueryOperant('GREATER_THAN', 'greater than', '>');

    static readonly GREATER_THAN_OR_EQUAL_TO = new QueryOperant(
        'GREATER_THAN_OR_EQUAL_TO',
        'greater than or equal to',
        '>=',
    );

    static readonly LESS_THAN = new QueryOperant('LESS_THAN', 'less than', '<');

    static readonly LESS_THAN_OR_EQUAL_TO = new QueryOperant('LESS_THAN_OR_EQUAL_TO', 'less than or equal to', '<=');

    readonly key: string;

    readonly name: string;

    readonly symbol: string;

    private readonly opposite: QueryOperant | null;

    private static operantBySymbol: Record<string, QueryOperant>;

    constructor(key: string, name: string, symbol: string | null = null, opposite: QueryOperant | null = null) {
        this.key = key;
        this.name = name;
        this.symbol = symbol || key;
        this.opposite = opposite;
    }

    public getOpposite() {
        if (!R.isNil(this.opposite)) {
            return this.opposite;
        }

        for (let qo = 0; qo < QueryOperant.all().length; qo++) {
            const operant = QueryOperant.all()[qo];
            if (operant.opposite && operant.opposite.key === this.key) {
                return operant;
            }
        }

        return null;
    }

    static all() {
        return [
            QueryOperant.EQUALS,
            QueryOperant.NOT_EQUALS,
            QueryOperant.CONTAINS,
            QueryOperant.STARTS_WITH,
            QueryOperant.ENDS_WITH,
            QueryOperant.GREATER_THAN,
            QueryOperant.GREATER_THAN_OR_EQUAL_TO,
            QueryOperant.LESS_THAN,
            QueryOperant.LESS_THAN_OR_EQUAL_TO,
        ];
    }

    static find(key: string) {
        for (let qo = 0; qo < QueryOperant.all().length; qo++) {
            const operant = QueryOperant.all()[qo];
            if (operant.key === key) {
                return operant;
            }
        }

        return null;
    }

    static findBySymbol(symbol: string) {
        if (!QueryOperant.operantBySymbol) {
            QueryOperant.operantBySymbol = {};
            for (let qo = 0; qo < QueryOperant.all().length; qo++) {
                const operant = QueryOperant.all()[qo];
                QueryOperant.operantBySymbol[operant.symbol] = operant;
            }
        }

        return S.has(symbol, QueryOperant.operantBySymbol) ? QueryOperant.operantBySymbol[symbol] : null;
    }
}
