import router from '@/app/router';
import store from '@/app/store';
import { useKeycloak } from '@/modules/auth/composables/keycloak';
import { Route } from 'vue-router';

export function useErrors(route: Route | null = null) {
    const { isEnabled, login } = useKeycloak();
    const redirectToLogin = () => {
        if (!store.getters.auth.isAuthenticated) return;
        store.commit.auth.CLEAR_USER();
        if (isEnabled()) {
            login(route);
        } else {
            router.push({ name: 'home' });
        }
    };

    const parseGQLError = (error: any): void => {
        const { graphQLErrors }: { graphQLErrors: any[] } = error;
        if (graphQLErrors)
            graphQLErrors.forEach(({ message }: { message: string }) => {
                if (message === 'Unauthorized') redirectToLogin();
            });
    };

    const checkGQLAuthentication = (error: any): void => {
        console.warn('checkGQLAuthentication is deprecated! Feel free to remove it from your code!');
    };

    return { redirectToLogin, checkGQLAuthentication, parseGQLError };
}
