import { AuthGuard } from '@/modules/auth/router/auth.guard';
import { OrganisationGuard } from '@/modules/auth/router/organisation.guard';
import multiguard from 'vue-router-multiguard';
import { LockGuard } from './lock.guard';

export default [
    {
        name: 'data-checkin-jobs',
        path: '/data-checkin-pipelines',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/DataCheckinPipelines.vue'),
        meta: { title: 'Data Check-in Pipelines' },
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'data-checkin-jobs:create',
        path: '/data-checkin-pipelines/create',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/ConfigurePipeline.vue'),
        meta: { title: 'Create Data Check-in Pipeline' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'data-checkin-jobs:monitoring',
        path: '/data-checkin-pipelines/monitoring',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/DataCheckinMonitoring.vue'),
        meta: { title: 'Data Check-in Pipelines Monitoring' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'data-checkin-jobs:clone',
        path: '/data-checkin-pipelines/:id/clone',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/ConfigurePipeline.vue'),
        meta: { title: 'Clone Data Check-in Pipeline' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
    {
        name: 'harvester',
        path: '/data-checkin-pipelines/:id/harvester',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/Harvester.vue'),
        meta: { title: 'Configure Harvester' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
    {
        name: 'mapping',
        path: '/data-checkin-pipelines/:id/mapping/:step?',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/Mapping.vue'),
        meta: { title: 'Configure Mapping' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
    {
        name: 'cleaning',
        path: '/data-checkin-pipelines/:id/cleaning',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/Cleaning.vue'),
        meta: { title: 'Configure Cleaning' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
    {
        name: 'anonymisation',
        path: '/data-checkin-pipelines/:id/anonymisation/:step?',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/Anonymisation.vue'),
        meta: { title: 'Configure Anonymisation' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
    {
        name: 'encryption',
        path: '/data-checkin-pipelines/:id/encryption/:step?',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/Encryption.vue'),
        meta: { title: 'Configure Encryption' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
    {
        name: 'loader',
        path: '/data-checkin-pipelines/:id/loader',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/Loader.vue'),
        meta: { title: 'Configure Loader' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
    {
        name: 'data-checkin-jobs:history',
        path: '/data-checkin-pipelines/:id/history',
        component: () =>
            import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/DataCheckinPipelineExecutionHistory.vue'),
        meta: { title: 'Execution History' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard]),
    },
    {
        name: 'data-checkin-jobs:edit',
        path: '/data-checkin-pipelines/:id',
        component: () => import(/* webpackChunkName: "apollo" */ '@/modules/apollo/views/ConfigurePipeline.vue'),
        meta: { title: 'Update Data Check-in Pipeline' },
        props: true,
        beforeEnter: multiguard([AuthGuard, OrganisationGuard, LockGuard]),
    },
];
